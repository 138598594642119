import React, { useEffect, useState } from "react";
import axios from "axios";

import { ArrowRight, X, Sparkle } from "lucide-react";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

const READY_ONLY_TOKEN = "Bearer patGWmykhzhxVC3Cg.822fcf2f596965015a91805d6b3cc5abe6406a603043195fffee879c089f3f61";
const URL =
  "https://api.airtable.com/v0/appKf3YSVfmdHGuOj/tblB0xpebTtRJpof8?maxRecords=1&sort%5B0%5D%5Bfield%5D=date&sort%5B0%5D%5Bdirection%5D=desc";
const headers = {
  Authorization: READY_ONLY_TOKEN,
};

const fetchLastAirtableRecordFields = async () => {
  try {
    const { data } = await axios.get(URL, { headers });

    if (data.records.length === 0) return {};

    return data.records[0].fields;
  } catch {
    return {};
  }
};

function ProductChangelogPopup() {
  const [newChangelog, setNewChangelog] = useState(null);

  useEffect(() => {
    fetchLastAirtableRecordFields().then((fields) => {
      const { code, link } = fields;

      if (!code) return;

      const currentCode = localStorage.getItem("LINKANA_CHANGELOG");

      if (currentCode === code) return;

      setNewChangelog({ link, code });
    });
  }, []);

  if (!newChangelog) return null;

  const onClosePopup = () => {
    setNewChangelog(null);
    localStorage.setItem("LINKANA_CHANGELOG", newChangelog.code);
  };

  return (
    <div className="flex flex-col gap-3 rounded-md border px-3 py-4 shadow-sm animate-in">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-1">
          <Text size="small" weight="medium">
            Novo na Linkana
          </Text>
          <Sparkle className="h-2 w-2 animate-ping text-primary" />
        </div>
        <button type="button" onClick={onClosePopup}>
          <X className="h-4 w-4 cursor-pointer text-zinc-400" />
        </button>
      </div>
      <div>
        <Anchor
          onClick={() => {
            window.open(newChangelog.link);
            onClosePopup();
          }}
        >
          <div className="flex flex-row items-center">
            Ver atualizações
            <ArrowRight className="h-4 w-4" />
          </div>
        </Anchor>
      </div>
    </div>
  );
}
export default ProductChangelogPopup;
