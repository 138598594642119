import React from "react";
import PropTypes from "prop-types";

import { Clock } from "lucide-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Text } from "DS/custom/text";

import BgPufMin from "Assets/companies/bg-puf-min.png";

import { INTERNATIONAL, NATIONAL_PF, NATIONAL_PJ } from "Config/LegalEntity";
import formatDate from "Services/DateToString";

const UNAVAILABLE_FOR = "Perfil Universal indisponível para";

const MESSAGES = {
  [NATIONAL_PJ]: `${UNAVAILABLE_FOR} empresa nova na base da RFB.`,
  [INTERNATIONAL]: `${UNAVAILABLE_FOR} fornecedor internacional.`,
  [NATIONAL_PF]: `${UNAVAILABLE_FOR} fornecedor pessoa física.`,
};

/** @internal */
export const QUERY = gql`
  query UnavailablePanel {
    rfUpdatedAt
  }
`;

function UnavailablePanel({ legalEntity }) {
  const { data, loading } = useQuery(QUERY);

  if (loading) return null;

  return (
    <div className="relative -mb-20 max-h-96 overflow-hidden lg:max-h-[70vh]">
      <img src={BgPufMin} alt="Background" className="h-full w-full blur" />

      <div className="absolute inset-0 flex items-center justify-center">
        <div className="flex max-w-2xl flex-col items-center gap-1 rounded-lg border bg-white p-6">
          <div className="mb-3 rounded-md border p-2">
            <Clock className="h-5 w-5" />
          </div>

          <Text size="small" weight="medium" className="text-center">
            {MESSAGES[legalEntity]}
          </Text>

          {legalEntity === NATIONAL_PJ ? (
            <Text size="small" weight="medium" className="text-center">
              A última atualização mensal da base foi no dia <b>{formatDate(data.rfUpdatedAt)}</b>.
            </Text>
          ) : null}
        </div>
      </div>
    </div>
  );
}

UnavailablePanel.propTypes = { legalEntity: PropTypes.string.isRequired };

export default UnavailablePanel;
