import React from "react";
import PropTypes from "prop-types";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "DS/ui/table";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

import EmptyList from "Assets/settings/shared/EmptyList.png";

import formatDate from "Services/DateToString";

function PerformanceFormsTable({ performanceForms }) {
  if (performanceForms.length === 0)
    return (
      <div className="flex flex-col items-center justify-center gap-4">
        <img alt="Empty List" src={EmptyList} className="w-32" />
        <Text>Nenhum formulário de performance encontrado...</Text>
      </div>
    );

  return (
    <div className="rounded-md border border-slate-200">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">
              <Text size="small" weight="medium">
                Formulário de performance
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Data de criação
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Última atualização
              </Text>
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {performanceForms.map((performanceForm) => (
            <TableRow key={performanceForm.id}>
              <TableCell className="p-4">
                <Anchor to={performanceForm.id}>{performanceForm.displayName}</Anchor>
              </TableCell>

              <TableCell>
                <Text size="small">{formatDate(performanceForm.createdAt)}</Text>
              </TableCell>

              <TableCell>
                <Text size="small">{formatDate(performanceForm.updatedAt)}</Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

PerformanceFormsTable.propTypes = {
  performanceForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ).isRequired,
};

export default PerformanceFormsTable;
