import * as React from "react";
import PropTypes from "prop-types";

import { Tabs, TabsList as TabsListBase, TabsTrigger as TabsTriggerBase, TabsContent } from "../ui/tabs";

import { cn } from "../lib/utils.ts";

function TabsList({ className, ...props }) {
  return (
    <TabsListBase
      className={cn("h-9 flex-wrap-reverse justify-start rounded-none bg-transparent p-0", className)}
      {...props}
    />
  );
}

TabsList.defaultProps = {
  className: null,
};

TabsList.propTypes = {
  className: PropTypes.string,
};

function TabsTrigger({ className, ...props }) {
  return (
    <TabsTriggerBase
      className={cn(
        "-mb-1 rounded-none border-b-2 border-b-transparent data-[state=active]:border-b-foreground data-[state=active]:bg-transparent data-[state=active]:text-foreground data-[state=active]:shadow-none",
        className,
      )}
      {...props}
    />
  );
}

TabsTrigger.defaultProps = {
  className: null,
};

TabsTrigger.propTypes = {
  className: PropTypes.string,
};

export { Tabs, TabsList, TabsTrigger, TabsContent };
