import * as React from "react";
import PropTypes from "prop-types";

import { cva } from "class-variance-authority";
import { cn } from "../lib/utils.ts";

const textVariants = cva("", {
  variants: {
    size: {
      xsmall: "text-[10px] md:text-xs",
      small: "text-xs md:text-sm",
      base: "text-sm md:text-base",
    },
    weight: {
      normal: "font-normal",
      medium: "font-medium",
    },
    color: {
      title: "text-slate-900 dark:text-slate-50",
      primary: "text-slate-800 dark:text-slate-100",
      secondary: "text-slate-700 dark:text-slate-200",
      disabled: "text-slate-500 dark:text-slate-400",
    },
  },
  defaultVariants: {
    size: "base",
    weight: "normal",
    color: "primary",
  },
});

const Text = React.forwardRef(({ className, size, weight, color, as = "p", ...props }, ref) => {
  const Comp = as === "" ? "p" : as;

  return <Comp className={cn(textVariants({ size, weight, color, className }))} ref={ref} {...props} />;
});

Text.defaultProps = {
  className: null,
  size: "base",
  weight: "normal",
  color: "primary",
  as: "p",
};

Text.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  as: PropTypes.string,
};

export { Text, textVariants };
