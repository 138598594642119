import React from "react";
import { Box } from "grommet";

import Spinning from "Molecules/Spinner/Spinning";
import styled from "styled-components";

const StackStyled = styled(Box)`
  padding: 100px;
  justify-content: center;
  overflow: hidden;
  max-width: max-content;
  width: max-content;
  margin: 0 auto;
`;

function GlobalLoading() {
  return (
    <StackStyled align="center" justify="center" alignContent="center" direction="column">
      <Box data-testid="global-loading">{Spinning}</Box>
    </StackStyled>
  );
}

export default GlobalLoading;
