/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";

import { Checkbox } from "DS/ui/checkbox";
import { Anchor } from "DS/custom/anchor";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";

import { CSVBoxButton } from "@csvbox/react";

import { RESTRICTION_LIST_PEOPLE_TEMPLATE, RESTRICTION_LIST_PEOPLE_LICENSE_KEY } from "Config/Csvbox";

import DeleteRestrictionListPeople from "Organisms/UBuyer/Settings/Advanced/DeleteRestrictionListPeople";

function RestrictionListPeople({ buyer, disabled }) {
  const [checked, setChecked] = useState(false);
  const [csvboxResponse, setCsvboxResponse] = useState({});

  const onImport = (success, result) => {
    if (result.custom_fields.component !== "RestrictionListPeople") return;

    const message = success
      ? "O upload foi feito com sucesso! Você já pode salvar a configuração."
      : "Houve um erro ao processar seu arquivo. Por favor, atualize a página e tente novamente";

    setCsvboxResponse({ message, success });
  };

  return (
    <div className="space-y-2">
      <div className="space-y-1">
        <Text size="small" weight="medium">
          Lista de restrições - Pessoas físicas
        </Text>

        <Text size="small" className="max-w-lg" color="secondary">
          Suba a base de até 50.000 <b>pessoas físicas</b> relacionadas a sua empresa para cruzar com informações da sua
          base de fornecedores e partes relacionadas.
        </Text>
        <Anchor target="_blank" href={RESTRICTION_LIST_PEOPLE_TEMPLATE}>
          Download do template para preenchimento
        </Anchor>
      </div>

      {buyer.restrictionListPersonCreatedAt && !disabled ? (
        <DeleteRestrictionListPeople buyer={buyer} />
      ) : (
        <>
          <div className="items-top flex space-x-2">
            <Checkbox id="agreement" onCheckedChange={(agreed) => setChecked(agreed)} disabled={disabled} />

            <label className="max-w-lg" htmlFor="agreement">
              <Text size="small" color="secondary">
                Eu concordo, e assumo a responsabilidade, que todas as as pessoas que eu estou subindo nesta importação
                me deram autorização prévia, e consentimento, para utilizar seus dados de documentos na verificação de
                possíveis conflitos de interesses deles com algum sócio da empresa, de acordo com a Lei Geral de
                Proteção de Dados Pessoais (LGPD), Lei n° 13.709/2018.
              </Text>
            </label>
          </div>

          <CSVBoxButton
            id="PEOPLE"
            key="PEOPLE"
            onImport={onImport}
            licenseKey={RESTRICTION_LIST_PEOPLE_LICENSE_KEY}
            user={{ buyerId: buyer.id, component: "RestrictionListPeople" }}
            render={(launch, loading) => (
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  launch();
                }}
                disabled={disabled || csvboxResponse.success || !checked || loading}
              >
                Fazer upload
              </Button>
            )}
          />
        </>
      )}

      <Text size="small" weight="medium">
        {csvboxResponse.message}
      </Text>
    </div>
  );
}

RestrictionListPeople.propTypes = {
  disabled: PropTypes.bool.isRequired,
  buyer: PropTypes.shape({
    id: PropTypes.string,
    restrictionListPersonCreatedAt: PropTypes.string,
  }).isRequired,
};

export default RestrictionListPeople;
