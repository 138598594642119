import React from "react";
import { createRoot } from "react-dom/client";
import { Grommet } from "grommet";

import { ApolloProvider } from "@apollo/client";

import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";

import Theme from "Config/Theme";
import apolloClient from "Config/GraphqlApiService";

import { Toaster } from "DS/ui/toaster";
import FallbackComponent from "Config/FallbackComponent";

import Routes from "app/javascript/App/Routes";

function App() {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <ApolloProvider client={apolloClient}>
        <Toaster />

        <BrowserRouter>
          <Grommet full theme={Theme} plain>
            <Routes />
          </Grommet>
        </BrowserRouter>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

const container = document.getElementById("App");
const root = createRoot(container);

root.render(<App />);
