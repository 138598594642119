import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Trash, Trash2 } from "lucide-react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Button } from "DS/ui/button";

import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogDescription,
} from "DS/ui/alert-dialog";

/** @internal */
export const MUTATION = gql`
  mutation DestroySupplierAlertDialog($id: ID!) {
    destroySupplier(input: { id: $id }) {
      errors {
        message
        path
      }
    }
  }
`;

function DestroySupplierAlertDialog({ dropdownMenuItem }) {
  const { id } = useParams();
  const [destroySupplier, { loading }] = useMutation(MUTATION);

  const onSubmit = async () => {
    await destroySupplier({ variables: { id } });
    window.location.assign("/companies");
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        {dropdownMenuItem ? (
          <Button type="button" variant="ghost" className="p-4 text-destructive hover:text-destructive">
            <Trash2 className="mr-2 h-4 w-4 text-destructive" />
            Excluir homologação
          </Button>
        ) : (
          <Button type="button" variant="destructive">
            <Trash className="mr-2 h-5 w-5" /> Excluir homologação
          </Button>
        )}
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Tem certeza que deseja excluir essa homologação?</AlertDialogTitle>
          <AlertDialogDescription>
            Ao excluir essa homologação, todas as informações relacionadas serão permanentemente removidas e não poderão
            ser recuperadas.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button type="button" onClick={onSubmit} disabled={loading} variant="destructive">
            Excluir homologação
          </Button>

          <AlertDialogCancel asChild>
            <Button type="button" variant="outline">
              Cancelar
            </Button>
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

DestroySupplierAlertDialog.defaultProps = { dropdownMenuItem: false };

DestroySupplierAlertDialog.propTypes = { dropdownMenuItem: PropTypes.bool };

export default DestroySupplierAlertDialog;
