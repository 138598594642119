import React from "react";
import PropTypes from "prop-types";

const getRatingColor = (rating) => {
  if (rating === 100) return "bg-green-400";
  if (rating >= 81) return "bg-green-600";
  if (rating >= 41) return "bg-yellow-600";
  if (rating >= 1) return "bg-orange-600";
  return "bg-red-600";
};

function RatingIcon({ ratingLabel, rating }) {
  const bgColor = getRatingColor(rating);

  return (
    <div className={`h-7 w-7 flex-none rounded-full text-center text-base leading-7 text-white ${bgColor}`}>
      {ratingLabel}
    </div>
  );
}

RatingIcon.propTypes = {
  ratingLabel: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
};

export default RatingIcon;
