import React from "react";
import PropTypes from "prop-types";

import { Box, Button, Layer, Text } from "grommet";
import { X } from "lucide-react";

const DIVIDER = { color: "gray-200", size: "small", side: "bottom" };

function RightSideLayer({ children, header, subHeader, actions, onClose, width }) {
  const margin = subHeader ? "none" : { bottom: "xsmall" };

  return (
    <Layer position="right" full="vertical" onClickOutside={onClose} onEsc={onClose}>
      <Box fill="vertical" alignSelf="center" overflow="auto" width={width} pad="medium">
        <Button a11yTitle="closeLayer" icon={<X size={16} />} onClick={onClose} alignSelf="end" />

        <Box flex={false} border={DIVIDER} margin={{ top: "none", bottom: "xsmall" }}>
          <Box direction="row" align="center">
            <Box fill="horizontal">
              <Text weight={500} size="large" color="light-0" margin={margin}>
                {header}
              </Text>

              {subHeader ? <Box margin={{ vertical: "xsmall" }}>{subHeader}</Box> : null}
            </Box>

            {actions ? <Box align="center">{actions}</Box> : null}
          </Box>
        </Box>

        <Box flex={false} margin={{ vertical: "small" }}>
          <Box margin={{ bottom: "medium" }}>{children}</Box>
        </Box>
      </Box>
    </Layer>
  );
}

RightSideLayer.defaultProps = {
  width: "500px",
  subHeader: null,
  actions: null,
};

RightSideLayer.propTypes = {
  header: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  subHeader: PropTypes.node,
  actions: PropTypes.node,
  width: PropTypes.string,
};

export default RightSideLayer;
