import React from "react";
import PropTypes from "prop-types";

import { RadioGroup, RadioGroupItem } from "DS/ui/radio-group";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

const COMMENT_REQUIRED_OPTIONS = [
  { key: "Não", value: false },
  { key: "Sim", value: true },
];

function PerformanceFormFormBase({ form }) {
  return (
    <div className="gap-x-8 gap-y-4 border-b pb-5 lg:grid lg:grid-cols-[286px_1fr]">
      <div className="flex w-72 flex-col gap-1">
        <Text weight="medium">Formulário</Text>

        <Text>
          Este é o nome de exibição do formulário. A pessoa que responder ao formulário também verá este nome.
        </Text>
      </div>

      <div className="flex flex-col gap-3">
        <FormField
          name="displayName"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-80">
              <div className="mt-1 flex flex-col gap-2">
                <FormLabel htmlFor="displayName">Nome</FormLabel>

                <FormControl>
                  <Input {...field} id="displayName" placeholder="Digite o nome do formulário" />
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="commentRequired"
          control={form.control}
          render={({ field }) => (
            <FormItem className="space-y-0">
              <FormLabel>É obrigatório preencher o comentário dos pilares?</FormLabel>

              <FormControl>
                <RadioGroup defaultValue={field.value} onValueChange={field.onChange} className="flex flex-row gap-3">
                  {COMMENT_REQUIRED_OPTIONS.map(({ key, value }) => (
                    <div key={key}>
                      <FormItem className="flex flex-row items-end">
                        <FormControl>
                          <RadioGroupItem value={value} />
                        </FormControl>

                        <FormLabel className="pl-2">{key}</FormLabel>
                      </FormItem>
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
}

PerformanceFormFormBase.propTypes = {
  form: PropTypes.shape({ control: PropTypes.shape() }).isRequired,
};

export default PerformanceFormFormBase;
