import React from "react";
import { Route, Routes } from "react-router-dom";

import FormNew from "Organisms/UBuyer/Settings/Forms/FormNew";
import FormInfo from "Organisms/UBuyer/Settings/Forms/FormInfo";
import FormEdit from "Organisms/UBuyer/Settings/Forms/FormEdit";
import FormsList from "Organisms/UBuyer/Settings/Forms/FormsList";
import FormsListReorder from "Organisms/UBuyer/Settings/Forms/FormsListReorder";

function Forms() {
  return (
    <Routes>
      <Route path="" element={<FormsList />} />
      <Route path="reorder" element={<FormsListReorder />} />
      <Route path="new" element={<FormNew />} />
      <Route path=":settingFormId" element={<FormInfo />} />
      <Route path=":settingFormId/edit" element={<FormEdit />} />
    </Routes>
  );
}

export default Forms;
