import React from "react";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

import ESGRating from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/ESGRating";
import ESGRatingBar from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/ESGRatingBar";

const ESG_URL = "https://suporte.linkana.com/pt-BR/articles/6612125-linkana-esg-rating";

function ESG() {
  return (
    <div className="mb-5 flex flex-row flex-wrap gap-x-8 gap-y-3">
      <div className="flex w-72 flex-col gap-1">
        <Text weight="medium">Análise ESG</Text>

        <Text size="small" color="secondary">
          Classificação dos riscos ambientais, sociais e de governança do CNPJ.
        </Text>

        <Anchor onClick={() => window.open(ESG_URL)}>Saiba mais</Anchor>
      </div>

      <div>
        <div className="flex flex-row flex-wrap gap-x-8 gap-y-4 pb-4">
          <div className="mb-3 lg:mb-0">
            <Text weight="medium" color="title">
              ESG Rating
            </Text>

            <Text size="xsmall" color="secondary">
              powered by Linkana
            </Text>
          </div>

          <ESGRatingBar />
        </div>

        <ESGRating />
      </div>
    </div>
  );
}

export default ESG;
