import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";
import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import { Card, CardContent } from "DS/custom/card";
import { Anchor } from "DS/custom/anchor";
import { Badge } from "DS/custom/badge";

import { ACTIVE } from "Config/PUF";
import { formatCnpj, formatCnae } from "Services/RFFormatter";

/** @internal */
export const QUERY = gql`
  query HeadquarterCard($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      branch {
        id
        cnpj
        numero
        situacao
        cnaeFiscal
        logradouro
        complemento
        cnaeFiscalTexto
        descTipoLogradouro
      }
    }
  }
`;

const SKELETON = (
  <div>
    <Skeleton className="mb-2 h-4 w-52" />
    <Skeleton className="my-2 h-4 w-36" />
    <div className="flex flex-wrap items-center gap-4">
      <Skeleton className="h-24 w-96" repeat={6} />
    </div>
  </div>
);

function HeadquarterCard() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { branch } = data.supplier;

  return (
    <div className="flex flex-col gap-2">
      <Heading>Matriz</Heading>

      <Card className="w-fit max-w-full">
        <CardContent className="gap-2">
          <div className="flex flex-row gap-2.5">
            <Anchor onClick={() => window.open(`/puf/${branch.cnpj}`)}>{formatCnpj(branch.cnpj)}</Anchor>

            <Badge variant={branch.situacao === ACTIVE ? "green" : "red"}>{branch.situacao}</Badge>
          </div>

          <Text size="small">
            {formatCnae(branch.cnaeFiscal)} - {branch.cnaeFiscalTexto}
          </Text>

          <Text size="small">
            {branch.descTipoLogradouro} {branch.logradouro} {branch.numero} {branch.complemento}
          </Text>
        </CardContent>
      </Card>
    </div>
  );
}

export default HeadquarterCard;
