import React from "react";
import { useOutletContext } from "react-router-dom";

import { AlertCircle } from "lucide-react";

import { useCubeQuery } from "@cubejs-client/react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "DS/ui/accordion";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

import { infoPillar, SKELETON_DETAILS } from "Config/PUF";
import formatCubeInfo from "Services/PUFFormatter";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";

/** @internal */
export const accordionName = (rating) => {
  if (rating === 5) return "Constituída há menos de 03 anos";

  return rating ? "Constituída há mais de 03 anos" : "Constituída há menos de 01 ano";
};

function FiscalActingTimeDetails() {
  const { identifier } = useOutletContext();

  const info = "FiscalActingTime.info";
  const member = "FiscalActingTime.cnpj";
  const rating = "FiscalActingTime.fiscal_item_acting_time";

  const { resultSet } = useCubeQuery({
    dimensions: [info, rating],
    filters: [{ member, operator: "equals", values: [identifier] }],
  });

  if (!resultSet) return SKELETON_DETAILS;

  const data = resultSet.rawData();
  const cube = data[0] || {};

  const { variant, text } = infoPillar(cube[rating]);

  return (
    <div>
      <Breadcrumb firstPage="Análise Linkana" lastPage="Tempo de atuação" currentPage="Detalhes" />

      <div className="flex-start mt-4 flex flex-wrap content-start justify-start gap-8 py-4 lg:flex-nowrap">
        <div className="flex max-w-[349px] shrink-0 flex-col gap-2 self-start rounded-lg border border-slate-200 p-6">
          <div className="flex flex-row gap-4 ">
            <Text size="small" weight="medium">
              Tempo de atuação
            </Text>

            <Badge variant={variant}>{text}</Badge>
          </div>

          <Text size="small">
            Avaliar a estabilidade financeira da empresa considerando o tempo de existência de sua matriz.
          </Text>
        </div>

        <div className="flex-start flex w-full flex-col">
          {data.length ? (
            <Accordion collapsible defaultValue="info-0">
              <AccordionItem value="info-0">
                <AccordionTrigger>
                  <Text size="small" weight="medium">
                    {accordionName(cube[rating])}
                  </Text>
                </AccordionTrigger>

                <AccordionContent>
                  <Text size="small" weight="medium">
                    {formatCubeInfo(cube[info])}
                  </Text>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ) : (
            <div className="flex h-full flex-row items-center gap-2">
              <AlertCircle size={26} />

              <Text size="small">Não identificamos a data de aberutra da matriz.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FiscalActingTimeDetails;
