export const ANSWERED = "ANSWERED";

export const labelAndColor = (score) => {
  if (score === null) return { label: "Não se aplica", color: "light-4" };

  const label = score.toString();

  if (score === 100) return { label, color: "status-ok" };

  if (score >= 75) return { label, color: "#76DBB7" };

  if (score >= 50) return { label, color: "accent-4" };

  if (score >= 25) return { label, color: "status-critical" };

  return { label, color: "#CC0909" };
};

export const ANSWERS_LABELS = {
  0: "Péssimo",
  25: "Ruim",
  33: "Ruim",
  50: "Regular",
  66: "Bom",
  75: "Bom",
  100: "Excelente",
  [null]: "Não se aplica",
};
