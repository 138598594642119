import React from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { useToast } from "DS/ui/use-toast";

import { parseFields } from "Config/SettingForm";

import FormEditForm from "Organisms/UBuyer/Settings/Forms/FormEditForm";

/** @internal */
export const QUERY = gql`
  query FormEdit($buyerId: ID!, $settingFormId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingForm(id: $settingFormId) {
        id
        name
        hint
        fields
        expirable
        displayName
        settingGrouperId
        createdByTemplate
        triggerWebhookAfterExpiredReview
        settingGrouper {
          id
          displayName
        }
      }
    }
  }
`;

/** @internal */
export const MUTATION = gql`
  mutation FormEdit(
    $hint: String
    $fields: JSON!
    $settingFormId: ID!
    $expirable: Boolean!
    $displayName: String!
    $settingGrouperId: ID!
    $triggerWebhookAfterExpiredReview: Boolean!
  ) {
    updateSettingForm(
      input: {
        hint: $hint
        fields: $fields
        expirable: $expirable
        displayName: $displayName
        settingFormId: $settingFormId
        settingGrouperId: $settingGrouperId
        triggerWebhookAfterExpiredReview: $triggerWebhookAfterExpiredReview
      }
    ) {
      settingForm {
        id
        name
        hint
        fields
        expirable
        displayName
        settingGrouperId
        triggerWebhookAfterExpiredReview
        settingGrouper {
          id
          displayName
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

function FormEdit() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { settingFormId, buyerId } = useParams();

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId, settingFormId },
  });

  const [updateSettingForm, { loading: submitting }] = useMutation(MUTATION);

  if (loading) return <GlobalLoading />;

  const { settingForm } = data.buyer;

  const onSubmit = async (values) => {
    const fields = parseFields(values.fields);
    const variables = { ...values, fields, settingFormId };
    const { data: mutationData } = await updateSettingForm({ variables });
    const { errors } = mutationData.updateSettingForm;

    if (errors.length) {
      toast({
        variant: "destructive",
        description: errors[0].message,
        title: "Erro ao criar o formulário",
      });
    } else {
      toast({ title: "Formulário editado com sucesso" });
      navigate("./..");
    }
  };

  if (!settingForm) return <Navigate to="../forms" />;

  const fields = settingForm.fields.map((field) => {
    const options = field.options?.map((displayName) => ({ displayName }));
    return { ...field, options };
  });

  const initialValues = {
    ...settingForm,
    hint: settingForm.hint || "",
    fields,
  };

  return <FormEditForm onSubmit={onSubmit} submitting={submitting} formInitialValues={initialValues} />;
}

export default FormEdit;
