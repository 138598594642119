import React from "react";
import { PatternFormat } from "react-number-format";
import { Input } from "@/DS/ui/input.tsx";

const MaskedInput = React.forwardRef(({ ...rest }, ref) => (
  <PatternFormat {...rest} getInputRef={ref} customInput={Input} />
));

export default MaskedInput;

export { MaskedInput };
