import React from "react";
import { useOutletContext } from "react-router-dom";

import { AlertCircle } from "lucide-react";

import { useCubeQuery } from "@cubejs-client/react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "DS/ui/accordion";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

import { setBadgeTextColorPending, SKELETON_DETAILS } from "Config/PUF";
import formatCubeInfo from "Services/PUFFormatter";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";

function FiscalBankruptDetails() {
  const { identifier } = useOutletContext();

  const info = "FiscalBankrupt.info";
  const member = "FiscalBankrupt.cnpj";

  const { resultSet } = useCubeQuery({
    dimensions: [info],
    filters: [{ member, values: [identifier], operator: "equals" }],
  });

  if (!resultSet) return SKELETON_DETAILS;

  const data = resultSet.rawData();
  const cube = data[0] || {};

  const { variant, text } = setBadgeTextColorPending(data.length);

  return (
    <div>
      <Breadcrumb firstPage="Análise Linkana" lastPage="Situação falimentar" currentPage="Detalhes" />

      <div className="flex-start mt-4 flex flex-wrap content-start justify-start gap-8 py-4 lg:flex-nowrap">
        <div className="flex max-w-[349px] shrink-0 flex-col gap-2 self-start rounded-lg border border-slate-200 p-6">
          <div className="flex flex-row gap-4 ">
            <Text size="small" weight="medium">
              Situação falimentar
            </Text>

            <Badge variant={variant}>{text}</Badge>
          </div>

          <Text size="small">
            Avaliar a condição de falência, liquidação ou recuperação judicial da empresa a partir de sua razão social.
          </Text>
        </div>

        <div className="flex-start flex w-full flex-col">
          {data.length ? (
            <Accordion collapsible defaultValue="info-0">
              <AccordionItem value="info-0">
                <AccordionTrigger>
                  <Text size="small" weight="medium">
                    Falência, recuperação judicial ou liquidação
                  </Text>
                </AccordionTrigger>

                <AccordionContent>
                  <Text size="small" weight="medium">
                    {formatCubeInfo(cube[info])}
                  </Text>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ) : (
            <div className="flex h-full flex-row items-center gap-2">
              <AlertCircle size={26} />

              <Text size="small">
                Não identificamos situação de falência, recuperação judicial ou liquidação na empresa
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FiscalBankruptDetails;
