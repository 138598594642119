import * as React from "react";
import PropTypes from "prop-types";

import { cva } from "class-variance-authority";

import { cn } from "../lib/utils.ts";

const badgeVariants = cva("inline-flex items-center border rounded-md px-2.5 py-0.5 text-xs font-medium", {
  variants: {
    variant: {
      primary: "bg-blue-50 border-blue-100 text-primary",
      secondary: "bg-slate-50 border-slate-100 text-slate-700",
      red: "bg-red-50 border-red-100 text-red-700",
      green: "bg-green-50 border-green-100 text-green-700",
      yellow: "bg-yellow-50 border-yellow-100 text-yellow-700",
      orange: "bg-orange-50 border-orange-100 text-orange-700",
      transparent: "bg-transparent border-slate-100 text-slate-700",
      neutral: "text-neutral-500 bg-neutral-500/10 ring-neutral-500/20",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

function Badge({ className, variant, ...props }) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

Badge.defaultProps = {
  className: null,
  variant: "primary",
};

Badge.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};

export { Badge, badgeVariants };
