import React from "react";
import PropTypes from "prop-types";

import { PlusCircle } from "lucide-react";

import { useFieldArray } from "react-hook-form";

import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

import FormSortableFields from "Organisms/UBuyer/Settings/Forms/FormSortableFields";
import FormFieldsEmptyState from "Organisms/UBuyer/Settings/Forms/FormFieldsEmptyState";

/** @internal */
export const duplicateField = (field) => ({ ...field, name: undefined, display_name: `${field.display_name} (cópia)` });

const CREATED_BY_TEMPLATE_MESSAGE = "Não é possível criar perguntas em campos padrões";

function FormQuestionsFormFields({ form, onEditFormField, createdByTemplate }) {
  const { remove, append, swap } = useFieldArray({ control: form.control, name: "fields" });
  const { fields } = form.getValues();

  const onDelete = (fieldIndex) => remove(fieldIndex);
  const onReorder = (oldIndex, newIndex) => swap(oldIndex, newIndex);
  const onDuplicate = (fieldIndex) => append(duplicateField(fields[fieldIndex]));

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between gap-8">
        <div>
          <Heading>Perguntas do documento</Heading>

          <Text size="small" color="secondary">
            Perguntas que terão que ser respondidas para envio deste documento.
          </Text>
        </div>

        <WrappedTooltip message={createdByTemplate ? CREATED_BY_TEMPLATE_MESSAGE : ""}>
          <Button
            type="button"
            variant="outline"
            className="w-fit"
            disabled={createdByTemplate}
            onClick={() => onEditFormField()}
          >
            <PlusCircle className="mr-2 text-zinc-900" />
            Adicionar pergunta
          </Button>
        </WrappedTooltip>
      </div>

      <div className="flex flex-col gap-2">
        {fields.length === 0 ? (
          <FormFieldsEmptyState onCreateFormField={() => onEditFormField()} />
        ) : (
          <div className="flex flex-col gap-2">
            <FormSortableFields
              fields={fields}
              onDelete={onDelete}
              onReorder={onReorder}
              onEdit={onEditFormField}
              onDuplicate={onDuplicate}
              createdByTemplate={createdByTemplate}
            />
          </div>
        )}
      </div>
      <div />
    </div>
  );
}

FormQuestionsFormFields.defaultProps = { createdByTemplate: false };

FormQuestionsFormFields.propTypes = {
  createdByTemplate: PropTypes.bool,
  onEditFormField: PropTypes.func.isRequired,
  form: PropTypes.shape({ control: PropTypes.shape(), watch: PropTypes.func, getValues: PropTypes.func }).isRequired,
};

export default FormQuestionsFormFields;
