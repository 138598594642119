import React from "react";
import { useParams, useLocation, Outlet, Navigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";

import { useToast } from "DS/ui/use-toast";

import SupplierSimpleHeader, {
  SKELETON as SUPPLIER_SIMPLE_HEADER_SKELETON,
} from "Organisms/UBuyer/Shared/SupplierSimpleHeader/SupplierSimpleHeader";

/** @internal */
export const QUERY = gql`
  query SupplierLayout($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      pendingPayment
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col overflow-auto">
    {SUPPLIER_SIMPLE_HEADER_SKELETON}
    <Skeleton className="ml-6 h-6 w-3/4" repeat={2} />
    <Skeleton className="ml-6 h-6 w-1/3" repeat={3} />
    <Skeleton className="ml-6 h-6 w-1/2" repeat={2} />
  </div>
);

function SupplierLayout() {
  const { id } = useParams();
  const { toast } = useToast();
  const { state, pathname } = useLocation();

  const { data, loading } = useQuery(QUERY, {
    variables: { id },
    onCompleted: () => {
      const { newRecord } = state || {};
      if (newRecord) toast({ title: "Processo iniciado com sucesso!" });
    },
  });

  if (loading) return SKELETON;

  const { pendingPayment } = data.supplier;

  if (pendingPayment && !/panel$/.test(pathname)) return <Navigate to={`/companies/${id}/panel`} replace />;

  return (
    <div className="flex flex-col overflow-auto">
      <SupplierSimpleHeader />

      <div className="max-w-7xl p-4 lg:p-10 lg:pb-20">
        <Outlet />
      </div>
    </div>
  );
}

export default SupplierLayout;
