import React from "react";
import { useParams, Outlet } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";

import LayoutTabs from "Organisms/UBuyer/Companies/PUF/LayoutTabs";
import LayoutSelect from "Organisms/UBuyer/Companies/PUF/LayoutSelect";
import UnavailablePanel from "Organisms/UBuyer/Companies/PUF/UnavailablePanel";

/** @internal */
export const QUERY = gql`
  query Layout($id: ID!) {
    rfUpdatedAt
    supplier: supplierById(id: $id) {
      id
      identifier
      legalEntity
      branch {
        id
      }
    }
  }
`;

const ROUTES = [
  { key: "info-cnpj", value: "Dados CNPJ" },
  { key: "analysis", value: "Análise Linkana" },
];

const SKELETON = (
  <div className="flex flex-col gap-4">
    <div className="flex flex-row items-center justify-between gap-4">
      <Skeleton className="h-10 w-96" />
      <Skeleton className="h-14 w-96" />
    </div>
    <Skeleton className="h-60 w-full" />
    <Skeleton className="h-36 w-1/2" />
    <Skeleton className="h-36 w-3/4" />
  </div>
);

function Layout() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { rfUpdatedAt } = data;
  const { identifier, legalEntity, branch } = data.supplier;

  if (!branch) return <UnavailablePanel legalEntity={legalEntity} />;

  return (
    <div>
      <LayoutTabs routes={ROUTES} rfUpdatedAt={rfUpdatedAt} />
      <LayoutSelect routes={ROUTES} rfUpdatedAt={rfUpdatedAt} />

      <Outlet context={{ identifier }} />
    </div>
  );
}

export default Layout;
