import React from "react";
import PropTypes from "prop-types";

import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "DS/ui/alert";

import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
} from "DS/ui/alert-dialog";

import { Anchor } from "DS/custom/anchor";

function CategoryEditConfirmationDialog({ onConfirm, onClose, activeSuppliersCount }) {
  const onClick = async () => {
    onConfirm();
    onClose();
  };

  const supplierText = activeSuppliersCount === 1 ? "fornecedor impactado" : "fornecedores impactados";

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Tem certeza que deseja salvar e sincronizar a categoria?</AlertDialogTitle>
          <AlertDialogDescription>
            <Anchor
              target="_blank"
              href="https://suporte.linkana.com/pt-BR/articles/8719028-como-posso-configurar-uma-categoria-na-linkana#h_b5e441cf87"
            >
              Saiba mais{" "}
            </Anchor>
            sobre os possíveis impactos que essa mudança poderá causar.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Número de fornecedores impactados</AlertTitle>
          <AlertDescription>
            {activeSuppliersCount} {supplierText}
          </AlertDescription>
        </Alert>

        <AlertDialogFooter>
          <AlertDialogAction onClick={onClick}>Salvar alterações e sincronizar</AlertDialogAction>
          <AlertDialogCancel onClick={onClose}>Cancelar</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

CategoryEditConfirmationDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  activeSuppliersCount: PropTypes.number.isRequired,
};

export default CategoryEditConfirmationDialog;
