import axios from "axios";
import client from "Config/GraphqlApiService";

import KEY from "./Config";

const Signup = (values, token) =>
  new Promise((res, rej) => {
    axios
      .post("/sso/signup_password", { user: values, token })
      .then(({ headers }) => {
        client.clearStore();
        localStorage.setItem(KEY, headers.authorization);

        res(true);
        window.location.assign("/");
      })
      .catch((error) => {
        rej(new Error(error.response.data.error));
      });
  });
export default Signup;
