import React from "react";

import {
  Baseline,
  AlignLeft,
  CircleDot,
  CheckSquare,
  ChevronDown,
  DollarSign,
  Hash,
  IdCard,
  Mail,
  Upload,
  Files,
} from "lucide-react";

export const FORM_FIELD_TYPE_OPTIONS = [
  { icon: <Baseline size={16} />, label: "Texto curto", value: "text_input" },
  { icon: <AlignLeft size={16} />, label: "Texto longo", value: "long_text_input" },
  { icon: <CircleDot size={16} />, label: "Multipla escolha", value: "radio_button" },
  { icon: <CheckSquare size={16} />, label: "Caixa de seleção", value: "check_box" },
  { icon: <ChevronDown size={16} />, label: "Lista suspensa", value: "select" },
  { icon: <Hash size={16} />, label: "Número", value: "number_input" },
  { icon: <DollarSign size={16} />, label: "Moeda", value: "currency" },
  { icon: <IdCard size={16} />, label: "CPF", value: "cpf_input" },
  { icon: <Mail size={16} />, label: "E-mail", value: "email_input" },
  { icon: <Upload size={16} />, label: "Upload de documento", value: "upload" },
  { icon: <Files size={16} />, label: "Upload de documentos múltiplos", value: "multiple_uploads" },
];

export const FIELDS_WITH_OPTIONS = ["radio_button", "check_box", "select"];
