import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { CubeProvider } from "@cubejs-client/react";

import cubejs from "@cubejs-client/core";
import Skeleton from "DS/custom/skeleton";

const API_URL = "https://tomato-mandrill.gcp-us-central1.cubecloudapp.dev/cubejs-api/v1";

/** @internal */
export const QUERY = gql`
  query cubeBuyerProvider($buyerId: ID) {
    buyer {
      id
      cubeToken(buyerId: $buyerId)
    }
  }
`;

function CubeBuyerProvider({ children, buyerId }) {
  const { data, loading } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return <Skeleton className="h-28 w-96" repeat={5} />;

  const cubeApi = cubejs(data.buyer.cubeToken, { apiUrl: API_URL });

  return <CubeProvider cubeApi={cubeApi}>{children}</CubeProvider>;
}

CubeBuyerProvider.defaultProps = { buyerId: null };

CubeBuyerProvider.propTypes = {
  buyerId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CubeBuyerProvider;
