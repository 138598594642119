import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

/** @internal */
export const MUTATION = gql`
  mutation useManageSupplierRegularization($id: ID!, $regularization: Boolean!, $email: String, $issues: String) {
    manageSupplierRegularization(input: { id: $id, regularization: $regularization, email: $email, issues: $issues }) {
      supplier {
        id
        regularization
      }
    }
  }
`;

const useManageSupplierRegularization = () => {
  const [mutation, hash] = useMutation(MUTATION);

  const mutationCallback = ({ id, regularization, email, issues }) =>
    mutation({ variables: { id, regularization, email, issues } });

  return [mutationCallback, hash];
};

export default useManageSupplierRegularization;
