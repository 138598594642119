import React from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import z from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useToast } from "DS/ui/use-toast";
import { Button } from "DS/ui/button";

import UserPermissions from "Organisms/UBuyer/Settings/Users/UserPermissions";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";

/** @internal */
export const MUTATION = gql`
  mutation EditPermissionForm($id: ID!, $iam: IamAttributes!, $owner: Boolean!) {
    changeUserPermissions(input: { id: $id, iam: $iam, owner: $owner }) {
      user {
        id
        owner
        iam {
          addSupplier
          reviewDoc
          reviewSupplier
          addBuyerDocument
          destroySupplier
          addPerformanceForm
          startWorkflow
          purchaseSupplierProfile
        }
      }
    }
  }
`;

const zodSchema = () =>
  z.object({
    owner: z.boolean(),
    addSupplier: z.boolean(),
    reviewDoc: z.boolean(),
    addBuyerDocument: z.boolean(),
    reviewSupplier: z.boolean(),
    destroySupplier: z.boolean(),
    addPerformanceForm: z.boolean(),
    startWorkflow: z.boolean(),
    purchaseSupplierProfile: z.boolean(),
  });

function EditPermissionForm({ owner, permissions }) {
  const { toast } = useToast();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [changeUserPermissions, { loading }] = useMutation(MUTATION);

  const defaultValues = {
    owner,
    addSupplier: permissions.addSupplier,
    reviewDoc: permissions.reviewDoc,
    addBuyerDocument: permissions.addBuyerDocument,
    reviewSupplier: permissions.reviewSupplier,
    destroySupplier: permissions.destroySupplier,
    addPerformanceForm: permissions.addPerformanceForm,
    startWorkflow: permissions.startWorkflow,
    purchaseSupplierProfile: permissions.purchaseSupplierProfile,
  };
  const form = useForm({ resolver: zodResolver(zodSchema()), defaultValues });

  const onSubmit = async (values) => {
    const iam = {
      addSupplier: values.addSupplier,
      reviewDoc: values.reviewDoc,
      addBuyerDocument: values.addBuyerDocument,
      reviewSupplier: values.reviewSupplier,
      destroySupplier: values.destroySupplier,
      addPerformanceForm: values.addPerformanceForm,
      startWorkflow: values.startWorkflow,
      purchaseSupplierProfile: values.purchaseSupplierProfile,
    };

    await changeUserPermissions({
      variables: { id: userId, owner: values.owner, iam },
    });

    toast({ title: "Permissões salvas com sucesso!" });
    navigate("../users");
  };

  return (
    <FormProvider {...form}>
      <form id="editPermissions" className="flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-[672px] flex-col gap-4">
          <UserPermissions />

          <div>
            <OwnerChecker>
              <Button type="submit" disabled={loading} form="editPermissions">
                Salvar alterações
              </Button>
            </OwnerChecker>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

EditPermissionForm.propTypes = {
  owner: PropTypes.bool.isRequired,
  permissions: PropTypes.shape({
    addSupplier: PropTypes.bool,
    reviewDoc: PropTypes.bool,
    addBuyerDocument: PropTypes.bool,
    reviewSupplier: PropTypes.bool,
    destroySupplier: PropTypes.bool,
    addPerformanceForm: PropTypes.bool,
    startWorkflow: PropTypes.bool,
    purchaseSupplierProfile: PropTypes.bool,
  }).isRequired,
};

export default EditPermissionForm;
