import React from "react";

import { Breadcrumb } from "DS/custom/breadcrumb";

import BranchesTable from "Organisms/UBuyer/Companies/PUF/InfoCNPJ/Branches/BranchesTable";
import HeadquarterCard from "Organisms/UBuyer/Companies/PUF/InfoCNPJ/Branches/HeadquarterCard";

function AllBranches() {
  return (
    <div>
      <Breadcrumb levels={["Dados CNPJ", "Matriz e filiais"]} />

      <div className="mt-4 flex flex-col gap-6">
        <HeadquarterCard />
        <BranchesTable />
      </div>
    </div>
  );
}

export default AllBranches;
