import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { useForm, FormProvider } from "react-hook-form";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Anchor } from "DS/custom/anchor";
import { Checkbox } from "DS/ui/checkbox";
import Skeleton from "DS/custom/skeleton";
import { RadioGroup, RadioGroupItem } from "DS/ui/radio-group";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import { NATIONAL_PF } from "Config/LegalEntity";
import { parseErrors } from "Services/ErrorsParser";

import {
  parseVariables,
  calculateResolver,
} from "Organisms/UBuyer/Companies/Index/AddSupplier/calculateBaseSupplierForm";

import useSettingBuyerDocumentsOnCreation from "Organisms/UBuyer/Companies/Index/AddSupplier/BuyerDocuments/useSettingBuyerDocumentsOnCreation";

import CategoryAlert from "Organisms/UBuyer/Companies/Index/AddSupplier/SettingCategory/CategoryAlert";
import CategoryCombobox from "Organisms/UBuyer/Companies/Index/AddSupplier/SettingCategory/CategoryCombobox";
import BuyerDocumentFieldset from "Organisms/UBuyer/Companies/Index/AddSupplier/BuyerDocuments/BuyerDocumentFieldset";

export const SKELETON = (
  <div className="flex flex-col gap-2">
    <div className="flex flex-row gap-10 border-b pb-4">
      <Skeleton className="h-10 w-96" />
      <Skeleton className="h-32 w-96" />
    </div>
    <div className="flex flex-row gap-10 border-b py-4">
      <Skeleton className="h-20 w-96" />
      <Skeleton className="h-20 w-96" />
    </div>
    <div className="flex flex-row gap-10 pt-4">
      <Skeleton className="h-14 w-96" />
      <Skeleton className="h-28 w-96" />
    </div>
    <div className="mt-8 flex flex-row justify-center gap-8">
      <Skeleton className="h-12 w-48" repeat={2} />
    </div>
  </div>
);

/** @internal */
export const MUTATION = gql`
  mutation BaseSupplierForm(
    $name: String!
    $country: String
    $identifier: String!
    $contactEmail: String
    $settingCategoryId: ID!
    $buyerDocumentFields: [BuyerDocumentInput!]
  ) {
    addSupplier(
      input: {
        name: $name
        country: $country
        identifier: $identifier
        contactEmail: $contactEmail
        settingCategoryId: $settingCategoryId
        buyerDocumentFields: $buyerDocumentFields
      }
    ) {
      supplier {
        id
        name
        state
        stateI18n
      }
      errors {
        message
        path
      }
    }
  }
`;

function BaseSupplierForm({ children, categories }) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { name, identifier, country } = Object.fromEntries(searchParams);

  const { loading, settingBuyerDocumentsOnCreation } = useSettingBuyerDocumentsOnCreation();
  const [addSupplier, { loading: submitting }] = useMutation(MUTATION);

  const form = useForm({
    resolver: calculateResolver(settingBuyerDocumentsOnCreation),
    defaultValues: { contactEmail: "" },
  });

  if (loading) return SKELETON;

  const onSubmit = async (values) => {
    const variables = await parseVariables(values);
    const { data: mutationData } = await addSupplier({ variables: { ...variables, name, identifier, country } });

    const { supplier, errors } = mutationData.addSupplier;

    if (errors.length) _.each(parseErrors(errors), (message, path) => form.setError(path, { type: "custom", message }));
    else navigate(`/companies/${supplier.id}/panel`, { state: { newRecord: true } });
  };

  const settingCategory = form.watch("settingCategory");
  const hasContactEmail = form.watch("hasContactEmail");

  return (
    <FormProvider {...form}>
      <form
        id="BaseSupplierForm"
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-6 flex flex-col gap-6 overflow-hidden"
      >
        <div className="flex flex-col gap-5">
          {children}

          <div className="flex flex-col gap-4 max-w-3xl ml-1">
            <Text weight="medium">Categoria</Text>

            <FormField
              control={form.control}
              name="settingCategory"
              render={({ field: { onChange, ...field } }) => (
                <FormItem>
                  <FormLabel className="font-medium">Selecione uma categoria</FormLabel>

                  <FormControl>
                    <CategoryCombobox
                      {...field}
                      categories={categories}
                      onChange={(value) => {
                        if (value.hasSettingForms) form.setValue("hasContactEmail", "1");
                        else {
                          form.resetField("contactEmail");
                          form.setValue("hasContactEmail", "0");
                        }

                        onChange(value);
                      }}
                    />
                  </FormControl>

                  <FormMessage />

                  <CategoryAlert settingCategoryId={settingCategory?.id} />
                </FormItem>
              )}
            />

            {settingCategory ? (
              <div key={settingCategory.id} className="flex flex-col gap-4">
                <FormItem>
                  <FormLabel>Deseja convidar o fornecedor para preenchimento das informações solicitadas?</FormLabel>

                  <FormControl>
                    <RadioGroup
                      className="flex flex-row items-center gap-4"
                      defaultValue={settingCategory.hasSettingForms ? "1" : "0"}
                      onValueChange={(value) => {
                        form.resetField("contactEmail");
                        form.setValue("hasContactEmail", value);
                      }}
                    >
                      <FormItem className="flex flex-row items-center gap-2">
                        <FormControl>
                          <RadioGroupItem value="1" />
                        </FormControl>

                        <FormLabel style={{ margin: 0 }}>Sim</FormLabel>
                      </FormItem>

                      <FormItem className="flex flex-row items-center gap-2">
                        <FormControl>
                          <RadioGroupItem value="0" />
                        </FormControl>

                        <FormLabel style={{ margin: 0 }}>Ainda não</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>

                <FormField
                  name="contactEmail"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-medium">E-mail do fornecedor</FormLabel>

                      <FormControl>
                        <Input {...field} placeholder="Digite um e-mail" disabled={hasContactEmail === "0"} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                {settingCategory.legalEntity === NATIONAL_PF ? (
                  <FormField
                    name="confirmation"
                    defaultValue={false}
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormControl>
                          <div className="flex flex-row items-center gap-2">
                            <Checkbox id="confirmation" checked={field.value} onCheckedChange={field.onChange} />

                            <FormLabel htmlFor="confirmation">
                              <Text size="small" weight="medium" className={fieldState.invalid ? "text-red-600" : ""}>
                                Eu concordo, e assumo a responsabilidade, que esta pessoa que estou adicionando me deu
                                autorização prévia, e consentimento, para utilizar seus dados de documentos como
                                cadastro de fornecedor de minha empresa, de acordo com a Lei Geral de Proteção de Dados
                                Pessoais (LGPD), Lei n° 13.709/2018.
                              </Text>
                            </FormLabel>
                          </div>
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : null}
              </div>
            ) : null}
          </div>

          {settingBuyerDocumentsOnCreation.length ? <div className="w-full border-b" /> : null}

          <BuyerDocumentFieldset
            control={form.control}
            settingBuyerDocumentsOnCreation={settingBuyerDocumentsOnCreation}
          />
        </div>

        <div className="flex flex-row gap-3">
          <Button type="submit" disabled={submitting}>
            Iniciar homologação
          </Button>

          <Anchor to={-1}>
            <Button type="button" variant="outline">
              Cancelar
            </Button>
          </Anchor>
        </div>
      </form>
    </FormProvider>
  );
}

BaseSupplierForm.propTypes = {
  children: PropTypes.node.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      hasSettingForms: PropTypes.bool,
    }),
  ).isRequired,
};

export default BaseSupplierForm;
