import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Lock } from "lucide-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";
import { Heading } from "DS/custom/heading";

import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogDescription,
} from "DS/ui/alert-dialog";

import BlockSupplierMutation from "Organisms/UBuyer/SupplierHeader/BlockSupplier/BlockSupplierMutation";

/** @internal */
export const QUERY = gql`
  query BlockSupplier($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      state
      blockedAt
      blockedBy
      blockedReason
      qualification {
        id
        state
      }
    }
  }
`;

function BlockSupplier() {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const { loading, data } = useQuery(QUERY, { variables: { id } });

  const onClose = () => setShowModal(false);

  if (loading) return <Skeleton className="h-10 w-full" />;

  const { supplier } = data;
  const { blockedAt } = supplier;

  if (blockedAt) return <BlockSupplierMutation unblock onClose={onClose} />;

  return (
    <AlertDialog open={showModal}>
      <AlertDialogTrigger asChild>
        <Button type="button" variant="ghost" disabled={loading} onClick={() => setShowModal(true)}>
          <Lock className="mr-2 h-4 w-4" /> Bloquear homologação
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle asChild>
            <Heading>Bloquear o homologação</Heading>
          </AlertDialogTitle>

          <AlertDialogDescription asChild>
            <Text size="small" color="secondary">
              O bloqueio constará no painel do fornecedor e será informado via integração para seu ERP, caso aplicável.
            </Text>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <BlockSupplierMutation onClose={onClose} />
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default BlockSupplier;
