import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

import { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectItem } from "DS/ui/select";

import { Text } from "DS/custom/text";
import { Card, CardContent } from "DS/custom/card";

import formatDate from "Services/DateToString";

function LayoutSelect({ routes, rfUpdatedAt }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const rootRoute = pathname.split("/")[4];

  return (
    <div key={pathname} className="mb-8 lg:hidden">
      <Select defaultValue={rootRoute} onValueChange={(path) => navigate(path)}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>

        <SelectContent>
          <SelectGroup>
            {routes.map(({ key, value }) => (
              <SelectItem key={`puf-select-${key}`} value={key}>
                {value}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Card className="mt-2 p-4">
        <CardContent className="flex flex-row items-center gap-2">
          <Text size="small" color="secondary">
            Última atualização na Receita Federal <b>{formatDate(rfUpdatedAt)}</b>
          </Text>
        </CardContent>
      </Card>
    </div>
  );
}

LayoutSelect.propTypes = {
  rfUpdatedAt: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })).isRequired,
};

export default LayoutSelect;
