import React from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import Skeleton from "DS/custom/skeleton";

import RegularSupplierInfo from "Organisms/UBuyer/Shared/SupplierSimpleHeader/RegularSupplierInfo";
import PendingPaymentSupplierInfo from "Organisms/UBuyer/Shared/SupplierSimpleHeader/PendingPaymentSupplierInfo";
import SupplierTabs from "Organisms/Shared/Navigation/SupplierTabs";
import SupplierSelect from "Organisms/Shared/Navigation/SupplierSelect";

/** @internal */
export const QUERY = gql`
  query SupplierSimpleHeader($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      name
      state
      stateI18n
      legalEntity
      regularization
      pendingPayment
      leadTimeMessage
      identifierMasked
      stateUpdatedAtI18n
      qualification {
        id
        state
        createdAt
        userEmail
        decisionAt
        withWorkflow
        decisionReason
        decisionJustification
      }
      buyer {
        id
        settingPerformanceForms {
          id
        }
      }
    }
  }
`;

const calculateTabs = ({ buyer, id, pendingPayment }) => {
  const tabs = [
    { turbo: false, title: "Painel", path: "panel" },
    { turbo: true, title: "Perfil Universal", path: `/buyers/suppliers/${id}/puf/info_cnpj` },
    { turbo: true, title: "Revisões", path: `/buyers/suppliers/${id}/reviews` },
    { turbo: true, title: "Documentos", path: `/buyers/suppliers/${id}/documents` },
    { turbo: true, title: "Convites", path: `/buyers/suppliers/${id}/invites` },
    { turbo: false, title: "Linha do tempo", path: "history" },
  ];

  if (pendingPayment) return tabs.filter(({ title }) => ["Painel", "Convites"].includes(title));

  if (buyer.settingPerformanceForms.length)
    tabs.push({ turbo: true, title: "Performance", path: `/buyers/suppliers/${id}/performance` });

  return tabs;
};

export const SKELETON = (
  <div className="flex flex-col gap-4 border-b p-4 lg:p-10 lg:pb-0">
    <div className="flex flex-row flex-wrap items-center justify-between gap-4">
      <div className="flex flex-row items-center gap-6">
        <Skeleton className="h-16 w-16 flex-none rounded-full" />
        <div>
          <Skeleton className="h-12 w-80" />
          <div className="mt-3 flex flex-row flex-wrap items-center gap-x-6">
            <Skeleton className="h-5 w-28" repeat={4} />
          </div>
        </div>
      </div>
      <Skeleton className="h-12 w-52" />
    </div>
    <Skeleton className="h-9 max-w-3xl" />
  </div>
);

function SupplierSimpleHeader() {
  const { id } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { supplier } = data;

  const tabs = calculateTabs(supplier);

  return (
    <div className="flex flex-col gap-4 border-b p-4 lg:p-6 lg:pb-0">
      {supplier.pendingPayment ? (
        <PendingPaymentSupplierInfo supplier={supplier} />
      ) : (
        <RegularSupplierInfo supplier={supplier} />
      )}

      <SupplierTabs tabs={tabs} />
      <SupplierSelect tabs={tabs} />
    </div>
  );
}

export default SupplierSimpleHeader;
