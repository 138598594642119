import React from "react";

import { Box, Image, Heading, Anchor } from "grommet";
import NotFound from "Assets/landing/404.svg";
import { useNavigate } from "react-router-dom";

function NetworkError() {
  const navigate = useNavigate();

  return (
    <Box align="center" justify="center" height="100vh">
      <Image src={NotFound} />
      <Heading level="5">
        Não conseguimos conectar ao servidor. <br /> Verifique sua conexão com a internet.
        <br />
        <Anchor onClick={() => navigate("/login")}> Ir para home.</Anchor>
      </Heading>
    </Box>
  );
}

export default NetworkError;
