import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Menu, Trash } from "lucide-react";

import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { Card, CardHeader, CardTitle, CardContent } from "DS/custom/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "DS/ui/dropdown-menu";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import DocumentsList from "Organisms/UBuyer/Settings/Categories/DocumentsList";
import CategoryDeleteAlert from "Organisms/UBuyer/Settings/Categories/CategoryDeleteAlert";
import { Breadcrumb } from "DS/custom/breadcrumb";
import { INTERNATIONAL, NATIONAL_PJ, NATIONAL_PF } from "Config/LegalEntity";
import formatDate from "Services/DateToString";
import DuplicateCategoryMenuItem from "Organisms/UBuyer/Settings/Categories/DuplicateCategoryMenuItem";

/** @internal */
export const QUERY = gql`
  query categoryInfo($buyerId: ID!, $id: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingCategory(id: $id) {
        id
        name
        createdAt
        updatedAt
        displayName
        legalEntity
        keepActiveOnSupplierReview
      }
    }
  }
`;

const CATEGORY_TYPES = {
  [NATIONAL_PJ]: "Nacional Pessoa Jurídica (CNPJ)",
  [NATIONAL_PF]: "Nacional Pessoa Fisica (CPF)",
  [INTERNATIONAL]: "Internacional",
};

function CategoryInfo() {
  const navigate = useNavigate();
  const { id, buyerId } = useParams();
  const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
  const { loading, data } = useQuery(QUERY, { variables: { buyerId, id } });

  if (loading) return <GlobalLoading />;

  const { settingCategory } = data.buyer;

  return (
    <div className="space-y-6 p-4 lg:px-10 lg:py-6">
      <div className="flex flex-row flex-wrap items-center justify-between">
        <Breadcrumb levels={["Categorias", settingCategory.displayName]} />

        <div className="flex flex-row gap-4">
          <OwnerChecker>
            <Button type="button" onClick={() => navigate(`./edit`)}>
              Editar
            </Button>

            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="p-2" aria-label="secondary-menu">
                  <Menu className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent className="w-56">
                <DuplicateCategoryMenuItem settingCategoryId={settingCategory.id} />

                <DropdownMenuSeparator />

                <DropdownMenuItem onClick={() => setDeleteAlertVisible(true)}>
                  <Text className="text-red-600">
                    <Trash strokeWidth={3} className="mr-2 h-4 w-4 text-red-600" />
                  </Text>

                  <Text weight="medium" className="text-red-600">
                    Excluir
                  </Text>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </OwnerChecker>
        </div>
      </div>

      <div className="flex flex-col-reverse gap-8 xl:flex-row">
        <div className="w-full xl:w-2/3">
          <DocumentsList settingCategoryId={settingCategory.id} />
        </div>

        <div className="w-full xl:w-1/3">
          <Card className="border-slate-100">
            <CardHeader>
              <CardTitle>Documentos da categoria</CardTitle>
            </CardHeader>

            <CardContent>
              <div>
                <Text color="secondary">Manter fornecedor ativo após aprovação</Text>

                <Text weight="medium">{settingCategory.keepActiveOnSupplierReview ? "Ativado" : "Desativado"}</Text>
              </div>

              <div>
                <Text color="secondary">Tipo de categoria</Text>
                <Text weight="medium">{CATEGORY_TYPES[settingCategory.legalEntity]}</Text>
              </div>

              <div>
                <Text color="secondary">Data da criação</Text>
                <Text weight="medium">{formatDate(settingCategory.createdAt)}</Text>
              </div>

              <div>
                <Text color="secondary">Data da última edição</Text>
                <Text weight="medium">{formatDate(settingCategory.updatedAt)}</Text>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      <CategoryDeleteAlert visible={deleteAlertVisible} setVisible={setDeleteAlertVisible} />
    </div>
  );
}

export default CategoryInfo;
