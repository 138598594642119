import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { useToast } from "DS/ui/use-toast";

import { Button, Text } from "grommet";

/** @internal */
export const MUTATION = gql`
  mutation WorkflowsDestroyButton($settingWorkflowId: ID!) {
    destroySettingWorkflow(input: { settingWorkflowId: $settingWorkflowId }) {
      buyer {
        id
        settingWorkflows {
          id
          displayName
        }
      }
    }
  }
`;

function WorkflowsDestroyButton() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { settingWorkflowId } = useParams();
  const [destroySettingWorkflow, { loading }] = useMutation(MUTATION);

  return (
    <Button
      color="status-error"
      label={<Text color="status-error">Excluir fluxo de aprovação</Text>}
      disabled={loading}
      onClick={async () => {
        await destroySettingWorkflow({ variables: { settingWorkflowId } });

        toast({ title: "Fluxo de aprovação excluído com sucesso!" });
        navigate("../.");
      }}
    />
  );
}

export default WorkflowsDestroyButton;
