import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogDescription,
} from "DS/ui/alert-dialog";

function MyAlertDialog({ onSuccess, onCancel, open, description }) {
  const [disabled, setDisabled] = useState(false);

  const onClick = async () => {
    setDisabled(true);

    await onSuccess();

    setDisabled(false);
  };

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Você deseja continuar?</AlertDialogTitle>

          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel disabled={disabled} onClick={onCancel}>
            Cancelar
          </AlertDialogCancel>

          <AlertDialogAction onClick={onClick} disabled={disabled} className="bg-blue-600">
            Continuar
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

MyAlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
};

export default MyAlertDialog;
