import React from "react";
import PropTypes from "prop-types";

import { Table, TableRow, TableBody, TableHead, TableHeader } from "DS/ui/table";

import SupplierTableRow from "Organisms/UBuyer/Companies/Index/SuppliersSearch/SupplierTableRow";

function SuppliersTable({ edges, supplierPaymentStripeLink }) {
  if (!edges.length) return null;

  return (
    <div className="rounded-md border border-slate-200">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Empresa</TableHead>
            <TableHead>Categoria</TableHead>
            <TableHead>Pendências</TableHead>
            <TableHead>Homologação</TableHead>
            <TableHead>Características</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {edges.map(({ node: supplier }) => (
            <SupplierTableRow
              key={supplier.id}
              supplierPaymentStripeLink={supplierPaymentStripeLink}
              supplier={supplier}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

SuppliersTable.defaultProps = { edges: [], supplierPaymentStripeLink: "" };

SuppliersTable.propTypes = {
  supplierPaymentStripeLink: PropTypes.string,
  edges: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};

export default SuppliersTable;
