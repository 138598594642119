import PropTypes from "prop-types";

import useAbilityChecker from "./useAbilityChecker";

function AbilityChecker({ ability, allow, children, deny }) {
  const { loading, abilities } = useAbilityChecker();

  if (loading) return null;

  if (abilities[ability] === undefined) return null;

  return abilities[ability] ? allow || children : deny;
}

AbilityChecker.propTypes = {
  ability: PropTypes.string.isRequired,
  allow: PropTypes.node,
  children: PropTypes.node,
  deny: PropTypes.node,
};

AbilityChecker.defaultProps = {
  allow: null,
  deny: null,
  children: null,
};

export default AbilityChecker;
