import React from "react";
import PropTypes from "prop-types";

import { Box, RadioButtonGroup } from "grommet";

import { Angry, Frown, Meh, Smile, Laugh, Ban } from "lucide-react";

import BlackTip from "Molecules/Tip/BlackTip";

const OPTIONS = {
  0: { Icon: Angry, tooltip: "Péssimo (0%)" },
  25: { Icon: Frown, tooltip: "Ruim (25%)" },
  50: { Icon: Meh, tooltip: "Regular (50%)" },
  75: { Icon: Smile, tooltip: "Bom (75%)" },
  100: { Icon: Laugh, tooltip: "Excelente (100%)" },
  [-1]: { Icon: Ban, tooltip: "Não se aplica" },
};

const getBackground = (option, { checked, focus, hover }) => {
  let background = option >= 0 ? "light-2" : "white";

  if (hover || focus) background = "light-4";

  if (checked) background = "brand";

  return background;
};

function ScoreRadioButtonGroup({ name }) {
  return (
    <RadioButtonGroup id={name} gap="none" name={name} direction="row" options={[0, 25, 50, 75, 100, -1]}>
      {(option, props) => {
        const { Icon, tooltip } = OPTIONS[option];

        return (
          <BlackTip content={tooltip} position="top">
            <Box background={getBackground(option, props)} pad="18px">
              <Icon aria-label={tooltip} size={26} />
            </Box>
          </BlackTip>
        );
      }}
    </RadioButtonGroup>
  );
}

ScoreRadioButtonGroup.propTypes = { name: PropTypes.string.isRequired };

export default ScoreRadioButtonGroup;
