import React from "react";
import PropTypes from "prop-types";

import { Label } from "DS/ui/label";
import { Checkbox } from "DS/ui/checkbox";

function CheckboxFilter({ name, textColor, checked, label, onCheckedChange }) {
  const className = `flex w-full flex-row items-center gap-2 ${textColor}`;

  return (
    <Label htmlFor={name} className={className}>
      <Checkbox id={name} onCheckedChange={onCheckedChange} checked={checked} />

      {label}
    </Label>
  );
}

CheckboxFilter.defaultProps = { textColor: "" };

CheckboxFilter.propTypes = {
  textColor: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheckedChange: PropTypes.func.isRequired,
};

export default CheckboxFilter;
