import { ApolloClient, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "@apollo/client/cache";
import HerokuReleaseVersion from "Config/HerokuReleaseVersion";
import jwtDecode from "jwt-decode";
import Auth from "Services/Auth";

const PER_PAGE = 50;

const setAuthorizationLink = setContext(() => ({
  headers: { authorization: Auth.get() },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const errors = graphQLErrors.map(({ message }) => message);

    if (errors.includes("UNAUTHORIZED")) {
      const currentTime = Date.now() / 1000;
      const token = Auth.get();

      if (token) {
        try {
          const jwt = jwtDecode(token);
          if (jwt.exp < currentTime) Auth.destroy();
        } catch (e) {
          Auth.destroy();
        }
      }

      window.location.assign("/");
    }
  }

  if (networkError?.message === "Failed to fetch") window.location.assign("/network-error");

  return null;
});

// eslint-disable-next-line default-param-last
function paginatedMerge(existing = [], incoming, { args: { page = 0 } }) {
  const offset = PER_PAGE * page;

  const merged = existing ? existing.slice(0) : [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i];
  }

  return merged;
}

const link = from([
  HerokuReleaseVersion,
  errorLink,
  new RetryLink(),
  setAuthorizationLink,
  new HttpLink({ uri: "/graphql" }),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Supplier: { fields: { formGroups: { merge: false } } },
      Query: {
        fields: {
          shareholdersRf: {
            keyArgs: ["cnpj"],
            merge: paginatedMerge,
          },
          companyBranches: {
            keyArgs: ["cnpj"],
            merge: paginatedMerge,
          },
          allCompaniesByCnpj: {
            keyArgs: ["cnpj"],
            merge: paginatedMerge,
          },
          allCompaniesByCpfName: {
            keyArgs: ["cpf", "name"],
            merge: paginatedMerge,
          },
        },
      },
    },
  }),
});

export default client;
