import React from "react";
import PropTypes from "prop-types";

import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";

import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";

import PerformanceFormFormSortableQuestion from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormFormSortableQuestion";

function PerformanceFormFormSortableQuestions({ form, index, questions, onDeleteQuestion, onReorderQuestions }) {
  const sensorsOptions = { activationConstraint: { distance: 4 } };

  const onDragEnd = ({ active, over }) => {
    if (active.id === over.id) return;

    const oldIndex =
      typeof active.id === "string"
        ? questions.findIndex((q) => q.id === active.id)
        : questions.findIndex((q) => q.displayName === active.id.displayName);

    const newIndex =
      typeof over.id === "string"
        ? questions.findIndex((q) => q.id === over.id)
        : questions.findIndex((q) => q.displayName === over.id.displayName);

    onReorderQuestions(index, arrayMove(questions, oldIndex, newIndex));
  };

  return (
    <DndContext
      onDragEnd={onDragEnd}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      sensors={useSensors(useSensor(PointerSensor, sensorsOptions))}
    >
      <SortableContext items={questions} strategy={verticalListSortingStrategy}>
        {questions.map((question, questionIndex) => (
          <PerformanceFormFormSortableQuestion
            form={form}
            index={index}
            question={question}
            questionIndex={questionIndex}
            onDeleteQuestion={onDeleteQuestion}
            key={index + question.displayName + questionIndex} // eslint-disable-line react/no-array-index-key
            showTrash={questions.filter((q) => !q._destroy).length > 1}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

PerformanceFormFormSortableQuestions.propTypes = {
  form: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  onReorderQuestions: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({ displayName: PropTypes.string })).isRequired,
};

export default PerformanceFormFormSortableQuestions;
