import React from "react";
import { Route, Routes } from "react-router-dom";

import NoMatch from "Pages/LandingPage/Static/NoMatch";

import EmailInput from "Organisms/Login/EmailInput";
import LoginLayout from "Organisms/Login/LoginLayout";
import IdpOnlyLogin from "Organisms/Login/IdpOnlyLogin";
import ForgotPassword from "Organisms/Login/ForgotPassword";
import PasswordOnlyLogin from "Organisms/Login/PasswordOnlyLogin";
import MultipleMethodsLogin from "Organisms/Login/MultipleMethodsLogin";
import LoginWithoutPassword from "Organisms/Login/LoginWithoutPassword";

function Login() {
  return (
    <Routes>
      <Route path="" element={<LoginLayout />}>
        <Route path="" element={<EmailInput />} />
        <Route path="idp-only" element={<IdpOnlyLogin />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="password-only" element={<PasswordOnlyLogin />} />
        <Route path="multiple-methods" element={<MultipleMethodsLogin />} />
        <Route path="without-password" element={<LoginWithoutPassword />} />
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default Login;
