import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Text, Anchor } from "grommet";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { useToast } from "DS/ui/use-toast";

import WorkflowsForm from "Organisms/UBuyer/Settings/Workflows/WorkflowsForm";

/** @internal */
export const MUTATION = gql`
  mutation WorkflowsNew(
    $buyerId: ID!
    $displayName: String!
    $settingWorkflowStepsAttributes: [SettingWorkflowStepInput!]!
  ) {
    addSettingWorkflow(
      input: {
        buyerId: $buyerId
        displayName: $displayName
        settingWorkflowStepsAttributes: $settingWorkflowStepsAttributes
      }
    ) {
      settingWorkflow {
        id
        buyer {
          id
          settingWorkflows {
            id
            displayName
          }
        }
      }
    }
  }
`;

function WorkflowsNew() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [addSettingWorkflow, { loading }] = useMutation(MUTATION);

  const onSubmit = async ({ displayName, settingWorkflowStepsAttributes }) => {
    const { data } = await addSettingWorkflow({ variables: { buyerId, displayName, settingWorkflowStepsAttributes } });
    const { id } = data.addSettingWorkflow.settingWorkflow;
    toast({ title: "Fluxo de aprovação criado com sucesso!" });
    navigate(`../${id}`);
  };

  return (
    <Box className="px-4 py-2 lg:py-6 lg:px-10">
      <Box direction="row" align="center" gap="small">
        <Anchor label="Fluxos de aprovação" onClick={() => navigate("../.")} weight="normal" size="22px" />
        <Text size="22px">&gt;</Text>
      </Box>

      <Text size="large" color="dark-2" weight="bold" margin="none">
        Novo fluxo de aprovação
      </Text>

      <Box margin={{ vertical: "medium" }} flex={false}>
        <Text color="dark-1">
          Você está no modo de criação de fluxo de aprovação. Você poderá criar quais alçadas de aprovação do fluxo e
          quais usuários estarão vinculados às alçadas. Apenas usuários existentes poderão ser vinculados a um fluxo de
          aprovação.
        </Text>
      </Box>

      <WorkflowsForm onSubmit={onSubmit} submitting={loading} />
    </Box>
  );
}

export default WorkflowsNew;
