import React from "react";
import PropTypes from "prop-types";

import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "DS/ui/select";

import { cn } from "DS/lib/utils";

import { RISK } from "Config/DataAndDocuments";

function DocumentRiskSelect({ className, risk, settingDocument, onSelectRisk }) {
  const value = risk || "0";

  const onValueChange = (newRisk) => onSelectRisk(newRisk, settingDocument);

  return (
    <Select disabled={!risk} value={value} onValueChange={onValueChange}>
      <SelectTrigger className={cn(className, RISK[value].color)}>
        <SelectValue className="text-yellow-500" />
      </SelectTrigger>

      <SelectContent>
        <SelectItem value="0" className={RISK[0].color}>
          menos 0 pontos
        </SelectItem>

        <SelectItem value="7" className={RISK[7].color}>
          menos 7 pontos
        </SelectItem>

        <SelectItem value="20" className={RISK[20].color}>
          menos 20 pontos
        </SelectItem>

        <SelectItem value="60" className={RISK[60].color}>
          menos 60 pontos
        </SelectItem>

        <SelectItem value="100" className={RISK[100].color}>
          menos 100 pontos
        </SelectItem>
      </SelectContent>
    </Select>
  );
}

DocumentRiskSelect.defaultProps = {
  risk: null,
  className: "h-9 w-full sm:w-[190px]",
};

DocumentRiskSelect.propTypes = {
  className: PropTypes.string,
  risk: PropTypes.string,
  onSelectRisk: PropTypes.func.isRequired,
  settingDocument: PropTypes.shape({}).isRequired,
};

export default DocumentRiskSelect;
