import { useCubeQuery } from "@cubejs-client/react";
import { useOutletContext } from "react-router-dom";

function useCubeEsgRating() {
  const { identifier } = useOutletContext();

  const { resultSet } = useCubeQuery({
    dimensions: ["insights_esg.esg_rating"],
    filters: [{ member: "insights_esg.cnpj", operator: "equals", values: [identifier] }],
  });

  if (!resultSet) return { loading: true };

  const [rating] = resultSet.rawData();

  if (!rating) return { loading: false, esgRating: null };

  const esgRating = rating["insights_esg.esg_rating"];

  return {
    loading: false,
    esgRating: parseFloat(esgRating).toFixed(1),
  };
}

export default useCubeEsgRating;
