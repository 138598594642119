import React from "react";
import { NumericFormat } from "react-number-format";

import { Input } from "@/DS/ui/input.tsx";

const CurrencyInput = React.forwardRef(({ ...rest }, ref) => (
  <NumericFormat {...rest} getInputRef={ref} customInput={Input} />
));

export default CurrencyInput;

export { CurrencyInput };
