import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { REQUIRED_EMAIL_WITH_REGEX, REQUIRED_STRING } from "Config/Zod";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Textarea } from "DS/ui/textarea";
import { useToast } from "DS/ui/use-toast";

import { Form, FormItem, FormLabel, FormField, FormMessage, FormControl } from "DS/ui/form";

import useManageSupplierRegularization from "Organisms/UBuyer/SupplierHeader/Regularization/useManageSupplierRegularization";

const zodSchema = () => z.object({ issues: REQUIRED_STRING, email: REQUIRED_EMAIL_WITH_REGEX });

function RegularizationForm({ user, issues, onClose, setSubmitting }) {
  const { id } = useParams();
  const { toast } = useToast();
  const [manageSupplierRegularization] = useManageSupplierRegularization();

  const parsedIssues = issues.length
    ? `- ${issues.map(({ displayName }) => displayName).join("\n- ")}`
    : "Nenhum problema encontrado. Descreva o que quer solicitar para o fornecedor.";

  const defaultValues = { email: user?.email || "", issues: parsedIssues };
  const form = useForm({ defaultValues, resolver: zodResolver(zodSchema()) });

  const onSubmit = async (formInfo) => {
    setSubmitting(true);

    await manageSupplierRegularization({
      id,
      regularization: true,
      email: formInfo.email,
      issues: formInfo.issues,
    });

    toast({ title: "E-mail enviado com sucesso!" });
    setSubmitting(false);
    onClose();
  };

  return (
    <Form {...form}>
      <form id="RegularizationForm" className="flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem className="border-b pb-5">
              <FormLabel className="text-sm font-medium" htmlFor="email">
                E-mail do fornecedor
              </FormLabel>

              <FormControl>
                <Input {...field} id="email" placeholder="Digite um e-mail" />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="issues"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-sm font-medium" htmlFor="issues">
                Resumo das pendências
              </FormLabel>

              <FormControl>
                <Textarea
                  {...field}
                  id="issues"
                  className="h-40"
                  placeholder="Digite quais pendências gostaria que sejam regularizadas"
                />
              </FormControl>

              <Text size="small" color="disabled">
                Essa informação será enviada para o fornecedor.
              </Text>

              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}

RegularizationForm.defaultProps = { user: null };

RegularizationForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  user: PropTypes.shape({ email: PropTypes.string }),
  issues: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, displayName: PropTypes.string })).isRequired,
};

export default RegularizationForm;
