import React from "react";
import PropTypes from "prop-types";

import { ChevronLeft, ChevronRight } from "lucide-react";
import _ from "lodash";

function Pagination({ page, total, setPage, pageSize, nextPage, previousPage }) {
  const pageCount = Math.ceil(total / pageSize);

  return (
    <div className="flex flex-1 items-center justify-between gap-4">
      <div>
        <p className="text-sm text-gray-600">
          Mostrando <span className="font-medium">{page * pageSize + 1}</span> ao{" "}
          <span className="font-medium">{_.min([total, page * pageSize + pageSize])}</span> de um total de{" "}
          <span className="font-medium">{total}</span> resultados
        </p>
      </div>
      <div>
        <nav className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => {
              setPage(page - 1);
              previousPage();
            }}
            disabled={page === 0}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => {
              setPage(page + 1);
              nextPage();
            }}
            disabled={page >= pageCount - 1}
          >
            <span className="sr-only">Next</span>
            <ChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  );
}

Pagination.defaultProps = {
  page: 0,
  total: 0,
  pageSize: 8,
  nextPage: () => {},
  previousPage: () => {},
};

Pagination.propTypes = {
  page: PropTypes.number,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  pageSize: PropTypes.number,
  previousPage: PropTypes.func,
  setPage: PropTypes.func.isRequired,
};

export default Pagination;
