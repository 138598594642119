import * as React from "react";
import PropTypes from "prop-types";

import { cva } from "class-variance-authority";
import { cn } from "../lib/utils.ts";

const headingVariants = cva("text-slate-900 dark:text-slate-50", {
  variants: {
    level: {
      1: "text-2xl md:text-[32px]",
      2: "text-2xl md:text-[28px]",
      3: "text-xl md:text-2xl",
      4: "text-base md:text-xl",
    },
    color: {
      title: "text-slate-900 dark:text-slate-50",
      primary: "text-slate-800 dark:text-slate-100",
    },
    weight: {
      profile: "font-semibold",
      medium: "font-medium",
    },
  },
  defaultVariants: {
    level: 4,
    color: "title",
    weight: "medium",
  },
});

const Heading = React.forwardRef(({ className, level, color, as, ...props }, ref) => {
  const Comp = as;

  return <Comp className={cn(headingVariants({ level, color, className }))} ref={ref} {...props} />;
});

Heading.defaultProps = {
  className: null,
  level: 4,
  color: "title",
  as: "h1",
};

Heading.propTypes = {
  className: PropTypes.string,
  level: PropTypes.number,
  color: PropTypes.string,
  as: PropTypes.string,
};

export { Heading, headingVariants };
