import React from "react";
import PropTypes from "prop-types";

import { useFieldArray } from "react-hook-form";

import { PlusCircle, Trash2 } from "lucide-react";

import { RadioGroup, RadioGroupItem } from "DS/ui/radio-group";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Anchor } from "DS/custom/anchor";

import { Card, CardTitle, CardContent } from "DS/custom/card";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import PerformanceFormFormSortableQuestions from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormFormSortableQuestions";

function PerformanceFormFormQuestionnaires({ form }) {
  const { fields, append, update } = useFieldArray({
    control: form.control,
    name: "settingPerformanceQuestionnairesAttributes",
  });

  const { settingPerformanceQuestionnairesAttributes } = form.getValues();

  const onCreateQuestionnaire = () =>
    append({
      weight: 1,
      displayName: "",
      settingPerformanceQuestionsAttributes: [{ displayName: "" }],
    });

  const onDeleteQuestionnaire = (index) =>
    update(index, {
      ...settingPerformanceQuestionnairesAttributes[index],
      settingPerformanceQuestionsAttributes: [],
      _destroy: true,
    });

  const onReorderQuestions = (index, questions) =>
    update(index, {
      ...settingPerformanceQuestionnairesAttributes[index],
      settingPerformanceQuestionsAttributes: questions,
    });

  const onCreateQuestion = (index) => {
    const qnaire = { ...settingPerformanceQuestionnairesAttributes[index] };
    const questions = qnaire.settingPerformanceQuestionsAttributes;

    if (questions.some((q) => !q._destroy && !q.displayName)) return;

    questions.push({ displayName: "" });

    update(index, qnaire);
  };

  const onDeleteQuestion = (index, questionIndex) => {
    const qnaire = { ...settingPerformanceQuestionnairesAttributes[index] };
    const questions = qnaire.settingPerformanceQuestionsAttributes;

    if (questions[questionIndex].displayName) questions[questionIndex]._destroy = true;
    else questions.splice(questionIndex, 1);

    update(index, qnaire);
  };

  return (
    <div className="grid gap-x-8 gap-y-4 lg:grid-cols-[288px_1fr]">
      <div className="flex w-72 flex-col gap-1">
        <Text weight="medium">Pilares</Text>

        <Text>
          A nota geral de performance do fornecedor é calculada com a nota de cada pilar, multiplicada pelo peso que
          aquele pilar possui. A nota do pilar é a média das respostas de seus critérios. Para mais informações, leia o{" "}
          <Anchor
            target="_blank"
            className="text-sm md:text-base"
            href="https://suporte.linkana.com/pt-BR/articles/5760725"
          >
            guia para cálculo do score de performance
          </Anchor>
          .
        </Text>
      </div>

      <div className="flex flex-col gap-3">
        {fields.map((settingPerformanceQuestionnaire, index) => {
          if (settingPerformanceQuestionnaire._destroy) return null;

          const { settingPerformanceQuestionsAttributes } = settingPerformanceQuestionnaire;

          const weight = `settingPerformanceQuestionnairesAttributes.${index}.weight`;
          const displayName = `settingPerformanceQuestionnairesAttributes.${index}.displayName`;

          return (
            <Card key={settingPerformanceQuestionnaire.id} className="flex flex-1 flex-col gap-3">
              <CardTitle className="flex flex-row justify-between">
                <Text weight="medium">{form.watch(displayName) || "Pillar de performance"}</Text>

                {fields.filter(({ _destroy }) => !_destroy).length > 1 ? (
                  <Button
                    type="button"
                    variant="ghost"
                    onClick={() => onDeleteQuestionnaire(index)}
                    className="flex flex-row items-center gap-2 p-0"
                  >
                    <Trash2 size={24} className="text-red-600" />

                    <Text weight="medium" size="small" className="text-red-600">
                      Excluir
                    </Text>
                  </Button>
                ) : null}
              </CardTitle>

              <CardContent>
                <FormField
                  name={displayName}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <div className="mt-1 flex flex-col gap-2">
                        <FormLabel htmlFor={displayName}>Nome do pilar</FormLabel>

                        <FormControl>
                          <Input {...field} id={displayName} placeholder="Digite um nome para o pilar" />
                        </FormControl>

                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />

                <FormField
                  name={weight}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Peso do pilar na avaliação</FormLabel>

                      <FormControl>
                        <RadioGroup
                          id={weight}
                          style={{ margin: 0 }}
                          defaultValue={field.value}
                          onValueChange={field.onChange}
                          className="flex flex-row gap-3"
                        >
                          {[1, 2, 3, 4, 5].map((number) => (
                            <FormItem key={number} className="flex flex-row items-end">
                              <FormControl>
                                <RadioGroupItem value={number} />
                              </FormControl>

                              <FormLabel className="pl-2">{number}</FormLabel>
                            </FormItem>
                          ))}
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="my-1 border-b" />

                <Text weight="medium" size="small">
                  Critérios de avaliação
                </Text>

                <PerformanceFormFormSortableQuestions
                  form={form}
                  index={index}
                  onDeleteQuestion={onDeleteQuestion}
                  onReorderQuestions={onReorderQuestions}
                  questions={settingPerformanceQuestionsAttributes}
                />

                <Anchor onClick={() => onCreateQuestion(index)} className="flex flex-row items-center gap-2 pt-2">
                  <PlusCircle size={20} /> Adicionar pergunta
                </Anchor>
              </CardContent>
            </Card>
          );
        })}
      </div>

      <div />

      <Button type="button" variant="outline" className="w-fit" onClick={onCreateQuestionnaire}>
        Criar novo pilar
      </Button>
    </div>
  );
}

PerformanceFormFormQuestionnaires.propTypes = {
  form: PropTypes.shape({
    control: PropTypes.shape(),
    watch: PropTypes.func,
    getValues: PropTypes.func,
  }).isRequired,
};

export default PerformanceFormFormQuestionnaires;
