import { ApolloLink } from "@apollo/client";

export default new ApolloLink((operation, forward) =>
  forward(operation).map((data) => {
    const isQuery = operation.query.definitions.some((userAction) => userAction.operation === "query");

    if (!isQuery) return data;

    const backendVersion = operation.getContext().response.headers.get("Heroku-Release-Version");

    const frontendVersion = sessionStorage.getItem("HEROKU_RELEASE_VERSION");

    if (!frontendVersion) {
      sessionStorage.setItem("HEROKU_RELEASE_VERSION", backendVersion);

      return data;
    }

    if (frontendVersion !== backendVersion) {
      sessionStorage.setItem("HEROKU_RELEASE_VERSION", backendVersion);
      window.location.reload();
    }

    return data;
  }),
);
