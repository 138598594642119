import React from "react";
import { Navigate, useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Logo from "Assets/logo.svg";
import Placeholder from "Assets/signup-placeholder.jpg";

import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";
import Skeleton from "DS/custom/skeleton";

import LinkanaTerms from "Pages/User/LinkanaTerms";
import AcceptInvite from "Organisms/Login/AcceptInvite";
import SupplierSignupForm from "Pages/User/SupplierSignupForm";

/** @internal */
export const QUERY = gql`
  query SupplierSignup($token: String!) {
    user {
      id
    }
    invite(token: $token) {
      id
      email
      logoUrl
      buyerName
      name
      supplierId
      signupImgUrl
      sidebarLogoUrl
      userAlreadyExists
    }
  }
`;

const SKELETON = (
  <div className="flex h-screen flex-row flex-wrap items-center justify-evenly gap-16">
    <div className="flex max-w-md flex-1 flex-col sm:w-[420px]">
      <Skeleton className="mb-2 h-10 w-10" />
      <Skeleton className="mb-1 h-7 w-full" />
      <Skeleton className="h-20 w-full" />
      <Skeleton className="my-4 h-36 w-full" />
      <Skeleton className="mb-10 h-10 w-full" />
      <Skeleton className="h-14 w-full" />
    </div>
  </div>
);

function SupplierSignup() {
  const { token } = useParams();

  const { loading, data } = useQuery(QUERY, { variables: { token } });

  if (loading) return SKELETON;

  const { user, invite } = data;

  if (!invite) return <Navigate to="/" state={{ tokenExpired: true }} />;

  if (invite.userAlreadyExists) return <AcceptInvite user={user} invite={invite} />;

  return (
    <div className="flex h-screen flex-row items-center justify-evenly">
      <div className="flex flex-col items-center justify-center p-10">
        <div className="flex max-w-md flex-1 flex-col sm:w-[420px]">
          <img
            alt="Buyer Sidebar Logo"
            src={invite.sidebarLogoUrl}
            className="h-20 max-w-[200px] object-contain object-left"
          />

          <Heading className="mb-1 mt-2">Criação de conta na Linkana</Heading>

          <Text weight="medium" color="secondary">
            Você foi convidado para se homologar na empresa {invite.buyerName}.
            <br />
            Crie sua conta e envie as documentações necessárias.
          </Text>

          <SupplierSignupForm email={invite.email} />

          <LinkanaTerms />

          <div className="flex flex-row items-center gap-2">
            <img src={Logo} alt="Linkana Logo" className="h-7 w-7" />

            <Text color="secondary" size="xsmall">
              A {invite.buyerName} confia na ferramenta de cadastro e homologação de fornecedores da Linkana para
              simplificar e melhorar seus processos internos de gestão de fornecedores.
            </Text>
          </div>
        </div>
      </div>

      <div className="hidden p-6 xl:block">
        <img
          alt="Signup or Placeholder"
          src={invite.signupImgUrl || Placeholder}
          className="h-[calc(100vh-4rem)] rounded-3xl"
        />
      </div>
    </div>
  );
}

export default SupplierSignup;
