import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { FolderSync } from "lucide-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";
import { Heading } from "DS/custom/heading";

import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogDescription,
} from "DS/ui/alert-dialog";

import useAbilityChecker from "Organisms/Shared/Authorization/useAbilityChecker";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";
import ChangeSettingCategoryMutation from "Organisms/UBuyer/SupplierHeader/ChangeSettingCategory/ChangeSettingCategoryMutation";

/** @internal */
export const QUERY = gql`
  query ChangeSettingCategory($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      settingCategoryId
      displayCategory
      legalEntity
      buyer {
        id
        settingCategoriesEnabled {
          id
          displayName
          legalEntity
        }
      }
    }
  }
`;

/** @internal */
export const calculateDisabledMessage = (abilities) => {
  if (!abilities.addSupplier) return "Você não tem permissão para realizar esta ação.";

  return null;
};

function ChangeSettingCategory() {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const { loading, data } = useQuery(QUERY, { variables: { id } });
  const { abilities, loading: loadingAbilities } = useAbilityChecker();

  const onClose = () => setShowModal(false);

  if (loading || loadingAbilities) return <Skeleton className="h-10 w-full" />;

  const { supplier } = data;
  const { buyer } = supplier;

  const disabledMessage = calculateDisabledMessage(abilities);

  return (
    <AlertDialog open={showModal}>
      <WrappedTooltip message={disabledMessage}>
        <AlertDialogTrigger asChild>
          <Button
            type="button"
            variant="ghost"
            disabled={loading || disabledMessage}
            onClick={() => setShowModal(true)}
          >
            <FolderSync className="mr-2 h-4 w-4" /> Alterar categoria
          </Button>
        </AlertDialogTrigger>
      </WrappedTooltip>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle asChild>
            <Heading>Esta ação não pode ser desfeita</Heading>
          </AlertDialogTitle>

          <AlertDialogDescription asChild>
            <Text size="small" color="secondary">
              A homologação será atualizada para as configurações da nova categoria e voltará a uma atapa anterior a
              aprovação.
            </Text>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <ChangeSettingCategoryMutation
          onClose={onClose}
          currentSettingCategory={{ id: supplier.settingCategoryId, displayName: supplier.displayCategory }}
          legalEntity={supplier.legalEntity}
          settingCategories={buyer.settingCategoriesEnabled}
        />
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ChangeSettingCategory;
