import React from "react";
import PropTypes from "prop-types";

import { cn } from "DS/lib/utils";
import { Text } from "DS/custom/text";

import { formatCnpj, formatCnae, formatCEP } from "Services/RFFormatter";
import { ACTIVE } from "Config/PUF";

function Branch({ branch }) {
  if (!branch) return null;

  return (
    <div>
      <p className="text-sm font-medium text-gray-500">{branch.mf === "1" ? "Matriz" : "Filial"}</p>

      <div className="flex flex-row items-center gap-2">
        <div className={cn("h-3 w-3 rounded-full", branch.situacao === ACTIVE ? "bg-green-600" : "bg-red-600")} />
        <Text>{formatCnpj(branch.cnpj)}</Text>
      </div>

      <p className="text-sm font-medium text-gray-400">
        {formatCnae(branch.cnaeFiscal)} - {branch.cnaeFiscalTexto}
      </p>

      <p className="text-sm font-medium text-gray-400">
        {branch.descTipoLogradouro} {branch.logradouro}, {branch.numero} {branch.descricaoMunicipio}-{branch.uf} -{" "}
        {formatCEP(branch.cep)}
      </p>
    </div>
  );
}

Branch.defaultProps = { branch: null };

Branch.propTypes = {
  branch: PropTypes.shape({
    mf: PropTypes.string,
    uf: PropTypes.string,
    cep: PropTypes.string,
    cnpj: PropTypes.string,
    numero: PropTypes.string,
    situacao: PropTypes.string,
    cnaeFiscal: PropTypes.string,
    logradouro: PropTypes.string,
    cnaeFiscalTexto: PropTypes.string,
    descricaoMunicipio: PropTypes.string,
    descTipoLogradouro: PropTypes.string,
  }),
};

export default Branch;
