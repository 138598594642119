import React from "react";

import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";

import { setBadgeTextColorPending } from "Config/PUF";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import useCubeInfo from "Organisms/UBuyer/Companies/PUF/Hooks/useCubeInfo";
import CardDetails from "Organisms/UBuyer/Companies/PUF/Analysis/CardDetails";

function SocialChildLabor() {
  const { resultSet } = useCubeInfo({ table: "ChecksChildLabor" });

  if (!resultSet) return <Skeleton className="mb-4 h-24 w-full flex-1" />;

  const data = resultSet.rawData();

  const { variant, text } = setBadgeTextColorPending(data.length);

  return (
    <div>
      <Breadcrumb lastPage="Análise Linkana" currentPage="Trabalho infantil" />

      <div className="mt-4 flex flex-wrap items-start pb-6 lg:flex-nowrap">
        <div className="flex max-w-[300px] flex-col items-start justify-center gap-1 py-4">
          <Text size="small" weight="medium">
            Itens avaliados
          </Text>

          <Text size="small">Informações analisadas pela Linkana para geração da pontuação do pilar.</Text>
        </div>

        <div className="flex flex-1 flex-col gap-2 p-4 pt-4">
          <CardDetails
            text={text}
            variant={variant}
            path="child-labor"
            name="Autuações (MTE) - Trabalho de Menor"
            description="Consulta relativa a empresa ou QSA."
          />
        </div>
      </div>
    </div>
  );
}

export default SocialChildLabor;
