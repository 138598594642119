import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Switch } from "DS/ui/switch";
import { Text } from "DS/custom/text";

import useManageSupplierRegularization from "Organisms/UBuyer/SupplierHeader/Regularization/useManageSupplierRegularization";

function RegularizationSwitch({ inRegularization }) {
  const { id } = useParams();
  const [regularization, setRegularization] = useState(!inRegularization);

  const [manageSupplierRegularization, { loading }] = useManageSupplierRegularization();

  const onSwitch = async () => {
    await manageSupplierRegularization({ id, regularization });
    setRegularization(!regularization);
  };

  return (
    <div className="flex flex-row items-center justify-between gap-4 rounded-lg border p-4">
      <div className="flex flex-col gap-1">
        <Text weight="medium">Fornecedor em regularização</Text>

        <Text size="small">Comunicar ao fornecedor sobre a cobrança de regularização das pendências.</Text>
      </div>

      <Switch disabled={loading} checked={!regularization} onCheckedChange={onSwitch} />
    </div>
  );
}

RegularizationSwitch.propTypes = {
  inRegularization: PropTypes.bool.isRequired,
};

export default RegularizationSwitch;
