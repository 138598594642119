import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

/** @internal */
export const QUERY = gql`
  query useSettingBuyerDocumentsOnCreation {
    buyer {
      id
      settingBuyerDocumentsOnCreation {
        id
        name
        hint
        risk
        order
        origin
        options
        optional
        fieldType
        displayName
      }
    }
  }
`;

const useSettingBuyerDocumentsOnCreation = () => {
  const { data, loading } = useQuery(QUERY);

  if (loading) return { loading, settingBuyerDocumentsOnCreation: [] };

  const { settingBuyerDocumentsOnCreation } = data.buyer;

  return { loading: false, settingBuyerDocumentsOnCreation };
};

export default useSettingBuyerDocumentsOnCreation;
