import React from "react";
import { useOutletContext } from "react-router-dom";

import { useCubeQuery } from "@cubejs-client/react";

import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";

import { infoPillar } from "Config/PUF";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import CardDetails from "Organisms/UBuyer/Companies/PUF/Analysis/CardDetails";

const SKELETON = (
  <div className="p-4 lg:p-10">
    <Skeleton className="mb-4 h-6 w-72" />

    <div className="flex items-start pb-6">
      <Skeleton className="mr-4 h-32 w-80" />
      <div className="flex flex-1 flex-col gap-2">
        <Skeleton className="mb-2 h-24 w-full" repeat={2} />
      </div>
    </div>
  </div>
);

function FiscalCompanyScale() {
  const { identifier } = useOutletContext();

  const member = "insights_financial.cnpj";
  const rating = "insights_financial.fiscal_company_scale";

  const { resultSet } = useCubeQuery({
    dimensions: [rating],
    filters: [{ member, operator: "equals", values: [identifier] }],
  });

  if (!resultSet) return SKELETON;

  const data = resultSet.rawData();
  const cube = data[0] || {};

  const { variant, text } = infoPillar(cube[rating]);

  return (
    <div>
      <Breadcrumb lastPage="Análise Linkana" currentPage="Porte" />

      <div className="mt-4 flex flex-wrap items-start pb-6 lg:flex-nowrap">
        <div className="flex max-w-[300px] flex-col items-start justify-center gap-1 py-4">
          <Text size="small" weight="medium">
            Itens avaliados
          </Text>

          <Text size="small">Informações analisadas pela Linkana para geração da pontuação do pilar.</Text>
        </div>

        <div className="flex flex-1 flex-col gap-2 p-4 pt-4">
          <CardDetails
            text={text}
            name="Porte"
            variant={variant}
            path="company-scale"
            description="Análise do porte fiscal como fator relevante para sua analisar sua estabilidade financeira."
          />
        </div>
      </div>
    </div>
  );
}

export default FiscalCompanyScale;
