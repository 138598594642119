import React from "react";
import { useParams } from "react-router-dom";

import { Download } from "lucide-react";

import { DropdownMenuItem } from "DS/ui/dropdown-menu";

import { Text } from "DS/custom/text";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useToast } from "DS/ui/use-toast";

/** @internal */
export const MUTATION = gql`
  mutation FormInfo($settingFormId: ID!) {
    createSettingFormReportCsv(input: { settingFormId: $settingFormId }) {
      errors {
        message
        path
      }
    }
  }
`;

function CreateSettingFormReportCsv() {
  const { toast } = useToast();
  const { settingFormId } = useParams();
  const [createSettingFormReportCsv, { loading }] = useMutation(MUTATION);

  const onClick = () => {
    createSettingFormReportCsv({ variables: { settingFormId } });

    toast({
      title: "Respostas exportadas com sucesso!",
      description: "Em breve você as receberá por e-mail.",
    });
  };

  return (
    <DropdownMenuItem onClick={onClick} disabled={loading}>
      <Text color="secondary">
        <Download strokeWidth={3} className="mr-2 h-4 w-4" />
      </Text>

      <Text weight="medium" color="secondary">
        Exportar respostas
      </Text>
    </DropdownMenuItem>
  );
}

export default CreateSettingFormReportCsv;
