import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { REQUIRED_STRING, REQUIRED_PASSWORD_WITH_REGEX } from "Config/Zod";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";

import { Form, FormItem, FormField, FormLabel, FormMessage, FormControl, FormDescription } from "DS/ui/form";

import login from "Services/Authentication/Login";

/** @internal */
export const MUTATION = gql`
  mutation BuyerSignupForm($name: String!, $token: String!, $password: String!) {
    createBuyer(input: { name: $name, token: $token, password: $password, termsOfService: true }) {
      user {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

const zodSchema = () => z.object({ name: REQUIRED_STRING, password: REQUIRED_PASSWORD_WITH_REGEX });

function BuyerSignupForm({ email }) {
  const { toast } = useToast();
  const { token } = useParams();
  const [createBuyer] = useMutation(MUTATION);
  const [submitting, setSubmitting] = useState(false);

  const defaultValues = { name: "", password: "" };
  const form = useForm({ resolver: zodResolver(zodSchema()), defaultValues });

  const onSubmit = async ({ name, password }) => {
    setSubmitting(true);

    const variables = { token, name, password, termsOfService: true };
    const { data } = await createBuyer({ variables });
    const { errors } = data.createBuyer;

    if (errors.length) {
      const title = "Erro no cadastro!";
      toast({ title, variant: "destructive", description: errors[0].message });
    } else {
      const { error } = await login({ email, password });

      if (error) {
        const title = "Erro no login!";
        toast({ title, variant: "destructive", description: error });
      }
    }

    setSubmitting(false);
  };

  return (
    <Form {...form}>
      <form id="BuyerSignupForm" className="my-4 flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          name="email"
          control={form.control}
          render={() => (
            <FormItem className="w-full">
              <div className="flex flex-col gap-1">
                <FormLabel>Seu e-mail</FormLabel>
                <Input value={email} disabled />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="name"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-full">
              <div className="flex flex-col gap-1">
                <FormLabel htmlFor="name">Seu nome completo</FormLabel>

                <FormControl>
                  <Input id="name" {...field} placeholder="Digite seu nome..." />
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="password"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-full">
              <div className="flex flex-col gap-1">
                <FormLabel htmlFor="password">Escolha uma senha</FormLabel>

                <FormControl>
                  <Input {...field} id="password" type="password" placeholder="Digite sua nova senha..." />
                </FormControl>

                <FormDescription>
                  Certifique-se de que a sua senha tenha pelo menos 8 caracteres; incluindo um número, uma letra
                  maiúscula, uma letra minúscula e um caractere especial.
                </FormDescription>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <Button type="submit" form="BuyerSignupForm" disabled={submitting}>
          Criar minha conta
        </Button>
      </form>
    </Form>
  );
}

BuyerSignupForm.propTypes = { email: PropTypes.string.isRequired };

export default BuyerSignupForm;
