import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

import { AlertTriangle } from "lucide-react";
import { Alert, AlertDescription } from "DS/ui/alert";
import { Anchor } from "DS/custom/anchor";

/** @internal */
export const QUERY = gql`
  query CategoryAlert($identifier: String!, $settingCategoryId: ID!) {
    buyer {
      id
      supplier: supplierByIdentifierAndSettingCategoryId(
        identifier: $identifier
        settingCategoryId: $settingCategoryId
      ) {
        id
      }
    }
  }
`;

function CategoryAlert({ settingCategoryId }) {
  const { state } = useLocation();
  const { identifier } = state || {};
  const [supplierId, setSupplierId] = useState(null);

  const [lazyQuery] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
    variables: { identifier, settingCategoryId },
  });

  useEffect(() => {
    if (!settingCategoryId || !identifier) return;

    async function fetchData() {
      const { data } = await lazyQuery();

      setSupplierId(data.buyer.supplier?.id);
    }

    fetchData();
  }, [identifier, lazyQuery, settingCategoryId]);

  if (!settingCategoryId || !supplierId) return null;

  return (
    <Alert variant="destructive">
      <AlertTriangle className="h-4 w-4 flex-none" />

      <AlertDescription>
        Este fornecedor já está cadastrado nessa categoria. Altere a categoria ou{" "}
        <Anchor className="text-red-600" to={`/companies/${supplierId}`}>
          clique aqui para visualizar a homologação.
        </Anchor>
      </AlertDescription>
    </Alert>
  );
}

CategoryAlert.defaultProps = { settingCategoryId: null };

CategoryAlert.propTypes = { settingCategoryId: PropTypes.string };

export default CategoryAlert;
