import React, { useState } from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation, useLazyQuery } from "@apollo/client";

import { Printer, Loader2 } from "lucide-react";

import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";

/** @internal */
export const MUTATION = gql`
  mutation DownloadSupplierReport($id: ID!) {
    downloadSupplierReport(input: { id: $id }) {
      supplier {
        id
      }
    }
  }
`;

/** @internal */
export const QUERY = gql`
  query DownloadSupplierReport($id: ID!) {
    supplierById(id: $id) {
      id
      reportPdf {
        id
        url
      }
    }
  }
`;

function DownloadSupplierReport() {
  const { id } = useParams();
  const { toast } = useToast();
  const [downloadSupplierReport] = useMutation(MUTATION);
  const [reportRequested, setReportRequested] = useState(false);

  const [downloadSupplierReportQuery, { stopPolling }] = useLazyQuery(QUERY, {
    variables: { id },
    pollInterval: 4000,
    onCompleted: ({ supplierById }) => {
      const { reportPdf } = supplierById;

      if (!reportPdf) return;

      window.open(reportPdf.url);

      stopPolling();
      setReportRequested(false);
    },
  });

  const onClick = async () => {
    setReportRequested(true);
    toast({ title: "Seu download iniciará em breve..." });

    await downloadSupplierReport({ variables: { id } });

    downloadSupplierReportQuery();
  };

  const Icon = reportRequested ? (
    <Loader2 className="mr-2 h-5 w-5 animate-spin" />
  ) : (
    <Printer className="mr-2 h-5 w-5" />
  );

  return (
    <Button type="button" variant="outline" onClick={onClick} disabled={reportRequested}>
      {Icon} Emitir relatório
    </Button>
  );
}

export default DownloadSupplierReport;
