import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";

import { Popover, PopoverTrigger, PopoverContent } from "DS/ui/popover";
import { Button } from "DS/ui/button";
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem } from "DS/ui/command";
import { ChevronsUpDown, Check } from "lucide-react";
import { cn } from "DS/lib/utils";

const CategoryCombobox = forwardRef(({ onChange, value, categories }, ref) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={ref}
          type="button"
          role="combobox"
          variant="outline"
          aria-expanded={open}
          className="flex w-full justify-between"
        >
          {value ? value.displayName : "Selecione"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-fit p-0">
        <Command>
          <CommandInput placeholder="Buscar..." />

          <CommandEmpty>Nenhuma categoria encontrada.</CommandEmpty>

          <CommandGroup className="max-h-56 overflow-auto">
            {categories.map((category) => {
              const { id, displayName } = value || {};

              const onSelect = (selectedValue) => {
                const sameDisplayName = selectedValue.toLowerCase() === displayName?.toLowerCase();

                onChange(sameDisplayName ? "" : category);

                setOpen(false);
              };

              const opacity = id === category.id ? "opacity-100" : "opacity-0";

              return (
                <CommandItem value={category} key={category.id} onSelect={onSelect}>
                  <Check className={cn("mr-2 h-4 w-4", opacity)} />
                  {category.displayName}
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
});

CategoryCombobox.defaultProps = {
  value: null,
};

CategoryCombobox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      hasSettingForm: PropTypes.bool,
    }),
  ).isRequired,
};

export default CategoryCombobox;
