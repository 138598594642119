import React from "react";
import PropTypes from "prop-types";

import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

import { AlignJustify, Trash2 } from "lucide-react";

import { FormControl, FormField, FormItem, FormMessage } from "DS/ui/form";
import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";

function PerformanceFormFormSortableQuestion({ form, index, question, showTrash, questionIndex, onDeleteQuestion }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question.id || question });

  if (question._destroy) return null;

  const style = { transition, transform: CSS.Transform.toString(transform) };

  const displayName = `settingPerformanceQuestionnairesAttributes.${index}.settingPerformanceQuestionsAttributes.${questionIndex}.displayName`;

  return (
    <div {...attributes} ref={setNodeRef} style={style}>
      <FormField
        name={displayName}
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <div className="flex flex-row items-center gap-2">
              <Button type="button" {...listeners} variant="ghost" className="p-0" aria-label="Drag to reorder">
                <AlignJustify size={24} />
              </Button>

              <FormControl>
                <Input {...field} id={displayName} placeholder="Digite a pergunta que deseja fazer..." />
              </FormControl>

              {showTrash ? (
                <Button type="button" variant="ghost" className="p-0" aria-label="Delete question">
                  <Trash2 size={24} className="text-red-600" onClick={() => onDeleteQuestion(index, questionIndex)} />
                </Button>
              ) : null}
            </div>

            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}

PerformanceFormFormSortableQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  showTrash: PropTypes.bool.isRequired,
  questionIndex: PropTypes.number.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  form: PropTypes.shape({ control: PropTypes.shape() }).isRequired,
  question: PropTypes.shape({
    id: PropTypes.string,
    _destroy: PropTypes.bool,
    displayName: PropTypes.string,
  }).isRequired,
};

export default PerformanceFormFormSortableQuestion;
