import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

/** @internal */
export const QUERY = gql`
  query useSettingCategories {
    buyer {
      id
      settingCategoriesEnabled {
        id
        displayName
        legalEntity
        hasSettingForms
      }
    }
  }
`;

const useSettingCategories = () => {
  const { data, loading } = useQuery(QUERY);

  if (loading) return { loading, settingCategories: [] };

  const { settingCategoriesEnabled } = data.buyer;

  return { loading: false, settingCategories: settingCategoriesEnabled };
};

export default useSettingCategories;
