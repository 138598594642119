import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import axios from "axios";

import { ArrowLeft } from "lucide-react";

import LogoSVG from "Assets/logo-brand.svg";
import BuyerLoginSVG from "Assets/buyer-login-min.svg";

import { Button } from "DS/ui/button";
import { Anchor } from "DS/custom/anchor";
import { Heading } from "DS/custom/heading";
import { useToast } from "DS/ui/use-toast";

function IdpLogin() {
  const [submitting, setSubmitting] = useState(false);
  const { setLayoutInfo } = useOutletContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    setLayoutInfo({
      svg: BuyerLoginSVG,
      heading: "Os melhores compradores confiam na Linkana",
      text: "Analise e homologue fornecedores em segundos, não semanas.",
    });
  }, [setLayoutInfo]);

  const onClick = async () => {
    setSubmitting(true);

    try {
      const email = state.email.toLowerCase();
      const { data } = await axios.post("/sso/idp", { email });
      window.location.assign(data.url);
    } catch (e) {
      const title = "Erro ao fazer login!";
      const description = "E-mail inválido ou SSO não disponível.";
      toast({ description, title, variant: "destructive" });
    }

    setSubmitting(false);
  };

  return (
    <>
      <img src={LogoSVG} alt="Linkana Logo" className="w-32" />

      <Anchor className="flex items-center gap-2" onClick={() => navigate(-1)}>
        <ArrowLeft size={16} className="text-blue-600" strokeWidth={2.5} />
        Voltar
      </Anchor>

      <Heading>Entrar com seu e-mail corporativo</Heading>

      <Button type="button" disabled={submitting} onClick={onClick}>
        Seguir para o login com e-mail corporativo
      </Button>
    </>
  );
}

export default IdpLogin;
