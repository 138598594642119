import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
} from "DS/ui/alert-dialog";

import { Button } from "DS/ui/button";

import AddUserWithoutIDPForm from "Organisms/UBuyer/Settings/Users/AddUserWithoutIDPForm";
import AddUserWithIDPForm from "Organisms/UBuyer/Settings/Users/AddUserWithIDPForm";

function AddUserButton({ idpConnection }) {
  const [open, setOpen] = useState(false);

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button type="button" size="sm" onClick={() => setOpen(true)}>
          Convidar usuário
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent className="max-h-screen overflow-y-auto">
        <AlertDialogHeader>
          <AlertDialogTitle>Convite de novo usuário</AlertDialogTitle>
        </AlertDialogHeader>

        {idpConnection ? (
          <AddUserWithIDPForm onOpenChange={setOpen} />
        ) : (
          <AddUserWithoutIDPForm onOpenChange={setOpen} />
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
}

AddUserButton.defaultProps = { idpConnection: null };

AddUserButton.propTypes = { idpConnection: PropTypes.string };

export default AddUserButton;
