import React from "react";
import PropTypes from "prop-types";

import { Label } from "DS/ui/label";
import { Switch } from "DS/ui/switch";

function SwitchFilter({ name, label, Icon, checked, onCheckedChange }) {
  return (
    <div className="flex w-full flex-row justify-between gap-4 p-1">
      <Label htmlFor={name} className="flex w-full flex-row items-center gap-2">
        <Icon size={16} /> {label}
      </Label>

      <Switch id={name} checked={checked} onCheckedChange={onCheckedChange} />
    </div>
  );
}

SwitchFilter.defaultProps = { checked: null };

SwitchFilter.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.shape({}).isRequired,
  onCheckedChange: PropTypes.func.isRequired,
};

export default SwitchFilter;
