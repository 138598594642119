const accentColors = ["#49C78B", "#61EC9F", "#60EBE1", "#FFCA58"];
const neutralColors = ["#425563", "#5F7A76", "#80746E", "#767676"];
const statusColors = {
  critical: "#FF4040",
  error: "#F04953",
  warning: "#FFAA15",
  ok: "#00C781",
  unknown: "#CCCCCC",
  disabled: "#CCCCCC",
};

// Default Grommet Colors
// https://v2.grommet.io/color

export const colors = {
  disabled: "#CCCCCC",
  warning: "#FFAA15",
  black: "#121212",
  "black-100": "rgba(18, 18, 18, 0.3)",
  "black-200": "rgba(18, 18, 18, 0.6)",
  "black-300": "rgba(18, 18, 18, 0.75)",
  "white-100": "rgba(255, 255, 255, 0.3)",
  "white-200": "rgba(255, 255, 255, 0.6)",
  "white-300": "rgba(255, 255, 255, 0.75)",
  "gray-100": "#FCFCFD",
  "gray-200": "#F2F4F7",
  "gray-300": "#E4E7EC",
  "gray-400": "#D0D5DD",
  "gray-500": "#98A2B3",
  "gray-600": "#667085",
  "gray-700": "#101828",
  brand: "#2563eb",
  "brand-shade-200": "#395DDC",
  "brand-shade-300": "#254AD2",
  "brand-shade-400": "#2041b8",
  "brand-shade-500": "#1b389d",
  "brand-tint-200": "#6480e4",
  "brand-tint-300": "#758ee7",
  "brand-tint-400": "#879cea",
  "brand-tint-500": "#98aaed",
  "new-brand": "#0A2342",
  focus: "#2563eb",
  text: {
    dark: "#f8f8f8",
    light: "#333333",
  },
  heading: {
    dark: "#f8f8f8",
    light: "#333333",
  },
  dark: "#333333",
  red: "#E15353",
  error: "#F04953",
  "error-100": "#ec7063",
  "error-200": "#e95e50",
  "error-300": "#E74C3C",
  "error-400": "#e43422",
  "warning-100": "#fbc7af",
  "warning-200": "#f89d72",
  "warning-300": "#F57336",
  "warning-400": "#f45f1a",
  "success-100": "#d5f5e5",
  "success-200": "#6bdda4",
  "success-300": "#2fca7d",
  "success-400": "#2ab46f",
  "info-100": "#DCEDFC",
  "info-200": "#3c94e9",
  "info-300": "#1465B4",
  "info-400": "#0F4E8C",
  "light-0": "#444444",
  "light-1": "#F6F6F6",
  "light-4": "#DADADA",
  "gray-3": "#828282",
  "gray-6": "#F2F2F2",
  "dark-4": "#999999",
};

const colorArray = (array, prefix) =>
  array.forEach((color, index) => {
    colors[`${prefix}-${index + 1}`] = color;
  });

colorArray(accentColors, "accent");
colorArray(neutralColors, "neutral");
Object.keys(statusColors).forEach((color) => {
  colors[`status-${color}`] = statusColors[color];
});

export default {
  global: {
    colors,
    elevation: {
      light: {
        sidebar: "inset -8px 0px 8px rgba(0, 0, 0, 0.15)",
        sidebarIcon: "8px 0px 8px rgba(0, 0, 0, 0.15)",
      },
      dark: {
        sidebar: "inset -8px 0px 8px rgba(0, 0, 0, 0.15)",
        sidebarIcon: "8px 0px 8px rgba(0, 0, 0, 0.15)",
      },
    },
    drop: {
      background: "white",
    },
  },

  anchor: {
    textDecoration: "none",
    fontWeight: "bold",
    color: {
      dark: colors.brand,
      light: colors.brand,
    },
  },
  checkBox: {
    check: {
      invert: true,
    },
  },
  button: {
    color: "white",
    extend: () => `
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `,
  },
  table: {
    body: {
      pad: { horizontal: "small", vertical: "small" },
    },
    footer: {
      pad: { horizontal: "small", vertical: "small" },
    },
    header: {
      fill: "horizontal",
      pad: { horizontal: "small", vertical: "xsmall" },
      verticalAlign: "middle",
    },
  },

  accordion: {
    icons: {
      color: "brand",
    },
    border: undefined,
  },
};
