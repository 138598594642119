import React, { useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";

function LoginLayout() {
  const { state, pathname } = useLocation();
  const [layoutInfo, setLayoutInfo] = useState({});

  if (!state && pathname !== "/login") return <Navigate to="/" />;

  const { svg, heading, text } = layoutInfo;

  return (
    <div className="flex flex-row items-center justify-between gap-10">
      <div className="flex flex-1">
        <div className="m-auto flex h-screen w-80 flex-col justify-center gap-6 lg:w-96">
          <Outlet context={{ setLayoutInfo }} />
        </div>
      </div>

      <div className="hidden h-screen flex-1 flex-col items-center justify-center bg-blue-800/90 lg:flex">
        <img alt="Login SVG" src={svg} />

        <Heading className="max-w-md text-center text-white">{heading}</Heading>

        {text ? <Text className="mt-1 text-white">{text}</Text> : null}
      </div>
    </div>
  );
}

export default LoginLayout;
