import React from "react";
import PropTypes from "prop-types";

import { Building, UserSquare2, Globe, AlertTriangle, MessageCircle, User } from "lucide-react";

import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

import { NATIONAL_PF, NATIONAL_PJ, INTERNATIONAL } from "Config/LegalEntity";

import SimpleMenu from "Organisms/UBuyer/SupplierHeader/SimpleMenu";
import UpdateTags from "Organisms/UBuyer/Shared/SupplierSimpleHeader/UpdateTags";
import RatingMeter from "Organisms/UBuyer/Shared/SupplierSimpleHeader/RatingMeter";
import DownloadSupplierReport from "Organisms/UBuyer/SupplierHeader/DownloadSupplierReport";
import CategoriesListAnchor from "Organisms/UBuyer/Shared/SupplierSimpleHeader/CategoriesListAnchor";

import { STATES_NAME as SUPPLIER_STATES_NAME } from "Config/Supplier";
import { STATES_NAME as QUALIFICATION_STATES_NAME } from "Config/Qualification";

import formatDate from "Services/DateToString";

const ICONS = {
  [INTERNATIONAL]: Globe,
  [NATIONAL_PJ]: Building,
  [NATIONAL_PF]: UserSquare2,
};

function RegularSupplierInfo({ supplier }) {
  const Icon = ICONS[supplier.legalEntity];

  const { qualification } = supplier;

  return (
    <div className="flex max-w-[1500px] flex-row flex-wrap items-center justify-between">
      <div className="flex flex-row flex-wrap items-center gap-4">
        <RatingMeter />

        <div className="flex flex-col gap-2">
          <CategoriesListAnchor />

          <Heading level={3} className="max-w-3xl">
            {supplier.name.toUpperCase()}
          </Heading>

          <div className="flex flex-row flex-wrap items-center divide-x-2">
            <div className="flex flex-row items-center gap-2">
              <Icon size={20} className="text-slate-700" />
              <Text className="pr-3" color="secondary">
                {supplier.identifierMasked}
              </Text>
            </div>

            <div className="flex flex-row items-center gap-2 px-3">
              <Text>Pendências:</Text>
              <Text className="text-zinc-500">{SUPPLIER_STATES_NAME[supplier.state]}</Text>
            </div>

            <div className="flex flex-row items-center gap-2 px-3">
              <Text>Homologação:</Text>
              <Text className="text-zinc-500">
                {QUALIFICATION_STATES_NAME[qualification.state]} desde{" "}
                {formatDate(qualification.decisionAt || qualification.createdAt)}
              </Text>

              {qualification.decisionReason || qualification.decisionJustification ? (
                <WrappedTooltip
                  message={
                    <div className="flex flex-col gap-2 w-80 p-2">
                      {qualification.decisionReason ? (
                        <div>
                          <p className="text-base font-bold text-gray-700">Justificativa da decisão</p>
                          <p>{qualification.decisionReason}</p>
                        </div>
                      ) : null}

                      {qualification.decisionJustification ? (
                        <div>
                          <p className="text-base font-bold text-gray-700">Comentário da decisão</p>
                          <p>{qualification.decisionJustification}</p>
                        </div>
                      ) : null}

                      <div className="flex flex-row items-center gap-2">
                        <User size={20} className="text-slate-700" />
                        <p>{qualification.userEmail}</p>
                      </div>
                    </div>
                  }
                >
                  <MessageCircle size={16} className="ml-2 text-primary" />
                </WrappedTooltip>
              ) : null}
            </div>

            {supplier.regularization ? (
              <div className="flex flex-row items-center gap-2 px-3">
                <AlertTriangle size={20} className="text-slate-700" />
                <Text color="secondary">Em regularização</Text>
              </div>
            ) : null}
          </div>

          <UpdateTags />
        </div>
      </div>

      <div className="mt-2 flex flex-row items-center gap-2">
        <DownloadSupplierReport />

        <SimpleMenu id={supplier.id} />
      </div>
    </div>
  );
}

RegularSupplierInfo.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
    stateI18n: PropTypes.string,
    legalEntity: PropTypes.string,
    regularization: PropTypes.bool,
    leadTimeMessage: PropTypes.string,
    identifierMasked: PropTypes.string,
    stateUpdatedAtI18n: PropTypes.string,
    qualification: PropTypes.shape({
      id: PropTypes.string,
      state: PropTypes.string,
      createdAt: PropTypes.string,
      userEmail: PropTypes.string,
      decisionAt: PropTypes.string,
      decisionReason: PropTypes.string,
      decisionJustification: PropTypes.string,
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        settingTagId: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default RegularSupplierInfo;
