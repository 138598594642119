import React from "react";

import { Card, CardContent } from "DS/custom/card";

import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";

function EmptyList() {
  return (
    <div className="flex flex-col items-center space-y-8 p-6 pt-16">
      <Card className="w-fit">
        <CardContent>
          <div className="flex flex-col gap-4">
            <Skeleton className="h-6 w-36" />
            <Skeleton className="h-6 w-52" repeat={3} />
          </div>
        </CardContent>
      </Card>
      <div className="space-y-2  text-center">
        <Text weight="medium">Categoria vazia!</Text>
        <Text color="secondary">Você não possui informações adicionadas nesta categoria.</Text>
      </div>
    </div>
  );
}

export default EmptyList;
