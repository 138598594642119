import React, { useState } from "react";
import PropTypes from "prop-types";

import { PlusCircle } from "lucide-react";

import { CommandDialog, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem } from "DS/ui/command";

import { useToast } from "DS/ui/use-toast";
import { Button } from "DS/ui/button";
import { Badge } from "DS/custom/badge";

const AddNewDocument = React.forwardRef(({ settingGroupers, value, onChange, name }, ref) => {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();

  const onSelect = (settingDocumentId, settingGrouper) => {
    const parsedValue = { ...value };

    parsedValue[settingDocumentId] = "0";

    setOpen(false);

    toast({
      title: "Informação adicionada com sucesso!",
      description: `A sua informação foi adicionada com sucesso no grupo de revisão ${settingGrouper}`,
    });

    onChange({ target: { name, value: parsedValue } });
  };

  return (
    <div>
      <Button
        ref={ref}
        variant="outline"
        type="button"
        role="combobox"
        onClick={(event) => {
          event.preventDefault();
          setOpen(true);
        }}
      >
        <PlusCircle className="mr-2 h-4 w-5" />
        Adicionar documento
      </Button>

      <CommandDialog open={open} onOpenChange={setOpen} modal={false}>
        <CommandInput placeholder="Buscar..." />

        <CommandList>
          <CommandEmpty>Nenhum documento encontrado.</CommandEmpty>

          {settingGroupers.map((settingGrouper) => {
            if (settingGrouper.settingDocuments.length === 0) return null;

            return (
              <CommandGroup key={settingGrouper.id} heading={settingGrouper.displayName}>
                {settingGrouper.settingDocuments.map((settingDocument) => {
                  if (value[settingDocument.id]) return null;

                  return (
                    <CommandItem
                      key={settingDocument.id}
                      value={settingDocument.displayName}
                      onSelect={() => onSelect(settingDocument.id, settingGrouper.displayName)}
                    >
                      {settingDocument.displayName}

                      {settingDocument.type === "Settings::SettingForm" ? (
                        <Badge variant="secondary" className="ml-2 flex-none">
                          Enviado pelo fornecedor
                        </Badge>
                      ) : null}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            );
          })}
        </CommandList>
      </CommandDialog>
    </div>
  );
});

AddNewDocument.defaultProps = { value: {} };

AddNewDocument.propTypes = {
  value: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  settingGroupers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      settingDocuments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.string,
          displayName: PropTypes.string,
          availableForInternational: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
};

export default AddNewDocument;
