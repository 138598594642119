import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "DS/ui/table";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "DS/ui/dropdown-menu";

import { MoreVertical, KeyRound } from "lucide-react";

import { Avatar, AvatarImage, AvatarFallback } from "DS/ui/avatar";
import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import RemoveUserMutation from "Organisms/UBuyer/Settings/Users/RemoveUserMutation";
import UserSettingGroupersBadges from "Organisms/UBuyer/Settings/Users/UserSettingGroupersBadges";

import { firstInitialsFromName } from "Services/MaskUtil";

function UsersTable({ users, refetch }) {
  const navigate = useNavigate();

  return (
    <div className="rounded-md border border-slate-200">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">
              <Text size="small" weight="medium">
                Usuário
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                E-mail
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Grupos de revisão
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Ações
              </Text>
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell className="flex flex-row items-center gap-2 p-4">
                <Avatar>
                  <AvatarImage src={user.photoUrl} />
                  <AvatarFallback>{firstInitialsFromName(user.name)}</AvatarFallback>
                </Avatar>
                <Text size="small" weight="medium">
                  {user.name}
                </Text>
                {user.owner ? <Badge variant="secondary">Administrador</Badge> : null}
              </TableCell>

              <TableCell>
                <Text size="small">{user.email}</Text>
              </TableCell>

              <TableCell>
                <UserSettingGroupersBadges settingGroupers={user.settingGroupers} />
              </TableCell>

              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <MoreVertical />
                  </DropdownMenuTrigger>

                  <DropdownMenuContent className="ml-6">
                    <DropdownMenuItem
                      onSelect={(e) => e.preventDefault()}
                      onClick={() => {
                        navigate(user.id);
                      }}
                      className="flex flex-row items-center gap-2"
                    >
                      <KeyRound size={16} className="text-slate-700" />
                      <Text size="small" color="secondary">
                        Ver permissões
                      </Text>
                    </DropdownMenuItem>

                    <OwnerChecker>
                      <DropdownMenuSeparator />

                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <RemoveUserMutation singleUser={user} refetch={refetch} />
                      </DropdownMenuItem>
                    </OwnerChecker>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      owner: PropTypes.bool,
      name: PropTypes.string,
      email: PropTypes.string,
      photoUrl: PropTypes.string,
      settingGroupers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default UsersTable;
