import KEY from "Services/Authentication/Config";

// TODO LEGACY
export default {
  isAuthenticated() {
    return !!localStorage.getItem(KEY);
  },

  get() {
    return localStorage.getItem(KEY);
  },

  destroy() {
    localStorage.removeItem(KEY);
  },
};
