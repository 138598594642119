import React, { useState } from "react";
import PropTypes from "prop-types";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { Trash } from "lucide-react";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";

import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
} from "DS/ui/alert-dialog";

/** @internal */
export const MUTATION = gql`
  mutation removeUserMutation($id: ID!) {
    removeUser(input: { id: $id }) {
      errors {
        message
        path
      }
    }
  }
`;

function RemoveUserMutation({ singleUser, refetch }) {
  const { toast } = useToast();
  const [removeUser, { loading }] = useMutation(MUTATION);
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const onClick = async () => {
    const { id } = singleUser;
    const { data } = await removeUser({ variables: { id } });
    const [error] = data.removeUser.errors;

    if (error) {
      let description = error.message;
      const title = "Erro na exclusão do usuário!";

      if (error.message.includes("setting workflow steps"))
        description = "Usuário presente nas configurações de fluxos de aprovação";

      toast({ title, description, variant: "destructive" });
    } else {
      toast({ title: "Usuário excluído com sucesso!" });
      refetch();
    }
  };

  return (
    <AlertDialog open={showAlertDialog}>
      <AlertDialogTrigger onClick={() => setShowAlertDialog(true)}>
        <div className="flex flex-row items-center gap-2">
          <Trash size={16} className="text-red-600" />
          <Text size="small" className="text-red-600">
            Excluir usuário
          </Text>
        </div>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Tem certeza que deseja excluir este usuário?</AlertDialogTitle>

          <AlertDialogDescription>Ao excluir este usuário, ele não terá mais acesso à Linkana.</AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <Button type="button" onClick={onClick} disabled={loading} variant="destructive">
            Excluir
          </Button>

          <Button type="button" variant="outline" disabled={loading} onClick={() => setShowAlertDialog(false)}>
            Cancelar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

RemoveUserMutation.propTypes = {
  singleUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    owner: PropTypes.bool.isRequired,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default RemoveUserMutation;
