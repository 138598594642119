import React, { useState } from "react";
import PropTypes from "prop-types";

import Pagination from "Organisms/UBuyer/Shared/Pagination/Pagination";

function Paginator({ refetch, pageInfo, total, itemsPerPage }) {
  const [page, setPage] = useState(0);

  const previousPage = () => {
    setPage(page - 1);

    refetch({
      after: null,
      first: null,
      last: itemsPerPage,
      before: pageInfo.startCursor,
    });
  };

  const nextPage = () => {
    setPage(page + 1);

    refetch({
      last: null,
      before: null,
      first: itemsPerPage,
      after: pageInfo.endCursor,
    });
  };

  if (total <= itemsPerPage) return null;

  return (
    <div className="flex w-max justify-end self-end">
      <Pagination
        page={page}
        setPage={setPage}
        total={total}
        nextPage={nextPage}
        pageSize={itemsPerPage}
        previousPage={previousPage}
      />
    </div>
  );
}

Paginator.propTypes = {
  pageInfo: PropTypes.shape({
    hasPreviousPage: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    endCursor: PropTypes.string,
    startCursor: PropTypes.string,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default Paginator;
