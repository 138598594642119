import React from "react";
import { Outlet } from "react-router-dom";

import { Users, Lightbulb, ListChecks, BarChartBig } from "lucide-react";

import UserContext from "Organisms/Shared/UserContext/UserContext";
import CubeBuyerProvider from "Organisms/UBuyer/Shared/Cube/CubeBuyerProvider";
import MobileNavbar from "Organisms/Shared/Navigation/BuyerSidebar/MobileNavbar";
import BuyerSidebar from "Organisms/Shared/Navigation/BuyerSidebar/BuyerSidebar";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const UNIMED_FESP_ID = "ab1901a9-79fa-40b2-b6ab-04a84355b98c";

/** @internal */
export const QUERY = gql`
  query buyerSideBar {
    user {
      id
      name
      email
      photoUrl
    }
    buyer {
      id
      name
      logoUrl
    }
  }
`;

function MainContainerBuyer() {
  const { loading, data } = useQuery(QUERY);

  if (loading) return null;

  const { buyer, user } = data;
  const { id } = buyer;

  const SUPPLIERS = {
    Icon: Users,
    turbo: false,
    path: `/companies`,
    label: "Fornecedores",
    matchPath: /^\/companies/,
  };

  const INSIGHTS = {
    turbo: true,
    Icon: Lightbulb,
    label: "Insights",
    matchPath: /^\/insights/,
    path: `/buyers/${id}/insights/onboarding`,
  };

  const TASKS = {
    turbo: true,
    Icon: ListChecks,
    label: "Tarefas",
    matchPath: /^\/tasks/,
    path: `/buyers/${id}/tasks`,
  };

  const DASHBOARDS = {
    turbo: true,
    Icon: BarChartBig,
    label: "Dashboard",
    matchPath: /^\/dashboard/,
    path: `/buyers/${id}/dashboards/summary`,
  };

  let buttons = [SUPPLIERS, TASKS, DASHBOARDS, INSIGHTS];

  if (buyer.id === UNIMED_FESP_ID) {
    buttons = [SUPPLIERS, DASHBOARDS, INSIGHTS];
  }

  return (
    <CubeBuyerProvider buyerId={id}>
      <div className="flex min-h-screen flex-row">
        <BuyerSidebar buyer={buyer} buttons={buttons} />

        <div className="flex flex-grow flex-col lg:ml-56">
          <div className="flex min-w-0 max-w-[100vw] flex-1 flex-col overflow-hidden">
            <MobileNavbar buyer={buyer} buttons={buttons} />

            <main className="flex flex-1 overflow-hidden">
              <section className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                <UserContext.Provider value={user}>
                  <Outlet />
                </UserContext.Provider>
              </section>
            </main>
          </div>
        </div>
      </div>
    </CubeBuyerProvider>
  );
}

export default MainContainerBuyer;
