import React, { useState, useContext } from "react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";

import { Context } from "Organisms/UBuyer/Companies/Index/SuppliersSearch/SupplierSearchContext";

import Pagination from "Organisms/UBuyer/Shared/Pagination/Pagination";
import EmptySearch from "Organisms/UBuyer/Shared/EmptySearch/EmptySearch";
import EmptyTable from "Organisms/UBuyer/Companies/Index/SuppliersSearch/EmptyTable";
import SuppliersTable from "Organisms/UBuyer/Companies/Index/SuppliersSearch/SuppliersTable";

/** @internal */
export const QUERY = gql`
  query Search($last: Int, $first: Int, $after: String, $before: String, $filters: SuppliersConnectionInput!) {
    buyer {
      id
      supplierPaymentStripeLink
    }
    suppliersConnection(last: $last, first: $first, after: $after, before: $before, filters: $filters) {
      total
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          cnpj
          state
          rating
          addedBy
          blockedAt
          stateI18n
          connected
          ratingLabel
          legalEntity
          regularization
          pendingPayment
          displayCategory
          identifierMasked
          stateUpdatedAtI18n
          qualification {
            id
            state
            withWorkflow
          }
          tags {
            id
            displayName
          }
        }
      }
    }
  }
`;

const ITEMS_PER_PAGE = 8;

const SKELETON = (
  <div className="space-y-4">
    <Skeleton className="h-16 w-full" repeat={ITEMS_PER_PAGE} />
  </div>
);

function Search() {
  const { search } = useContext(Context);
  const [page, setPage] = useState(0);

  const filters = {
    ...search,
    term: undefined,
    settingCategories: undefined,
    searchTerm: search.term.substring(0, 128),
    settingCategoryIds: search.settingCategories,
  };

  const { loading, data, refetch } = useQuery(QUERY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { filters, first: ITEMS_PER_PAGE },
  });

  if (loading) return SKELETON;

  const { buyer, suppliersConnection } = data || {};

  const { supplierPaymentStripeLink } = buyer || {};
  const { edges, pageInfo, total } = suppliersConnection || {};

  const withoutFilters =
    !filters.active &&
    !filters.blocked &&
    !filters.addedByMe &&
    !filters.searchTerm &&
    !filters.states.length &&
    !filters.regularization &&
    !filters.pendingPayment &&
    !filters.workflowRequested &&
    !filters.ratingLabels.length &&
    !filters.settingTagIds.length &&
    !filters.qualificationStates.length &&
    !filters.settingCategoryIds.length;

  if (!total && withoutFilters) return <EmptyTable />;

  const previousPage = () => {
    setPage(page - 1);
    refetch({ after: null, first: null, last: ITEMS_PER_PAGE, before: pageInfo.startCursor });
  };

  const nextPage = () => {
    setPage(page + 1);
    refetch({ last: null, before: null, first: ITEMS_PER_PAGE, after: pageInfo.endCursor });
  };

  return (
    <div className="flex flex-col">
      <SuppliersTable edges={edges} supplierPaymentStripeLink={supplierPaymentStripeLink} />

      {total ? (
        <div className="mt-4 flex w-max justify-end self-end">
          <Pagination
            page={page}
            total={total}
            setPage={setPage}
            nextPage={nextPage}
            pageSize={ITEMS_PER_PAGE}
            previousPage={previousPage}
          />
        </div>
      ) : (
        <EmptySearch />
      )}
    </div>
  );
}

export default Search;
