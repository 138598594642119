import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import { Text } from "DS/custom/text";
import { RadioGroup, RadioGroupItem } from "DS/ui/radio-group";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import OwnerTooltip from "Organisms/UBuyer/Settings/Shared/OwnerTooltip";
import useOwnerChecker from "Organisms/Shared/Authorization/useOwnerChecker";
import RestrictionListPeople from "Organisms/UBuyer/Settings/Advanced/RestrictionListPeople";
import RestrictionListCompanies from "Organisms/UBuyer/Settings/Advanced/RestrictionListCompanies";

const preStateReview = [
  { label: "Ativado", value: true },
  { label: "Desativado", value: false },
];

function FormFields({ buyer }) {
  const { control } = useFormContext();
  const { loading, owner } = useOwnerChecker();

  if (loading) return null;

  return (
    <div className="space-y-4">
      <OwnerTooltip owner={owner}>
        <div className="space-y-4 border-b pb-5">
          <RestrictionListPeople buyer={buyer} disabled={!owner} />
          <RestrictionListCompanies buyer={buyer} disabled={!owner} />
        </div>
      </OwnerTooltip>

      <FormField
        control={control}
        name="preStateReview"
        render={({ field }) => (
          <OwnerTooltip owner={owner}>
            <FormItem>
              <FormLabel>Revisão manual de problemas em consultas automáticas</FormLabel>

              <div className="max-w-lg">
                <Text size="small" color="secondary">
                  Quando ativada, esta configuração permite que o usuário revisor avalie manualmente uma reprovação
                  automática de documento feita pela Linkana.
                </Text>
              </div>

              <FormControl>
                <RadioGroup disabled={!owner} defaultValue={field.value} onValueChange={field.onChange}>
                  {preStateReview.map(({ label, value }) => (
                    <FormItem key={`option-${value}`} className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value={value} />
                      </FormControl>

                      <FormLabel>{label}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          </OwnerTooltip>
        )}
      />
    </div>
  );
}

FormFields.propTypes = {
  buyer: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default FormFields;
