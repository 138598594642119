import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";

import { InfoIcon } from "lucide-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { RadioGroup, RadioGroupItem } from "DS/ui/radio-group";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import { HoverCard, HoverCardContent, HoverCardTrigger } from "DS/ui/hover-card";

/** @internal */
export const QUERY = gql`
  query FormAdvancedFormFields($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      n8nWebhook
      svixApplicationKey
    }
  }
`;

function FormAdvancedFormFields({ form }) {
  const { buyerId } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return null;

  const { n8nWebhook, svixApplicationKey } = data.buyer;

  return (
    <>
      <FormField
        control={form.control}
        name="expirable"
        render={({ field }) => (
          <FormItem>
            <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
              <FormLabel className="flex flex-row items-start gap-1 font-medium">
                Controle de vencimento
                <HoverCard openDelay={200} closeDelay={100}>
                  <HoverCardTrigger className="text-blue-600">
                    <InfoIcon size={12} className="text-primary" />
                  </HoverCardTrigger>

                  <HoverCardContent>
                    Uma nova versão do documento é solicitada 30 dias antes da validade definida.
                  </HoverCardContent>
                </HoverCard>
              </FormLabel>

              <FormControl>
                <RadioGroup defaultValue={field.value} onValueChange={field.onChange} className="flex flex-col gap-1">
                  <FormItem className="flex flex-row items-end">
                    <FormControl>
                      <RadioGroupItem value={false} />
                    </FormControl>

                    <FormLabel className="pl-2">Não</FormLabel>
                  </FormItem>

                  <FormItem className="flex flex-row items-end">
                    <FormControl>
                      <RadioGroupItem value />
                    </FormControl>

                    <FormLabel className="pl-2">Sim (opcional)</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>

              <FormMessage />
            </div>
          </FormItem>
        )}
      />

      {n8nWebhook || svixApplicationKey ? (
        <FormField
          control={form.control}
          name="triggerWebhookAfterExpiredReview"
          render={({ field }) => (
            <FormItem>
              <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                <FormLabel className="flex flex-row items-start gap-1 font-medium">
                  Reenvio automático de integração
                  <HoverCard openDelay={200} closeDelay={100}>
                    <HoverCardTrigger className="text-blue-600">
                      <InfoIcon size={12} className="text-primary" />
                    </HoverCardTrigger>

                    <HoverCardContent>
                      Caso uma nova versão deste campo seja aprovada, vamos disparar novamente o webhook de integração
                      do fornecedor para o sistema conectado via integração.
                    </HoverCardContent>
                  </HoverCard>
                </FormLabel>

                <FormControl>
                  <RadioGroup defaultValue={field.value} onValueChange={field.onChange} className="flex flex-row gap-3">
                    <FormItem className="flex flex-row items-end">
                      <FormControl>
                        <RadioGroupItem value={false} />
                      </FormControl>

                      <FormLabel className="pl-2">Não reenviar</FormLabel>
                    </FormItem>

                    <FormItem className="flex flex-row items-end">
                      <FormControl>
                        <RadioGroupItem value />
                      </FormControl>

                      <FormLabel className="pl-2">Reenviar</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      ) : null}
    </>
  );
}

FormAdvancedFormFields.propTypes = {
  form: PropTypes.shape({ control: PropTypes.shape() }).isRequired,
};

export default FormAdvancedFormFields;
