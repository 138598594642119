import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

import { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectItem } from "DS/ui/select";

function LayoutSelect({ routes }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const rootRoute = pathname.split("/")[4] || "forms";

  return (
    <div key={pathname} className="mb-2 lg:mb-6 px-4 lg:hidden">
      <Select defaultValue={rootRoute} onValueChange={(path) => navigate(`../${path}`)}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>

        <SelectContent>
          <SelectGroup>
            {routes.map(({ key, value }) => (
              <SelectItem key={`form-select-${key}`} value={key}>
                {value}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

LayoutSelect.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })).isRequired,
};

export default LayoutSelect;
