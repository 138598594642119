import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";

import { Popover, PopoverTrigger, PopoverContent } from "DS/ui/popover";
import { Button } from "DS/ui/button";
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem } from "DS/ui/command";
import { ChevronsUpDown, Check } from "lucide-react";

import { cn } from "DS/lib/utils";
import { COUNTRIES_LIST } from "Config/Supplier";

const CountryCombobox = forwardRef(({ onChange, value }, ref) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={ref}
          type="button"
          role="combobox"
          variant="outline"
          aria-expanded={open}
          className="flex w-full justify-between"
        >
          {value || "Selecione"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-fill p-0">
        <Command>
          <CommandInput placeholder="Search framework..." />

          <CommandEmpty>Nenhuma categoria encontrada.</CommandEmpty>

          <CommandGroup className="max-h-56 overflow-auto">
            {COUNTRIES_LIST.map((country) => {
              const onSelect = (selectedValue) => {
                onChange(selectedValue === value ? "" : country);
                setOpen(false);
              };

              const opacity = value === country ? "opacity-100" : "opacity-0";

              return (
                <CommandItem key={country} value={country} onSelect={onSelect}>
                  <Check className={cn("mr-2 h-4 w-4", opacity)} />
                  {country}
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
});

CountryCombobox.defaultProps = { value: null };

CountryCombobox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CountryCombobox;
