import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import z from "zod";
import { REQUIRED_STRING } from "Config/Zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";
import Skeleton from "DS/custom/skeleton";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import { CATEGORY_OPTIONS_LABEL } from "Config/LegalEntity";

import DocumentsCheckboxes from "Organisms/UBuyer/Settings/Categories/DocumentsCheckboxes";

/** @internal */
export const QUERY = gql`
  query categoryForm($buyerId: ID!, $legalEntity: String!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingGroupers {
        id
        displayName
        settingDocuments(legalEntity: $legalEntity) {
          id
          type
          displayName
          risk: defaultRisk
          availableForInternational
        }
      }
    }
  }
`;

const SKELETON = (
  <div className="p-4 lg:p-10">
    <div className="flex flex-row flex-wrap items-center justify-between">
      <Skeleton className="h-6 w-64" />
      <div className="flex flex-row gap-4">
        <Skeleton className="h-10 w-16" />
        <Skeleton className="h-10 w-20" />
        <Skeleton className="h-10 w-16" />
      </div>
    </div>

    <div className="mt-4 border-b border-slate-100 py-2 pb-6 pr-6">
      <div className="flex flex-row gap-1">
        <div className="flex w-80 flex-col gap-2 text-sm">
          <Skeleton className="h-4 w-32" />
          <Skeleton className="h-4 w-64" />
        </div>
        <Skeleton className="h-10 w-80" />
      </div>
    </div>

    <div className="mt-4 flex flex-row items-center justify-center gap-2">
      <Skeleton className="h-8 w-36" />
      <Skeleton className="h-8 w-40" />
    </div>

    <div className="flex flex-row gap-8 py-2">
      <div className="flex w-72 flex-col gap-2 text-sm">
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-52 w-80" />
      </div>
      <Skeleton className="m-6 h-96 w-full" />
    </div>
  </div>
);

const formSchema = z.object({
  displayName: REQUIRED_STRING,
  legalEntity: REQUIRED_STRING,
  keepActiveOnSupplierReview: z.boolean(),
  settingDocuments: z.record(z.string()).optional(),
});

function CategoryForm({ onSubmit, submitting, legalEntity }) {
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const { data, loading } = useQuery(QUERY, {
    variables: { buyerId, legalEntity },
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      legalEntity,
      displayName: "",
      settingDocuments: {},
      keepActiveOnSupplierReview: true,
    },
  });

  if (loading) return SKELETON;

  const { settingGroupers } = data.buyer;

  return (
    <Form {...form}>
      <form
        id="CategoryNewForm"
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8 lg:px-10 lg:py-6"
      >
        <Breadcrumb levels={["Categorias", "Nova categoria", CATEGORY_OPTIONS_LABEL[legalEntity]]} />

        <div>
          <div className="space-y-4 border-b border-slate-100 pb-5">
            <Heading level={4}>Geral</Heading>

            <FormField
              control={form.control}
              name="displayName"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                    <FormLabel className="font-medium" htmlFor="displayName">
                      Nome da categoria
                    </FormLabel>

                    <FormControl>
                      <Input id="displayName" placeholder="Digite um nome" {...field} />
                    </FormControl>

                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>

          <div className="pt-5">
            <FormField
              control={form.control}
              name="settingDocuments"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-col gap-4">
                    <div>
                      <Heading level={4}>Documentos</Heading>
                    </div>

                    <FormControl>
                      <DocumentsCheckboxes {...field} settingGroupers={settingGroupers} />
                    </FormControl>
                  </div>
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className="flex flex-row gap-4">
          <Button form="CategoryNewForm" type="submit" disabled={submitting}>
            Criar
          </Button>

          <Button type="button" variant="outline" onClick={() => navigate(-1)}>
            Cancelar
          </Button>
        </div>
      </form>
    </Form>
  );
}

CategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  legalEntity: PropTypes.string.isRequired,
};

export default CategoryForm;
