import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

import { ArrowLeft } from "lucide-react";

import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";

function Sidebar({ groups }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClick = (path, turbo) => {
    if (turbo) {
      window.location = path;
    } else {
      navigate(path);
    }
  };

  return (
    <div className="z-10 hidden h-screen overflow-auto w-56 flex-col justify-between border-r bg-white p-4 py-6 lg:fixed lg:flex">
      <div className="flex flex-col gap-6">
        <Button
          type="button"
          onClick={() => onClick("/")}
          variant="ghost"
          className="px-7 justify-start gap-2 p-0 hover:bg-transparent"
        >
          <ArrowLeft className="text-zinc-950" size={20} />{" "}
          <Text weight="medium" size="small">
            Configurações
          </Text>
        </Button>

        <div className="flex flex-col space-y-6">
          {groups.map(({ title, Icon, items }) => (
            <div className="space-y-2" key={`group-${title}`}>
              <div className="flex flex-row space-x-2.5 px-1">
                <Icon size={14} className="text-zinc-500 " />{" "}
                <Text size="xsmall" weight="medium" className="text-zinc-500 uppercase">
                  {title}
                </Text>
              </div>
              <div className="flex flex-col">
                {items.map(({ path, label, matchPath, turbo }) => {
                  const selected = matchPath.test(pathname);

                  return (
                    <Button
                      type="button"
                      key={label}
                      onClick={() => onClick(path, turbo)}
                      variant="ghost"
                      className={`px-7 justify-start ${selected ? "bg-accent" : ""}`}
                    >
                      <Text size="small" weight="medium">
                        {label}
                      </Text>
                    </Button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      Icon: PropTypes.elementType,
      items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
    }),
  ).isRequired,
};

export default Sidebar;
