import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import cnaesJSON from "JSON/cnaes.json";

import Skeleton from "DS/custom/skeleton";
import { Anchor } from "DS/custom/anchor";

import { formatCnae } from "Services/RFFormatter";

/** @internal */
export const QUERY = gql`
  query CNAEs($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      branch {
        id
        cnaeFiscal
        cnaeFiscalTexto
        cnaesSecundarios
      }
    }
  }
`;

const SKELETON = (
  <div className="mt-4 gap-x-4 border-b pb-4 lg:grid lg:grid-cols-[288px_1fr_1fr]">
    <Skeleton className="h-24 w-64 max-w-[286px]" />
    <Skeleton className="h-10 w-96" />
    <Skeleton className="h-10 w-96" />
  </div>
);

function CNAEs() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { branch } = data.supplier;
  const { cnaeFiscal, cnaeFiscalTexto, cnaesSecundarios } = branch;

  return (
    <div className="mt-4 gap-x-4 border-b pb-4 lg:grid lg:grid-cols-[286px_1fr_1fr]">
      <div className="flex max-w-[286px] flex-col gap-1">
        <p className="text-base font-bold text-gray-700">CNAEs</p>

        <p className="text-sm text-gray-500">
          Códigos das atividades econômicas principais e secundárias deste estabelecimento.
        </p>
      </div>

      <div>
        <br className="hidden lg:block" />

        <p className="text-sm font-medium uppercase text-gray-700">
          {formatCnae(cnaeFiscal)} - {cnaeFiscalTexto}
        </p>

        <p className="text-sm font-medium text-gray-500">CNAE Principal</p>

        {cnaesSecundarios.length ? (
          <Anchor to="cnaes" className="mb-2 mt-2 no-underline lg:mb-0">
            Ver todos os CNAEs
          </Anchor>
        ) : null}
      </div>

      {cnaesSecundarios.length ? (
        <div>
          <br className="hidden lg:block" />

          <p className="text-sm font-medium uppercase text-gray-700">
            {formatCnae(cnaesSecundarios[0])} - {cnaesJSON[cnaesSecundarios[0]]}
          </p>

          <p className="text-sm font-medium text-gray-500">CNAE secundário</p>
        </div>
      ) : null}
    </div>
  );
}

export default CNAEs;
