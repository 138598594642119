import React, { useContext } from "react";
import _ from "lodash";

import {
  Tag,
  Lock,
  Trash,
  Network,
  ListTodo,
  CheckIcon,
  ListFilter,
  PlusCircle,
  MonitorCheck,
  AlertTriangle,
  CircleDollarSign,
} from "lucide-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { cn } from "DS/lib/utils";
import { Label } from "DS/ui/label";
import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";
import { Anchor } from "DS/custom/anchor";
import { Separator } from "DS/ui/separator";
import { Command, CommandItem, CommandList, CommandEmpty, CommandGroup, CommandInput } from "DS/ui/command";

import {
  DropdownMenu,
  DropdownMenuSub,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "DS/ui/dropdown-menu";

import { Context } from "Organisms/UBuyer/Companies/Index/SuppliersSearch/SupplierSearchContext";

import SwitchFilter from "Organisms/UBuyer/Companies/Index/SuppliersSearch/Filters/SwitchFilter";
import CheckboxFilter from "Organisms/UBuyer/Companies/Index/SuppliersSearch/Filters/CheckboxFilter";

/** @internal */
export const QUERY = gql`
  query AdvancedFilters {
    buyer {
      id
      supplierPaymentStripeLink
      ratingLabels
      settingTags {
        id
        displayName
      }
    }
  }
`;

const RATING_COLORS = {
  A: "text-green-400",
  B: "text-green-600",
  C: "text-yellow-600",
  D: "text-orange-600",
  E: "text-red-600",
};

function AdvancedFilters() {
  const { loading, data } = useQuery(QUERY, { fetchPolicy: "network-only" });
  const { search, setSearch } = useContext(Context);

  const omittedKeys = ["term", "states", "qualificationStates", "addedByMe", "settingCategories"];
  const omittedValues = _.values(_.omit(search, omittedKeys));
  const filtersCount = _.compact(_.flattenDeep(omittedValues)).length;

  if (loading) return null;

  const { buyer } = data;

  const resetFilters = () => {
    const resettedSearch = {
      ...search,
      active: null,
      blocked: null,
      ratingLabels: [],
      settingTagIds: [],
      regularization: null,
      pendingPayment: null,
      qualificationStates: [],
      workflowRequested: null,
    };

    setSearch(resettedSearch);
  };

  const PATH = `/buyers/${buyer.id}/setting_tags`;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button size="sm" type="button" variant="outline" className="h-9">
          <ListFilter size={20} className="mr-2 h-4 w-4" /> Filtros avançados
          {filtersCount ? (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="px-1">
                {filtersCount}
              </Badge>
            </>
          ) : null}
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="z-0">
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <SwitchFilter
            name="active"
            label="Ativos"
            Icon={MonitorCheck}
            checked={search.active}
            onCheckedChange={(checked) => setSearch({ ...search, active: checked || null })}
          />
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <SwitchFilter
            Icon={Lock}
            name="blocked"
            label="Bloqueados"
            checked={search.blocked}
            onCheckedChange={(checked) => setSearch({ ...search, blocked: checked || null })}
          />
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <SwitchFilter
            Icon={Network}
            name="workflowRequested"
            label="Fluxo de aprovação"
            checked={search.workflowRequested}
            onCheckedChange={(checked) => setSearch({ ...search, workflowRequested: checked || null })}
          />
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <SwitchFilter
            Icon={AlertTriangle}
            name="regularization"
            label="Regularização"
            checked={search.regularization}
            onCheckedChange={(checked) => setSearch({ ...search, regularization: checked || null })}
          />
        </DropdownMenuItem>

        {buyer.supplierPaymentStripeLink ? (
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <SwitchFilter
              Icon={CircleDollarSign}
              name="pendingPayment"
              label="Pagamento pendente"
              checked={search.pendingPayment}
              onCheckedChange={(checked) => setSearch({ ...search, pendingPayment: checked || null })}
            />
          </DropdownMenuItem>
        ) : null}

        <DropdownMenuSeparator />

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <div className="w-full px-1 py-1.5">
              <Label className="flex flex-1 flex-row items-center gap-2">
                <ListTodo size={16} /> Score de homologação
                {search.ratingLabels.length ? (
                  <>
                    <Separator orientation="vertical" className="h-4" />

                    <Badge variant="secondary" className="px-1">
                      {search.ratingLabels.length}
                    </Badge>
                  </>
                ) : null}
              </Label>
            </div>
          </DropdownMenuSubTrigger>

          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              {buyer.ratingLabels.map(({ label, value }) => (
                <DropdownMenuItem key={value} onSelect={(e) => e.preventDefault()}>
                  <CheckboxFilter
                    label={label}
                    name={`ratingLabels-${value}`}
                    textColor={RATING_COLORS[value]}
                    checked={search.ratingLabels.includes(value)}
                    onCheckedChange={(checked) => {
                      const ratingLabels = checked
                        ? [...search.ratingLabels, value]
                        : _.without(search.ratingLabels, value);

                      setSearch({ ...search, ratingLabels });
                    }}
                  />
                </DropdownMenuItem>
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <div className="w-full px-1 py-1.5">
              <Label className="flex flex-1 flex-row items-center gap-2">
                <Tag size={16} /> Marcadores
                {search.settingTagIds.length ? (
                  <>
                    <Separator orientation="vertical" className="h-4" />

                    <Badge variant="secondary" className="px-1">
                      {search.settingTagIds.length}
                    </Badge>
                  </>
                ) : null}
              </Label>
            </div>
          </DropdownMenuSubTrigger>

          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              {buyer.settingTags.length ? (
                <Command>
                  <CommandInput placeholder="Filtrar marcadores..." className="h-fit border-0 p-2.5 focus:ring-0" />

                  <CommandList>
                    <CommandEmpty>
                      <Text size="small">Nenhum marcador encontrado</Text>
                    </CommandEmpty>

                    <CommandGroup>
                      {_.sortBy(buyer.settingTags, "displayName").map((settingTag) => {
                        const checked = search.settingTagIds.includes(settingTag.id);

                        return (
                          <CommandItem
                            key={settingTag.id}
                            onSelect={() => {
                              const settingTagIds = checked
                                ? _.without(search.settingTagIds, settingTag.id)
                                : [...search.settingTagIds, settingTag.id];

                              setSearch({ ...search, settingTagIds });
                            }}
                          >
                            <div
                              className={cn(
                                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                checked ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                              )}
                            >
                              <CheckIcon className={cn("h-4 w-4")} />
                            </div>

                            <span>{settingTag.displayName}</span>
                          </CommandItem>
                        );
                      })}
                    </CommandGroup>
                  </CommandList>
                </Command>
              ) : (
                <div className="flex flex-col items-center gap-2 p-4">
                  <Text size="small">Você não possui marcadores</Text>

                  <Anchor className="flex flex-row items-center" onClick={() => window.location.assign(PATH)}>
                    <PlusCircle className="mr-2 h-4 w-4" /> Criar um marcador
                  </Anchor>
                </div>
              )}
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>

        {filtersCount ? (
          <>
            <DropdownMenuSeparator />

            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              <Button type="button" variant="ghost" onClick={resetFilters} className="h-fit w-full justify-start p-1">
                <Trash className="mr-2 h-4 w-4" /> Limpar filtros avançados
              </Button>
            </DropdownMenuItem>
          </>
        ) : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default AdvancedFilters;
