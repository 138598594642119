import React from "react";
import { useParams } from "react-router-dom";

import { AlertCircle } from "lucide-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";
import { Anchor } from "DS/custom/anchor";

import Shareholder from "Organisms/UBuyer/Companies/PUF/InfoCNPJ/Shareholders/Shareholder";

/** @internal */
export const QUERY = gql`
  query Shareholders($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      shareholders {
        cnpjOrCpfNome
        info {
          nome
          cpfNome
          dataEntradaSociedade
          descricaoQualificacaoSocio
        }
      }
    }
  }
`;

const SKELETON = (
  <div className="mt-4 gap-x-4 border-b pb-4 lg:grid lg:grid-cols-[288px_1fr_1fr]">
    <Skeleton className="h-24 w-64 max-w-[286px]" />
    <div className="col-span-2 flex flex-row items-center">
      <Skeleton className="h-6 w-6 flex-none rounded-full" />
      <Skeleton className="ml-1 h-5 w-96" />
    </div>
  </div>
);

function Shareholders() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { shareholders } = data.supplier;

  return (
    <div className="mt-4 gap-x-4 border-b pb-4 lg:grid lg:grid-cols-[286px_1fr_1fr]">
      <div className="flex max-w-[286px] flex-col gap-1">
        <p className="text-base font-bold text-gray-700">Quadro societário</p>

        <p className="text-sm text-gray-500">
          Pessoas físicas e jurídicas presentes no quadro societário de acordo com a Receita Federal do Brasil.
        </p>
      </div>

      {shareholders.length ? (
        <>
          <div className="flex flex-col gap-2 lg:gap-4">
            <Shareholder shareholder={shareholders[0]} />
            <Shareholder shareholder={shareholders[2]} />

            {shareholders.length > 4 ? (
              <Anchor to="shareholders" className="mb-2 no-underline lg:mb-0">
                Ver todos os sócios
              </Anchor>
            ) : null}
          </div>

          <div className="flex flex-col gap-2 lg:gap-4">
            <Shareholder shareholder={shareholders[1]} />
            <Shareholder shareholder={shareholders[3]} />
          </div>
        </>
      ) : (
        <div className="col-span-2 flex flex-row items-center gap-1">
          <AlertCircle size={26} className="flex-none text-gray-500" />

          <p className="text-sm font-medium text-gray-700">
            A empresa é um empresário individual e, por isso, não tem o seu Quadro Social disponibilizado pela Receita
            Federal.
          </p>
        </div>
      )}
    </div>
  );
}

export default Shareholders;
