{
  "0111301": "Cultivo de arroz",
  "0111302": "Cultivo de milho",
  "0111303": "Cultivo de trigo",
  "0111399": "Cultivo de outros cereais não especificados anteriormente",
  "0112101": "Cultivo de algodão herbáceo",
  "0112102": "Cultivo de juta",
  "0112199": "Cultivo de outras fibras de lavoura temporária não especificadas anteriormente",
  "0113000": "Cultivo de cana-de-açúcar",
  "0114800": "Cultivo de fumo",
  "0115600": "Cultivo de soja",
  "0116401": "Cultivo de amendoim",
  "0116402": "Cultivo de girassol",
  "0116403": "Cultivo de mamona",
  "0116499": "Cultivo de outras oleaginosas de lavoura temporária não especificadas anteriormente",
  "0119901": "Cultivo de abacaxi",
  "0119902": "Cultivo de alho",
  "0119903": "Cultivo de batata-inglesa",
  "0119904": "Cultivo de cebola",
  "0119905": "Cultivo de feijão",
  "0119906": "Cultivo de mandioca",
  "0119907": "Cultivo de melão",
  "0119908": "Cultivo de melancia",
  "0119909": "Cultivo de tomate rasteiro",
  "0119999": "Cultivo de outras plantas de lavoura temporária não especificadas anteriormente",
  "0121101": "Horticultura, exceto morango",
  "0121102": "Cultivo de morango",
  "0122900": "Cultivo de flores e plantas ornamentais",
  "0131800": "Cultivo de laranja",
  "0132600": "Cultivo de uva",
  "0133401": "Cultivo de açaí",
  "0133402": "Cultivo de banana",
  "0133403": "Cultivo de caju",
  "0133404": "Cultivo de cítricos, exceto laranja",
  "0133405": "Cultivo de coco-da-baía",
  "0133406": "Cultivo de guaraná",
  "0133407": "Cultivo de maçã",
  "0133408": "Cultivo de mamão",
  "0133409": "Cultivo de maracujá",
  "0133410": "Cultivo de manga",
  "0133411": "Cultivo de pêssego",
  "0133499": "Cultivo de frutas de lavoura permanente não especificadas anteriormente",
  "0134200": "Cultivo de café",
  "0135100": "Cultivo de cacau",
  "0139301": "Cultivo de chá-da-índia",
  "0139302": "Cultivo de erva-mate",
  "0139303": "Cultivo de pimenta-do-reino",
  "0139304": "Cultivo de plantas para condimento, exceto pimenta-do-reino",
  "0139305": "Cultivo de dendê",
  "0139306": "Cultivo de seringueira",
  "0139399": "Cultivo de outras plantas de lavoura permanente não especificadas anteriormente",
  "0141501": "Produção de sementes certificadas, exceto de forrageiras para pasto",
  "0141502": "Produção de sementes certificadas de forrageiras para formação de pasto",
  "0142300": "Produção de mudas e outras formas de propagação vegetal, certificadas",
  "0151201": "Criação de bovinos para corte",
  "0151202": "Criação de bovinos para leite",
  "0151203": "Criação de bovinos, exceto para corte e leite",
  "0152101": "Criação de bufalinos",
  "0152102": "Criação de eqüinos",
  "0152103": "Criação de asininos e muares",
  "0153901": "Criação de caprinos",
  "0153902": "Criação de ovinos, inclusive para produção de lã",
  "0154700": "Criação de suínos",
  "0155501": "Criação de frangos para corte",
  "0155502": "Produção de pintos de um dia",
  "0155503": "Criação de outros galináceos, exceto para corte",
  "0155504": "Criação de aves, exceto galináceos",
  "0155505": "Produção de ovos",
  "0159801": "Apicultura",
  "0159802": "Criação de animais de estimação",
  "0159803": "Criação de escargô",
  "0159804": "Criação de bicho-da-seda",
  "0159899": "Criação de outros animais não especificados anteriormente",
  "0161001": "Serviço de pulverização e controle de pragas agrícolas",
  "0161002": "Serviço de poda de árvores para lavouras",
  "0161003": "Serviço de preparação de terreno, cultivo e colheita",
  "0161099": "Atividades de apoio à agricultura não especificadas anteriormente",
  "0162801": "Serviço de inseminação artificial em animais",
  "0162802": "Serviço de tosquiamento de ovinos",
  "0162803": "Serviço de manejo de animais",
  "0162899": "Atividades de apoio à pecuária não especificadas anteriormente",
  "0163600": "Atividades de pós-colheita",
  "0170900": "Caça e serviços relacionados",
  "0210101": "Cultivo de eucalipto",
  "0210102": "Cultivo de acácia-negra",
  "0210103": "Cultivo de pinus",
  "0210104": "Cultivo de teca",
  "0210105": "Cultivo de espécies madeireiras, exceto eucalipto, acácia-negra, pinus e teca",
  "0210106": "Cultivo de mudas em viveiros florestais",
  "0210107": "Extração de madeira em florestas plantadas",
  "0210108": "Produção de carvão vegetal - florestas plantadas",
  "0210109": "Produção de casca de acácia-negra - florestas plantadas",
  "0210199": "Produção de produtos não-madeireiros não especificados anteriormente em florestas plantadas",
  "0220901": "Extração de madeira em florestas nativas",
  "0220902": "Produção de carvão vegetal - florestas nativas",
  "0220903": "Coleta de castanha-do-pará em florestas nativas",
  "0220904": "Coleta de látex em florestas nativas",
  "0220905": "Coleta de palmito em florestas nativas",
  "0220906": "Conservação de florestas nativas",
  "0220999": "Coleta de produtos não-madeireiros não especificados anteriormente em florestas nativas",
  "0230600": "Atividades de apoio à produção florestal",
  "0311601": "Pesca de peixes em água salgada",
  "0311602": "Pesca de crustáceos e moluscos em água salgada",
  "0311603": "Coleta de outros produtos marinhos",
  "0311604": "Atividades de apoio à pesca em água salgada",
  "0312401": "Pesca de peixes em água doce",
  "0312402": "Pesca de crustáceos e moluscos em água doce",
  "0312403": "Coleta de outros produtos aquáticos de água doce",
  "0312404": "Atividades de apoio à pesca em água doce",
  "0321301": "Criação de peixes em água salgada e salobra",
  "0321302": "Criação de camarões em água salgada e salobra",
  "0321303": "Criação de ostras e mexilhões em água salgada e salobra",
  "0321304": "Criação de peixes ornamentais em água salgada e salobra",
  "0321305": "Atividades de apoio à aqüicultura em água salgada e salobra",
  "0321399": "Cultivos e semicultivos da aqüicultura em água salgada e salobra não especificados anteriormente",
  "0322101": "Criação de peixes em água doce",
  "0322102": "Criação de camarões em água doce",
  "0322103": "Criação de ostras e mexilhões em água doce",
  "0322104": "Criação de peixes ornamentais em água doce",
  "0322105": "Ranicultura",
  "0322106": "Criação de jacaré",
  "0322107": "Atividades de apoio à aqüicultura em água doce",
  "0322199": "Cultivos e semicultivos da aqüicultura em água doce não especificados anteriormente",
  "0500301": "Extração de carvão mineral",
  "0500302": "Beneficiamento de carvão mineral",
  "0600001": "Extração de petróleo e gás natural",
  "0600002": "Extração e beneficiamento de xisto",
  "0600003": "Extração e beneficiamento de areias betuminosas",
  "0710301": "Extração de minério de ferro",
  "0710302": "Pelotização, sinterização e outros beneficiamentos de minério de ferro",
  "0721901": "Extração de minério de alumínio",
  "0721902": "Beneficiamento de minério de alumínio",
  "0722701": "Extração de minério de estanho",
  "0722702": "Beneficiamento de minério de estanho",
  "0723501": "Extração de minério de manganês",
  "0723502": "Beneficiamento de minério de manganês",
  "0724301": "Extração de minério de metais preciosos",
  "0724302": "Beneficiamento de minério de metais preciosos",
  "0725100": "Extração de minerais radioativos",
  "0729401": "Extração de minérios de nióbio e titânio",
  "0729402": "Extração de minério de tungstênio",
  "0729403": "Extração de minério de níquel",
  "0729404": "Extração de minérios de cobre, chumbo, zinco e outros minerais metálicos não-ferrosos não especificados anteriormente",
  "0729405": "Beneficiamento de minérios de cobre, chumbo, zinco e outros minerais metálicos não-ferrosos não especificados anteriormente",
  "0810001": "Extração de ardósia e beneficiamento associado",
  "0810002": "Extração de granito e beneficiamento associado",
  "0810003": "Extração de mármore e beneficiamento associado",
  "0810004": "Extração de calcário e dolomita e beneficiamento associado",
  "0810005": "Extração de gesso e caulim",
  "0810006": "Extração de areia, cascalho ou pedregulho e beneficiamento associado",
  "0810007": "Extração de argila e beneficiamento associado",
  "0810008": "Extração de saibro e beneficiamento associado",
  "0810009": "Extração de basalto e beneficiamento associado",
  "0810010": "Beneficiamento de gesso e caulim associado à extração",
  "0810099": "Extração e britamento de pedras e outros materiais para construção e beneficiamento associado",
  "0891600": "Extração de minerais para fabricação de adubos, fertilizantes e outros produtos químicos",
  "0892401": "Extração de sal marinho",
  "0892402": "Extração de sal-gema",
  "0892403": "Refino e outros tratamentos do sal",
  "0893200": "Extração de gemas (pedras preciosas e semipreciosas)",
  "0899101": "Extração de grafita",
  "0899102": "Extração de quartzo",
  "0899103": "Extração de amianto",
  "0899199": "Extração de outros minerais não-metálicos não especificados anteriormente",
  "0910600": "Atividades de apoio à extração de petróleo e gás natural",
  "0990401": "Atividades de apoio à extração de minério de ferro",
  "0990402": "Atividades de apoio à extração de minerais metálicos não-ferrosos",
  "0990403": "Atividades de apoio à extração de minerais não-metálicos",
  "1011201": "Frigorífico - abate de bovinos",
  "1011202": "Frigorífico - abate de eqüinos",
  "1011203": "Frigorífico - abate de ovinos e caprinos",
  "1011204": "Frigorífico - abate de bufalinos",
  "1011205": "Matadouro - abate de reses sob contrato - exceto abate de suínos",
  "1012101": "Abate de aves",
  "1012102": "Abate de pequenos animais",
  "1012103": "Frigorífico - abate de suínos",
  "1012104": "Matadouro - abate de suínos sob contrato",
  "1013901": "Fabricação de produtos de carne",
  "1013902": "Preparação de subprodutos do abate",
  "1020101": "Preservação de peixes, crustáceos e moluscos",
  "1020102": "Fabricação de conservas de peixes, crustáceos e moluscos",
  "1031700": "Fabricação de conservas de frutas",
  "1032501": "Fabricação de conservas de palmito",
  "1032599": "Fabricação de conservas de legumes e outros vegetais, exceto palmito",
  "1033301": "Fabricação de sucos concentrados de frutas, hortaliças e legumes",
  "1033302": "Fabricação de sucos de frutas, hortaliças e legumes, exceto concentrados",
  "1041400": "Fabricação de óleos vegetais em bruto, exceto óleo de milho",
  "1042200": "Fabricação de óleos vegetais refinados, exceto óleo de milho",
  "1043100": "Fabricação de margarina e outras gorduras vegetais e de óleos não-comestíveis de animais",
  "1051100": "Preparação do leite",
  "1052000": "Fabricação de laticínios",
  "1053800": "Fabricação de sorvetes e outros gelados comestíveis",
  "1061901": "Beneficiamento de arroz",
  "1061902": "Fabricação de produtos do arroz",
  "1062700": "Moagem de trigo e fabricação de derivados",
  "1063500": "Fabricação de farinha de mandioca e derivados",
  "1064300": "Fabricação de farinha de milho e derivados, exceto óleos de milho",
  "1065101": "Fabricação de amidos e féculas de vegetais",
  "1065102": "Fabricação de óleo de milho em bruto",
  "1065103": "Fabricação de óleo de milho refinado",
  "1066000": "Fabricação de alimentos para animais",
  "1069400": "Moagem e fabricação de produtos de origem vegetal não especificados anteriormente",
  "1071600": "Fabricação de açúcar em bruto",
  "1072401": "Fabricação de açúcar de cana refinado",
  "1072402": "Fabricação de açúcar de cereais (dextrose) e de beterraba",
  "1081301": "Beneficiamento de café",
  "1081302": "Torrefação e moagem de café",
  "1082100": "Fabricação de produtos à base de café",
  "1091100": "Fabricação de produtos de panificação",
  "1091101": "Fabricação de produtos de panificação industrial",
  "1091102": "Fabricação de produtos de padaria e confeitaria com predominância de produção própria",
  "1092900": "Fabricação de biscoitos e bolachas",
  "1093701": "Fabricação de produtos derivados do cacau e de chocolates",
  "1093702": "Fabricação de frutas cristalizadas, balas e semelhantes",
  "1094500": "Fabricação de massas alimentícias",
  "1095300": "Fabricação de especiarias, molhos, temperos e condimentos",
  "1096100": "Fabricação de alimentos e pratos prontos",
  "1099601": "Fabricação de vinagres",
  "1099602": "Fabricação de pós alimentícios",
  "1099603": "Fabricação de fermentos e leveduras",
  "1099604": "Fabricação de gelo comum",
  "1099605": "Fabricação de produtos para infusão (chá, mate, etc.)",
  "1099606": "Fabricação de adoçantes naturais e artificiais",
  "1099607": "Fabricação de alimentos dietéticos e complementos alimentares",
  "1099699": "Fabricação de outros produtos alimentícios não especificados anteriormente",
  "1111901": "Fabricação de aguardente de cana-de-açúcar",
  "1111902": "Fabricação de outras aguardentes e bebidas destiladas",
  "1112700": "Fabricação de vinho",
  "1113501": "Fabricação de malte, inclusive malte uísque",
  "1113502": "Fabricação de cervejas e chopes",
  "1121600": "Fabricação de águas envasadas",
  "1122401": "Fabricação de refrigerantes",
  "1122402": "Fabricação de chá mate e outros chás prontos para consumo",
  "1122403": "Fabricação de refrescos, xaropes e pós para refrescos, exceto refrescos de frutas",
  "1122404": "Fabricação de bebidas isotônicas",
  "1122499": "Fabricação de outras bebidas não-alcoólicas não especificadas anteriormente",
  "1210700": "Processamento industrial do fumo",
  "1220401": "Fabricação de cigarros",
  "1220402": "Fabricação de cigarrilhas e charutos",
  "1220403": "Fabricação de filtros para cigarros",
  "1220499": "Fabricação de outros produtos do fumo, exceto cigarros, cigarrilhas e charutos",
  "1311100": "Preparação e fiação de fibras de algodão",
  "1312000": "Preparação e fiação de fibras têxteis naturais, exceto algodão",
  "1313800": "Fiação de fibras artificiais e sintéticas",
  "1314600": "Fabricação de linhas para costurar e bordar",
  "1321900": "Tecelagem de fios de algodão",
  "1322700": "Tecelagem de fios de fibras têxteis naturais, exceto algodão",
  "1323500": "Tecelagem de fios de fibras artificiais e sintéticas",
  "1330800": "Fabricação de tecidos de malha",
  "1340501": "Estamparia e texturização em fios, tecidos, artefatos têxteis e peças do vestuário",
  "1340502": "Alvejamento, tingimento e torção em fios, tecidos, artefatos têxteis e peças do vestuário",
  "1340599": "Outros serviços de acabamento em fios, tecidos, artefatos têxteis e peças do vestuário",
  "1351100": "Fabricação de artefatos têxteis para uso doméstico",
  "1352900": "Fabricação de artefatos de tapeçaria",
  "1353700": "Fabricação de artefatos de cordoaria",
  "1354500": "Fabricação de tecidos especiais, inclusive artefatos",
  "1359600": "Fabricação de outros produtos têxteis não especificados anteriormente",
  "1411801": "Confecção de roupas íntimas",
  "1411802": "Facção de roupas íntimas",
  "1412601": "Confecção de peças de vestuário, exceto roupas íntimas e as confeccionadas sob medida",
  "1412602": "Confecção, sob medida, de peças do vestuário, exceto roupas íntimas",
  "1412603": "Facção de peças do vestuário, exceto roupas íntimas",
  "1413401": "Confecção de roupas profissionais, exceto sob medida",
  "1413402": "Confecção, sob medida, de roupas profissionais",
  "1413403": "Facção de roupas profissionais",
  "1414200": "Fabricação de acessórios do vestuário, exceto para segurança e proteção",
  "1421500": "Fabricação de meias",
  "1422300": "Fabricação de artigos do vestuário, produzidos em malharias e tricotagens, exceto meias",
  "1510600": "Curtimento e outras preparações de couro",
  "1521100": "Fabricação de artigos para viagem, bolsas e semelhantes de qualquer material",
  "1529700": "Fabricação de artefatos de couro não especificados anteriormente",
  "1531901": "Fabricação de calçados de couro",
  "1531902": "Acabamento de calçados de couro sob contrato",
  "1532700": "Fabricação de tênis de qualquer material",
  "1533500": "Fabricação de calçados de material sintético",
  "1539400": "Fabricação de calçados de materiais não especificados anteriormente",
  "1540800": "Fabricação de partes para calçados, de qualquer material",
  "1610201": "Serrarias com desdobramento de madeira",
  "1610202": "Serrarias sem desdobramento de madeira",
  "1610203": "Serrarias com desdobramento de madeira em bruto",
  "1610204": "Serrarias sem desdobramento de madeira em bruto  -Resserragem",
  "1610205": "Serviço de tratamento de madeira realizado sob contrato",
  "1621800": "Fabricação de madeira laminada e de chapas de madeira compensada, prensada e aglomerada",
  "1622601": "Fabricação de casas de madeira pré-fabricadas",
  "1622602": "Fabricação de esquadrias de madeira e de peças de madeira para instalações industriais e comerciais",
  "1622699": "Fabricação de outros artigos de carpintaria para construção",
  "1623400": "Fabricação de artefatos de tanoaria e de embalagens de madeira",
  "1629301": "Fabricação de artefatos diversos de madeira, exceto móveis",
  "1629302": "Fabricação de artefatos diversos de cortiça, bambu, palha, vime e outros materiais trançados, exceto móveis",
  "1710900": "Fabricação de celulose e outras pastas para a fabricação de papel",
  "1721400": "Fabricação de papel",
  "1722200": "Fabricação de cartolina e papel-cartão",
  "1731100": "Fabricação de embalagens de papel",
  "1732000": "Fabricação de embalagens de cartolina e papel-cartão",
  "1733800": "Fabricação de chapas e de embalagens de papelão ondulado",
  "1741901": "Fabricação de formulários contínuos",
  "1741902": "Fabricação de produtos de papel, cartolina, papel cartão e papelão ondulado para uso comercial e de escritório, exceto formulário contínuo",
  "1742701": "Fabricação de fraldas descartáveis",
  "1742702": "Fabricação de absorventes higiênicos",
  "1742799": "Fabricação de produtos de papel para uso doméstico e higiênico-sanitário não especificados anteriormente",
  "1749400": "Fabricação de produtos de pastas celulósicas, papel, cartolina, papel-cartão e papelão ondulado não especificados anteriormente",
  "1811301": "Impressão de jornais",
  "1811302": "Impressão de livros, revistas e outras publicações periódicas",
  "1812100": "Impressão de material de segurança",
  "1813001": "Impressão de material para uso publicitário",
  "1813099": "Impressão de material para outros usos",
  "1821100": "Serviços de pré-impressão",
  "1822900": "Serviços de acabamentos gráficos",
  "1822901": "Serviços de encadernação e plastificação",
  "1822999": "Serviços de acabamentos gráficos, exceto encadernação e plastificação",
  "1830001": "Reprodução de som em qualquer suporte",
  "1830002": "Reprodução de vídeo em qualquer suporte",
  "1830003": "Reprodução de software em qualquer suporte",
  "1910100": "Coquerias",
  "1921700": "Fabricação de produtos do refino de petróleo",
  "1922501": "Formulação de combustíveis",
  "1922502": "Rerrefino de óleos lubrificantes",
  "1922599": "Fabricação de outros produtos derivados do petróleo, exceto produtos do refino",
  "1931400": "Fabricação de álcool",
  "1932200": "Fabricação de biocombustíveis, exceto álcool",
  "2011800": "Fabricação de cloro e álcalis",
  "2012600": "Fabricação de intermediários para fertilizantes",
  "2013400": "Fabricação de adubos e fertilizantes",
  "2013401": "Fabricação de adubos e fertilizantes organo-minerais",
  "2013402": "Fabricação de adubos e fertilizantes, exceto organo-minerais",
  "2014200": "Fabricação de gases industriais",
  "2019301": "Elaboração de combustíveis nucleares",
  "2019399": "Fabricação de outros produtos químicos inorgânicos não especificados anteriormente",
  "2021500": "Fabricação de produtos petroquímicos básicos",
  "2022300": "Fabricação de intermediários para plastificantes, resinas e fibras",
  "2029100": "Fabricação de produtos químicos orgânicos não especificados anteriormente",
  "2031200": "Fabricação de resinas termoplásticas",
  "2032100": "Fabricação de resinas termofixas",
  "2033900": "Fabricação de elastômeros",
  "2040100": "Fabricação de fibras artificiais e sintéticas",
  "2051700": "Fabricação de defensivos agrícolas",
  "2052500": "Fabricação de desinfestantes domissanitários",
  "2061400": "Fabricação de sabões e detergentes sintéticos",
  "2062200": "Fabricação de produtos de limpeza e polimento",
  "2063100": "Fabricação de cosméticos, produtos de perfumaria e de higiene pessoal",
  "2071100": "Fabricação de tintas, vernizes, esmaltes e lacas",
  "2072000": "Fabricação de tintas de impressão",
  "2073800": "Fabricação de impermeabilizantes, solventes e produtos afins",
  "2091600": "Fabricação de adesivos e selantes",
  "2092401": "Fabricação de pólvoras, explosivos e detonantes",
  "2092402": "Fabricação de artigos pirotécnicos",
  "2092403": "Fabricação de fósforos de segurança",
  "2093200": "Fabricação de aditivos de uso industrial",
  "2094100": "Fabricação de catalisadores",
  "2099101": "Fabricação de chapas, filmes, papéis e outros materiais e produtos químicos para fotografia",
  "2099199": "Fabricação de outros produtos químicos não especificados anteriormente",
  "2110600": "Fabricação de produtos farmoquímicos",
  "2121101": "Fabricação de medicamentos alopáticos para uso humano",
  "2121102": "Fabricação de medicamentos homeopáticos para uso humano",
  "2121103": "Fabricação de medicamentos fitoterápicos para uso humano",
  "2122000": "Fabricação de medicamentos para uso veterinário",
  "2123800": "Fabricação de preparações farmacêuticas",
  "2211100": "Fabricação de pneumáticos e de câmaras-de-ar",
  "2212900": "Reforma de pneumáticos usados",
  "2219600": "Fabricação de artefatos de borracha não especificados anteriormente",
  "2221800": "Fabricação de laminados planos e tubulares de material plástico",
  "2222600": "Fabricação de embalagens de material plástico",
  "2223400": "Fabricação de tubos e acessórios de material plástico para uso na construção",
  "2229301": "Fabricação de artefatos de material plástico para uso pessoal e doméstico",
  "2229302": "Fabricação de artefatos de material plástico para usos industriais",
  "2229303": "Fabricação de artefatos de material plástico para uso na construção, exceto tubos e acessórios",
  "2229399": "Fabricação de artefatos de material plástico para outros usos não especificados anteriormente",
  "2311700": "Fabricação de vidro plano e de segurança",
  "2312500": "Fabricação de embalagens de vidro",
  "2319200": "Fabricação de artigos de vidro",
  "2320600": "Fabricação de cimento",
  "2330301": "Fabricação de estruturas pré-moldadas de concreto armado, em série e sob encomenda",
  "2330302": "Fabricação de artefatos de cimento para uso na construção",
  "2330303": "Fabricação de artefatos de fibrocimento para uso na construção",
  "2330304": "Fabricação de casas pré-moldadas de concreto",
  "2330305": "Preparação de massa de concreto e argamassa para construção",
  "2330399": "Fabricação de outros artefatos e produtos de concreto, cimento, fibrocimento, gesso e materiais semelhantes",
  "2341900": "Fabricação de produtos cerâmicos refratários",
  "2342701": "Fabricação de azulejos e pisos",
  "2342702": "Fabricação de artefatos de cerâmica e barro cozido para uso na construção, exceto azulejos e pisos",
  "2349401": "Fabricação de material sanitário de cerâmica",
  "2349499": "Fabricação de produtos cerâmicos não-refratários não especificados anteriormente",
  "2391501": "Britamento de pedras, exceto associado à extração",
  "2391502": "Aparelhamento de pedras para construção, exceto associado à extração",
  "2391503": "Aparelhamento de placas e execução de trabalhos em mármore, granito, ardósia e outras pedras",
  "2392300": "Fabricação de cal e gesso",
  "2399101": "Decoração, lapidação, gravação, vitrificação e outros trabalhos em cerâmica, louça, vidro e cristal",
  "2399102": "Fabricação de abrasivos",
  "2399199": "Fabricação de outros produtos de minerais não-metálicos não especificados anteriormente",
  "2411300": "Produção de ferro-gusa",
  "2412100": "Produção de ferroligas",
  "2421100": "Produção de semi-acabados de aço",
  "2422901": "Produção de laminados planos de aço ao carbono, revestidos ou não",
  "2422902": "Produção de laminados planos de aços especiais",
  "2423701": "Produção de tubos de aço sem costura",
  "2423702": "Produção de laminados longos de aço, exceto tubos",
  "2424501": "Produção de arames de aço",
  "2424502": "Produção de relaminados, trefilados e perfilados de aço, exceto arames",
  "2431800": "Produção de tubos de aço com costura",
  "2439300": "Produção de outros tubos de ferro e aço",
  "2441501": "Produção de alumínio e suas ligas em formas primárias",
  "2441502": "Produção de laminados de alumínio",
  "2442300": "Metalurgia dos metais preciosos",
  "2443100": "Metalurgia do cobre",
  "2449101": "Produção de zinco em formas primárias",
  "2449102": "Produção de laminados de zinco",
  "2449103": "Fabricação de ânodos para galvanoplastia",
  "2449199": "Metalurgia de outros metais não-ferrosos e suas ligas não especificados anteriormente",
  "2451200": "Fundição de ferro e aço",
  "2452100": "Fundição de metais não-ferrosos e suas ligas",
  "2511000": "Fabricação de estruturas metálicas",
  "2512800": "Fabricação de esquadrias de metal",
  "2513600": "Fabricação de obras de caldeiraria pesada",
  "2521700": "Fabricação de tanques, reservatórios metálicos e caldeiras para aquecimento central",
  "2522500": "Fabricação de caldeiras geradoras de vapor, exceto para aquecimento central e para veículos",
  "2531401": "Produção de forjados de aço",
  "2531402": "Produção de forjados de metais não-ferrosos e suas ligas",
  "2532201": "Produção de artefatos estampados de metal",
  "2532202": "Metalurgia do pó",
  "2539000": "Serviços de usinagem, solda, tratamento e revestimento em metais",
  "2539001": "Serviços de usinagem, tornearia e solda",
  "2539002": "Serviços de tratamento e revestimento em metais",
  "2541100": "Fabricação de artigos de cutelaria",
  "2542000": "Fabricação de artigos de serralheria, exceto esquadrias",
  "2543800": "Fabricação de ferramentas",
  "2550101": "Fabricação de equipamento bélico pesado, exceto veículos militares de combate",
  "2550102": "Fabricação de armas de fogo, outras armas e munições",
  "2591800": "Fabricação de embalagens metálicas",
  "2592601": "Fabricação de produtos de trefilados de metal padronizados",
  "2592602": "Fabricação de produtos de trefilados de metal, exceto padronizados",
  "2593400": "Fabricação de artigos de metal para uso doméstico e pessoal",
  "2599301": "Serviços de confecção de armações metálicas para a construção",
  "2599302": "Serviço de corte e dobra de metais",
  "2599399": "Fabricação de outros produtos de metal não especificados anteriormente",
  "2610800": "Fabricação de componentes eletrônicos",
  "2621300": "Fabricação de equipamentos de informática",
  "2622100": "Fabricação de periféricos para equipamentos de informática",
  "2631100": "Fabricação de equipamentos transmissores de comunicação, peças e acessórios",
  "2632900": "Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação, peças e acessórios",
  "2640000": "Fabricação de aparelhos de recepção, reprodução, gravação e amplificação de áudio e vídeo",
  "2651500": "Fabricação de aparelhos e equipamentos de medida, teste e controle",
  "2652300": "Fabricação de cronômetros e relógios",
  "2660400": "Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação",
  "2670101": "Fabricação de equipamentos e instrumentos ópticos, peças e acessórios",
  "2670102": "Fabricação de aparelhos fotográficos e cinematográficos, peças e acessórios",
  "2680900": "Fabricação de mídias virgens, magnéticas e ópticas",
  "2710401": "Fabricação de geradores de corrente contínua e alternada, peças e acessórios",
  "2710402": "Fabricação de transformadores, indutores, conversores, sincronizadores e semelhantes, peças e acessórios",
  "2710403": "Fabricação de motores elétricos, peças e acessórios",
  "2721000": "Fabricação de pilhas, baterias e acumuladores elétricos, exceto para veículos automotores",
  "2722801": "Fabricação de baterias e acumuladores para veículos automotores",
  "2722802": "Recondicionamento de baterias e acumuladores para veículos automotores",
  "2731700": "Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica",
  "2732500": "Fabricação de material elétrico para instalações em circuito de consumo",
  "2733300": "Fabricação de fios, cabos e condutores elétricos isolados",
  "2740601": "Fabricação de lâmpadas",
  "2740602": "Fabricação de luminárias e outros equipamentos de iluminação",
  "2751100": "Fabricação de fogões, refrigeradores e máquinas de lavar e secar para uso doméstico, peças e acessórios",
  "2759701": "Fabricação de aparelhos elétricos de uso pessoal, peças e acessórios",
  "2759799": "Fabricação de outros aparelhos eletrodomésticos não especificados anteriormente, peças e acessórios",
  "2790201": "Fabricação de eletrodos, contatos e outros artigos de carvão e grafita para uso elétrico, eletroímãs e isoladores",
  "2790202": "Fabricação de equipamentos para sinalização e alarme",
  "2790299": "Fabricação de outros equipamentos e aparelhos elétricos não especificados anteriormente",
  "2811900": "Fabricação de motores e turbinas, peças e acessórios, exceto para aviões e veículos rodoviários",
  "2812700": "Fabricação de equipamentos hidráulicos e pneumáticos, peças e acessórios, exceto válvulas",
  "2813500": "Fabricação de válvulas, registros e dispositivos semelhantes, peças e acessórios",
  "2814301": "Fabricação de compressores para uso industrial, peças e acessórios",
  "2814302": "Fabricação de compressores para uso não industrial, peças e acessórios",
  "2815101": "Fabricação de rolamentos para fins industriais",
  "2815102": "Fabricação de equipamentos de transmissão para fins industriais, exceto rolamentos",
  "2821601": "Fabricação de fornos industriais, aparelhos e equipamentos não-elétricos para instalações térmicas, peças e acessórios",
  "2821602": "Fabricação de estufas e fornos elétricos para fins industriais, peças e acessórios",
  "2822401": "Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de pessoas, peças e acessórios",
  "2822402": "Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas, peças e acessórios",
  "2823200": "Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial, peças e acessórios",
  "2824101": "Fabricação de aparelhos e equipamentos de ar condicionado para uso industrial",
  "2824102": "Fabricação de aparelhos e equipamentos de ar condicionado para uso não-industrial",
  "2825900": "Fabricação de máquinas e equipamentos para saneamento básico e ambiental, peças e acessórios",
  "2829101": "Fabricação de máquinas de escrever, calcular e outros equipamentos não-eletrônicos para escritório, peças e acessórios",
  "2829199": "Fabricação de outras máquinas e equipamentos de uso geral não especificados anteriormente, peças e acessórios",
  "2831300": "Fabricação de tratores agrícolas, peças e acessórios",
  "2832100": "Fabricação de equipamentos para irrigação agrícola, peças e acessórios",
  "2833000": "Fabricação de máquinas e equipamentos para a agricultura e pecuária, peças e acessórios, exceto para irrigação",
  "2840200": "Fabricação de máquinas-ferramenta, peças e acessórios",
  "2851800": "Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo, peças e acessórios",
  "2852600": "Fabricação de outras máquinas e equipamentos para uso na extração mineral, peças e acessórios, exceto na extração de petróleo",
  "2853400": "Fabricação de tratores, peças e acessórios, exceto agrícolas",
  "2854200": "Fabricação de máquinas e equipamentos para terraplenagem, pavimentação e construção, peças e acessórios, exceto tratores",
  "2861500": "Fabricação de máquinas para a indústria metalúrgica, peças e acessórios, exceto máquinas-ferramenta",
  "2862300": "Fabricação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo, peças e acessórios",
  "2863100": "Fabricação de máquinas e equipamentos para a indústria têxtil, peças e acessórios",
  "2864000": "Fabricação de máquinas e equipamentos para as indústrias do vestuário, do couro e de calçados, peças e acessórios",
  "2865800": "Fabricação de máquinas e equipamentos para as indústrias de celulose, papel e papelão e artefatos, peças e acessórios",
  "2866600": "Fabricação de máquinas e equipamentos para a indústria do plástico, peças e acessórios",
  "2869100": "Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente, peças e acessórios",
  "2910701": "Fabricação de automóveis, camionetas e utilitários",
  "2910702": "Fabricação de chassis com motor para automóveis, camionetas e utilitários",
  "2910703": "Fabricação de motores para automóveis, camionetas e utilitários",
  "2920401": "Fabricação de caminhões e ônibus",
  "2920402": "Fabricação de motores para caminhões e ônibus",
  "2930101": "Fabricação de cabines, carrocerias e reboques para caminhões",
  "2930102": "Fabricação de carrocerias para ônibus",
  "2930103": "Fabricação de cabines, carrocerias e reboques para outros veículos automotores, exceto caminhões e ônibus",
  "2941700": "Fabricação de peças e acessórios para o sistema motor de veículos automotores",
  "2942500": "Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores",
  "2943300": "Fabricação de peças e acessórios para o sistema de freios de veículos automotores",
  "2944100": "Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores",
  "2945000": "Fabricação de material elétrico e eletrônico para veículos automotores, exceto baterias",
  "2949201": "Fabricação de bancos e estofados para veículos automotores",
  "2949299": "Fabricação de outras peças e acessórios para veículos automotores não especificadas anteriormente",
  "2950600": "Recondicionamento e recuperação de motores para veículos automotores",
  "3011301": "Construção de embarcações de grande porte",
  "3011302": "Construção de embarcações para uso comercial e para usos especiais, exceto de grande porte",
  "3012100": "Construção de embarcações para esporte e lazer",
  "3031800": "Fabricação de locomotivas, vagões e outros materiais rodantes",
  "3032600": "Fabricação de peças e acessórios para veículos ferroviários",
  "3041500": "Fabricação de aeronaves",
  "3042300": "Fabricação de turbinas, motores e outros componentes e peças para aeronaves",
  "3050400": "Fabricação de veículos militares de combate",
  "3091100": "Fabricação de motocicletas, peças e acessórios",
  "3091101": "Fabricação de motocicletas",
  "3091102": "Fabricação de peças e acessórios para motocicletas",
  "3092000": "Fabricação de bicicletas e triciclos não-motorizados, peças e acessórios",
  "3099700": "Fabricação de equipamentos de transporte não especificados anteriormente",
  "3101200": "Fabricação de móveis com predominância de madeira",
  "3102100": "Fabricação de móveis com predominância de metal",
  "3103900": "Fabricação de móveis de outros materiais, exceto madeira e metal",
  "3104700": "Fabricação de colchões",
  "3211601": "Lapidação de gemas",
  "3211602": "Fabricação de artefatos de joalheria e ourivesaria",
  "3211603": "Cunhagem de moedas e medalhas",
  "3212400": "Fabricação de bijuterias e artefatos semelhantes",
  "3220500": "Fabricação de instrumentos musicais, peças e acessórios",
  "3230200": "Fabricação de artefatos para pesca e esporte",
  "3240001": "Fabricação de jogos eletrônicos",
  "3240002": "Fabricação de mesas de bilhar, de sinuca e acessórios não associada à locação",
  "3240003": "Fabricação de mesas de bilhar, de sinuca e acessórios associada à locação",
  "3240099": "Fabricação de outros brinquedos e jogos recreativos não especificados anteriormente",
  "3250701": "Fabricação de instrumentos não-eletrônicos e utensílios para uso médico, cirúrgico, odontológico e de laboratório",
  "3250702": "Fabricação de mobiliário para uso médico, cirúrgico, odontológico e de laboratório",
  "3250703": "Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral sob encomenda",
  "3250704": "Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral, exceto sob encomenda",
  "3250705": "Fabricação de materiais para medicina e odontologia",
  "3250706": "Serviços de prótese dentária",
  "3250707": "Fabricação de artigos ópticos",
  "3250708": "Fabricação de artefatos de tecido não tecido para uso odonto-médico-hospitalar",
  "3250709": "Serviço de laboratório óptico",
  "3291400": "Fabricação de escovas, pincéis e vassouras",
  "3292201": "Fabricação de roupas de proteção e segurança e resistentes a fogo",
  "3292202": "Fabricação de equipamentos e acessórios para segurança pessoal e profissional",
  "3299001": "Fabricação de guarda-chuvas e similares",
  "3299002": "Fabricação de canetas, lápis e outros artigos para escritório",
  "3299003": "Fabricação de letras, letreiros e placas de qualquer material, exceto luminosos",
  "3299004": "Fabricação de painéis e letreiros luminosos",
  "3299005": "Fabricação de aviamentos para costura",
  "3299006": "Fabricação de velas, inclusive decorativas",
  "3299099": "Fabricação de produtos diversos não especificados anteriormente",
  "3311200": "Manutenção e reparação de tanques, reservatórios metálicos e caldeiras, exceto para veículos",
  "3312101": "Manutenção e reparação de equipamentos transmissores de comunicação",
  "3312102": "Manutenção e reparação de aparelhos e instrumentos de medida, teste e controle",
  "3312103": "Manutenção e reparação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação",
  "3312104": "Manutenção e reparação de equipamentos e instrumentos ópticos",
  "3313901": "Manutenção e reparação de geradores, transformadores e motores elétricos",
  "3313902": "Manutenção e reparação de baterias e acumuladores elétricos, exceto para veículos",
  "3313999": "Manutenção e reparação de máquinas, aparelhos e materiais elétricos não especificados anteriormente",
  "3314701": "Manutenção e reparação de máquinas motrizes não-elétricas",
  "3314702": "Manutenção e reparação de equipamentos hidráulicos e pneumáticos, exceto válvulas",
  "3314703": "Manutenção e reparação de válvulas industriais",
  "3314704": "Manutenção e reparação de compressores",
  "3314705": "Manutenção e reparação de equipamentos de transmissão para fins industriais",
  "3314706": "Manutenção e reparação de máquinas, aparelhos e equipamentos para instalações térmicas",
  "3314707": "Manutenção e reparação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial",
  "3314708": "Manutenção e reparação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas",
  "3314709": "Manutenção e reparação de máquinas de escrever, calcular e de outros equipamentos não-eletrônicos para escritório",
  "3314710": "Manutenção e reparação de máquinas e equipamentos para uso geral não especificados anteriormente",
  "3314711": "Manutenção e reparação de máquinas e equipamentos para agricultura e pecuária",
  "3314712": "Manutenção e reparação de tratores agrícolas",
  "3314713": "Manutenção e reparação de máquinas-ferramenta",
  "3314714": "Manutenção e reparação de máquinas e equipamentos para a prospecção e extração de petróleo",
  "3314715": "Manutenção e reparação de máquinas e equipamentos para uso na extração mineral, exceto na extração de petróleo",
  "3314716": "Manutenção e reparação de tratores, exceto agrícolas",
  "3314717": "Manutenção e reparação de máquinas e equipamentos de terraplenagem, pavimentação e construção, exceto tratores",
  "3314718": "Manutenção e reparação de máquinas para a indústria metalúrgica, exceto máquinas-ferramenta",
  "3314719": "Manutenção e reparação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo",
  "3314720": "Manutenção e reparação de máquinas e equipamentos para a indústria têxtil, do vestuário, do couro e calçados",
  "3314721": "Manutenção e reparação de máquinas e aparelhos para a indústria de celulose, papel e papelão e artefatos",
  "3314722": "Manutenção e reparação de máquinas e aparelhos para a indústria do plástico",
  "3314799": "Manutenção e reparação de outras máquinas e equipamentos para usos industriais não especificados anteriormente",
  "3315500": "Manutenção e reparação de veículos ferroviários",
  "3316301": "Manutenção e reparação de aeronaves, exceto a manutenção na pista",
  "3316302": "Manutenção de aeronaves na pista",
  "3317101": "Manutenção e reparação de embarcações e estruturas flutuantes",
  "3317102": "Manutenção e reparação de embarcações para esporte e lazer",
  "3319800": "Manutenção e reparação de equipamentos e produtos não especificados anteriormente",
  "3321000": "Instalação de máquinas e equipamentos industriais",
  "3329501": "Serviços de montagem de móveis de qualquer material",
  "3329599": "Instalação de outros equipamentos não especificados anteriormente",
  "3511500": "Geração de energia elétrica",
  "3511501": "Geração de energia elétrica",
  "3511502": "Atividades de coordenação e controle da operação da geração e transmissão de energia elétrica",
  "3512300": "Transmissão de energia elétrica",
  "3513100": "Comércio atacadista de energia elétrica",
  "3514000": "Distribuição de energia elétrica",
  "3520401": "Produção de gás; processamento de gás natural",
  "3520402": "Distribuição de combustíveis gasosos por redes urbanas",
  "3530100": "Produção e distribuição de vapor, água quente e ar condicionado",
  "3600601": "Captação, tratamento e distribuição de água",
  "3600602": "Distribuição de água por caminhões",
  "3701100": "Gestão de redes de esgoto",
  "3702900": "Atividades relacionadas a esgoto, exceto a gestão de redes",
  "3811400": "Coleta de resíduos não-perigosos",
  "3812200": "Coleta de resíduos perigosos",
  "3821100": "Tratamento e disposição de resíduos não-perigosos",
  "3822000": "Tratamento e disposição de resíduos perigosos",
  "3831901": "Recuperação de sucatas de alumínio",
  "3831999": "Recuperação de materiais metálicos, exceto alumínio",
  "3832700": "Recuperação de materiais plásticos",
  "3839401": "Usinas de compostagem",
  "3839499": "Recuperação de materiais não especificados anteriormente",
  "3900500": "Descontaminação e outros serviços de gestão de resíduos",
  "4110700": "Incorporação de empreendimentos imobiliários",
  "4120400": "Construção de edifícios",
  "4211101": "Construção de rodovias e ferrovias",
  "4211102": "Pintura para sinalização em pistas rodoviárias e aeroportos",
  "4212000": "Construção de obras de arte especiais",
  "4213800": "Obras de urbanização - ruas, praças e calçadas",
  "4221901": "Construção de barragens e represas para geração de energia elétrica",
  "4221902": "Construção de estações e redes de distribuição de energia elétrica",
  "4221903": "Manutenção de redes de distribuição de energia elétrica",
  "4221904": "Construção de estações e redes de telecomunicações",
  "4221905": "Manutenção de estações e redes de telecomunicações",
  "4222701": "Construção de redes de abastecimento de água, coleta de esgoto e construções correlatas, exceto obras de irrigação",
  "4222702": "Obras de irrigação",
  "4223500": "Construção de redes de transportes por dutos, exceto para água e esgoto",
  "4291000": "Obras portuárias, marítimas e fluviais",
  "4292801": "Montagem de estruturas metálicas",
  "4292802": "Obras de montagem industrial",
  "4299501": "Construção de instalações esportivas e recreativas",
  "4299599": "Outras obras de engenharia civil não especificadas anteriormente",
  "4311801": "Demolição de edifícios e outras estruturas",
  "4311802": "Preparação de canteiro e limpeza de terreno",
  "4312600": "Perfurações e sondagens",
  "4313400": "Obras de terraplenagem",
  "4319300": "Serviços de preparação do terreno não especificados anteriormente",
  "4321500": "Instalação e manutenção elétrica",
  "4322301": "Instalações hidráulicas, sanitárias e de gás",
  "4322302": "Instalação e manutenção de sistemas centrais de ar condicionado, de ventilação e refrigeração",
  "4322303": "Instalações de sistema de prevenção contra incêndio",
  "4329101": "Instalação de painéis publicitários",
  "4329102": "Instalação de equipamentos para orientação à navegação marítima fluvial e lacustre",
  "4329103": "Instalação, manutenção e reparação de elevadores, escadas e esteiras rolantes",
  "4329104": "Montagem e instalação de sistemas e equipamentos de iluminação e sinalização em vias públicas, portos e aeroportos",
  "4329105": "Tratamentos térmicos, acústicos ou de vibração",
  "4329199": "Outras obras de instalações em construções não especificadas anteriormente",
  "4330401": "Impermeabilização em obras de engenharia civil",
  "4330402": "Instalação de portas, janelas, tetos, divisórias e armários embutidos de qualquer material",
  "4330403": "Obras de acabamento em gesso e estuque",
  "4330404": "Serviços de pintura de edifícios em geral",
  "4330405": "Aplicação de revestimentos e de resinas em interiores e exteriores",
  "4330499": "Outras obras de acabamento da construção",
  "4391600": "Obras de fundações",
  "4399101": "Administração de obras",
  "4399102": "Montagem e desmontagem de andaimes e outras estruturas temporárias",
  "4399103": "Obras de alvenaria",
  "4399104": "Serviços de operação e fornecimento de equipamentos para transporte e elevação de cargas e pessoas para uso em obras",
  "4399105": "Perfuração e construção de poços de água",
  "4399199": "Serviços especializados para construção não especificados anteriormente",
  "4511101": "Comércio a varejo de automóveis, camionetas e utilitários novos",
  "4511102": "Comércio a varejo de automóveis, camionetas e utilitários usados",
  "4511103": "Comércio por atacado de automóveis, camionetas e utilitários novos e usados",
  "4511104": "Comércio por atacado de caminhões novos e usados",
  "4511105": "Comércio por atacado de reboques e semi-reboques novos e usados",
  "4511106": "Comércio por atacado de ônibus e microônibus novos e usados",
  "4512901": "Representantes comerciais e agentes do comércio de veículos automotores",
  "4512902": "Comércio sob consignação de veículos automotores",
  "4520001": "Serviços de manutenção e reparação mecânica de veículos automotores",
  "4520002": "Serviços de lanternagem ou funilaria e pintura de veículos automotores",
  "4520003": "Serviços de manutenção e reparação elétrica de veículos automotores",
  "4520004": "Serviços de alinhamento e balanceamento de veículos automotores",
  "4520005": "Serviços de lavagem, lubrificação e polimento de veículos automotores",
  "4520006": "Serviços de borracharia para veículos automotores",
  "4520007": "Serviços de instalação, manutenção e reparação de acessórios para veículos automotores",
  "4520008": "Serviços de capotaria",
  "4530701": "Comércio por atacado de peças e acessórios novos para veículos automotores",
  "4530702": "Comércio por atacado de pneumáticos e câmaras-de-ar",
  "4530703": "Comércio a varejo de peças e acessórios novos para veículos automotores",
  "4530704": "Comércio a varejo de peças e acessórios usados para veículos automotores",
  "4530705": "Comércio a varejo de pneumáticos e câmaras-de-ar",
  "4530706": "Representantes comerciais e agentes do comércio de peças e acessórios novos e usados para veículos automotores",
  "4541201": "Comércio por atacado de motocicletas e motonetas",
  "4541202": "Comércio por atacado de peças e acessórios para motocicletas e motonetas",
  "4541203": "Comércio a varejo de motocicletas e motonetas novas",
  "4541204": "Comércio a varejo de motocicletas e motonetas usadas",
  "4541205": "Comércio a varejo de peças e acessórios para motocicletas e motonetas",
  "4541206": "Comércio a varejo de peças e acessórios novos para  motocicletas e motonetas",
  "4541207": "Comércio a varejo de peças e acessórios usados para motocicletas e motonetas",
  "4542101": "Representantes comerciais e agentes do comércio de motocicletas e motonetas, peças e acessórios",
  "4542102": "Comércio sob consignação de motocicletas e motonetas",
  "4543900": "Manutenção e reparação de motocicletas e motonetas",
  "4611700": "Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos",
  "4612500": "Representantes comerciais e agentes do comércio de combustíveis, minerais, produtos siderúrgicos e químicos",
  "4613300": "Representantes comerciais e agentes do comércio de madeira, material de construção e ferragens",
  "4614100": "Representantes comerciais e agentes do comércio de máquinas, equipamentos, embarcações e aeronaves",
  "4615000": "Representantes comerciais e agentes do comércio de eletrodomésticos, móveis e artigos de uso doméstico",
  "4616800": "Representantes comerciais e agentes do comércio de têxteis, vestuário, calçados e artigos de viagem",
  "4617600": "Representantes comerciais e agentes do comércio de produtos alimentícios, bebidas e fumo",
  "4618401": "Representantes comerciais e agentes do comércio de medicamentos, cosméticos e produtos de perfumaria",
  "4618402": "Representantes comerciais e agentes do comércio de instrumentos e materiais odonto-médico-hospitalares",
  "4618403": "Representantes comerciais e agentes do comércio de jornais, revistas e outras publicações",
  "4618499": "Outros representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente",
  "4619200": "Representantes comerciais e agentes do comércio de mercadorias em geral não especializado",
  "4621400": "Comércio atacadista de café em grão",
  "4622200": "Comércio atacadista de soja",
  "4623101": "Comércio atacadista de animais vivos",
  "4623102": "Comércio atacadista de couros, lãs, peles e outros subprodutos não-comestíveis de origem animal",
  "4623103": "Comércio atacadista de algodão",
  "4623104": "Comércio atacadista de fumo em folha não beneficiado",
  "4623105": "Comércio atacadista de cacau",
  "4623106": "Comércio atacadista de sementes, flores, plantas e gramas",
  "4623107": "Comércio atacadista de sisal",
  "4623108": "Comércio atacadista de matérias-primas agrícolas com atividade de fracionamento e acondicionamento associada",
  "4623109": "Comércio atacadista de alimentos para animais",
  "4623199": "Comércio atacadista de matérias-primas agrícolas não especificadas anteriormente",
  "4631100": "Comércio atacadista de leite e laticínios",
  "4632001": "Comércio atacadista de cereais e leguminosas beneficiados",
  "4632002": "Comércio atacadista de farinhas, amidos e féculas",
  "4632003": "Comércio atacadista de cereais e leguminosas beneficiados, farinhas, amidos e féculas, com atividade de fracionamento e acondicionamento associada",
  "4633801": "Comércio atacadista de frutas, verduras, raízes, tubérculos, hortaliças e legumes frescos",
  "4633802": "Comércio atacadista de aves vivas e ovos",
  "4633803": "Comércio atacadista de coelhos e outros pequenos animais vivos para alimentação",
  "4634601": "Comércio atacadista de carnes bovinas e suínas e derivados",
  "4634602": "Comércio atacadista de aves abatidas e derivados",
  "4634603": "Comércio atacadista de pescados e frutos do mar",
  "4634699": "Comércio atacadista de carnes e derivados de outros animais",
  "4635401": "Comércio atacadista de água mineral",
  "4635402": "Comércio atacadista de cerveja, chope e refrigerante",
  "4635403": "Comércio atacadista de bebidas com atividade de fracionamento e acondicionamento associada",
  "4635499": "Comércio atacadista de bebidas não especificadas anteriormente",
  "4636201": "Comércio atacadista de fumo beneficiado",
  "4636202": "Comércio atacadista de cigarros, cigarrilhas e charutos",
  "4637101": "Comércio atacadista de café torrado, moído e solúvel",
  "4637102": "Comércio atacadista de açúcar",
  "4637103": "Comércio atacadista de óleos e gorduras",
  "4637104": "Comércio atacadista de pães, bolos, biscoitos e similares",
  "4637105": "Comércio atacadista de massas alimentícias",
  "4637106": "Comércio atacadista de sorvetes",
  "4637107": "Comércio atacadista de chocolates, confeitos, balas, bombons e semelhantes",
  "4637199": "Comércio atacadista especializado em outros produtos alimentícios não especificados anteriormente",
  "4639701": "Comércio atacadista de produtos alimentícios em geral",
  "4639702": "Comércio atacadista de produtos alimentícios em geral, com atividade de fracionamento e acondicionamento associada",
  "4641901": "Comércio atacadista de tecidos",
  "4641902": "Comércio atacadista de artigos de cama, mesa e banho",
  "4641903": "Comércio atacadista de artigos de armarinho",
  "4642701": "Comércio atacadista de artigos do vestuário e acessórios, exceto profissionais e de segurança",
  "4642702": "Comércio atacadista de roupas e acessórios para uso profissional e de segurança do trabalho",
  "4643501": "Comércio atacadista de calçados",
  "4643502": "Comércio atacadista de bolsas, malas e artigos de viagem",
  "4644301": "Comércio atacadista de medicamentos e drogas de uso humano",
  "4644302": "Comércio atacadista de medicamentos e drogas de uso veterinário",
  "4645101": "Comércio atacadista de instrumentos e materiais para uso médico, cirúrgico, hospitalar e de laboratórios",
  "4645102": "Comércio atacadista de próteses e artigos de ortopedia",
  "4645103": "Comércio atacadista de produtos odontológicos",
  "4646001": "Comércio atacadista de cosméticos e produtos de perfumaria",
  "4646002": "Comércio atacadista de produtos de higiene pessoal",
  "4647801": "Comércio atacadista de artigos de escritório e de papelaria",
  "4647802": "Comércio atacadista de livros, jornais e outras publicações",
  "4649401": "Comércio atacadista de equipamentos elétricos de uso pessoal e doméstico",
  "4649402": "Comércio atacadista de aparelhos eletrônicos de uso pessoal e doméstico",
  "4649403": "Comércio atacadista de bicicletas, triciclos e outros veículos recreativos",
  "4649404": "Comércio atacadista de móveis e artigos de colchoaria",
  "4649405": "Comércio atacadista de artigos de tapeçaria; persianas e cortinas",
  "4649406": "Comércio atacadista de lustres, luminárias e abajures",
  "4649407": "Comércio atacadista de filmes, CDs, DVDs, fitas e discos",
  "4649408": "Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar",
  "4649409": "Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar, com atividade de fracionamento e acondicionamento associada",
  "4649410": "Comércio atacadista de jóias, relógios e bijuterias, inclusive pedras preciosas e semipreciosas lapidadas",
  "4649499": "Comércio atacadista de outros equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente",
  "4651601": "Comércio atacadista de equipamentos de informática",
  "4651602": "Comércio atacadista de suprimentos para informática",
  "4652400": "Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação",
  "4661300": "Comércio atacadista de máquinas, aparelhos e equipamentos para uso agropecuário; partes e peças",
  "4662100": "Comércio atacadista de máquinas, equipamentos para terraplenagem, mineração e construção; partes e peças",
  "4663000": "Comércio atacadista de Máquinas e equipamentos para uso industrial; partes e peças",
  "4664800": "Comércio atacadista de máquinas, aparelhos e equipamentos para uso odonto-médico-hospitalar; partes e peças",
  "4665600": "Comércio atacadista de máquinas e equipamentos para uso comercial; partes e peças",
  "4669901": "Comércio atacadista de bombas e compressores; partes e peças",
  "4669999": "Comércio atacadista de outras máquinas e equipamentos não especificados anteriormente; partes e peças",
  "4671100": "Comércio atacadista de madeira e produtos derivados",
  "4672900": "Comércio atacadista de ferragens e ferramentas",
  "4673700": "Comércio atacadista de material elétrico",
  "4674500": "Comércio atacadista de cimento",
  "4679601": "Comércio atacadista de tintas, vernizes e similares",
  "4679602": "Comércio atacadista de mármores e granitos",
  "4679603": "Comércio atacadista de vidros, espelhos, vitrais e molduras",
  "4679604": "Comércio atacadista especializado de materiais de construção não especificados anteriormente",
  "4679699": "Comércio atacadista de materiais de construção em geral",
  "4681801": "Comércio atacadista de álcool carburante, biodiesel, gasolina e demais derivados de petróleo, exceto lubrificantes, não realizado por transportador re",
  "4681802": "Comércio atacadista de combustíveis realizado por transportador retalhista (T.R.R.)",
  "4681803": "Comércio atacadista de combustíveis de origem vegetal, exceto álcool carburante",
  "4681804": "Comércio atacadista de combustíveis de origem mineral em bruto",
  "4681805": "Comércio atacadista de lubrificantes",
  "4682600": "Comércio atacadista de gás liqüefeito de petróleo (GLP)",
  "4683400": "Comércio atacadista de defensivos agrícolas, adubos, fertilizantes e corretivos do solo",
  "4684201": "Comércio atacadista de resinas e elastômeros",
  "4684202": "Comércio atacadista de solventes",
  "4684299": "Comércio atacadista de outros produtos químicos e petroquímicos não especificados anteriormente",
  "4685100": "Comércio atacadista de produtos siderúrgicos e metalúrgicos, exceto para construção",
  "4686901": "Comércio atacadista de papel e papelão em bruto",
  "4686902": "Comércio atacadista de embalagens",
  "4687701": "Comércio atacadista de resíduos de papel e papelão",
  "4687702": "Comércio atacadista de resíduos e sucatas não-metálicos, exceto de papel e papelão",
  "4687703": "Comércio atacadista de resíduos e sucatas metálicos",
  "4689301": "Comércio atacadista de produtos da extração mineral, exceto combustíveis",
  "4689302": "Comércio atacadista de fios e fibras beneficiados",
  "4689399": "Comércio atacadista especializado em outros produtos intermediários não especificados anteriormente",
  "4691500": "Comércio atacadista de mercadorias em geral, com predominância de produtos alimentícios",
  "4692300": "Comércio atacadista de mercadorias em geral, com predominância de insumos agropecuários",
  "4693100": "Comércio atacadista de mercadorias em geral, sem predominância de alimentos ou de insumos agropecuários",
  "4711301": "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - hipermercados",
  "4711302": "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - supermercados",
  "4712100": "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - minimercados, mercearias e armazéns",
  "4713001": "Lojas de departamentos ou magazines",
  "4713002": "Lojas de variedades, exceto lojas de departamentos ou magazines",
  "4713003": "Lojas duty free de aeroportos internacionais",
  "4713004": "Lojas de departamentos ou magazines, exceto lojas francas (Duty free)",
  "4713005": "Lojas francas (Duty free) de aeroportos, portos e em fronteiras terrestres",
  "4721101": "Padaria e confeitaria com predominância de produção própria",
  "4721102": "Padaria e confeitaria com predominância de revenda",
  "4721103": "Comércio varejista de laticínios e frios",
  "4721104": "Comércio varejista de doces, balas, bombons e semelhantes",
  "4722901": "Comércio varejista de carnes - açougues",
  "4722902": "Peixaria",
  "4723700": "Comércio varejista de bebidas",
  "4724500": "Comércio varejista de hortifrutigranjeiros",
  "4729601": "Tabacaria",
  "4729602": "Comércio varejista de mercadorias em lojas de conveniência",
  "4729699": "Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente",
  "4731800": "Comércio varejista de combustíveis para veículos automotores",
  "4732600": "Comércio varejista de lubrificantes",
  "4741500": "Comércio varejista de tintas e materiais para pintura",
  "4742300": "Comércio varejista de material elétrico",
  "4743100": "Comércio varejista de vidros",
  "4744001": "Comércio varejista de ferragens e ferramentas",
  "4744002": "Comércio varejista de madeira e artefatos",
  "4744003": "Comércio varejista de materiais hidráulicos",
  "4744004": "Comércio varejista de cal, areia, pedra britada, tijolos e telhas",
  "4744005": "Comércio varejista de materiais de construção não especificados anteriormente",
  "4744006": "Comércio varejista de pedras para revestimento",
  "4744099": "Comércio varejista de materiais de construção em geral",
  "4751200": "Comércio varejista especializado de equipamentos e suprimentos de informática",
  "4751201": "Comércio varejista especializado de equipamentos e suprimentos de informática",
  "4751202": "Recarga de cartuchos para equipamentos de informática",
  "4752100": "Comércio varejista especializado de equipamentos de telefonia e comunicação",
  "4753900": "Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo",
  "4754701": "Comércio varejista de móveis",
  "4754702": "Comércio varejista de artigos de colchoaria",
  "4754703": "Comércio varejista de artigos de iluminação",
  "4755501": "Comércio varejista de tecidos",
  "4755502": "Comercio varejista de artigos de armarinho",
  "4755503": "Comercio varejista de artigos de cama, mesa e banho",
  "4756300": "Comércio varejista especializado de instrumentos musicais e acessórios",
  "4757100": "Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico, exceto informática e comunicação",
  "4759801": "Comércio varejista de artigos de tapeçaria, cortinas e persianas",
  "4759899": "Comércio varejista de outros artigos de uso pessoal e doméstico não especificados anteriormente",
  "4761001": "Comércio varejista de livros",
  "4761002": "Comércio varejista de jornais e revistas",
  "4761003": "Comércio varejista de artigos de papelaria",
  "4762800": "Comércio varejista de discos, CDs, DVDs e fitas",
  "4763601": "Comércio varejista de brinquedos e artigos recreativos",
  "4763602": "Comércio varejista de artigos esportivos",
  "4763603": "Comércio varejista de bicicletas e triciclos; peças e acessórios",
  "4763604": "Comércio varejista de artigos de caça, pesca e camping",
  "4763605": "Comércio varejista de embarcações e outros veículos recreativos; peças e acessórios",
  "4771701": "Comércio varejista de produtos farmacêuticos, sem manipulação de fórmulas",
  "4771702": "Comércio varejista de produtos farmacêuticos, com manipulação de fórmulas",
  "4771703": "Comércio varejista de produtos farmacêuticos homeopáticos",
  "4771704": "Comércio varejista de medicamentos veterinários",
  "4772500": "Comércio varejista de cosméticos, produtos de perfumaria e de higiene pessoal",
  "4773300": "Comércio varejista de artigos médicos e ortopédicos",
  "4774100": "Comércio varejista de artigos de óptica",
  "4781400": "Comércio varejista de artigos do vestuário e acessórios",
  "4782201": "Comércio varejista de calçados",
  "4782202": "Comércio varejista de artigos de viagem",
  "4783101": "Comércio varejista de artigos de joalheria",
  "4783102": "Comércio varejista de artigos de relojoaria",
  "4784900": "Comércio varejista de gás liqüefeito de petróleo (GLP)",
  "4785701": "Comércio varejista de antigüidades",
  "4785799": "Comércio varejista de outros artigos usados",
  "4789001": "Comércio varejista de suvenires, bijuterias e artesanatos",
  "4789002": "Comércio varejista de plantas e flores naturais",
  "4789003": "Comércio varejista de objetos de arte",
  "4789004": "Comércio varejista de animais vivos e de artigos e alimentos para animais de estimação",
  "4789005": "Comércio varejista de produtos saneantes domissanitários",
  "4789006": "Comércio varejista de fogos de artifício e artigos pirotécnicos",
  "4789007": "Comércio varejista de equipamentos para escritório",
  "4789008": "Comércio varejista de artigos fotográficos e para filmagem",
  "4789009": "Comércio varejista de armas e munições",
  "4789099": "Comércio varejista de outros produtos não especificados anteriormente",
  "4911600": "Transporte ferroviário de carga",
  "4912401": "Transporte ferroviário de passageiros intermunicipal e interestadual",
  "4912402": "Transporte ferroviário de passageiros municipal e em região metropolitana",
  "4912403": "Transporte metroviário",
  "4921301": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, municipal",
  "4921302": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal em região metropolitana",
  "4922101": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal, exceto em região metropolitana",
  "4922102": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, interestadual",
  "4922103": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, internacional",
  "4923001": "Serviço de táxi",
  "4923002": "Serviço de transporte de passageiros - locação de automóveis com motorista",
  "4924800": "Transporte escolar",
  "4929901": "Transporte rodoviário coletivo de passageiros, sob regime de fretamento, municipal",
  "4929902": "Transporte rodoviário coletivo de passageiros, sob regime de fretamento, intermunicipal, interestadual e internacional",
  "4929903": "Organização de excursões em veículos rodoviários próprios, municipal",
  "4929904": "Organização de excursões em veículos rodoviários próprios, intermunicipal, interestadual e internacional",
  "4929999": "Outros transportes rodoviários de passageiros não especificados anteriormente",
  "4930201": "Transporte rodoviário de carga, exceto produtos perigosos e mudanças, municipal.",
  "4930202": "Transporte rodoviário de carga, exceto produtos perigosos e mudanças, intermunicipal, interestadual e internacional",
  "4930203": "Transporte rodoviário de produtos perigosos",
  "4930204": "Transporte rodoviário de mudanças",
  "4940000": "Transporte dutoviário",
  "4950700": "Trens turísticos, teleféricos e similares",
  "5011401": "Transporte marítimo de cabotagem - Carga",
  "5011402": "Transporte marítimo de cabotagem - passageiros",
  "5012201": "Transporte marítimo de longo curso - Carga",
  "5012202": "Transporte marítimo de longo curso - Passageiros",
  "5021101": "Transporte por navegação interior de carga, municipal, exceto travessia",
  "5021102": "Transporte por navegação interior de carga, intermunicipal, interestadual e internacional, exceto travessia",
  "5022001": "Transporte por navegação interior de passageiros em linhas regulares, municipal, exceto travessia",
  "5022002": "Transporte por navegação interior de passageiros em linhas regulares, intermunicipal, interestadual e internacional, exceto travessia",
  "5030101": "Navegação de apoio marítimo",
  "5030102": "Navegação de apoio portuário",
  "5030103": "Serviço de rebocadores e empurradores",
  "5091201": "Transporte por navegação de travessia, municipal",
  "5091202": "Transporte por navegação de travessia intermunicipal, interestadual e internacional",
  "5099801": "Transporte aquaviário para passeios turísticos",
  "5099899": "Outros transportes aquaviários não especificados anteriormente",
  "5111100": "Transporte aéreo de passageiros regular",
  "5112901": "Serviço de táxi aéreo e locação de aeronaves com tripulação",
  "5112999": "Outros serviços de transporte aéreo de passageiros não-regular",
  "5120000": "Transporte aéreo de carga",
  "5130700": "Transporte espacial",
  "5211701": "Armazéns gerais - emissão de warrant",
  "5211702": "Guarda-móveis",
  "5211799": "Depósitos de mercadorias para terceiros, exceto armazéns gerais e guarda-móveis",
  "5212500": "Carga e descarga",
  "5221400": "Concessionárias de rodovias, pontes, túneis e serviços relacionados",
  "5222200": "Terminais rodoviários e ferroviários",
  "5223100": "Estacionamento de veículos",
  "5229001": "Serviços de apoio ao transporte por táxi, inclusive centrais de chamada",
  "5229002": "Serviços de reboque de veículos",
  "5229099": "Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente",
  "5231101": "Administração da infra-estrutura portuária",
  "5231102": "Atividades do Operador Portuário",
  "5231103": "Gestão de terminais aquaviários",
  "5232000": "Atividades de agenciamento marítimo",
  "5239700": "Atividades auxiliares dos transportes aquaviários não especificadas anteriormente",
  "5239701": "Serviços de praticagem",
  "5239799": "Atividades auxiliares dos transportes aquaviários não especificadas anteriormente",
  "5240101": "Operação dos aeroportos e campos de aterrissagem",
  "5240199": "Atividades auxiliares dos transportes aéreos, exceto operação dos aeroportos e campos de aterrissagem",
  "5250801": "Comissaria de despachos",
  "5250802": "Atividades de despachantes aduaneiros",
  "5250803": "Agenciamento de cargas, exceto para o transporte marítimo",
  "5250804": "Organização logística do transporte de carga",
  "5250805": "Operador de transporte multimodal - OTM",
  "5310501": "Atividades do Correio Nacional",
  "5310502": "Atividades de franqueadas do Correio Nacional",
  "5320201": "Serviços de malote não realizados pelo Correio Nacional",
  "5320202": "Serviços de entrega rápida",
  "5510801": "Hotéis",
  "5510802": "Apart-hotéis",
  "5510803": "Motéis",
  "5590601": "Albergues, exceto assistenciais",
  "5590602": "Campings",
  "5590603": "Pensões (alojamento)",
  "5590699": "Outros alojamentos não especificados anteriormente",
  "5611201": "Restaurantes e similares",
  "5611202": "Bares e outros estabelecimentos especializados em servir bebidas",
  "5611203": "Lanchonetes, casas de chá, de sucos e similares",
  "5611204": "Bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento",
  "5611205": "Bares e outros estabelecimentos especializados em servir bebidas, com entretenimento",
  "5612100": "Serviços ambulantes de alimentação",
  "5620101": "Fornecimento de alimentos preparados preponderantemente para empresas",
  "5620102": "Serviços de alimentação para eventos e recepções - bufê",
  "5620103": "Cantinas - serviços de alimentação privativos",
  "5620104": "Fornecimento de alimentos preparados preponderantemente para consumo domiciliar",
  "5811500": "Edição de livros",
  "5812300": "Edição de jornais",
  "5812301": "Edição de jornais diários",
  "5812302": "Edição de jornais não diários",
  "5813100": "Edição de revistas",
  "5819100": "Edição de cadastros, listas e de outros produtos gráficos",
  "5821200": "Edição integrada à impressão de livros",
  "5822100": "Edição integrada à impressão de jornais",
  "5822101": "Edição integrada à impressão de jornais diários",
  "5822102": "Edição integrada à impressão de jornais não diários",
  "5823900": "Edição integrada à impressão de revistas",
  "5829800": "Edição integrada à impressão de cadastros, listas e de outros produtos gráficos",
  "5911101": "Estúdios cinematográficos",
  "5911102": "Produção de filmes para publicidade",
  "5911199": "Atividades de produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente",
  "5912001": "Serviços de dublagem",
  "5912002": "Serviços de mixagem sonora em produção audiovisual",
  "5912099": "Atividades de pós-produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente",
  "5913800": "Distribuição cinematográfica, de vídeo e de programas de televisão",
  "5914600": "Atividades de exibição cinematográfica",
  "5920100": "Atividades de gravação de som e de edição de música",
  "6010100": "Atividades de rádio",
  "6021700": "Atividades de televisão aberta",
  "6022501": "Programadoras",
  "6022502": "Atividades relacionadas à televisão por assinatura, exceto programadoras",
  "6110801": "Serviços de telefonia fixa comutada - STFC",
  "6110802": "Serviços de redes de transportes de telecomunicações - SRTT",
  "6110803": "Serviços de comunicação multimídia - SCM",
  "6110899": "Serviços de telecomunicações por fio não especificados anteriormente",
  "6120501": "Telefonia móvel celular",
  "6120502": "Serviço móvel especializado - SME",
  "6120599": "Serviços de telecomunicações sem fio não especificados anteriormente",
  "6130200": "Telecomunicações por satélite",
  "6141800": "Operadoras de televisão por assinatura por cabo",
  "6142600": "Operadoras de televisão por assinatura por microondas",
  "6143400": "Operadoras de televisão por assinatura por satélite",
  "6190601": "Provedores de acesso às redes de comunicações",
  "6190602": "Provedores de voz sobre protocolo internet - VOIP",
  "6190699": "Outras atividades de telecomunicações não especificadas anteriormente",
  "6201500": "Desenvolvimento de programas de computador sob encomenda",
  "6201501": "Desenvolvimento de programas de computador sob encomenda",
  "6201502": "Web design",
  "6202300": "Desenvolvimento e licenciamento de programas de computador customizáveis",
  "6203100": "Desenvolvimento e licenciamento de programas de computador não-customizáveis",
  "6204000": "Consultoria em tecnologia da informação",
  "6209100": "Suporte técnico, manutenção e outros serviços em tecnologia da informação",
  "6311900": "Tratamento de dados, provedores de serviços de aplicação e serviços de hospedagem na internet",
  "6319400": "Portais, provedores de conteúdo e outros serviços de informação na internet",
  "6391700": "Agências de notícias",
  "6399200": "Outras atividades de prestação de serviços de informação não especificadas anteriormente",
  "6410700": "Banco Central",
  "6421200": "Bancos comerciais",
  "6422100": "Bancos múltiplos, com carteira comercial",
  "6423900": "Caixas econômicas",
  "6424701": "Bancos cooperativos",
  "6424702": "Cooperativas centrais de crédito",
  "6424703": "Cooperativas de crédito mútuo",
  "6424704": "Cooperativas de crédito rural",
  "6431000": "Bancos múltiplos, sem carteira comercial",
  "6432800": "Bancos de investimento",
  "6433600": "Bancos de desenvolvimento",
  "6434400": "Agências de fomento",
  "6435201": "Sociedades de crédito imobiliário",
  "6435202": "Associações de poupança e empréstimo",
  "6435203": "Companhias hipotecárias",
  "6436100": "Sociedades de crédito, financiamento e investimento - financeiras",
  "6437900": "Sociedades de crédito ao microempreendedor",
  "6438701": "Bancos de câmbio",
  "6438799": "Outras instituições de intermediação não-monetária não especificadas anteriormente",
  "6440900": "Arrendamento mercantil",
  "6450600": "Sociedades de capitalização",
  "6461100": "Holdings de instituições financeiras",
  "6462000": "Holdings de instituições não-financeiras",
  "6463800": "Outras sociedades de participação, exceto holdings",
  "6470101": "Fundos de investimento, exceto previdenciários e imobiliários",
  "6470102": "Fundos de investimento previdenciários",
  "6470103": "Fundos de investimento imobiliários",
  "6491300": "Sociedades de fomento mercantil - factoring",
  "6492100": "Securitização de créditos",
  "6493000": "Administração de consórcios para aquisição de bens e direitos",
  "6499901": "Clubes de investimento",
  "6499902": "Sociedades de investimento",
  "6499903": "Fundo garantidor de crédito",
  "6499904": "Caixas de financiamento de corporações",
  "6499905": "Concessão de crédito pelas OSCIP",
  "6499999": "Outras atividades de serviços financeiros não especificadas anteriormente",
  "6511101": "Sociedade seguradora de seguros vida",
  "6511102": "Planos de auxílio-funeral",
  "6512000": "Sociedade seguradora de seguros não vida",
  "6520100": "Sociedade seguradora de seguros saúde",
  "6530800": "Resseguros",
  "6541300": "Previdência complementar fechada",
  "6542100": "Previdência complementar aberta",
  "6550200": "Planos de saúde",
  "6611801": "Bolsa de valores",
  "6611802": "Bolsa de mercadorias",
  "6611803": "Bolsa de mercadorias e futuros",
  "6611804": "Administração de mercados de balcão organizados",
  "6612601": "Corretoras de títulos e valores mobiliários",
  "6612602": "Distribuidoras de títulos e valores mobiliários",
  "6612603": "Corretoras de câmbio",
  "6612604": "Corretoras de contratos de mercadorias",
  "6612605": "Agentes de investimentos em aplicações financeiras",
  "6613400": "Administração de cartões de crédito",
  "6619301": "Serviços de liquidação e custódia",
  "6619302": "Correspondentes de instituições financeiras",
  "6619303": "Representações de bancos estrangeiros",
  "6619304": "Caixas eletrônicos",
  "6619305": "Operadoras de cartões de débito",
  "6619399": "Outras atividades auxiliares dos serviços financeiros não especificadas anteriormente",
  "6621501": "Peritos e avaliadores de seguros",
  "6621502": "Auditoria e consultoria atuarial",
  "6622300": "Corretores e agentes de seguros, de planos de previdência complementar e de saúde",
  "6629100": "Atividades auxiliares dos seguros, da previdência complementar e dos planos de saúde não especificadas anteriormente",
  "6630400": "Atividades de administração de fundos por contrato ou comissão",
  "6810201": "Compra e venda de imóveis próprios",
  "6810202": "Aluguel de imóveis próprios",
  "6810203": "Loteamento de imóveis próprios",
  "6821801": "Corretagem na compra e venda e avaliação de imóveis",
  "6821802": "Corretagem no aluguel de imóveis",
  "6822600": "Gestão e administração da propriedade imobiliária",
  "6911701": "Serviços advocatícios",
  "6911702": "Atividades auxiliares da justiça",
  "6911703": "Agente de propriedade industrial",
  "6912500": "Cartórios",
  "6920601": "Atividades de contabilidade",
  "6920602": "Atividades de consultoria e auditoria contábil e tributária",
  "7020400": "Atividades de consultoria em gestão empresarial, exceto consultoria técnica específica",
  "7111100": "Serviços de arquitetura",
  "7112000": "Serviços de engenharia",
  "7119701": "Serviços de cartografia, topografia e geodésia",
  "7119702": "Atividades de estudos geológicos",
  "7119703": "Serviços de desenho técnico relacionados à arquitetura e engenharia",
  "7119704": "Serviços de perícia técnica relacionados à segurança do trabalho",
  "7119799": "Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente",
  "7120100": "Testes e análises técnicas",
  "7210000": "Pesquisa e desenvolvimento experimental em ciências físicas e naturais",
  "7220700": "Pesquisa e desenvolvimento experimental em ciências sociais e humanas",
  "7311400": "Agências de publicidade",
  "7312200": "Agenciamento de espaços para publicidade, exceto em veículos de comunicação",
  "7319001": "Criação de estandes para feiras e exposições",
  "7319002": "Promoção de vendas",
  "7319003": "Marketing direto",
  "7319004": "Consultoria em publicidade",
  "7319099": "Outras atividades de publicidade não especificadas anteriormente",
  "7320300": "Pesquisas de mercado e de opinião pública",
  "7410201": "Design",
  "7410202": "Design de interiores",
  "7410203": "Design de produto",
  "7410299": "atividades de design não especificadas anteriormente",
  "7420001": "Atividades de produção de fotografias, exceto aérea e submarina",
  "7420002": "Atividades de produção de fotografias aéreas e submarinas",
  "7420003": "Laboratórios fotográficos",
  "7420004": "Filmagem de festas e eventos",
  "7420005": "Serviços de microfilmagem",
  "7490101": "Serviços de tradução, interpretação e similares",
  "7490102": "Escafandria e mergulho",
  "7490103": "Serviços de agronomia e de consultoria às atividades agrícolas e pecuárias",
  "7490104": "Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários",
  "7490105": "Agenciamento de profissionais para atividades esportivas, culturais e artísticas",
  "7490199": "Outras atividades profissionais, científicas e técnicas não especificadas anteriormente",
  "7500100": "Atividades veterinárias",
  "7711000": "Locação de automóveis sem condutor",
  "7719501": "Locação de embarcações sem tripulação, exceto para fins recreativos",
  "7719502": "Locação de aeronaves sem tripulação",
  "7719599": "Locação de outros meios de transporte não especificados anteriormente, sem condutor",
  "7721700": "Aluguel de equipamentos recreativos e esportivos",
  "7722500": "Aluguel de fitas de vídeo, DVDs e similares",
  "7723300": "Aluguel de objetos do vestuário, jóias e acessórios",
  "7729201": "Aluguel de aparelhos de jogos eletrônicos",
  "7729202": "Aluguel de móveis, utensílios e aparelhos de uso doméstico e pessoal; instrumentos musicais",
  "7729203": "Aluguel de material médico",
  "7729299": "Aluguel de outros objetos pessoais e domésticos não especificados anteriormente",
  "7731400": "Aluguel de máquinas e equipamentos agrícolas sem operador",
  "7732201": "Aluguel de máquinas e equipamentos para construção sem operador, exceto andaimes",
  "7732202": "Aluguel de andaimes",
  "7733100": "Aluguel de máquinas e equipamentos para escritórios",
  "7739001": "Aluguel de máquinas e equipamentos para extração de minérios e petróleo, sem operador",
  "7739002": "Aluguel de equipamentos científicos, médicos e hospitalares, sem operador",
  "7739003": "Aluguel de palcos, coberturas e outras estruturas de uso temporário, exceto andaimes",
  "7739099": "Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente, sem operador",
  "7740300": "Gestão de ativos intangíveis não-financeiros",
  "7810800": "Seleção e agenciamento de mão-de-obra",
  "7820500": "Locação de mão-de-obra temporária",
  "7830200": "Fornecimento e gestão de recursos humanos para terceiros",
  "7911200": "Agências de viagens",
  "7912100": "Operadores turísticos",
  "7990200": "Serviços de reservas e outros serviços de turismo não especificados anteriormente",
  "8011101": "Atividades de vigilância e segurança privada",
  "8011102": "Serviços de adestramento de cães de guarda",
  "8012900": "Atividades de transporte de valores",
  "8020000": "Atividades de monitoramento de sistemas de segurança",
  "8020001": "Atividades de monitoramento de sistemas de segurança eletrônico",
  "8020002": "Outras atividades de serviços de segurança",
  "8030700": "Atividades de investigação particular",
  "8111700": "Serviços combinados para apoio a edifícios, exceto condomínios prediais",
  "8112500": "Condomínios prediais",
  "8121400": "Limpeza em prédios e em domicílios",
  "8122200": "Imunização e controle de pragas urbanas",
  "8129000": "Atividades de limpeza não especificadas anteriormente",
  "8130300": "Atividades paisagísticas",
  "8211300": "Serviços combinados de escritório e apoio administrativo",
  "8219901": "Fotocópias",
  "8219999": "Preparação de documentos e serviços especializados de apoio administrativo não especificados anteriormente",
  "8220200": "Atividades de teleatendimento",
  "8230001": "Serviços de organização de feiras, congressos, exposições e festas",
  "8230002": "Casas de festas e eventos",
  "8291100": "Atividades de cobranças e informações cadastrais",
  "8292000": "Envasamento e empacotamento sob contrato",
  "8299701": "Medição de consumo de energia elétrica, gás e água",
  "8299702": "Emissão de vales-alimentação, vales-transporte e similares",
  "8299703": "Serviços de gravação de carimbos, exceto confecção",
  "8299704": "Leiloeiros independentes",
  "8299705": "Serviços de levantamento de fundos sob contrato",
  "8299706": "Casas lotéricas",
  "8299707": "Salas de acesso à internet",
  "8299799": "Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente",
  "8411600": "Administração pública em geral",
  "8412400": "Regulação das atividades de saúde, educação, serviços culturais e outros serviços sociais",
  "8413200": "Regulação das atividades econômicas",
  "8421300": "Relações exteriores",
  "8422100": "Defesa",
  "8423000": "Justiça",
  "8424800": "Segurança e ordem pública",
  "8425600": "Defesa Civil",
  "8430200": "Seguridade social obrigatória",
  "8511200": "Educação infantil - creche",
  "8512100": "Educação infantil - pré-escola",
  "8513900": "Ensino fundamental",
  "8520100": "Ensino médio",
  "8531700": "Educação superior - graduação",
  "8532500": "Educação superior - graduação e pós-graduação",
  "8533300": "Educação superior - pós-graduação e extensão",
  "8541400": "Educação profissional de nível técnico",
  "8542200": "Educação profissional de nível tecnológico",
  "8550301": "Administração de caixas escolares",
  "8550302": "Atividades de apoio à educação, exceto caixas escolares",
  "8591100": "Ensino de esportes",
  "8592901": "Ensino de dança",
  "8592902": "Ensino de artes cênicas, exceto dança",
  "8592903": "Ensino de música",
  "8592999": "Ensino de arte e cultura não especificado anteriormente",
  "8593700": "Ensino de idiomas",
  "8599601": "Formação de condutores",
  "8599602": "Cursos de pilotagem",
  "8599603": "Treinamento em informática",
  "8599604": "Treinamento em desenvolvimento profissional e gerencial",
  "8599605": "Cursos preparatórios para concursos",
  "8599699": "Outras atividades de ensino não especificadas anteriormente",
  "8610101": "Atividades de atendimento hospitalar, exceto pronto-socorro e unidades para atendimento a urgências",
  "8610102": "Atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgências",
  "8621601": "UTI móvel",
  "8621602": "Serviços móveis de atendimento a urgências, exceto por UTI móvel",
  "8622400": "Serviços de remoção de pacientes, exceto os serviços móveis de atendimento a urgências",
  "8630501": "Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos",
  "8630502": "Atividade médica ambulatorial com recursos para realização de exames complementares",
  "8630503": "Atividade médica ambulatorial restrita a consultas",
  "8630504": "Atividade odontológica",
  "8630505": "Atividade odontológica sem recursos para realização de procedimentos cirúrgicos",
  "8630506": "Serviços de vacinação e imunização humana",
  "8630507": "Atividades de reprodução humana assistida",
  "8630599": "Atividades de atenção ambulatorial não especificadas anteriormente",
  "8640201": "Laboratórios de anatomia patológica e citológica",
  "8640202": "Laboratórios clínicos",
  "8640203": "Serviços de diálise e nefrologia",
  "8640204": "Serviços de tomografia",
  "8640205": "Serviços de diagnóstico por imagem com uso de radiação ionizante, exceto tomografia",
  "8640206": "Serviços de ressonância magnética",
  "8640207": "Serviços de diagnóstico por imagem sem uso de radiação ionizante, exceto ressonância magnética",
  "8640208": "Serviços de diagnóstico por registro gráfico - ECG, EEG e outros exames análogos",
  "8640209": "Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos",
  "8640210": "Serviços de quimioterapia",
  "8640211": "Serviços de radioterapia",
  "8640212": "Serviços de hemoterapia",
  "8640213": "Serviços de litotripcia",
  "8640214": "Serviços de bancos de células e tecidos humanos",
  "8640299": "Atividades de serviços de complementação diagnóstica e terapêutica não especificadas anteriormente",
  "8650001": "Atividades de enfermagem",
  "8650002": "Atividades de profissionais da nutrição",
  "8650003": "Atividades de psicologia e psicanálise",
  "8650004": "Atividades de fisioterapia",
  "8650005": "Atividades de terapia ocupacional",
  "8650006": "Atividades de fonoaudiologia",
  "8650007": "Atividades de terapia de nutrição enteral e parenteral",
  "8650099": "Atividades de profissionais da área de saúde não especificadas anteriormente",
  "8660700": "Atividades de apoio à gestão de saúde",
  "8690901": "Atividades de práticas integrativas e complementares em saúde humana",
  "8690902": "Atividades de banco de leite humano",
  "8690903": "Atividades de acupuntura",
  "8690904": "Atividades de podologia",
  "8690999": "Outras atividades de atenção à saúde humana não especificadas anteriormente",
  "8711501": "Clínicas e residências geriátricas",
  "8711502": "Instituições de longa permanência para idosos",
  "8711503": "Atividades de assistência a deficientes físicos, imunodeprimidos e convalescentes",
  "8711504": "Centros de apoio a pacientes com câncer e com AIDS",
  "8711505": "Condomínios residenciais para idosos e deficientes físicos",
  "8712300": "Atividades de fornecimento de infra-estrutura de apoio e assistência a paciente no domicílio",
  "8720401": "Atividades de centros de assistência psicossocial",
  "8720499": "Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos, deficiência mental e dependência química e grupos similares não",
  "8730101": "Orfanatos",
  "8730102": "Albergues assistenciais",
  "8730199": "Atividades de assistência social prestadas em residências coletivas e particulares não especificadas anteriormente",
  "8800600": "Serviços de assistência social sem alojamento",
  "8888888": "Atividade Econônica não informada",
  "9001901": "Produção teatral",
  "9001902": "Produção musical",
  "9001903": "Produção de espetáculos de dança",
  "9001904": "Produção de espetáculos circenses, de marionetes e similares",
  "9001905": "Produção de espetáculos de rodeios, vaquejadas e similares",
  "9001906": "Atividades de sonorização e de iluminação",
  "9001999": "Artes cênicas, espetáculos e atividades complementares não especificadas anteriormente",
  "9002701": "Atividades de artistas plásticos, jornalistas independentes e escritores",
  "9002702": "Restauração de obras-de-arte",
  "9003500": "Gestão de espaços para artes cênicas, espetáculos e outras atividades artísticas",
  "9101500": "Atividades de bibliotecas e arquivos",
  "9102301": "Atividades de museus e de exploração de lugares e prédios históricos e atrações similares",
  "9102302": "Restauração e conservação de lugares e prédios históricos",
  "9103100": "Atividades de jardins botânicos, zoológicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental",
  "9200301": "Casas de bingo",
  "9200302": "Exploração de apostas em corridas de cavalos",
  "9200399": "Exploração de jogos de azar e apostas não especificados anteriormente",
  "9311500": "Gestão de instalações de esportes",
  "9312300": "Clubes sociais, esportivos e similares",
  "9313100": "Atividades de condicionamento físico",
  "9319101": "Produção e promoção de eventos esportivos",
  "9319199": "Outras atividades esportivas não especificadas anteriormente",
  "9321200": "Parques de diversão e parques temáticos",
  "9329801": "Discotecas, danceterias, salões de dança e similares",
  "9329802": "Exploração de boliches",
  "9329803": "Exploração de jogos de sinuca, bilhar e similares",
  "9329804": "Exploração de jogos eletrônicos recreativos",
  "9329899": "Outras atividades de recreação e lazer não especificadas anteriormente",
  "9411100": "Atividades de organizações associativas patronais e empresariais",
  "9412000": "Atividades de organizações associativas profissionais",
  "9412001": "Atividades de fiscalização profissional",
  "9412099": "Outras atividades associativas profissionais",
  "9420100": "Atividades de organizações sindicais",
  "9430800": "Atividades de associações de defesa de direitos sociais",
  "9491000": "Atividades de organizações religiosas ou filosóficas",
  "9492800": "Atividades de organizações políticas",
  "9493600": "Atividades de organizações associativas ligadas à cultura e à arte",
  "9499500": "Atividades associativas não especificadas anteriormente",
  "9511800": "Reparação e manutenção de computadores e de equipamentos periféricos",
  "9512600": "Reparação e manutenção de equipamentos de comunicação",
  "9521500": "Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico",
  "9529101": "Reparação de calçados, bolsas e artigos de viagem",
  "9529102": "Chaveiros",
  "9529103": "Reparação de relógios",
  "9529104": "Reparação de bicicletas, triciclos e outros veículos não-motorizados",
  "9529105": "Reparação de artigos do mobiliário",
  "9529106": "Reparação de jóias",
  "9529199": "Reparação e manutenção de outros objetos e equipamentos pessoais e domésticos não especificados anteriormente",
  "9601701": "Lavanderias",
  "9601702": "Tinturarias",
  "9601703": "Toalheiros",
  "9602501": "Cabeleireiros, manicure e pedicure",
  "9602502": "Atividades de estética e outros serviços de cuidados com a beleza",
  "9603301": "Gestão e manutenção de cemitérios",
  "9603302": "Serviços de cremação",
  "9603303": "Serviços de sepultamento",
  "9603304": "Serviços de funerárias",
  "9603305": "Serviços de somatoconservação",
  "9603399": "Atividades funerárias e serviços relacionados não especificados anteriormente",
  "9609201": "Clinicas de estética e similares",
  "9609202": "Agências matrimoniais",
  "9609203": "Alojamento, higiene e embelezamento de animais",
  "9609204": "Exploração de máquinas de serviços pessoais acionadas por moeda",
  "9609205": "Atividades de sauna e banhos",
  "9609206": "Serviços de tatuagem e colocação de piercing",
  "9609207": "Alojamento de animais domésticos",
  "9609208": "Higiene e embelezamento de animais domésticos",
  "9609299": "Outras atividades de serviços pessoais não especificadas anteriormente",
  "9700500": "Serviços domésticos",
  "9900800": "Organismos internacionais e outras instituições extraterritoriais"
}
