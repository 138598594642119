import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";

import AbilityChecker from "Organisms/Shared/Authorization/AbilityChecker";
import SuppliersReportRequestButton from "Organisms/UBuyer/Companies/Index/SuppliersReportRequestButton";

function SuppliersLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showButtons = pathname === "/companies";

  return (
    <div key={pathname} className="flex flex-col overflow-auto">
      <div
        className={`flex flex-row items-center justify-between gap-4 border-b px-6 ${showButtons ? "py-4" : "py-6"}`}
      >
        <Heading>Fornecedores</Heading>

        {showButtons ? (
          <div className="flex flex-row items-center gap-4">
            <AbilityChecker ability="addSupplier">
              <Button type="button" onClick={() => navigate("new")}>
                Iniciar homologação
              </Button>
            </AbilityChecker>

            <SuppliersReportRequestButton />
          </div>
        ) : null}
      </div>

      <div className="flex flex-grow flex-col p-4 lg:p-10 lg:pb-20">
        <Outlet />
      </div>
    </div>
  );
}

export default SuppliersLayout;
