import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

import { ArrowLeft } from "lucide-react";

import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Select, SelectValue, SelectGroup, SelectLabel, SelectTrigger, SelectContent, SelectItem } from "DS/ui/select";

function MobileMenu({ groups }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const rootRoute = `react|${pathname.split("/")[3]}`;

  const onValueChange = (pathValue) => {
    const pathValues = pathValue.split("|");
    const turbo = pathValues[0] === "turbo";
    const path = pathValues[1];

    if (turbo) {
      window.location = path;
    } else {
      navigate(path);
    }
  };

  return (
    <div className="lg:hidden">
      <div className="flex flex-col w-full border border-zinc-50 shadow-sm p-2">
        <Button
          type="button"
          onClick={() => navigate("/")}
          variant="ghost"
          className="px-7 justify-start gap-2 p-0 hover:bg-transparent"
        >
          <ArrowLeft className="text-zinc-950" size={20} />
          <Text weight="medium" size="small">
            Configurações
          </Text>
        </Button>
      </div>
      <div className="flex flex-col px-4 py-4 border-b">
        <Select defaultValue={rootRoute} onValueChange={onValueChange}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>

          <SelectContent>
            {groups.map(({ title, items }) => (
              <SelectGroup key={title}>
                <SelectLabel>{title}</SelectLabel>
                {items.map(({ path, label, turbo }) => (
                  <SelectItem key={`form-select-${path}`} value={`${turbo ? "turbo" : "react"}|${path}`}>
                    {label}
                  </SelectItem>
                ))}
              </SelectGroup>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

MobileMenu.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      Icon: PropTypes.elementType,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          path: PropTypes.string,
          turbo: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
};

export default MobileMenu;
