import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { Heading } from "DS/custom/heading";
import Skeleton from "DS/custom/skeleton";

import { COMMENT, STATE_CHANGED, RATING_CHANGED } from "Config/SupplierEvent";

import Timeline from "Organisms/UBuyer/Companies/History/Timeline";
import Paginator from "Organisms/UBuyer/Companies/History/Paginator";
import CreateComment from "Organisms/UBuyer/Companies/History/CreateComment";
import ComboboxFilter from "Organisms/UBuyer/Companies/Index/SuppliersSearch/ComboboxFilter";
import DatePickerRangeFilter from "Organisms/UBuyer/Companies/History/DatePickerRangeFilter";

/** @internal */
export const QUERY = gql`
  query SupplierEvents(
    $id: ID!
    $last: Int
    $first: Int
    $after: String
    $before: String
    $recordTypes: [String!]
    $rangeTo: ISO8601DateTime
    $rangeFrom: ISO8601DateTime
  ) {
    supplierEventsConnection(
      last: $last
      first: $first
      after: $after
      before: $before
      supplierId: $id
      rangeTo: $rangeTo
      rangeFrom: $rangeFrom
      recordTypes: $recordTypes
    ) {
      total
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          state
          comment
          createdAt
          pdf {
            id
            url
            filename
          }
        }
      }
    }
  }
`;

const ITEMS_PER_PAGE = 9;

const SKELETON = (
  <div className="flex max-w-4xl flex-col gap-4">
    <Skeleton className="h-8 w-full max-w-4xl" repeat={ITEMS_PER_PAGE} />
    <Skeleton className="h-8 w-96 self-end" />
  </div>
);

const FILTERS = { recordTypes: [], createdAtRange: {} };

const RECORD_TYPES = [
  { value: STATE_CHANGED, label: "Alteração de etapa ou status" },
  { value: RATING_CHANGED, label: "Alteração de risco" },
  { value: COMMENT, label: "Comentários" },
];

function SupplierEvents() {
  const { id } = useParams();
  const [filters, setFilters] = useState(FILTERS);
  const { recordTypes = [], createdAtRange = {} } = filters;
  const { from = null, to = null } = createdAtRange;

  const { loading, data, refetch } = useQuery(QUERY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
      recordTypes,
      rangeTo: to,
      rangeFrom: from,
      first: ITEMS_PER_PAGE,
    },
  });

  const { edges, pageInfo, total } = data?.supplierEventsConnection || {};

  const renderTimeline = () => (
    <div className="flex flex-col gap-4">
      <Timeline edges={edges} />

      <Paginator total={total} refetch={refetch} pageInfo={pageInfo} itemsPerPage={ITEMS_PER_PAGE} />
    </div>
  );

  return (
    <div className="flex max-w-4xl flex-col gap-4">
      <CreateComment refetch={refetch} />

      <div className="flex flex-row flex-wrap items-center justify-between border-t pt-4">
        <Heading>Registros</Heading>

        <div className="flex flex-row items-center gap-4">
          <ComboboxFilter
            title="Evento"
            options={RECORD_TYPES}
            value={filters.recordTypes}
            onChange={(value) => setFilters({ ...filters, recordTypes: value })}
          />

          <DatePickerRangeFilter filters={filters} setFilters={setFilters} />
        </div>
      </div>

      {loading ? SKELETON : renderTimeline()}
    </div>
  );
}

export default SupplierEvents;
