import React from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { REQUIRED_STRING, REQUIRED_MESSAGE, REQUIRED_NUMBER } from "Config/Zod";

import { Button } from "DS/ui/button";
import { Form } from "DS/ui/form";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import PerformanceFormFormBase from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormFormBase";
import PerformanceFormFormQuestionnaires from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormFormQuestionnaires";
import PerformanceFormDelete from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormDelete";

export const addOrderToQuestions = (questionnaires) =>
  questionnaires.map((questionnaire) => ({
    ...questionnaire,
    settingPerformanceQuestionsAttributes: questionnaire.settingPerformanceQuestionsAttributes.map(
      (question, order) => ({ ...question, order }),
    ),
  }));

const zodSchema = () => {
  const id = z.string().optional();
  const displayName = REQUIRED_STRING;
  const _destroy = z.boolean().default(false);

  const QUESTION = z.object({ id, _destroy, displayName });

  const QUESTIONNAIRE = z.object({
    id,
    _destroy,
    displayName,
    weight: REQUIRED_NUMBER,
    settingPerformanceQuestionsAttributes: z.array(QUESTION),
  });

  return z.object({
    id,
    displayName,
    commentRequired: z.boolean({ required_error: REQUIRED_MESSAGE }),
    settingPerformanceQuestionnairesAttributes: z.array(QUESTIONNAIRE),
  });
};

function PerformanceFormForm({ defaultValues, onSubmit, loading, breadcrumb }) {
  const navigate = useNavigate();
  const { settingPerformanceFormId } = useParams();
  const { firstPage, lastPage, currentPage } = breadcrumb;
  const form = useForm({ resolver: zodResolver(zodSchema()), defaultValues });

  return (
    <Form {...form}>
      <form
        id="PerformanceFormForm"
        className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="flex flex-row flex-wrap items-start justify-between">
          <Breadcrumb firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} />

          <div className="flex flex-row gap-4">
            <Button type="submit" form="PerformanceFormForm" disabled={loading}>
              {settingPerformanceFormId ? "Salvar" : "Criar"}
            </Button>

            <Button type="button" variant="outline" onClick={() => navigate("./..")}>
              Cancelar
            </Button>

            {settingPerformanceFormId ? <PerformanceFormDelete /> : null}
          </div>
        </div>

        <PerformanceFormFormBase form={form} />
        <PerformanceFormFormQuestionnaires form={form} />
      </form>
    </Form>
  );
}

PerformanceFormForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  breadcrumb: PropTypes.shape({
    firstPage: PropTypes.string,
    lastPage: PropTypes.string,
    currentPage: PropTypes.string,
  }).isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
};

export default PerformanceFormForm;
