import React from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { useToast } from "DS/ui/use-toast";

import FormEditForm from "Organisms/UBuyer/Settings/BuyerDocuments/FormEditForm";

/** @internal */
export const QUERY = gql`
  query FormEdit($buyerId: ID!, $settingBuyerDocumentId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingBuyerDocument(id: $settingBuyerDocumentId) {
        id
        displayName
        hint
        risk
        options
        fieldType
        name
        updatedAt
      }
    }
  }
`;

/** @internal */
export const MUTATION = gql`
  mutation FormEdit(
    $hint: String!
    $displayName: String!
    $risk: Int!
    $options: JSON
    $fieldType: String!
    $name: String
    $settingBuyerDocumentId: ID!
  ) {
    updateSettingBuyerDocument(
      input: {
        displayName: $displayName
        hint: $hint
        risk: $risk
        options: $options
        fieldType: $fieldType
        name: $name
        settingBuyerDocumentId: $settingBuyerDocumentId
      }
    ) {
      settingBuyerDocument {
        id
        buyer {
          id
          settingBuyerDocuments {
            id
            displayName
            hint
            risk
            options
            fieldType
            name
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

function FormEdit() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { settingBuyerDocumentId, buyerId } = useParams();

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId, settingBuyerDocumentId },
  });

  const [updateSettingBuyerDocument, { loading: submitting }] = useMutation(MUTATION);

  if (loading) return <GlobalLoading />;

  const { settingBuyerDocument } = data.buyer;

  const onSubmit = async (values) => {
    const variables = { ...values, settingBuyerDocumentId };
    await updateSettingBuyerDocument({ variables });

    toast({ title: "Formulário editado com sucesso!" });
    navigate("./..");
  };

  if (!settingBuyerDocument) return <Navigate to="../buyer_documents" />;

  return <FormEditForm onSubmit={onSubmit} submitting={submitting} formInitialValues={settingBuyerDocument} />;
}

export default FormEdit;
