import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { useToast } from "DS/ui/use-toast";

import PerformanceFormForm, {
  addOrderToQuestions,
} from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormForm";

const MUTATION = gql`
  mutation PerformanceFormNew(
    $buyerId: ID!
    $displayName: String!
    $commentRequired: Boolean!
    $settingPerformanceQuestionnairesAttributes: [SettingPerformanceQuestionnaireInput!]!
  ) {
    createSettingPerformanceForm(
      input: {
        buyerId: $buyerId
        displayName: $displayName
        commentRequired: $commentRequired
        settingPerformanceQuestionnairesAttributes: $settingPerformanceQuestionnairesAttributes
      }
    ) {
      settingPerformanceForm {
        id
        buyer {
          id
          settingPerformanceForms {
            id
          }
        }
      }
    }
  }
`;

/** @internal */
export const DEFAULT_VALUES = {
  displayName: "",
  commentRequired: false,
  settingPerformanceQuestionnairesAttributes: [
    {
      weight: 1,
      displayName: "",
      settingPerformanceQuestionsAttributes: [{ displayName: "" }],
    },
  ],
};

function PerformanceFormNew() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [createSettingPerformanceForm, { loading }] = useMutation(MUTATION);

  const onSubmit = async ({ displayName, commentRequired, settingPerformanceQuestionnairesAttributes }) => {
    const orderedQuestionnaires = addOrderToQuestions([...settingPerformanceQuestionnairesAttributes]);

    const { data } = await createSettingPerformanceForm({
      variables: {
        buyerId,
        displayName,
        commentRequired,
        settingPerformanceQuestionnairesAttributes: orderedQuestionnaires,
      },
    });

    const { settingPerformanceForm } = data.createSettingPerformanceForm;

    toast({ title: "Formulário de performance criado com sucesso!" });
    navigate(`./../${settingPerformanceForm.id}`);
  };

  return (
    <PerformanceFormForm
      loading={loading}
      onSubmit={onSubmit}
      defaultValues={DEFAULT_VALUES}
      breadcrumb={{
        lastPage: "Performance",
        currentPage: "Novo formulário de performance",
      }}
    />
  );
}

export default PerformanceFormNew;
