import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { REQUIRED_STRING, OPTIONAL_STRING } from "Config/Zod";

import { Form, FormItem, FormField, FormMessage, FormControl } from "DS/ui/form";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { Textarea } from "DS/ui/textarea";
import { useToast } from "DS/ui/use-toast";

import attachFile from "Services/Uploader";

import CustomFileInput from "Organisms/UBuyer/Companies/History/CustomFileInput";

/** @internal */
export const MUTATION = gql`
  mutation CreateComment($id: ID!, $comment: String!, $pdf: String) {
    createSupplierEvent(input: { supplierId: $id, comment: $comment, pdf: $pdf }) {
      errors {
        path
        message
      }
    }
  }
`;

const ZOD_SCHEMA = z.object({
  comment: REQUIRED_STRING,
  pdf: OPTIONAL_STRING.or(z.instanceof(FileList)),
});

function CreateComment({ refetch }) {
  const { id } = useParams();
  const { toast } = useToast();
  const [sending, setSending] = useState(false);
  const [createSupplierEvent, { loading }] = useMutation(MUTATION);

  const defaultValues = { comment: "", pdf: "" };
  const form = useForm({ resolver: zodResolver(ZOD_SCHEMA), defaultValues });

  const onSubmit = async (values) => {
    setSending(true);
    const pdf = await attachFile(values.pdf);
    setSending(false);

    const variables = { id, pdf, comment: values.comment };
    const { data } = await createSupplierEvent({ variables });
    const { errors } = data.createSupplierEvent;

    if (errors.length) {
      const title = "Erro ao criar comentário!";
      toast({ title, description: errors[0].message, variant: "destructive" });
    } else {
      toast({ title: "Comentário criado com sucesso!" });
      form.reset();
      refetch();
    }
  };

  return (
    <Form {...form}>
      <form id="CreateComment" onSubmit={form.handleSubmit(onSubmit)}>
        <Text size="small" weight="medium">
          Comentário
        </Text>

        <div className="mt-1 flex flex-col gap-1 rounded-lg border p-2">
          <FormField
            name="comment"
            control={form.control}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Textarea
                    {...field}
                    id="comment"
                    placeholder="Escreva um comentário..."
                    className="min-h-[50px] resize-none border-none p-1 focus-visible:ring-0"
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-row flex-wrap items-center justify-between gap-4">
            <FormField
              name="pdf"
              control={form.control}
              render={({ field }) => (
                <CustomFileInput {...field} name="pdf" pdf={form.watch("pdf")} disabled={loading || sending} />
              )}
            />

            <Button type="submit" variant="outline" form="CreateComment" disabled={loading || sending}>
              Inserir comentário
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}

CreateComment.propTypes = { refetch: PropTypes.func.isRequired };

export default CreateComment;
