import React, { useState } from "react";
import PropTypes from "prop-types";

import { Anchor } from "DS/custom/anchor";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";

import { CSVBoxButton } from "@csvbox/react";

import { RESTRICTION_LIST_COMPANIES_TEMPLATE, RESTRICTION_LIST_COMPANIES_LICENSE_KEY } from "Config/Csvbox";

import DeleteRestrictionListCompanies from "Organisms/UBuyer/Settings/Advanced/DeleteRestrictionListCompanies";

function RestrictionListCompanies({ buyer, disabled }) {
  const [csvboxResponse, setCsvboxResponse] = useState({});

  const onImport = (success, result) => {
    if (result.custom_fields.component !== "RestrictionListCompanies") return;

    const message = success
      ? "O upload foi feito com sucesso! Você já pode salvar a configuração."
      : "Houve um erro ao processar seu arquivo. Por favor, atualize a página e tente novamente";

    setCsvboxResponse({ message, success });
  };

  return (
    <div className="space-y-2">
      <div className="space-y-1">
        <Text size="small" weight="medium">
          Lista de restrições - Pessoas jurídicas
        </Text>

        <Text size="small" className="max-w-lg" color="secondary">
          Suba a base de até 50.000 <b>pessoas juridicas</b> relacionadas a sua empresa para cruzar com informações da
          sua base de fornecedores e partes relacionadas.
        </Text>

        <Anchor target="_blank" href={RESTRICTION_LIST_COMPANIES_TEMPLATE}>
          Download do template para preenchimento
        </Anchor>
      </div>

      {buyer.restrictionListCompanyCreatedAt && !disabled ? (
        <DeleteRestrictionListCompanies buyer={buyer} />
      ) : (
        <CSVBoxButton
          id="COMPANIES"
          key="COMPANIES"
          onImport={onImport}
          licenseKey={RESTRICTION_LIST_COMPANIES_LICENSE_KEY}
          user={{
            buyerId: buyer.id,
            component: "RestrictionListCompanies",
          }}
          render={(launch, loading) => (
            <Button
              type="button"
              onClick={(event) => {
                event.preventDefault();
                launch();
              }}
              disabled={disabled || csvboxResponse.success || loading}
            >
              Fazer upload
            </Button>
          )}
        />
      )}

      <Text size="small" weight="medium">
        {csvboxResponse.message}
      </Text>
    </div>
  );
}

RestrictionListCompanies.propTypes = {
  disabled: PropTypes.bool.isRequired,
  buyer: PropTypes.shape({
    id: PropTypes.string,
    restrictionListCompanyCreatedAt: PropTypes.string,
  }).isRequired,
};

export default RestrictionListCompanies;
