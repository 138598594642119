import React from "react";
import { useParams } from "react-router-dom";

import { Bell, BellOff } from "lucide-react";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { Button } from "DS/ui/button";
import Skeleton from "DS/custom/skeleton";

/** @internal */
export const QUERY = gql`
  query SimpleMenu($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      following
    }
  }
`;

/** @internal */
export const MUTATION = gql`
  mutation notificationToggleIcon($id: ID!, $follow: Boolean!) {
    followSupplier(input: { id: $id, follow: $follow }) {
      supplier {
        id
        following
      }
    }
  }
`;

function ToggleNotification() {
  const { id } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { id } });
  const [supplierFollow, { loading: submitting }] = useMutation(MUTATION);

  if (loading) return <Skeleton className="h-10 w-10" />;

  const { following } = data.supplier;

  const onClick = () => supplierFollow({ variables: { id, follow: !following } });

  if (following)
    return (
      <Button type="button" variant="ghost" onClick={onClick} disabled={submitting}>
        <BellOff className="mr-2 h-4 w-4" /> Desativar notificações
      </Button>
    );

  return (
    <Button type="button" variant="ghost" disabled={submitting} onClick={onClick}>
      <Bell className="mr-2 h-4 w-4" /> Ativar notificações
    </Button>
  );
}

export default ToggleNotification;
