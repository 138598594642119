import React from "react";
import { Route, Routes } from "react-router-dom";

import FormsList from "Organisms/UBuyer/Settings/BuyerDocuments/FormsList";
import FormNew from "Organisms/UBuyer/Settings/BuyerDocuments/FormNew";
import FormEdit from "Organisms/UBuyer/Settings/BuyerDocuments/FormEdit";
import FormInfo from "Organisms/UBuyer/Settings/BuyerDocuments/FormInfo";

function BuyerDocuments() {
  return (
    <Routes>
      <Route path="" element={<FormsList />} />
      <Route path="new" element={<FormNew />} />
      <Route path=":settingBuyerDocumentId" element={<FormInfo />} />
      <Route path=":settingBuyerDocumentId/edit" element={<FormEdit />} />
    </Routes>
  );
}

export default BuyerDocuments;
