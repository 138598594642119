import React, { useState } from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";

import { Send } from "lucide-react";

import { DropdownMenuItem } from "DS/ui/dropdown-menu";

import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import Skeleton from "DS/custom/skeleton";
import { useToast } from "DS/ui/use-toast";
import { Heading } from "DS/custom/heading";

import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
} from "DS/ui/alert-dialog";

/** @internal */
export const MUTATION = gql`
  mutation NewRequestedFormDialog($settingFormId: ID!) {
    refreshSettingForm(input: { settingFormId: $settingFormId }) {
      errors {
        path
        message
      }
    }
  }
`;

/** @internal */
export const QUERY = gql`
  query NewRequestedFormDialog($buyerId: ID!, $settingFormId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingForm(id: $settingFormId) {
        id
        notifySuppliersCount
      }
    }
  }
`;

function NewRequestedFormDialog() {
  const { toast } = useToast();
  const { buyerId, settingFormId } = useParams();
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const [refreshSettingForm, { loading: submitting }] = useMutation(MUTATION);

  const { loading, data: queryData } = useQuery(QUERY, {
    variables: { buyerId, settingFormId },
  });

  if (loading) return <Skeleton className="h-9 w-full" />;

  const { notifySuppliersCount } = queryData.buyer.settingForm;

  const onClick = async () => {
    const { data } = await refreshSettingForm({
      variables: { settingFormId },
    });
    const { errors } = data.refreshSettingForm;

    if (errors.length) {
      setShowAlertDialog(false);

      toast({
        title: "Não foi possível solicitar o reenvio do formulário!",
        description: errors[0].message,
        variant: "destructive",
      });
    } else {
      setShowAlertDialog(false);
      toast({ title: "Solicitação de reenvio deste formulário aprovada!" });
    }
  };

  return (
    <AlertDialog open={showAlertDialog}>
      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
        <AlertDialogTrigger
          onClick={() => setShowAlertDialog(true)}
          disabled={submitting}
          className="flex flex-row items-center"
        >
          <Text color="secondary">
            <Send strokeWidth={3} className="mr-2 h-4 w-4" />
          </Text>

          <Text weight="medium">Solicitar reenvio</Text>
        </AlertDialogTrigger>
      </DropdownMenuItem>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle asChild>
            <Heading>Tem certeza que deseja solicitar este reenvio?</Heading>
          </AlertDialogTitle>
        </AlertDialogHeader>

        <Text size="small" weight="medium">
          Seguindo com este processo, vamos solicitar o reenvio da nova versão deste formulário para todos os
          fornecedores aprovados e ativos na sua base. Deseja continuar?
        </Text>

        <Text size="small" weight="medium">
          Um total de {notifySuppliersCount} fornecedores serão impactados nesta solicitação.
        </Text>

        <AlertDialogFooter>
          <Button type="button" onClick={onClick} disabled={submitting} aria-label="Solicitar reenvio">
            Solicitar reenvio
          </Button>

          <Button type="button" variant="outline" disabled={submitting} onClick={() => setShowAlertDialog(false)}>
            Cancelar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default NewRequestedFormDialog;
