import { useCubeQuery } from "@cubejs-client/react";
import { useOutletContext } from "react-router-dom";

function useCubeEsgRatingInfo() {
  const { identifier } = useOutletContext();

  const { resultSet } = useCubeQuery({
    dimensions: [
      "insights_esg.social_pillar",
      "insights_esg.social_item_dei",
      "insights_esg.governance_pillar",
      "insights_esg.environmental_pillar",
      "insights_esg.social_item_child_labor",
      "insights_esg.social_item_work_safety",
      "insights_esg.social_item_forced_labor",
      "insights_esg.governance_item_corruption",
      "insights_esg.social_item_non_discrimination",
      "insights_esg.environmental_item_noncompliance",
    ],
    filters: [{ member: "insights_esg.cnpj", operator: "equals", values: [identifier] }],
  });

  if (!resultSet) return { loading: true };

  const [esgRating] = resultSet.rawData();

  return { loading: false, esgRating };
}

export default useCubeEsgRatingInfo;
