import React from "react";
import PropTypes from "prop-types";

import { Building } from "lucide-react";

import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";

import AbilityChecker from "Organisms/Shared/Authorization/AbilityChecker";
import DestroySupplierAlertDialog from "Organisms/UBuyer/SupplierHeader/DestroySupplier/DestroySupplierAlertDialog";
import CategoriesListAnchor from "Organisms/UBuyer/Shared/SupplierSimpleHeader/CategoriesListAnchor";

function PendingPaymentSupplierInfo({ supplier }) {
  return (
    <div className="flex flex-row flex-wrap items-center justify-between">
      <div className="flex flex-row flex-wrap items-center gap-3">
        <div className="flex flex-col gap-2">
          <CategoriesListAnchor />

          <div className="flex flex-row gap-2">
            <Heading level={3}>{supplier.name.toUpperCase()}</Heading>
          </div>

          <div className="flex flex-row flex-wrap items-center divide-x-2">
            <div className="flex flex-row items-center gap-3">
              <Building size={20} className="text-slate-700" />
              <Text className="pr-3" color="secondary">
                {supplier.identifierMasked} | Pagamento pendente
              </Text>
            </div>
          </div>
        </div>
      </div>

      <AbilityChecker ability="destroySupplier">
        <DestroySupplierAlertDialog />
      </AbilityChecker>
    </div>
  );
}

PendingPaymentSupplierInfo.propTypes = {
  supplier: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    identifierMasked: PropTypes.string.isRequired,
  }).isRequired,
};

export default PendingPaymentSupplierInfo;
