import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";

import z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { REQUIRED_EMAIL_WITH_REGEX, REQUIRED_PASSWORD_WITH_REGEX } from "Config/Zod";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";

import { Form, FormItem, FormField, FormLabel, FormMessage, FormDescription, FormControl } from "DS/ui/form";

import signUp from "Services/Authentication/Signup";

const zodSchema = () =>
  z.object({
    email: REQUIRED_EMAIL_WITH_REGEX,
    password: REQUIRED_PASSWORD_WITH_REGEX,
  });

function SupplierSignupForm({ email }) {
  const { toast } = useToast();
  const { token } = useParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const defaultValues = { email, password: "" };
  const form = useForm({ resolver: zodResolver(zodSchema()), defaultValues });

  const onSubmit = (values) => {
    setSubmitting(true);

    signUp(values, token).catch((error) => {
      const title = "Erro no cadastro!";
      let description = "Por favor, entre em contato com o nosso suporte.";

      if (error.message === "E-mail já está em uso") description = "E-mail já está em uso. Por favor, faça o login.";

      toast({ title, description, variant: "destructive" });
      navigate("/");
    });
  };

  return (
    <Form {...form}>
      <form id="SupplierSignupForm" className="my-4 flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-full">
              <div className="flex flex-col gap-1">
                <FormLabel htmlFor="email">Seu e-mail</FormLabel>

                <FormControl>
                  <Input {...field} id="email" placeholder="Digite seu email..." />
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="password"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-full">
              <div className="flex flex-col gap-1">
                <FormLabel htmlFor="password">Escolha uma senha</FormLabel>

                <FormControl>
                  <Input {...field} id="password" type="password" placeholder="Digite sua nova senha..." />
                </FormControl>

                <FormDescription>
                  Certifique-se de que a sua senha tenha pelo menos 8 caracteres; incluindo um número, uma letra
                  maiúscula, uma letra minúscula e um caractere especial.
                </FormDescription>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <Button type="submit" form="SupplierSignupForm" disabled={submitting}>
          Criar minha conta
        </Button>
      </form>
    </Form>
  );
}

SupplierSignupForm.propTypes = { email: PropTypes.string.isRequired };

export default SupplierSignupForm;
