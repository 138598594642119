import React from "react";
import PropTypes from "prop-types";

import { Checkbox } from "DS/ui/checkbox";
import { Label } from "DS/ui/label";
import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";
import { Card, CardHeader, CardTitle, CardContent } from "DS/custom/card";

import DocumentRiskSelect from "Organisms/UBuyer/Settings/Categories/DocumentRiskSelect";

const DocumentsCheckboxes = React.forwardRef(({ settingGroupers, value, onChange, name }, ref) => {
  const onSelectRisk = (risk, settingDocument) => {
    const parsedValue = { ...value };

    if (!parsedValue[settingDocument.id]) return;

    parsedValue[settingDocument.id] = risk;

    onChange({ target: { name, value: parsedValue } });
  };

  const onCheck = (checked, settingDocument) => {
    const parsedValue = { ...value };

    if (checked) parsedValue[settingDocument.id] = "0";
    else delete parsedValue[settingDocument.id];

    onChange({ target: { name, value: parsedValue } });
  };

  return (
    <>
      <input name={name} type="hidden" ref={ref} value={value} />

      <div className="flex flex-col gap-3 divide-slate-100">
        {settingGroupers.map((settingGrouper) => {
          if (settingGrouper.settingDocuments.length === 0) return null;

          return (
            <Card key={settingGrouper.id} value={settingGrouper.id}>
              <CardHeader>
                <CardTitle>{settingGrouper.displayName}</CardTitle>
              </CardHeader>

              <CardContent>
                <div className="flex flex-col gap-2.5 divide-y">
                  {settingGrouper.settingDocuments.map((settingDocument) => (
                    <div
                      key={settingDocument.id}
                      className="flex w-full flex-col gap-1.5 pt-2.5 text-sm font-medium first:pt-0 md:flex-row md:items-center"
                    >
                      <div className="flex flex-grow items-start gap-1.5 md:flex-row md:items-center">
                        <Checkbox
                          id={settingDocument.id}
                          checked={!!value[settingDocument.id]}
                          onCheckedChange={(checked) => onCheck(checked, settingDocument)}
                        />

                        <div className="flex flex-row flex-wrap items-start gap-1.5 text-black md:items-center md:justify-between">
                          <Label htmlFor={settingDocument.id}>
                            <Text size="small" weight="medium">
                              {settingDocument.displayName}
                            </Text>
                          </Label>

                          {settingDocument.type === "Settings::SettingForm" ? (
                            <Badge variant="secondary" className="w-fit align-top">
                              Enviado pelo fornecedor
                            </Badge>
                          ) : null}
                        </div>
                      </div>

                      <DocumentRiskSelect
                        onSelectRisk={onSelectRisk}
                        risk={value[settingDocument.id]}
                        settingDocument={settingDocument}
                      />
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </>
  );
});

DocumentsCheckboxes.defaultProps = { value: {} };

DocumentsCheckboxes.propTypes = {
  value: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  settingGroupers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      settingDocuments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          settingDocumentId: PropTypes.string,
          risk: PropTypes.number,
          displayName: PropTypes.string,
          availableForInternational: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
};

export default DocumentsCheckboxes;
