const highlightWords = (text, term) => {
  let highlight = text;

  term
    .trim()
    .split(" ")
    .forEach((word) => {
      highlight = highlight.replaceAll(word, `<mark>${word}</mark>`);
    });

  return highlight;
};

export default highlightWords;
