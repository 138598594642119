import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";
import Skeleton from "DS/custom/skeleton";

import { Avatar, AvatarImage, AvatarFallback } from "DS/ui/avatar";

import EditPermissionForm from "Organisms/UBuyer/Settings/Users/EditPermissionForm";
import UserSettingGroupersBadges from "Organisms/UBuyer/Settings/Users/UserSettingGroupersBadges";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import { firstInitialsFromName } from "Services/MaskUtil";

/** @internal */
export const QUERY = gql`
  query UserInfo($buyerId: ID!, $userId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      user(id: $userId) {
        id
        name
        email
        owner
        photoUrl
        iam {
          addSupplier
          reviewDoc
          reviewSupplier
          addBuyerDocument
          destroySupplier
          addPerformanceForm
          startWorkflow
          purchaseSupplierProfile
        }
        settingGroupers {
          id
          displayName
        }
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6">
    <Skeleton className="h-6 w-80" />
    <div className="flex flex-row flex-wrap justify-between gap-x-8 gap-y-4">
      <Skeleton className="h-[72px] w-[543px]" />
      <Skeleton className="h-10 w-60" />
    </div>
    <Skeleton className="h-6 w-96" repeat={6} />
  </div>
);

function UserInfo() {
  const { buyerId, userId } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { buyerId, userId } });

  if (loading) return SKELETON;

  const { name, email, photoUrl, owner, settingGroupers, iam } = data.buyer.user;

  return (
    <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
      <Breadcrumb lastPage="Usuários" currentPage={name} />

      <div className="flex flex-row items-center gap-3">
        <Avatar>
          <AvatarImage src={photoUrl} />
          <AvatarFallback>{firstInitialsFromName(name)}</AvatarFallback>
        </Avatar>

        <div className="flex flex-col gap-1">
          <Heading>{name}</Heading>

          <div className="flex flex-row items-center gap-2">
            <Text size="small" color="secondary">
              {email}
            </Text>

            <div className="flex flex-row items-center gap-2 border-l pl-2">
              <Text size="small" color="secondary">
                Grupos de revisão:
              </Text>

              <UserSettingGroupersBadges settingGroupers={settingGroupers} />
            </div>
          </div>
        </div>
      </div>

      <EditPermissionForm owner={owner} permissions={iam} />
    </div>
  );
}

export default UserInfo;
