import React from "react";
import PropTypes from "prop-types";
import { useLocation, Link } from "react-router-dom";

import { Info } from "lucide-react";

import { Text } from "DS/custom/text";
import { Card, CardContent } from "DS/custom/card";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";
import { Tabs, TabsList, TabsTrigger } from "DS/ui/tabs";

import formatDate from "Services/DateToString";

function LayoutTabs({ routes, rfUpdatedAt }) {
  const { pathname } = useLocation();
  const rootRoute = pathname.split("/")[4];

  return (
    <div key={pathname} className="mb-8 hidden flex-row items-center justify-between gap-4 lg:flex">
      <Tabs defaultValue={rootRoute}>
        <TabsList>
          {routes.map(({ key, value }) => (
            <Link to={key} key={`puf-tabs-${key}`}>
              <TabsTrigger value={key}>{value}</TabsTrigger>
            </Link>
          ))}
        </TabsList>
      </Tabs>

      <Card className="p-4">
        <CardContent className="flex flex-row items-center gap-2">
          <Text size="small" color="secondary">
            Última atualização na Receita Federal <b>{formatDate(rfUpdatedAt)}</b>
          </Text>

          <WrappedTooltip message="Qualquer atualização dos dados de um fornecedor feita após este período só será refletida na próxima atualização da base mensal que a Receita Federal disponibiliza">
            <Info size={12} className="text-blue-600" strokeWidth={2.5} />
          </WrappedTooltip>
        </CardContent>
      </Card>
    </div>
  );
}

LayoutTabs.propTypes = {
  rfUpdatedAt: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })).isRequired,
};

export default LayoutTabs;
