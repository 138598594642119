import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Layout from "Organisms/UBuyer/Companies/PUF/Layout";
import InfoCNPJ from "Organisms/UBuyer/Companies/PUF/InfoCNPJ";
import Analysis from "Organisms/UBuyer/Companies/PUF/Analysis";

import AllCNAEs from "Pages/Suppliers/PUF/InfoCNPJ/AllCNAEs";
import AllBranches from "Pages/Suppliers/PUF/InfoCNPJ/AllBranches";
import BranchLocation from "Pages/Suppliers/PUF/InfoCNPJ/BranchLocation";
import AllShareholders from "Pages/Suppliers/PUF/InfoCNPJ/AllShareholders";

import SocialDei from "Pages/Suppliers/PUF/Analysis/ESG/SocialDei";
import SocialDeiDetails from "Pages/Suppliers/PUF/Analysis/ESG/SocialDeiDetails";
import SocialChildLabor from "Pages/Suppliers/PUF/Analysis/ESG/SocialChildLabor";
import SocialWorkSafety from "Pages/Suppliers/PUF/Analysis/ESG/SocialWorkSafety";
import SocialForcedLabor from "Pages/Suppliers/PUF/Analysis/ESG/SocialForcedLabor";
import SocialChildLaborDetails from "Pages/Suppliers/PUF/Analysis/ESG/SocialChildLaborDetails";
import SocialNonDiscrimination from "Pages/Suppliers/PUF/Analysis/ESG/SocialNonDiscrimination";
import SocialWorkSafetyDetails from "Pages/Suppliers/PUF/Analysis/ESG/SocialWorkSafetyDetails";
import SocialForcedLaborDetails from "Pages/Suppliers/PUF/Analysis/ESG/SocialForcedLaborDetails";
import SocialNonDiscriminationDetails from "Pages/Suppliers/PUF/Analysis/ESG/SocialNonDiscriminationDetails";

import GovAlDetails from "Pages/Suppliers/PUF/Analysis/ESG/GovAlDetails";
import GovCorruption from "Pages/Suppliers/PUF/Analysis/ESG/GovCorruption";
import GovCeisDetails from "Pages/Suppliers/PUF/Analysis/ESG/GovCeisDetails";
import GovCeafDetails from "Pages/Suppliers/PUF/Analysis/ESG/GovCeafDetails";
import GovCnepDetails from "Pages/Suppliers/PUF/Analysis/ESG/GovCnepDetails";
import GovCepimDetails from "Pages/Suppliers/PUF/Analysis/ESG/GovCepimDetails";
import GovInabilitadosDetails from "Pages/Suppliers/PUF/Analysis/ESG/GovInabilitadosDetails";

import EnvCompliance from "Pages/Suppliers/PUF/Analysis/ESG/EnvCompliance";
import EnvComplianceDetails from "Pages/Suppliers/PUF/Analysis/ESG/EnvComplianceDetails";

import FiscalBankrupt from "Pages/Suppliers/PUF/Analysis/Financial/FiscalBankrupt";
import FiscalActingTime from "Pages/Suppliers/PUF/Analysis/Financial/FiscalActingTime";
import FiscalCompanyScale from "Pages/Suppliers/PUF/Analysis/Financial/FiscalCompanyScale";
import FiscalBankruptDetails from "Pages/Suppliers/PUF/Analysis/Financial/FiscalBankruptDetails";
import FiscalActingTimeDetails from "Pages/Suppliers/PUF/Analysis/Financial/FiscalActingTimeDetails";
import FiscalCompanyScaleDetails from "Pages/Suppliers/PUF/Analysis/Financial/FiscalCompanyScaleDetails";

import LaborStaff from "Pages/Suppliers/PUF/Analysis/Financial/LaborStaff";
import LaborMinShare from "Pages/Suppliers/PUF/Analysis/Financial/LaborMinShare";
import LaborStaffDetails from "Pages/Suppliers/PUF/Analysis/Financial/LaborStaffDetails";
import LaborMinShareDetails from "Pages/Suppliers/PUF/Analysis/Financial/LaborMinShareDetails";

function PUF() {
  return (
    <Routes>
      <Route path="" element={<Layout />}>
        <Route path="" element={<Navigate to="info-cnpj" />} />

        <Route path="info-cnpj/*">
          <Route path="" element={<InfoCNPJ />} />
          <Route path="cnaes" element={<AllCNAEs />} />
          <Route path="branches" element={<AllBranches />} />
          <Route path="shareholders" element={<AllShareholders />} />
          <Route path="location" element={<BranchLocation />} />
        </Route>

        <Route path="analysis/*">
          <Route path="" element={<Analysis />} />

          <Route path="gov-corruption">
            <Route path="" element={<GovCorruption />} />
            <Route path="al" element={<GovAlDetails />} />
            <Route path="ceis" element={<GovCeisDetails />} />
            <Route path="ceaf" element={<GovCeafDetails />} />
            <Route path="cnep" element={<GovCnepDetails />} />
            <Route path="cepim" element={<GovCepimDetails />} />
            <Route path="inabilitados" element={<GovInabilitadosDetails />} />
          </Route>

          <Route path="env-compliance">
            <Route path="" element={<EnvCompliance />} />
            <Route path="ibama" element={<EnvComplianceDetails />} />
          </Route>

          <Route path="social-forced-labor">
            <Route path="" element={<SocialForcedLabor />} />
            <Route path="forced-labor" element={<SocialForcedLaborDetails />} />
          </Route>

          <Route path="social-child-labor">
            <Route path="" element={<SocialChildLabor />} />
            <Route path="child-labor" element={<SocialChildLaborDetails />} />
          </Route>

          <Route path="social-dei">
            <Route path="" element={<SocialDei />} />
            <Route path="dei" element={<SocialDeiDetails />} />
          </Route>

          <Route path="social-non-discrimination">
            <Route path="" element={<SocialNonDiscrimination />} />

            <Route path="non-discrimination" element={<SocialNonDiscriminationDetails />} />
          </Route>

          <Route path="social-work-safety">
            <Route path="" element={<SocialWorkSafety />} />
            <Route path="work-safety" element={<SocialWorkSafetyDetails />} />
          </Route>

          <Route path="fiscal-bankrupt">
            <Route path="" element={<FiscalBankrupt />} />
            <Route path="bankrupt" element={<FiscalBankruptDetails />} />
          </Route>

          <Route path="fiscal-acting-time">
            <Route path="" element={<FiscalActingTime />} />
            <Route path="acting-time" element={<FiscalActingTimeDetails />} />
          </Route>

          <Route path="fiscal-company-scale">
            <Route path="" element={<FiscalCompanyScale />} />

            <Route path="company-scale" element={<FiscalCompanyScaleDetails />} />
          </Route>

          <Route path="labor-staff">
            <Route path="" element={<LaborStaff />} />
            <Route path="staff" element={<LaborStaffDetails />} />
          </Route>

          <Route path="labor-min-share">
            <Route path="" element={<LaborMinShare />} />
            <Route path="min-share" element={<LaborMinShareDetails />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default PUF;
