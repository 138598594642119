import React from "react";

import { AlignLeft, ChevronDown, DollarSign, Upload } from "lucide-react";

export const SELECT = "select";

const CURRENCY = "currency";

export const FORM_FIELD_TYPE_OPTIONS = [
  {
    icon: <AlignLeft size={16} />,
    label: "Texto curto",
    value: "text_input",
  },
  {
    icon: <ChevronDown size={16} />,
    label: "Lista suspensa",
    value: SELECT,
  },
  {
    icon: <DollarSign size={16} />,
    label: "Moeda",
    value: CURRENCY,
  },
  {
    icon: <Upload size={16} />,
    label: "Upload de documento",
    value: "document",
  },
];
