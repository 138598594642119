import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";

import NoMatch from "Pages/LandingPage/Static/NoMatch";

/** @internal */
export const QUERY = gql`
  query panel($id: ID!) {
    supplier: supplierById(id: $id) {
      id
    }
  }
`;

const SKELETON = (
  <div className="gap-8 lg:flex lg:flex-row">
    <div className="flex flex-col justify-start gap-6">
      <Skeleton className="h-6 w-60" />
      <Skeleton className="h-6 w-full" repeat={6} />
    </div>

    <div className="mt-6 flex flex-col justify-start gap-6 lg:mt-0 lg:w-[384px]">
      <Skeleton className="h-[584px] w-full" />
      <Skeleton className="h-44 w-full" />
    </div>
  </div>
);

function Panel() {
  const { id } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { supplier } = data;

  if (!supplier) return <NoMatch />;

  return window.location.assign(`/buyers/suppliers/${id}/panel`);
}

export default Panel;
