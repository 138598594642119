import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import Skeleton from "DS/custom/skeleton";

import FieldsHeader from "Organisms/UBuyer/Settings/Fields/Shared/FieldsHeader";
import SearchInput from "Organisms/UBuyer/Shared/SearchInput/SearchInput";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import EmptyList from "Assets/settings/shared/EmptyList.png";
import FormsTable from "Organisms/UBuyer/Settings/BuyerDocuments/FormsTable";

/** @internal */
export const QUERY = gql`
  query FormsList($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingBuyerDocumentsPredefined {
        id
        displayName
        risk
        fieldType
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6">
    <Skeleton className="h-6 w-80" />
    <div className="flex flex-row flex-wrap items-center justify-between gap-x-8 gap-y-4">
      <Skeleton className="h-12 w-[543px]" />
      <Skeleton className="h-10 w-80" />
    </div>
    <Skeleton className="h-10 w-full" />
    <Skeleton className="h-6 w-96" repeat={6} />
  </div>
);

function FormsList() {
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [term, setTerm] = useState("");
  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return SKELETON;
  const { settingBuyerDocumentsPredefined } = data.buyer;
  const filteredSettingBuyerDocuments = settingBuyerDocumentsPredefined.filter(({ displayName }) =>
    displayName.toLowerCase().includes(term.toLowerCase()),
  );

  return (
    <div>
      <FieldsHeader />

      <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-10">
        <div className="flex flex-col lg:flex-row flex-nowrap justify-between gap-x-8 gap-y-4">
          <div className="flex-1">
            <Text size="small">Campos preenchidos pelo comprador na homologação.</Text>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col lg:flex-row flex-nowrap justify-between gap-x-8 gap-y-4">
            <div className="flex-1">
              <SearchInput
                value={term}
                placeholder="Encontre um documento..."
                onChange={(value) => {
                  setTerm(_.deburr(value));
                }}
              />
            </div>
            <OwnerChecker className="flex-1">
              <div className="flex flex-row flex-wrap gap-4">
                <Button type="button" onClick={() => navigate("new")}>
                  Criar documento
                </Button>
              </div>
            </OwnerChecker>
          </div>

          {filteredSettingBuyerDocuments.length ? (
            <FormsTable forms={filteredSettingBuyerDocuments} />
          ) : (
            <div className="flex flex-col items-center justify-center gap-4">
              <img alt="Empty List" src={EmptyList} className="w-32" />
              <Text>Nenhum documento criado.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormsList;
