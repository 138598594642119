import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import z from "zod";
import { REQUIRED_STRING } from "Config/Zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";
import Skeleton from "DS/custom/skeleton";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import AddNewDocument from "Organisms/UBuyer/Settings/Categories/AddNewDocument";
import DocumentsEditList from "Organisms/UBuyer/Settings/Categories/DocumentsEditList";
import CategoryFormAdvanced from "Organisms/UBuyer/Settings/Categories/CategoryFormAdvanced";
import CategoryEditConfirmationDialog from "Organisms/UBuyer/Settings/Categories/CategoryEditConfirmationDialog";

/** @internal */
export const QUERY = gql`
  query categoryEditForm($buyerId: ID!, $legalEntity: String!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingGroupers {
        id
        displayName
        settingDocuments(legalEntity: $legalEntity) {
          id
          type
          displayName
          risk: defaultRisk
        }
      }
    }
  }
`;

const SKELETON = (
  <div>
    <div className="flex flex-row flex-wrap items-center justify-between">
      <Skeleton className="h-6 w-64" />
      <div className="flex flex-row gap-4">
        <Skeleton className="h-10 w-16" />
        <Skeleton className="h-10 w-20" />
        <Skeleton className="h-10 w-16" />
      </div>
    </div>

    <div className="mt-4 border-b border-slate-100 py-2 pb-6 pr-6">
      <div className="flex flex-row gap-1">
        <div className="flex w-80 flex-col gap-2 text-sm">
          <Skeleton className="h-4 w-32" />
          <Skeleton className="h-4 w-64" />
        </div>
        <Skeleton className="h-10 w-80" />
      </div>
    </div>

    <div className="mt-4 flex flex-row items-center justify-center gap-2">
      <Skeleton className="h-8 w-36" />
      <Skeleton className="h-8 w-40" />
    </div>

    <div className="flex flex-row gap-8 py-2">
      <div className="flex w-72 flex-col gap-2 text-sm">
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-52 w-80" />
      </div>
      <Skeleton className="m-6 h-96 w-full" />
    </div>
  </div>
);

const ZOD_SCHEMA = z.object({
  displayName: REQUIRED_STRING,
  legalEntity: REQUIRED_STRING,
  keepActiveOnSupplierReview: z.boolean(),
  settingDocuments: z.record(z.string()).optional(),
});

function parseSettingDocuments(settingCategory) {
  const settingRisksAttributes = {};

  _.each(settingCategory.settingRisks, ({ settingDocument, risk }) => {
    settingRisksAttributes[settingDocument.id] = String(risk);
  });

  return settingRisksAttributes;
}

function CategoryEditForm({ onSubmit, submitting, settingCategory }) {
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { keepActiveOnSupplierReview, displayName, legalEntity } = settingCategory;
  const { data, loading } = useQuery(QUERY, { variables: { buyerId, legalEntity } });

  const form = useForm({
    resolver: zodResolver(ZOD_SCHEMA),
    defaultValues: {
      displayName,
      legalEntity,
      keepActiveOnSupplierReview,
      settingDocuments: parseSettingDocuments(settingCategory),
    },
  });

  const onConfirm = () => {
    setShowConfirmDialog(false);
    onSubmit(form.getValues());
  };

  const showDialog = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowConfirmDialog(true);
  };

  if (loading) return SKELETON;

  const { settingGroupers } = data.buyer;

  return (
    <>
      <Form {...form}>
        <form id="CategoryEditForm" className="flex flex-col gap-6" onSubmit={showDialog}>
          <div className="flex flex-row flex-wrap items-center justify-between">
            <Breadcrumb levels={["Categoria", displayName, "Editando"]} />

            <div className="flex flex-row gap-4">
              <Button form="CategoryEditForm" type="submit" disabled={submitting}>
                Salvar
              </Button>

              <Button type="button" variant="outline" onClick={() => navigate("./..")}>
                Cancelar
              </Button>
            </div>
          </div>

          <div className="flex flex-col-reverse gap-8 xl:flex-row">
            <div className="w-full xl:w-2/3">
              <div className="space-y-4 border-b border-slate-100 pb-5">
                <Heading level={4}>Geral</Heading>

                <FormField
                  control={form.control}
                  name="displayName"
                  render={({ field }) => (
                    <FormItem className="flex flex-row gap-8">
                      <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                        <FormLabel className="font-medium" htmlFor="displayName">
                          Nome da categoria
                        </FormLabel>

                        <FormControl>
                          <Input id="displayName" placeholder="Digite um nome" {...field} />
                        </FormControl>

                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="pt-5">
                <FormField
                  control={form.control}
                  name="settingDocuments"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-row items-center justify-between gap-4">
                          <div>
                            <Heading level={4}>Documentos</Heading>
                          </div>

                          <AddNewDocument {...field} settingGroupers={settingGroupers} />
                        </div>

                        <FormControl>
                          <DocumentsEditList {...field} settingGroupers={settingGroupers} />
                        </FormControl>
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="w-full xl:w-1/3">
              <CategoryFormAdvanced settingCategory={settingCategory} />
            </div>
          </div>
        </form>
      </Form>

      {showConfirmDialog ? (
        <CategoryEditConfirmationDialog
          onConfirm={onConfirm}
          onClose={() => setShowConfirmDialog(false)}
          activeSuppliersCount={settingCategory.activeSuppliersCount}
        />
      ) : null}
    </>
  );
}

CategoryEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  settingCategory: PropTypes.shape({
    keepActiveOnSupplierReview: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    legalEntity: PropTypes.string,
    displayName: PropTypes.string,
    activeSuppliersCount: PropTypes.number,
  }).isRequired,
};

export default CategoryEditForm;
