import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";

import { Box, Text, Form } from "grommet";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { Button } from "DS/ui/button";

import { Menu } from "lucide-react";

import { DropdownMenu, DropdownMenuContent, DropdownMenuSeparator, DropdownMenuTrigger } from "DS/ui/dropdown-menu";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import CreateSettingFormReportCsv from "Organisms/UBuyer/Settings/Forms/CreateSettingFormReportCsv";
import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import FormDelete from "Organisms/UBuyer/Settings/Forms/FormDelete";
import NewRequestedFormDialog from "Organisms/UBuyer/Settings/Forms/NewRequestedFormDialog";
import DuplicateFormMenuItem from "Organisms/UBuyer/Settings/Forms/DuplicateFormMenuItem";

/** @internal */
export const QUERY = gql`
  query FormInfo($buyerId: ID!, $settingFormId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingForm(id: $settingFormId) {
        id
        name
        hint
        fields
        expirable
        displayName
      }
    }
  }
`;

function FormInfo() {
  const navigate = useNavigate();
  const { settingFormId, buyerId } = useParams();

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId, settingFormId },
  });

  if (loading) return <GlobalLoading />;

  const { settingForm } = data.buyer;

  return (
    <Box
      onFocus={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      className="max-w-7xl p-4 lg:px-10 lg:py-6"
    >
      <Box direction="row" justify="between" align="center">
        <Breadcrumb lastPage="Campos do fornecedor" currentPage={settingForm.displayName} />

        <Box direction="row" gap="small">
          <div className="flex flex-row gap-4">
            <OwnerChecker>
              <Button type="button" onClick={() => navigate("edit")}>
                Editar
              </Button>
            </OwnerChecker>

            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="p-2" aria-label="secondary-menu">
                  <Menu className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent className="w-56">
                <CreateSettingFormReportCsv />

                <OwnerChecker>
                  <DuplicateFormMenuItem />
                  <NewRequestedFormDialog />
                  <DropdownMenuSeparator />
                  <FormDelete />
                </OwnerChecker>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </Box>
      </Box>

      <Box margin={{ top: "small" }}>
        <Form>
          <Box margin={{ vertical: "small" }}>
            <Text size="small" color="dark-3" dangerouslySetInnerHTML={{ __html: settingForm.hint }} />
          </Box>

          {_.sortBy(settingForm.fields, "order").map((field) => (
            <div key={field.order} className="mb-2 flex flex-col p-4 border rounded-md">
              <Text size="small">
                {field.display_name} {field.optional ? " (opcional)" : ""}
              </Text>

              <Text size="small" color="dark-3" dangerouslySetInnerHTML={{ __html: field.hint }} />

              {field.options && field.options.length > 0
                ? field.options.map((option) => (
                    <Text size="small" key={field.order + option}>
                      {option}
                    </Text>
                  ))
                : null}
            </div>
          ))}
        </Form>
      </Box>
    </Box>
  );
}

export default FormInfo;
