import React from "react";
import PropTypes from "prop-types";

import { Flag, SearchX, Paperclip, SearchCheck, MessageCircle } from "lucide-react";

import { cn } from "DS/lib/utils";
import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

import EmptyHistorySVG from "Assets/companies/empty-history.svg";

import formatDate from "Services/DateToString";
import { INFORMATIVE, CLEAR } from "Config/DataAndDocuments";

/** @internal */
export const calculateObject = ({ state, pdf, comment }) => {
  if (comment) return { Icon: MessageCircle, bgColor: "bg-slate-600" };

  if (pdf) return { Icon: Paperclip, bgColor: "bg-blue-600" };

  if (state === INFORMATIVE) return { Icon: Flag, bgColor: "bg-blue-600" };

  if (state === CLEAR) return { Icon: SearchCheck, bgColor: "bg-green-500" };

  return { Icon: SearchX, bgColor: "bg-red-500" };
};

function Timeline({ edges }) {
  return edges.length === 0 ? (
    <div className="flex flex-col items-center justify-center gap-8 pt-8">
      <Text weight="medium">Não encontramos resultado para o filtro aplicado</Text>

      <img src={EmptyHistorySVG} alt="EMPTY" className="" />
    </div>
  ) : (
    <div className="flow-root">
      <ul>
        {edges.map(({ node: supplierEvent }, index) => {
          const { pdf } = supplierEvent;
          const { Icon, bgColor } = calculateObject(supplierEvent);

          return (
            <li key={supplierEvent.id}>
              <div className="relative pb-5">
                {index !== edges.length - 1 ? (
                  <span className="absolute left-2.5 top-4 h-full w-0.5 bg-gray-200" />
                ) : null}

                <div className="relative flex gap-4">
                  <div className={`flex h-6 w-6 flex-none items-center justify-center rounded-full ${bgColor}`}>
                    <Icon size={12} className="text-white" />
                  </div>

                  <div
                    className={cn(
                      "flex flex-1 flex-wrap justify-between gap-4",
                      supplierEvent.comment ? "rounded-md border p-3" : "",
                    )}
                  >
                    <div className="flex flex-col gap-1">
                      <Text size="small">{supplierEvent.name}</Text>

                      {supplierEvent.comment ? (
                        <Text size="small" color="disabled">
                          {supplierEvent.comment}
                        </Text>
                      ) : null}

                      {pdf ? (
                        <Anchor to={pdf.url} target="_blank" className="flex flex-row items-center gap-1 text-sm">
                          <Paperclip size={12} />
                          <span>arquivo.{pdf.filename.split(".").pop()}</span>
                        </Anchor>
                      ) : null}
                    </div>

                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                      <Text size="small" color="disabled">
                        {formatDate(supplierEvent.createdAt)}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

Timeline.propTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
      comment: PropTypes.string,
      createdAt: PropTypes.string,
      pdf: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        filename: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default Timeline;
