import axios from "axios";
import client from "Config/GraphqlApiService";
import KEY from "./Config";

const login = (user) =>
  axios
    .post("/users/sign_in.json", { user })
    .then(({ headers }) => {
      client.clearStore();
      localStorage.setItem(KEY, headers.authorization);
      window.location.assign("/");
    })
    .catch(({ response }) => response.data);

export default login;
