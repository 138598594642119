import React from "react";
import PropTypes from "prop-types";

import { Box } from "grommet";

import { cn } from "DS/lib/utils";
import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";

import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "DS/ui/accordion";

import { Select, SelectItem, SelectGroup, SelectValue, SelectTrigger, SelectContent } from "DS/ui/select";

import { RISK } from "Config/DataAndDocuments";
import { FORM_FIELD_TYPE_OPTIONS } from "Config/BuyerDocument";

const TOAST = "Código copiado para área de transferência";

function FormInfoForm({ settingBuyerDocument }) {
  const { toast } = useToast();
  const { hint, risk, name, fieldType } = settingBuyerDocument;

  return (
    <Box margin={{ top: "small" }}>
      <div className="mb-4 flex flex-col">
        <Text size="small" className="font-bold">
          Título do campo
        </Text>

        <Text size="small" weight="medium">
          {settingBuyerDocument.displayName}
        </Text>
      </div>

      <div className="mb-4 flex flex-col">
        <Text size="small" className="font-bold">
          Descrição
        </Text>

        <Text size="small" weight="medium">
          {hint}
        </Text>
      </div>

      <div className="flex flex-col">
        <Text size="small" className="font-bold">
          Pontuação
        </Text>

        <Text size="small" className={cn("min-w-fit text-left", RISK[risk].color)}>
          {RISK[risk].label}
        </Text>
      </div>

      <Accordion type="single" collapsible className="max-w-lg text-sm">
        <AccordionItem value="form" className="border-0">
          <AccordionTrigger className="font-medium text-blue-600">Configurações avançadas</AccordionTrigger>

          <AccordionContent>
            <div>
              <Text size="small" className="font-bold" htmlFor="hint">
                Código de integração
              </Text>

              <Text size="small" className="mb-4">
                Este código faz referência a este documento ao usar a API.
              </Text>

              <div className="flex flex-row gap-4 text-sm">
                <Input disabled className="w-full" value={name} name="name" />

                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    navigator.clipboard.writeText(name);
                    toast({ title: TOAST });
                  }}
                >
                  Copiar código
                </Button>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <div className="w-full max-w-lg border-t border-t-slate-200 pt-4">
        <div className="flex w-full max-w-lg flex-col gap-4 rounded-md border border-slate-200 p-4">
          <Text weight="medium">Tipo da resposta</Text>

          <Select defaultValue={fieldType} disabled>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>

            <SelectContent>
              <SelectGroup>
                {FORM_FIELD_TYPE_OPTIONS.map(({ label, value, icon }) => (
                  <SelectItem key={value} value={value}>
                    <div className="flex flex-row items-center gap-2">
                      {icon}
                      {label}
                    </div>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>

          <div className="flex flex-col gap-4">
            {settingBuyerDocument.options.map((option) => (
              <div key={option} className="flex flex-row items-center gap-2">
                <div className="h-4 w-4 flex-none rounded-full border border-slate-200" />
                <Input disabled value={option} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
}

FormInfoForm.propTypes = {
  settingBuyerDocument: PropTypes.shape({
    hint: PropTypes.string,
    risk: PropTypes.number,
    name: PropTypes.string,
    fieldType: PropTypes.string,
    displayName: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default FormInfoForm;
