import * as React from "react";
import { cn } from "@/DS/lib/utils.ts";

const Skeleton = React.forwardRef(({ className, repeat = 1, ...props }, ref) => {
  const iterator = Array(repeat).keys();

  return Array.from(iterator).map((key) => (
    <div key={key} className={cn("animate-pulse rounded-md bg-muted", className)} ref={ref} {...props} />
  ));
});

export default Skeleton;
