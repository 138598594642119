import React, { useState, useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { Search } from "lucide-react";
import { Input } from "DS/ui/input";

function SearchInput({ value, onChange, placeholder, disabled }) {
  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearch = useMemo(
    () =>
      _.debounce((term) => {
        onChange(term);
      }, 300),
    [onChange],
  );

  return (
    <div className="relative max-h-9 lg:w-1/3 rounded-md shadow-sm">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <Input
        disabled={disabled}
        type="search"
        name="searchTerm"
        id="searchTerm"
        placeholder={placeholder}
        className="max-h-9 pl-10"
        value={searchTerm}
        onChange={({ target }) => {
          setSearchTerm(target.value);
          debouncedSearch(target.value);
        }}
      />
    </div>
  );
}

SearchInput.defaultProps = { disabled: false };

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SearchInput;
