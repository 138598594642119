import React from "react";
import PropTypes from "prop-types";

import { useForm, useFieldArray } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";

import { FormControl, FormField, FormItem, FormLabel, FormMessage, Form } from "DS/ui/form";

import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";
import { Input } from "DS/ui/input";
import { Textarea } from "DS/ui/textarea";
import { Switch } from "DS/ui/switch";
import { Button } from "DS/ui/button";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
} from "DS/ui/alert-dialog";

import { Select, SelectItem, SelectGroup, SelectValue, SelectTrigger, SelectContent } from "DS/ui/select";

import OptionsFormField from "Organisms/UBuyer/Settings/Forms/OptionsFormField";

import { SETTING_FORM_FIELD } from "Organisms/UBuyer/Settings/Forms/FormNewForm";

import { FORM_FIELD_TYPE_OPTIONS, FIELDS_WITH_OPTIONS } from "Config/FormGroup";

function FormFieldAlertDialog({ formFieldIndex, mainForm, onClose, createdByTemplate }) {
  const { fields, append, update } = useFieldArray({ control: mainForm.control, name: "fields" });

  const indexPassed = typeof formFieldIndex === "number";

  const onSubmit = (values) => {
    if (indexPassed) update(formFieldIndex, values);
    else append(values);

    onClose();
  };

  const defaultValues = indexPassed
    ? fields[formFieldIndex]
    : { hint: "", type: "text_input", optional: false, display_name: "", options: undefined };

  const form = useForm({ resolver: zodResolver(SETTING_FORM_FIELD), defaultValues });

  const questionType = form.watch("type");
  const shouldRenderOptions = FIELDS_WITH_OPTIONS.includes(questionType);
  const disabledMessage = createdByTemplate ? "Não é possível alterar esta configuração em formulários padrão" : "";

  return (
    <AlertDialog defaultOpen onOpenChange={onClose}>
      <AlertDialogContent className="max-h-screen overflow-y-auto">
        <Form {...form}>
          <form id="FormFieldAlertDialog" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <AlertDialogHeader>
              <AlertDialogTitle asChild>
                <Heading>Nova pergunta</Heading>
              </AlertDialogTitle>
            </AlertDialogHeader>

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input name="name" type="hidden" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="display_name"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                    <FormLabel className="font-medium">Título da pergunta</FormLabel>

                    <FormControl>
                      <Input name="display_name" placeholder="Digite o título da sua pergunta" {...field} />
                    </FormControl>

                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="hint"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                    <FormLabel className="font-medium">
                      Descrição{" "}
                      <Text size="small" color="secondary" as="span">
                        (opcional)
                      </Text>
                    </FormLabel>

                    <FormControl>
                      <Textarea
                        className="h-40 min-h-[80px]"
                        placeholder="Dê uma dica que ajude a responder estar pergunta..."
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="optional"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row items-center gap-2">
                    <FormControl>
                      <WrappedTooltip message={disabledMessage}>
                        <Switch
                          {...field}
                          disabled={createdByTemplate}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </WrappedTooltip>
                    </FormControl>

                    <FormLabel htmlFor="notificationsFollowAddedByMe">Pergunta opcional</FormLabel>
                  </div>
                </FormItem>
              )}
            />

            <div className="border-t border-t-slate-200 pt-4">
              <div className="rounded-md border border-slate-200 p-4">
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field }) => (
                    <FormItem className="flex flex-row gap-8">
                      <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                        <FormLabel className="font-medium">Tipo da pergunta</FormLabel>

                        <FormControl>
                          <WrappedTooltip message={disabledMessage}>
                            <Select
                              id="fieldsType"
                              onValueChange={field.onChange}
                              defaultValue={questionType}
                              disabled={createdByTemplate}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Selecione um tipo de pergunta" />
                              </SelectTrigger>

                              <SelectContent>
                                <SelectGroup>
                                  {FORM_FIELD_TYPE_OPTIONS.map(({ label, value, icon }) => (
                                    <SelectItem key={value} value={value}>
                                      <div className="flex flex-row items-center gap-2">
                                        {icon}
                                        {label}
                                      </div>
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </WrappedTooltip>
                        </FormControl>

                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />

                <OptionsFormField form={form} visible={shouldRenderOptions} createdByTemplate={createdByTemplate} />
              </div>
            </div>

            <AlertDialogFooter>
              <div className="flex flex-row gap-4">
                <Button form="FormFieldAlertDialog" type="submit">
                  Adicionar
                </Button>

                <Button type="button" variant="outline" onClick={onClose}>
                  Cancelar
                </Button>
              </div>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}

FormFieldAlertDialog.propTypes = {
  formFieldIndex: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  createdByTemplate: PropTypes.bool,
  mainForm: PropTypes.shape({ control: PropTypes.shape({}).isRequired }).isRequired,
};

FormFieldAlertDialog.defaultProps = {
  formFieldIndex: null,
  createdByTemplate: false,
};

export default FormFieldAlertDialog;
