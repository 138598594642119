import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import _ from "lodash";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";

import { parseErrors } from "Services/ErrorsParser";

import FormFields from "Organisms/UBuyer/Settings/Advanced/FormFields";
import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";

/** @internal */
export const MUTATION = gql`
  mutation AdvancedForm($buyerId: ID!, $preStateReview: Boolean!) {
    updateAdvancedSettings(input: { buyerId: $buyerId, preStateReview: $preStateReview }) {
      errors {
        path
        message
      }
      buyer {
        id
        preStateReview
        restrictionListPersonCreatedAt
        restrictionListCompanyCreatedAt
      }
    }
  }
`;

const ZOD_SCHEMA = z.object({ preStateReview: z.boolean() });

function AdvancedForm({ buyer }) {
  const { buyerId } = useParams();
  const { preStateReview } = buyer;

  const form = useForm({
    resolver: zodResolver(ZOD_SCHEMA),
    defaultValues: { preStateReview },
  });

  const { toast } = useToast();
  const [updateGeneralInfo, { loading }] = useMutation(MUTATION);

  const onSubmit = async () => {
    const { data } = await updateGeneralInfo({
      variables: { buyerId, ...form.getValues() },
    });

    const { errors } = data.updateAdvancedSettings;

    if (errors.length) _.each(parseErrors(errors), (message, path) => form.setError(path, { type: "custom", message }));
    else toast({ title: "Dados atualizados com sucesso!" });
  };

  return (
    <FormProvider {...form}>
      <form id="AdvancedForm" onSubmit={form.handleSubmit(onSubmit)} className="px-4 py-2 lg:py-6 lg:px-10">
        <FormFields buyer={buyer} />

        <OwnerChecker>
          <Button type="submit" disabled={loading} className="mt-6">
            Salvar alterações
          </Button>
        </OwnerChecker>
      </form>
    </FormProvider>
  );
}

AdvancedForm.propTypes = {
  buyer: PropTypes.shape({
    preStateReview: PropTypes.bool,
  }).isRequired,
};

export default AdvancedForm;
