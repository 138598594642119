import React from "react";

import Skeleton from "DS/custom/skeleton";

import { setBadgeTextColorPending } from "Config/PUF";

import useCubeInfo from "Organisms/UBuyer/Companies/PUF/Hooks/useCubeInfo";
import CardDetails from "Organisms/UBuyer/Companies/PUF/Analysis/CardDetails";

function GovAl() {
  const { resultSet } = useCubeInfo({ table: "ChecksAl" });

  if (!resultSet) return <Skeleton className="mb-2 h-24 w-full" />;

  const data = resultSet.rawData();

  const { variant, text } = setBadgeTextColorPending(data.length);

  return (
    <CardDetails
      path="al"
      text={text}
      variant={variant}
      name="Acordo de Leniência"
      description="Consulta relativa a empresa e seu QSA."
    />
  );
}

export default GovAl;
