import React from "react";
import { Navigate, useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Placeholder from "Assets/signup-placeholder.jpg";

import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";
import Skeleton from "DS/custom/skeleton";

import LinkanaTerms from "Pages/User/LinkanaTerms";
import BuyerSignupForm from "Pages/User/BuyerSignupForm";

/** @internal */
export const QUERY = gql`
  query buyerSignup($token: String!) {
    buyerInvite(token: $token) {
      id
      email
      buyerName
      buyerLogoUrl
      buyerSignupImgUrl
      buyerSidebarLogoUrl
    }
  }
`;

const SKELETON = (
  <div className="flex h-screen flex-row flex-wrap items-center justify-evenly gap-16">
    <div className="flex max-w-md flex-1 flex-col sm:w-[420px]">
      <Skeleton className="mb-2 h-10 w-10" />
      <Skeleton className="mb-1 h-7 w-full" />
      <Skeleton className="h-20 w-full" />
      <Skeleton className="my-4 h-36 w-full" />
      <Skeleton className="mb-10 h-10 w-full" />
      <Skeleton className="h-14 w-full" />
    </div>
  </div>
);

function BuyerSignup() {
  const { token } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { token } });

  if (loading) return SKELETON;

  const { buyerInvite } = data;

  if (!buyerInvite) return <Navigate to="/" state={{ tokenExpired: true }} />;

  const { buyerSidebarLogoUrl, buyerName, email, buyerSignupImgUrl } = buyerInvite;

  return (
    <div className="flex h-screen flex-row items-center justify-evenly">
      <div className="flex flex-col items-center justify-center p-10">
        <div className="flex max-w-md flex-1 flex-col sm:w-[420px]">
          <img
            alt="Buyer Sidebar Logo"
            src={buyerSidebarLogoUrl}
            className="h-20 max-w-[200px] object-contain object-left"
          />

          <Heading className="mb-1 mt-2">Criação de conta na Linkana</Heading>

          <Text weight="medium" color="secondary">
            Você foi convidado para otimizar sua gestão de fornecedores dentro da {buyerName}. <br /> Preencha seus
            dados para criar sua conta.
          </Text>

          <BuyerSignupForm email={email} />

          <LinkanaTerms />
        </div>
      </div>

      <div className="hidden p-6 xl:block">
        <img
          alt="Signup or Placeholder"
          className="h-[calc(100vh-4rem)] rounded-3xl"
          src={buyerSignupImgUrl || Placeholder}
        />
      </div>
    </div>
  );
}

export default BuyerSignup;
