import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import _ from "lodash";

import { CirclePlus } from "lucide-react";

import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import Skeleton from "DS/custom/skeleton";

import SearchInput from "Organisms/UBuyer/Shared/SearchInput/SearchInput";
import EmptyList from "Assets/settings/shared/EmptyList.png";
import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";

import createSafeRegExp from "Services/Search";
import WorkflowsTable from "Organisms/UBuyer/Settings/Workflows/WorkflowsTable";

/** @internal */
export const QUERY = gql`
  query WorkflowsList($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingWorkflows {
        id
        displayName
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6">
    <Skeleton className="h-6 w-80" />
    <div className="flex flex-row flex-wrap items-center justify-between gap-x-8 gap-y-4">
      <Skeleton className="h-12 w-[543px]" />
      <Skeleton className="h-10 w-80" />
    </div>
    <Skeleton className="h-10 w-full" />
    <Skeleton className="h-6 w-96" repeat={6} />
  </div>
);

function WorkflowsList() {
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [term, setTerm] = useState("");
  const regExp = createSafeRegExp(term);

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId },
  });

  if (loading) return SKELETON;

  const { settingWorkflows } = data.buyer;

  const filteredSettingWorkflows = settingWorkflows.filter(({ displayName }) => regExp.test(_.deburr(displayName)));

  return (
    <div className="flex flex-col gap-6">
      <div className="hidden lg:block space-y-1 px-4 py-2 lg:py-6 lg:px-10 border-b">
        <Heading className="">Fluxos de aprovação</Heading>
        <Text size="small">Gerencie os fluxos de aprovação</Text>
      </div>

      <div className="px-4 py-2 lg:py-6 lg:pt-2 lg:px-10">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col-reverse gap-2 lg:flex-row justify-between">
            <SearchInput
              value={term}
              placeholder="Procurar fluxos de aprovação..."
              onChange={(value) => {
                setTerm(_.deburr(value));
              }}
            />
            <OwnerChecker className="flex-1">
              <Button type="button" size="sm" onClick={() => navigate("new")}>
                <CirclePlus size={16} className="mr-2" />
                Criar novo fluxo de aprovação
              </Button>
            </OwnerChecker>
          </div>

          {settingWorkflows.length ? (
            <WorkflowsTable workflows={filteredSettingWorkflows} />
          ) : (
            <div className="flex flex-col items-center justify-center gap-4">
              <img alt="Empty List" src={EmptyList} className="w-32" />
              <Text>Você ainda não criou nenhum fluxo de aprovação dentro da Linkana.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkflowsList;
