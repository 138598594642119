import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { REQUIRED_STRING } from "Config/Zod";

import { Button } from "DS/ui/button";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem } from "DS/ui/select";
import { useToast } from "DS/ui/use-toast";
import { AlertDialogFooter } from "DS/ui/alert-dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

/** @internal */
export const MUTATION = gql`
  mutation ChangeSettingCategoryMutation($id: ID!, $newSettingCategoryId: ID!) {
    changeSupplierSettingCategory(input: { id: $id, newSettingCategoryId: $newSettingCategoryId }) {
      supplier {
        id
        state
        stateI18n
        displayCategory
        settingCategoryId
      }
      errors {
        path
        message
      }
    }
  }
`;

const FORM_SCHEMA = z.object({ newSettingCategoryId: REQUIRED_STRING });

function ChangeSettingCategoryMutation({ currentSettingCategory, legalEntity, settingCategories, onClose }) {
  const { id } = useParams();
  const { toast } = useToast();
  const [changeSettingCategory, { loading }] = useMutation(MUTATION);

  const form = useForm({ resolver: zodResolver(FORM_SCHEMA), defaultValues: { newSettingCategoryId: "" } });

  const filteredOptions = settingCategories.filter(
    (category) => category.id !== currentSettingCategory.id && category.legalEntity === legalEntity,
  );

  const onSubmit = async ({ newSettingCategoryId }) => {
    const variables = { id, newSettingCategoryId };

    const { data } = await changeSettingCategory({
      variables,
    });

    const { errors } = data.changeSupplierSettingCategory;

    if (errors.length) toast({ title: errors[0].message });
    else window.location.reload();
  };

  return (
    <Form {...form}>
      <form id="ChangeSettingCategoryMutation" className="flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="newSettingCategoryId"
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor="newSettingCategoryId" className="text-sm font-medium">
                Nova categoria
              </FormLabel>

              <Select
                onValueChange={field.onChange}
                className="h-40"
                id="newSettingCategoryId"
                onKeyDown={(e) => e.stopPropagation()}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>
                    {filteredOptions.map((category) => (
                      <SelectItem key={`setting-category-${category.id}`} value={category.id}>
                        {category.displayName}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <AlertDialogFooter>
          <Button type="submit" disabled={loading} variant="destructive" form="ChangeSettingCategoryMutation">
            Entendi, quero alterar
          </Button>

          <Button type="button" variant="outline" onClick={onClose} disabled={loading}>
            Cancelar
          </Button>
        </AlertDialogFooter>
      </form>
    </Form>
  );
}

ChangeSettingCategoryMutation.propTypes = {
  currentSettingCategory: PropTypes.shape({ id: PropTypes.string, displayName: PropTypes.string }).isRequired,
  legalEntity: PropTypes.string.isRequired,
  settingCategories: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, displayName: PropTypes.string }))
    .isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeSettingCategoryMutation;
