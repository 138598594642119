import React from "react";
import PropTypes from "prop-types";
import { ptBR } from "date-fns/locale";

import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "DS/lib/utils";
import { Button } from "DS/ui/button";
import { Calendar } from "DS/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "DS/ui/popover";

import formatDate from "Services/DateToString";

function DatePickerRangeFilter({ filters, setFilters }) {
  const { createdAtRange = {} } = filters;

  const renderDatePicker = () => {
    const { to, from } = createdAtRange;

    if (!to) return formatDate(from);

    return `${formatDate(from)} - ${formatDate(to)}`;
  };

  return (
    <div className="grid gap-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn("h-9 w-fit justify-start text-left font-normal", !createdAtRange && "text-muted-foreground")}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />

            {createdAtRange.from ? renderDatePicker() : <span>Filtrar por período</span>}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            locale={ptBR}
            numberOfMonths={2}
            selected={createdAtRange}
            defaultMonth={createdAtRange.from}
            disabled={(date) => date > new Date()}
            onSelect={(value) => setFilters({ ...filters, createdAtRange: value })}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

DatePickerRangeFilter.propTypes = {
  filters: PropTypes.shape({
    createdAtRange: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default DatePickerRangeFilter;
