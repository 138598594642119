import React from "react";
import PropTypes from "prop-types";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "DS/ui/table";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

import EmptyList from "Assets/settings/shared/EmptyList.png";

function FormsTable({ forms }) {
  if (forms.length === 0)
    return (
      <div className="flex flex-col items-center justify-center gap-4">
        <img alt="Empty List" src={EmptyList} className="w-32" />
        <Text>Nenhum documento encontrado...</Text>
      </div>
    );

  return (
    <div className="rounded-md border border-slate-200">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">
              <Text size="small" weight="medium">
                Campo
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Grupo de revisão
              </Text>
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {forms.map((form) => (
            <TableRow key={form.id}>
              <TableCell className="p-4">
                <Anchor to={form.id}>{form.displayName}</Anchor>
              </TableCell>

              <TableCell>
                <Text size="small">{form.settingGrouper.displayName}</Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

FormsTable.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      settingGrouper: PropTypes.shape({
        displayName: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default FormsTable;
