import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import z from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { REQUIRED_EMAIL_WITH_REGEX } from "Config/Zod";

import { AlertDialogFooter } from "DS/ui/alert-dialog";

import { FormItem, FormField, FormLabel, FormMessage, FormControl } from "DS/ui/form";

import { useToast } from "DS/ui/use-toast";
import { Button } from "DS/ui/button";
import { Input } from "DS/ui/input";

import UserPermissions from "Organisms/UBuyer/Settings/Users/UserPermissions";

/** @internal */
export const MUTATION = gql`
  mutation addUserWithoutIDP($email: String!, $iam: IamAttributes!, $buyerId: ID!, $owner: Boolean!) {
    sendBuyerInvite(input: { email: $email, iam: $iam, buyerId: $buyerId, owner: $owner }) {
      buyer {
        id
        buyerInvitesSent {
          id
          email
          createdAtText
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

const zodSchema = () =>
  z.object({
    email: REQUIRED_EMAIL_WITH_REGEX,
    owner: z.boolean(),
    addSupplier: z.boolean(),
    reviewDoc: z.boolean(),
    addBuyerDocument: z.boolean(),
    reviewSupplier: z.boolean(),
    destroySupplier: z.boolean(),
    addPerformanceForm: z.boolean(),
    startWorkflow: z.boolean(),
    purchaseSupplierProfile: z.boolean(),
  });

function AddUserWithoutIDPForm({ onOpenChange }) {
  const { toast } = useToast();
  const { buyerId } = useParams();
  const [sendBuyerInvite, { loading: isSubmitting }] = useMutation(MUTATION);

  const defaultValues = {
    email: "",
    owner: false,
    addSupplier: true,
    reviewDoc: false,
    addBuyerDocument: true,
    reviewSupplier: false,
    destroySupplier: false,
    addPerformanceForm: false,
    startWorkflow: true,
    purchaseSupplierProfile: false,
  };
  const form = useForm({ resolver: zodResolver(zodSchema()), defaultValues });

  const onSubmit = async (values) => {
    const iam = {
      addSupplier: values.addSupplier,
      reviewDoc: values.reviewDoc,
      addBuyerDocument: values.addBuyerDocument,
      reviewSupplier: values.reviewSupplier,
      destroySupplier: values.destroySupplier,
      addPerformanceForm: values.addPerformanceForm,
      startWorkflow: values.startWorkflow,
      purchaseSupplierProfile: values.purchaseSupplierProfile,
    };

    const { email, owner } = values;

    const { data } = await sendBuyerInvite({
      variables: { buyerId, email, owner, iam },
    });

    const [formError] = data.sendBuyerInvite.errors;

    if (formError) {
      toast({
        variant: "destructive",
        description: formError.message,
        title: "Erro ao enviar convite!",
      });
    } else {
      toast({ title: "Usuário convidado com sucesso!" });
      onOpenChange(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form id="inviteUser" className="flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem className="w-full">
              <div className="flex flex-col gap-1">
                <FormLabel htmlFor="email">E-mail</FormLabel>

                <FormControl>
                  <Input {...field} id="email" placeholder="Digite o email para convite" />
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <UserPermissions />

        <AlertDialogFooter>
          <Button disabled={isSubmitting} type="submit" form="inviteUser">
            Convidar
          </Button>

          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
            Cancelar
          </Button>
        </AlertDialogFooter>
      </form>
    </FormProvider>
  );
}

AddUserWithoutIDPForm.propTypes = {
  onOpenChange: PropTypes.func.isRequired,
};

export default AddUserWithoutIDPForm;
