import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";

import { Paperclip, X } from "lucide-react";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { FormItem, FormLabel, FormControl, FormMessage } from "DS/ui/form";

const CustomFileInput = forwardRef(({ onChange, name, disabled, pdf }, ref) => {
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    if (!pdf) setSelectedFile(undefined);
  }, [pdf]);

  const onSelectFile = ({ target }) => {
    onChange(target.files);
    setSelectedFile(target.files[0]);
  };

  const onRemoveFile = (event) => {
    event.preventDefault();
    onSelectFile({ target: { files: "" } });
  };

  return (
    <FormItem>
      <FormControl>
        <FormLabel htmlFor={name}>
          <Input
            id={name}
            ref={ref}
            type="file"
            name={name}
            className="hidden"
            disabled={disabled}
            onChange={onSelectFile}
          />

          <div className="flex cursor-pointer flex-row items-center gap-2 rounded-md p-2 hover:bg-accent">
            <Paperclip className="h-4 w-4" />

            {selectedFile === undefined ? (
              <Text size="xsmall">Adicionar um anexo</Text>
            ) : (
              <div className="flex flex-row items-center gap-2">
                <Text size="xsmall" className="max-w-xs truncate">
                  {selectedFile.name}
                </Text>

                <Button type="button" variant="ghost" disabled={disabled} className="h-fit p-0" onClick={onRemoveFile}>
                  <X size={16} />
                </Button>
              </div>
            )}
          </div>
        </FormLabel>
      </FormControl>

      <FormMessage />
    </FormItem>
  );
});

CustomFileInput.defaultProps = { pdf: "", disabled: false };

CustomFileInput.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pdf: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export default CustomFileInput;
