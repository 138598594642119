import React from "react";
import PropTypes from "prop-types";

import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";

import FormSortableField from "Organisms/UBuyer/Settings/Forms/FormSortableField";

function FormSortableFields({ fields, onEdit, onDelete, onReorder, onDuplicate, createdByTemplate }) {
  const sensorsOptions = { activationConstraint: { distance: 4 } };

  const onDragEnd = ({ active, over }) => {
    if (active.id === over.id) return;

    const oldIndex = fields.indexOf(active.id);
    const newIndex = fields.indexOf(over.id);

    onReorder(oldIndex, newIndex);
  };

  return (
    <DndContext
      onDragEnd={onDragEnd}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      sensors={useSensors(useSensor(PointerSensor, sensorsOptions))}
    >
      <SortableContext items={fields} strategy={verticalListSortingStrategy}>
        {fields.map((field, index) => (
          <FormSortableField
            index={index}
            field={field}
            onEdit={onEdit}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            createdByTemplate={createdByTemplate}
            key={`${field.display_name}-${field.order}-${index}`} // eslint-disable-line react/no-array-index-key
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

FormSortableFields.defaultProps = { createdByTemplate: false };

FormSortableFields.propTypes = {
  onEdit: PropTypes.func.isRequired,
  createdByTemplate: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, display_name: PropTypes.string })).isRequired,
};

export default FormSortableFields;
