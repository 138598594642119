import axios from "axios";
import ParseErrors from "./ParseErrors";

const PasswordChange = (values, resetToken) => {
  const params = {
    ...values,
    reset_password_token: resetToken,
  };
  return new Promise((res, rej) => {
    axios
      .put("/users/password.json", {
        user: params,
      })
      .then(() => res(true))
      .catch((error) => rej(ParseErrors(error.response.data.errors)));
  });
};

export default PasswordChange;
