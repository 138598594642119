import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { Box, Text, Anchor, Button } from "grommet";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";

/** @internal */
export const QUERY = gql`
  query WorkflowsInfo($buyerId: ID!, $settingWorkflowId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingGroupers {
        id
        displayName
      }
      settingWorkflow(settingWorkflowId: $settingWorkflowId) {
        id
        displayName
        settingWorkflowSteps {
          id
          displayName
          mainApprover {
            id
            email
          }
          approvers {
            id
            email
          }
        }
      }
    }
  }
`;

function WorkflowsInfo() {
  const { settingWorkflowId, buyerId } = useParams();
  const navigate = useNavigate();

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId, settingWorkflowId },
  });

  if (loading) return <GlobalLoading />;

  const { settingWorkflow } = data.buyer;
  const { settingWorkflowSteps } = settingWorkflow;

  return (
    <Box className="px-4 py-2 lg:py-6 lg:px-10">
      <Box direction="row" justify="between" align="center">
        <Box direction="row" align="center" gap="small" margin={{ bottom: "x-small" }}>
          <Anchor label="Fluxos de aprovação" onClick={() => navigate("../.")} weight="normal" size="22px" />

          <Text size="22px">&gt;</Text>
        </Box>

        <OwnerChecker>
          <Button
            color="brand"
            label={<Text color="brand">Editar fluxo de aprovação</Text>}
            onClick={() => navigate(`./edit`)}
          />
        </OwnerChecker>
      </Box>

      <Text size="large" color="dark-2" weight="bold">
        {settingWorkflow.displayName}
      </Text>

      <Box margin={{ top: "large" }}>
        <Text color="dark-2" weight="bold" size="large">
          Alçadas de aprovação:
        </Text>

        {settingWorkflowSteps.map((step) => (
          <Box flex={false} key={step.id}>
            <Text weight="bold" color="dark-2" margin={{ bottom: "small", top: "medium" }}>
              {step.displayName}
            </Text>

            <Text as="li" color="dark-3" weight="bold">
              {step.mainApprover.email} (principal)
            </Text>

            {step.approvers.map((approver) => (
              <Text as="li" color="dark-3" key={approver.id}>
                {approver.email}
              </Text>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default WorkflowsInfo;
