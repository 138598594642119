import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import cnaesJSON from "JSON/cnaes.json";

import Skeleton from "DS/custom/skeleton";
import { formatCnae } from "Services/RFFormatter";

import { Breadcrumb } from "DS/custom/breadcrumb";

/** @internal */
export const QUERY = gql`
  query AllCNAEs($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      branch {
        id
        cnaeFiscal
        cnaeFiscalTexto
        cnaesSecundarios
      }
    }
  }
`;

const SKELETON = (
  <div>
    <Skeleton className="mb-2 h-4 w-52" />
    <Skeleton className="my-2 h-4 w-36" />
    <div className="flex flex-wrap items-center gap-4">
      <Skeleton className="h-24 w-96" repeat={6} />
    </div>
  </div>
);

function AllCNAEs() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { branch } = data.supplier;
  const { cnaeFiscal, cnaeFiscalTexto, cnaesSecundarios } = branch;

  return (
    <div>
      <Breadcrumb levels={["Dados CNPJ", "CNAEs"]} />

      <p className="mb-2 mt-4 text-xs font-medium text-slate-400">
        {cnaesSecundarios.length + 1} resultados encontrados
      </p>

      <div className="flex flex-wrap items-center gap-x-20 gap-y-6">
        <div className="lg:w-[46%]">
          <p className="text-sm text-gray-500">CNAE Principal</p>

          <p className="text-sm font-medium text-gray-700">
            {formatCnae(cnaeFiscal)} - {cnaeFiscalTexto}
          </p>
        </div>

        {cnaesSecundarios.map((cnae) => (
          <div key={cnae} className="w-full lg:w-[46%]">
            <p className="text-sm text-gray-500">CNAE Secundário</p>

            <p className="text-sm font-medium text-gray-700">
              {formatCnae(cnae)} - {cnaesJSON[cnae]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllCNAEs;
