import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { ArrowLeft, Lock } from "lucide-react";

import z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import Logo from "Assets/logo.svg";
import { REQUIRED_EMAIL_WITH_REGEX } from "Config/Zod";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";
import { Anchor } from "DS/custom/anchor";
import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";
import { Card, CardHeader, CardContent, CardFooter } from "DS/custom/card";

import { Form, FormItem, FormField, FormLabel, FormMessage, FormControl } from "DS/ui/form";

/** @internal */
export const MUTATION = gql`
  mutation userPasswordReset($email: String!) {
    resetPasswordRequest(input: { email: $email }) {
      error
    }
  }
`;

const zodSchema = () => z.object({ email: REQUIRED_EMAIL_WITH_REGEX });

function UserPasswordReset({ tokenExpired }) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [resetPasswordRequest, { loading }] = useMutation(MUTATION);

  const defaultValues = { email: "" };
  const form = useForm({ resolver: zodResolver(zodSchema()), defaultValues });

  const onSubmit = async ({ email }) => {
    await resetPasswordRequest({ variables: { email } });

    const description =
      `Se encontrarmos o e-mail ${email} entre nossos usuários,` +
      " enviaremos um e-mail com instruções para uma nova senha." +
      " Isso pode levar alguns minutos.";

    toast({ title: "Solicitação recebida com sucesso!", description });
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <Card className="max-w-md sm:w-[420px]">
        {tokenExpired ? null : (
          <Anchor className="flex items-center gap-2" to="/login">
            <ArrowLeft size={16} className="text-blue-600" strokeWidth={2.5} />
            Voltar
          </Anchor>
        )}

        <CardHeader className="flex flex-col gap-2">
          <div className="h-11 w-11 rounded-sm bg-slate-50 p-2">
            <img src={Logo} alt="Linkana Logo" />
          </div>

          <Heading>{tokenExpired ? "Seu token expirou!" : "Esqueceu sua senha?"}</Heading>

          <Text>Digite seu e-mail e enviaremos novamente as instruções para você criar uma nova senha.</Text>
        </CardHeader>

        <CardContent>
          <Form {...form}>
            <form id="UserPasswordReset" className="flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                name="email"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <div className="flex flex-col gap-1">
                      <FormLabel htmlFor="email">E-mail</FormLabel>

                      <FormControl>
                        <Input {...field} id="email" placeholder="Digite seu email..." />
                      </FormControl>

                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />

              <Button type="submit" disabled={loading} form="UserPasswordReset">
                Enviar instruções
              </Button>
            </form>
          </Form>
        </CardContent>

        <div className="w-full border-b" />

        <CardFooter className="flex flex-col gap-3">
          <Button type="button" variant="outline" className="w-full gap-2" onClick={() => navigate("/login")}>
            <Lock size={20} /> Lembrou da sua senha? Faça o login
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

UserPasswordReset.defaultProps = { tokenExpired: false };
UserPasswordReset.propTypes = { tokenExpired: PropTypes.bool };

export default UserPasswordReset;
