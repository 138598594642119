import React, { useContext } from "react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Label } from "DS/ui/label";
import { Switch } from "DS/ui/switch";
import Skeleton from "DS/custom/skeleton";

import { STATES_NAME as SUPPLIER_STATES_NAME } from "Config/Supplier";
import { STATES_NAME as QUALIFICATION_STATES_NAME } from "Config/Qualification";

import { Context } from "Organisms/UBuyer/Companies/Index/SuppliersSearch/SupplierSearchContext";

import ComboboxFilter from "Organisms/UBuyer/Companies/Index/SuppliersSearch/ComboboxFilter";

/** @internal */
export const QUERY = gql`
  query QuickFilters {
    buyer {
      id
      settingCategoriesInUse {
        id
        disabled
        displayName
      }
    }
  }
`;

const SKELETON = (
  <div className="flex items-center space-x-2">
    <Skeleton className="h-9 w-32" />
    <Skeleton className="h-9 w-32" />
    <Skeleton className="h-6 w-44" />
  </div>
);

function QuickFilters() {
  const { search, setSearch } = useContext(Context);
  const { loading, data } = useQuery(QUERY, { fetchPolicy: "network-only" });

  if (loading) return SKELETON;

  const { buyer } = data;

  const SUPPLIER_STATES = Object.keys(SUPPLIER_STATES_NAME).map((value) => {
    const label = SUPPLIER_STATES_NAME[value];
    return { label, value };
  });

  const QUALIFICATION_STATES = Object.keys(QUALIFICATION_STATES_NAME).map((value) => {
    const label = QUALIFICATION_STATES_NAME[value];
    return { label, value };
  });

  const SETTING_CATEGORIES = buyer.settingCategoriesInUse.map(({ id, disabled, displayName }) => {
    const label = disabled ? `${displayName} (excluída)` : displayName;
    return { label, value: id };
  });

  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center space-x-2">
        <Switch
          id="added-by-me"
          checked={search.addedByMe}
          onCheckedChange={(addedByMe) => setSearch({ ...search, addedByMe })}
        />

        <Label htmlFor="added-by-me">Iniciados por mim</Label>
      </div>

      <ComboboxFilter
        title="Pendências"
        value={search.states}
        options={SUPPLIER_STATES}
        onChange={(value) => setSearch({ ...search, states: value })}
      />

      <ComboboxFilter
        title="Homologação"
        options={QUALIFICATION_STATES}
        value={search.qualificationStates}
        onChange={(value) => setSearch({ ...search, qualificationStates: value })}
      />

      <ComboboxFilter
        title="Categorias"
        options={SETTING_CATEGORIES}
        value={search.settingCategories}
        onChange={(value) => setSearch({ ...search, settingCategories: value })}
      />
    </div>
  );
}

export default QuickFilters;
