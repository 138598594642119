import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectItem } from "DS/ui/select";

function SupplierSelect({ tabs }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const rootPath = `/companies/${id}`;
  const rootRoute = pathname.split("/")[3];

  const onValueChange = (path) =>
    path.startsWith("/buyers") ? window.location.assign(path) : navigate(`${rootPath}/${path}`);

  return (
    <div className="lg:hidden">
      <Select defaultValue={rootRoute} onValueChange={onValueChange}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>

        <SelectContent>
          <SelectGroup>
            {tabs.map(({ title, path }) => (
              <SelectItem key={path} value={path}>
                {title}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

SupplierSelect.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    }),
  ).isRequired,
};

export default SupplierSelect;
