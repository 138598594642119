import React from "react";
import PropTypes from "prop-types";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { Mail } from "lucide-react";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";
import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import EmptyList from "Assets/settings/shared/NewEmptyList.svg";

/** @internal */
export const REVOKE_BUYER_INVITE = gql`
  mutation revokeBuyerInvite($id: ID!) {
    revokeBuyerInvite(input: { id: $id }) {
      buyerInvite {
        id
      }
    }
  }
`;

function PendingBuyerInvites({ buyerInvitesSent, refetch }) {
  const { toast } = useToast();
  const [revokeBuyerInvite, { loading }] = useMutation(REVOKE_BUYER_INVITE);

  if (!buyerInvitesSent.length)
    return (
      <div className="flex h-64 w-full flex-col items-center justify-center gap-6">
        <img src={EmptyList} alt="Nenhum convite pendente" />
        <Text weight="medium">Nenhum convite pendente</Text>
      </div>
    );

  return (
    <div className="flex flex-col gap-3">
      {buyerInvitesSent.map((invite) => (
        <div key={invite.id} className="flex flex-row items-center justify-between rounded-md border px-6 py-3">
          <div className="flex flex-row items-center gap-2">
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-50">
              <Mail size={16} className="text-slate-600" />
            </div>
            <Text size="small" weigth="medium">
              {invite.email}
            </Text>
            <Badge variant="secondary">Convite enviado há {invite.createdAtText}</Badge>
          </div>

          <OwnerChecker>
            <div className="flex flex-row items-center gap-4">
              <Button
                type="button"
                variant="link"
                onClick={() => {
                  navigator.clipboard.writeText(invite.link);
                  toast({ title: "Link copiado com sucesso!" });
                }}
              >
                Copiar link
              </Button>

              <Button
                type="button"
                variant="outline"
                disabled={loading}
                onClick={async () => {
                  await revokeBuyerInvite({ variables: { id: invite.id } });
                  refetch();
                  toast({ title: "Convite revogado com sucesso!" });
                }}
              >
                Cancelar convite
              </Button>
            </div>
          </OwnerChecker>
        </div>
      ))}
    </div>
  );
}

PendingBuyerInvites.propTypes = {
  buyerInvitesSent: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      createdAtText: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default PendingBuyerInvites;
