import React from "react";
import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { X } from "lucide-react";
import { colors } from "Config/Theme";

import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
} from "DS/ui/alert-dialog";
import { Heading } from "DS/custom/heading";

/** @internal */
export const MUTATION = gql`
  mutation DeleteRestrictionListCompanies($buyerId: ID!) {
    deleteRestrictionListCompanies(input: { buyerId: $buyerId }) {
      buyer {
        id
        restrictionListCompanyCreatedAt
      }
    }
  }
`;

function DeleteRestrictionListCompanies() {
  const { buyerId } = useParams();
  const [deleteRestrictionListCompanies, { loading }] = useMutation(MUTATION);

  return (
    <div className="flex max-w-lg items-center justify-between rounded-md border border-dashed border-gray-300 pl-4">
      <Text size="small" color="secondary">
        restrictionList_companies.csv
      </Text>

      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="plain" aria-label="Excluir arquivo">
            <X size={20} color={colors["status-critical"]} />
          </Button>
        </AlertDialogTrigger>

        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle asChild>
              <Heading>Confirme a exclusão do arquivo</Heading>
            </AlertDialogTitle>

            <AlertDialogDescription asChild>
              <Text size="small" weight="medium">
                Essa ação não pode ser desfeita.
              </Text>
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <Button
              type="button"
              onClick={() => deleteRestrictionListCompanies({ variables: { buyerId } })}
              disabled={loading}
              variant="destructive"
              aria-label="Excluir"
            >
              Excluir
            </Button>

            <AlertDialogCancel asChild>
              <Button type="button" variant="outline" disabled={loading}>
                Cancelar
              </Button>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default DeleteRestrictionListCompanies;
