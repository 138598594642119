import React from "react";
import PropTypes from "prop-types";

import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";

import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";

import FormSortableForm from "Organisms/UBuyer/Settings/Forms/FormSortableForm";

function FormSortableForms({ settingForms, onReorderForms }) {
  const sensorsOptions = { activationConstraint: { distance: 4 } };

  const onDragEnd = ({ active, over }) => {
    if (active.id === over.id) return;

    const oldIndex = settingForms.findIndex(({ id }) => id === active.id);
    const newIndex = settingForms.findIndex(({ id }) => id === over.id);

    onReorderForms(arrayMove(settingForms, oldIndex, newIndex));
  };

  return (
    <div>
      <DndContext
        onDragEnd={onDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        sensors={useSensors(useSensor(PointerSensor, sensorsOptions))}
      >
        <SortableContext items={settingForms} strategy={verticalListSortingStrategy}>
          {settingForms.map((settingForm) => (
            <FormSortableForm key={settingForm.id} settingForm={settingForm} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}

FormSortableForms.propTypes = {
  settingForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      settingGrouper: PropTypes.shape({ id: PropTypes.string }),
    }),
  ).isRequired,
  onReorderForms: PropTypes.func.isRequired,
};

export default FormSortableForms;
