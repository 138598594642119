import _ from "lodash";

import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { REQUIRED_STRING, REQUIRED_MESSAGE, OPTIONAL_EMAIL_WITH_REGEX } from "Config/Zod";

import attachFile from "Services/Uploader";
import { NATIONAL_PF } from "Config/LegalEntity";

const REQUIRED_SETTING_CATEGORY_SCHEMA = z.object(
  { id: z.string(), displayName: z.string(), legalEntity: z.string(), hasSettingForms: z.boolean() },
  { required_error: REQUIRED_MESSAGE },
);

function resolveBuyerDocuments(buyerDocuments) {
  if (Object.keys(buyerDocuments).length < 1) return z.array().default([]);

  const schema = {};

  _.each(buyerDocuments, (buyerDocument) => {
    let validation =
      buyerDocument.fieldType === "document" ? z.instanceof(FileList, { message: REQUIRED_MESSAGE }) : REQUIRED_STRING;

    if (buyerDocument.optional) validation = validation.or(z.string().default(""));

    schema[buyerDocument.name] = validation;
  });

  return z.object(schema).transform((values) =>
    _.map(values, (value, name) => {
      const { fieldType } = buyerDocuments.find((buyerDocument) => buyerDocument.name === name);
      return { name, value, type: fieldType };
    }),
  );
}

export const calculateResolver = (buyerDocuments) => {
  const zodObject = z.object({
    hasContactEmail: REQUIRED_STRING,
    contactEmail: OPTIONAL_EMAIL_WITH_REGEX,
    confirmation: z.boolean().default(false),
    settingCategory: REQUIRED_SETTING_CATEGORY_SCHEMA,
    buyerDocumentFields: resolveBuyerDocuments(buyerDocuments),
  });

  const contactEmailValidation = ({ hasContactEmail, contactEmail }) =>
    hasContactEmail === "1" ? !!contactEmail : !contactEmail;

  const confirmationValidation = ({ settingCategory, confirmation }) =>
    settingCategory.legalEntity === NATIONAL_PF ? !!confirmation : !confirmation;

  return zodResolver(
    zodObject
      .refine(contactEmailValidation, { message: "Cheque o e-mail de contato.", path: ["contactEmail"] })
      .refine(confirmationValidation, { message: "Cheque a confirmação da LGPD.", path: ["confirmation"] }),
  );
};

export async function parseVariables({ settingCategory, buyerDocumentFields, ...values }) {
  const parsedFields = await Promise.all(
    buyerDocumentFields.map(async ({ type, name, value }) => {
      if (type !== "document" || !value) return { name, value };

      const newValue = await attachFile(value);

      return { name, value: newValue };
    }),
  );

  return { ...values, buyerDocumentFields: parsedFields, settingCategoryId: settingCategory.id };
}
