import React from "react";
import PropTypes from "prop-types";
import { XCircle } from "lucide-react";

import { Text } from "DS/custom/text";

function SearchResults({ found }) {
  const formatter = new Intl.NumberFormat("pt-BR");

  if (found > 0)
    return (
      <Text size="small" weight="medium" className="text-slate-500">
        {formatter.format(found)} resultados
      </Text>
    );

  return (
    <div className="space-y-3">
      <Text size="small" weight="medium" className="text-slate-500">
        Nenhum resultado encontrado
      </Text>
      <div className="flex max-w-2xl flex-col items-center gap-1 rounded-lg border p-6">
        <div className="mb-3 rounded-md border p-2">
          <XCircle className="h-5 w-5" />
        </div>
        <Text weight="medium" className="text-center">
          Não conseguimos carregar os dados desta empresa...
        </Text>
        <Text className="text-center">
          Essa é uma nova empresa e por isso ainda não consta em nossa base atualizada. Mas fique tranquilo, você pode
          adicioná-la e inserir seus dados manualmente!
        </Text>
      </div>
    </div>
  );
}

SearchResults.propTypes = {
  found: PropTypes.number.isRequired,
};

export default SearchResults;
