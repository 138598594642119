import React from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

import { Settings, LogOut } from "lucide-react";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";

import ProductChangelogPopup from "Organisms/Shared/Navigation/BuyerSidebar/ProductChangelogPopup";

import logout from "Services/Authentication/Logout";

function BuyerSidebar({ buyer, buttons }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClick = (path, turbo) => {
    if (turbo) {
      window.location = path;
    } else {
      navigate(path);
    }
  };

  return (
    <div className="z-10 hidden h-screen w-56 flex-col justify-between border-r bg-white p-4 py-6 lg:fixed lg:flex">
      <div className="flex flex-col space-y-1">
        <div className="flex flex-row items-center gap-1.5 w-full px-2 pb-4">
          <img alt="Buyer Logo" src={buyer.logoUrl} className="h-4 w-4 flex-none rounded-sm drop-shadow-sm" />

          <Text weight="medium" className="truncate" size="small">
            {buyer.name}
          </Text>
        </div>

        <div className="flex flex-col gap-1">
          {buttons.map((button) => {
            const { path, label, matchPath, Icon, turbo } = button;
            const highlight = matchPath.test(pathname);
            const className = highlight ? "text-white" : "";

            return (
              <Button
                type="button"
                key={button.label}
                onClick={() => onClick(path, turbo)}
                variant={highlight ? "default" : "ghost"}
                className="w-full justify-start gap-2 p-2"
              >
                <Icon size={14} className={className} />

                <Text size="small" weight="medium" className={className}>
                  {label}
                </Text>
              </Button>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <ProductChangelogPopup />

        <Button
          type="button"
          onClick={() => onClick(`/buyers/${buyer.id}/edit`, true)}
          variant="ghost"
          className="w-full justify-start gap-2 p-2"
        >
          <Settings size={14} />

          <Text size="small" weight="medium">
            Configurações
          </Text>
        </Button>
        <Button type="button" onClick={logout} variant="ghost" className="w-full justify-start gap-2 p-2">
          <LogOut size={14} />

          <Text size="small" weight="medium">
            Sair
          </Text>
        </Button>
      </div>
    </div>
  );
}

BuyerSidebar.propTypes = {
  buyer: PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string, logoUrl: PropTypes.string })
    .isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BuyerSidebar;
