import React from "react";
import PropTypes from "prop-types";

import { CheckIcon, PlusCircle, MinusIcon } from "lucide-react";

import {
  Command,
  CommandItem,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandSeparator,
} from "DS/ui/command";

import { cn } from "DS/lib/utils";
import { Button } from "DS/ui/button";
import { Badge } from "DS/custom/badge";
import { Separator } from "DS/ui/separator";
import { Popover, PopoverContent, PopoverTrigger } from "DS/ui/popover";

function ComboboxFilter({ value, title, options, onChange }) {
  const valueSet = new Set(value);

  const selectAllOnChangeParams = valueSet.size !== options.length ? options.map((option) => option.value) : [];

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size="sm" type="button" variant="outline" className="h-9 border-dashed">
          <PlusCircle className="mr-2 h-4 w-4" />
          {title}

          {valueSet.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="px-1">
                {valueSet.size}
              </Badge>
            </>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-[240px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} className="border-0 focus:ring-0" />
          <CommandList>
            <CommandEmpty>Nenhum encontrado.</CommandEmpty>

            <CommandGroup>
              <CommandItem onSelect={() => onChange(selectAllOnChangeParams)}>
                <div
                  className={cn(
                    "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                    valueSet.size === 0 ? "opacity-50 [&_svg]:invisible" : "bg-primary text-primary-foreground",
                  )}
                >
                  {valueSet.size !== options.length ? (
                    <MinusIcon className={cn("h-4 w-4")} />
                  ) : (
                    <CheckIcon className={cn("h-4 w-4")} />
                  )}
                </div>
                Selecionar todos
              </CommandItem>
            </CommandGroup>
            <CommandSeparator />

            <CommandGroup>
              {options.map((option) => {
                const selectedValues = new Set(value);
                const isSelected = valueSet.has(option.value);

                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      if (isSelected) selectedValues.delete(option.value);
                      else selectedValues.add(option.value);

                      onChange([...selectedValues.values()]);
                    }}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")} />
                    </div>

                    {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

ComboboxFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ComboboxFilter;
