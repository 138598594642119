import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";
import { Button } from "DS/ui/button";

import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
} from "DS/ui/alert-dialog";

/** @internal */
export const MUTATION = gql`
  mutation PerformanceFormDelete($settingPerformanceFormId: ID!) {
    deleteSettingPerformanceForm(input: { settingPerformanceFormId: $settingPerformanceFormId }) {
      buyer {
        id
        settingPerformanceForms {
          id
        }
      }
    }
  }
`;

function PerformanceFormDelete() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { settingPerformanceFormId } = useParams();
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [deleteSettingPerformanceForm, { loading }] = useMutation(MUTATION);

  const onClick = async () => {
    await deleteSettingPerformanceForm({
      variables: { settingPerformanceFormId },
    });

    toast({ title: "Formulário de performance excluído com sucesso!" });
    navigate("./../..");
  };

  return (
    <AlertDialog open={showAlertDialog}>
      <AlertDialogTrigger asChild>
        <Button type="button" variant="destructive" onClick={() => setShowAlertDialog(true)}>
          Excluir
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle asChild>
            <Heading>Tem certeza que deseja excluir este formulário de performance?</Heading>
          </AlertDialogTitle>

          <AlertDialogDescription asChild>
            <Text size="small" weight="medium">
              Você tem certeza que deseja deletar este formulário de performance? Este formulário não poderá ser
              visualizado após a exclusão.
            </Text>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <Button
            type="button"
            onClick={onClick}
            disabled={loading}
            variant="destructive"
            aria-label="Excluir formulário de performance"
          >
            Excluir
          </Button>

          <Button type="button" variant="outline" disabled={loading} onClick={() => setShowAlertDialog(false)}>
            Cancelar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default PerformanceFormDelete;
