import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Copy } from "lucide-react";

import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";
import { DropdownMenuItem } from "DS/ui/dropdown-menu";

/** @internal */
export const MUTATION = gql`
  mutation DuplicateFormMenuItem($settingFormId: ID!) {
    duplicateSettingForm(input: { settingFormId: $settingFormId }) {
      settingForm {
        id
        buyer {
          id
          settingForms {
            id
            name
            displayName
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

function DuplicateFormMenuItem() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { settingFormId } = useParams();
  const [duplicateSettingForm, { loading }] = useMutation(MUTATION);

  const onSelect = async () => {
    const variables = { settingFormId };
    const { data } = await duplicateSettingForm({ variables });
    const { settingForm, errors } = data.duplicateSettingForm;

    if (errors.length)
      return toast({
        variant: "destructive",
        description: errors[0].message,
        title: "Erro ao duplicar o formulário",
      });

    toast({
      title: "Formulário duplicado",
      description: "O formulário foi duplicado com sucesso!",
    });

    return navigate(`../${settingForm.id}/edit`);
  };

  return (
    <DropdownMenuItem onSelect={onSelect} disabled={loading}>
      <Text color="secondary">
        <Copy strokeWidth={3} className="mr-2 h-4 w-4 " />
      </Text>

      <Text weight="medium" color="secondary">
        Duplicar
      </Text>
    </DropdownMenuItem>
  );
}

export default DuplicateFormMenuItem;
