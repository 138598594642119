import React from "react";
import PropTypes from "prop-types";

import { MoreHorizontal, Pencil, Trash2, CopyPlus } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "DS/ui/dropdown-menu";

import { Button } from "DS/ui/button";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

function FormQuestionsMenu({ index, onEdit, onDelete, onDuplicate, createdByTemplate }) {
  const disabledDeleteMessage = createdByTemplate ? "Não é possível excluir perguntas em formulários padrão" : "";

  const disabledDuplicateMessage = createdByTemplate ? "Não é possível duplicar perguntas em formulários padrão" : "";

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button type="button" variant="ghost">
          <MoreHorizontal aria-label="Menu" className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="z-0">
        <DropdownMenuItem onSelect={() => onEdit(index)}>
          <Pencil className="mr-2 h-4 w-4" />
          Editar
        </DropdownMenuItem>

        <WrappedTooltip message={disabledDuplicateMessage}>
          <DropdownMenuItem onSelect={() => onDuplicate(index)} disabled={createdByTemplate}>
            <CopyPlus className="mr-2 h-4 w-4" />
            Duplicar
          </DropdownMenuItem>
        </WrappedTooltip>

        <DropdownMenuSeparator />

        <WrappedTooltip message={disabledDeleteMessage}>
          <DropdownMenuItem onSelect={() => onDelete(index)} disabled={createdByTemplate} className="text-red-600">
            <Trash2 className="mr-2 h-4 w-4" />
            Excluir
          </DropdownMenuItem>
        </WrappedTooltip>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

FormQuestionsMenu.propTypes = {
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  createdByTemplate: PropTypes.bool,
};

FormQuestionsMenu.defaultProps = {
  createdByTemplate: false,
};

export default FormQuestionsMenu;
