import PropTypes from "prop-types";

import useOwnerChecker from "./useOwnerChecker";

function OwnerChecker({ children }) {
  const { loading, owner } = useOwnerChecker();

  if (loading) return null;

  return owner ? children : null;
}

OwnerChecker.propTypes = { children: PropTypes.node.isRequired };

export default OwnerChecker;
