import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { useParams } from "react-router-dom";

import { Card, CardHeader, CardTitle, CardContent } from "DS/custom/card";
import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { RISK } from "Config/DataAndDocuments";

import EmptyList from "Organisms/UBuyer/Settings/Categories/EmptyList";

/** @internal */
export const QUERY = gql`
  query DocumentsList($buyerId: ID!, $settingCategoryId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingCategory(id: $settingCategoryId) {
        id
        settingRisks {
          id
          settingDocument {
            id
            type
            displayName
            settingGrouper {
              id
              displayName
            }
          }
          risk
        }
      }
    }
  }
`;

function DocumentsList({ settingCategoryId }) {
  const { buyerId } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { buyerId, settingCategoryId } });

  if (loading) return <GlobalLoading />;

  const { settingCategory } = data.buyer;
  const { settingRisks } = settingCategory;
  const itemsByGroup = _.groupBy(settingRisks, "settingDocument.settingGrouper.displayName");
  const itemsKeys = Object.keys(itemsByGroup);

  return (
    <div className="space-y-2">
      {itemsKeys.length ? null : (
        <Card className="border-slate-100">
          <CardContent>
            <EmptyList />
          </CardContent>
        </Card>
      )}
      {_.sortBy(itemsKeys).map((itemKey) => (
        <Card key={`groups-${itemKey}`} className="w-full border-slate-100">
          <CardHeader>
            <CardTitle>{itemKey}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2 divide-y">
              {itemsByGroup[itemKey].map(({ id, settingDocument, risk }) => (
                <div key={`item-${id}`} className="flex flex-row justify-between gap-3 pt-3 first:pt-0">
                  <Text
                    color="primary"
                    size="small"
                    weight="medium"
                    key={`primary-${id}`}
                    as="span"
                    className="truncate"
                  >
                    {settingDocument.displayName.split(" - %")[0]}

                    {settingDocument.type === "Settings::SettingForm" ? (
                      <Badge variant="secondary" className="ml-2 flex-none">
                        Enviado pelo fornecedor
                      </Badge>
                    ) : null}
                  </Text>

                  <Text
                    className={`${RISK[risk].color} min-w-fit text-end`}
                    size="small"
                    weight="medium"
                    key={`secondary-${id}`}
                  >
                    {RISK[risk].label}
                  </Text>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

DocumentsList.propTypes = { settingCategoryId: PropTypes.string.isRequired };

export default DocumentsList;
