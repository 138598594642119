import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { Box, Text, Form, FormField, Button } from "grommet";

import WorkflowsStepLayer from "Organisms/UBuyer/Settings/Workflows/WorkflowsStepLayer";
import WorkflowsDestroyButton from "Organisms/UBuyer/Settings/Workflows/WorkflowsDestroyButton";
import WorkflowsListSteps from "Organisms/UBuyer/Settings/Workflows/WorkflowsListSteps";

function WorkflowsForm({ initialValues, onSubmit, submitButtonLabel, cancelButtonLabel, removable, submitting }) {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [value, setValue] = useState(initialValues);
  const [editingIndex, setEditingIndex] = useState(null);
  const { settingWorkflowStepsAttributes } = value;

  const createStep = (stepValues) => {
    const steps = [...settingWorkflowStepsAttributes];
    const index = editingIndex !== null ? editingIndex : steps.length;

    steps[index] = stepValues;

    setValue({ ...value, settingWorkflowStepsAttributes: steps });
    setEditingIndex(null);
    setErrors({});
  };

  const destroyStep = () => {
    const steps = [...settingWorkflowStepsAttributes];

    if (steps[editingIndex]) {
      steps[editingIndex]._destroy = true;

      setValue({ ...value, settingWorkflowStepsAttributes: steps });
    }

    setEditingIndex(null);
  };

  const undoDestroyStep = (index) => {
    const steps = [...settingWorkflowStepsAttributes];

    steps[index]._destroy = false;

    setValue({ ...value, settingWorkflowStepsAttributes: steps });
    setErrors({});
  };

  const submitForm = (event) => {
    const parsedValue = { ...event.value };

    parsedValue.settingWorkflowStepsAttributes.forEach((step, index) => {
      const uniqueApproverIds = [...new Set(step.approverIds)];

      parsedValue.settingWorkflowStepsAttributes[index].approverIds = uniqueApproverIds;
    });

    const canSubmit = parsedValue.settingWorkflowStepsAttributes.filter(({ _destroy }) => !_destroy).length;

    if (canSubmit) onSubmit(parsedValue);
    else
      setErrors({
        settingWorkflowStepsAttributes: "Adicione ao menos uma alçada.",
      });
  };

  return (
    <>
      <Form
        onSubmit={submitForm}
        errors={errors}
        onChange={(nextValue) => setValue(nextValue)}
        value={value}
        messages={{ required: "O campo é obrigatório" }}
      >
        <Box gap="medium">
          <FormField
            name="displayName"
            a11yTitle="displayName"
            required
            autoFocus
            label={
              <Box wrap>
                <Text color="dark-1" weight="bold">
                  Nome do fluxo de aprovação
                </Text>

                <Box width={{ max: "large" }}>
                  <Text color="dark-3">Este será o nome de exibição do fluxo para o usuário.</Text>
                </Box>
              </Box>
            }
          />
        </Box>

        <Box margin={{ top: "medium" }} gap="small">
          <Text color="dark-1" margin="none" weight="bold">
            Alçadas de aprovação do fluxo
          </Text>

          <Box width={{ max: "large" }}>
            <Text color="dark-3">
              Crie alçadas de aprovação para este fluxo, e selecione os usuários aprovadores. Além do aprovador
              principal, você pode definir aprovadores secundários que podem substituir o principal em caso de ausência.
            </Text>
          </Box>

          <WorkflowsListSteps
            onClick={setEditingIndex}
            onRevert={undoDestroyStep}
            settingWorkflowStepsAttributes={settingWorkflowStepsAttributes}
          />
        </Box>

        <Box align="start" margin={{ top: "small" }}>
          <Button
            secondary
            label={<Text color="brand">Adicionar nova alçada</Text>}
            onClick={() => setEditingIndex(settingWorkflowStepsAttributes.length)}
          />
        </Box>

        {errors.settingWorkflowStepsAttributes ? (
          <Text color="status-critical">{errors.settingWorkflowStepsAttributes}</Text>
        ) : null}

        <Box direction="row" gap="medium" justify="center" margin={{ vertical: "large" }}>
          <Button primary type="submit" label={submitButtonLabel} disabled={submitting} />

          <Button label={<Text color="brand">{cancelButtonLabel}</Text>} onClick={() => navigate("../.")} />

          {removable ? <WorkflowsDestroyButton /> : null}
        </Box>
      </Form>

      {editingIndex !== null ? (
        <WorkflowsStepLayer
          initialValues={settingWorkflowStepsAttributes[editingIndex]}
          onClose={() => setEditingIndex(null)}
          onRemove={destroyStep}
          onSubmit={createStep}
        />
      ) : null}
    </>
  );
}

WorkflowsForm.defaultProps = {
  initialValues: {
    displayName: "",
    settingWorkflowStepsAttributes: [],
  },
  submitButtonLabel: "Criar fluxo de aprovação",
  cancelButtonLabel: "Cancelar criação",
  removable: false,
};

WorkflowsForm.propTypes = {
  initialValues: PropTypes.shape({
    displayName: PropTypes.string,
    settingWorkflowStepsAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  removable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default WorkflowsForm;
