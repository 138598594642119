import React from "react";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";
import { Anchor } from "DS/custom/anchor";
import Skeleton from "DS/custom/skeleton";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "DS/ui/accordion";

import { infoItem, infoPillar } from "Config/PUF";

import useCubeEsgRatingInfo from "Organisms/UBuyer/Companies/PUF/Hooks/useCubeEsgRatingInfo";

const SKELETON = (
  <div className="flex flex-1 flex-col gap-4">
    <Skeleton className="h-10 w-full" repeat={3} />
  </div>
);

const renderBadge = ({ text, variant }) => <Badge variant={variant}>{text}</Badge>;

function ESGRating() {
  const { loading, esgRating } = useCubeEsgRatingInfo();

  if (loading) return SKELETON;

  return (
    <div className="w-full lg:w-[740px]">
      <Accordion collapsible>
        <AccordionItem value="governance">
          <AccordionTrigger>
            <div className="mr-5 flex w-full justify-between">
              <Text weight="medium">Governança</Text>

              {renderBadge(infoPillar(esgRating["insights_esg.governance_pillar"]))}
            </div>
          </AccordionTrigger>

          <AccordionContent>
            <div className="flex w-full items-center gap-2">
              <div className="flex w-full items-center justify-between gap-4">
                <div className="flex flex-row gap-2">
                  <Text size="small">Combate à corrupção</Text>

                  {renderBadge(infoItem(esgRating["insights_esg.governance_item_corruption"]))}
                </div>

                <Anchor to="gov-corruption">Ver detalhes</Anchor>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="environmental">
          <AccordionTrigger>
            <div className="mr-5 flex w-full justify-between">
              <Text weight="medium">Ambiental</Text>

              {renderBadge(infoPillar(esgRating["insights_esg.environmental_pillar"]))}
            </div>
          </AccordionTrigger>

          <AccordionContent>
            <div className="flex w-full items-center justify-between gap-4">
              <div className="flex flex-row gap-2">
                <Text size="small">Conformidade ambiental</Text>

                {renderBadge(infoItem(esgRating["insights_esg.environmental_item_noncompliance"]))}
              </div>

              <Anchor to="env-compliance">Ver detalhes</Anchor>
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="social">
          <AccordionTrigger>
            <div className="mr-5 flex w-full justify-between">
              <Text weight="medium">Social</Text>

              {renderBadge(infoPillar(esgRating["insights_esg.social_pillar"]))}
            </div>
          </AccordionTrigger>

          <AccordionContent>
            <div className="mb-4 flex w-full items-center gap-2">
              <div className="flex w-full items-center justify-between gap-4">
                <div className="flex flex-row gap-2">
                  <Text size="small">Trabalho forçado ou análogo ao escravo</Text>

                  {renderBadge(infoItem(esgRating["insights_esg.social_item_forced_labor"]))}
                </div>

                <Anchor to="social-forced-labor">Ver detalhes</Anchor>
              </div>
            </div>

            <div className="mb-4 flex w-full items-center gap-2">
              <div className="flex w-full items-center justify-between gap-4">
                <div className="flex flex-row gap-2">
                  <Text size="small">Diversidade e igualdade de oportunidades</Text>

                  {renderBadge(infoItem(esgRating["insights_esg.social_item_dei"]))}
                </div>

                <Anchor to="social-dei">Ver detalhes</Anchor>
              </div>
            </div>

            <div className="mb-4 flex w-full items-center gap-2">
              <div className="flex w-full items-center justify-between gap-4">
                <div className="flex flex-row gap-2">
                  <Text size="small">Saúde e Segurança do Trabalho</Text>

                  {renderBadge(infoItem(esgRating["insights_esg.social_item_work_safety"]))}
                </div>

                <Anchor to="social-work-safety">Ver detalhes</Anchor>
              </div>
            </div>

            <div className="mb-4 flex w-full items-center gap-2">
              <div className="flex w-full items-center justify-between gap-4">
                <div className="flex flex-row gap-2">
                  <Text size="small">Não discriminação</Text>

                  {renderBadge(infoItem(esgRating["insights_esg.social_item_non_discrimination"]))}
                </div>

                <Anchor to="social-non-discrimination">Ver detalhes</Anchor>
              </div>
            </div>

            <div className="flex w-full items-center gap-2">
              <div className="flex w-full items-center justify-between gap-4">
                <div className="flex flex-row gap-2">
                  <Text size="small">Trabalho infantil</Text>

                  {renderBadge(infoItem(esgRating["insights_esg.social_item_child_labor"]))}
                </div>

                <Anchor to="social-child-labor">Ver detalhes</Anchor>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default ESGRating;
