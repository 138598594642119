import React from "react";
import { useParams } from "react-router-dom";

import { Heading } from "DS/custom/heading";

import LayoutTabs from "Organisms/UBuyer/Settings/Fields/LayoutTabs";
import LayoutSelect from "Organisms/UBuyer/Settings/Fields/LayoutSelect";

function FieldsHeader() {
  const { buyerId } = useParams();

  const ROUTES = [
    { key: "forms", value: "Campos do fornecedor", turbo: false },
    { key: `/buyers/${buyerId}/settings/fields/requester_fields`, value: "Campos do requisitante", turbo: true },
    { key: "buyer_documents", value: "Campos do comprador", turbo: false },
  ];

  return (
    <div className="pt-2 lg:pt-6 border-b">
      <Heading className="hidden lg:flex mb-4 px-4 lg:px-10">Campos</Heading>

      <LayoutTabs routes={ROUTES} />
      <LayoutSelect routes={ROUTES} />
    </div>
  );
}

export default FieldsHeader;
