import React from "react";
import PropTypes from "prop-types";
import { useParams, Navigate } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import Logo from "Assets/logo.svg";
import Placeholder from "Assets/signup-placeholder.jpg";

import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";

import LinkanaTerms from "Pages/User/LinkanaTerms";

/** @internal */
export const MUTATION = gql`
  mutation acceptInvite($token: String!) {
    acceptInvite(input: { token: $token }) {
      user {
        id
        invites {
          id
        }
        suppliers {
          id
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

function AcceptInvite({ user, invite }) {
  const { toast } = useToast();
  const { token } = useParams();
  const [acceptInvite, { loading }] = useMutation(MUTATION);

  if (!user) return <Navigate to="/" state={{ invitePending: true }} />;

  const onClick = async () => {
    const { data } = await acceptInvite({ variables: { token } });

    let variant = "default";
    let title = "Convite aceito com sucesso!";
    let description = "Conta vinculada, acesse para completar seu cadastro.";

    if (data.acceptInvite.errors.length) {
      variant = "destructive";
      title = "Erro ao aceitar o convite!";
      description = "O seu token de acesso está expirado ou inválido.";
    }

    toast({ title, description, variant });
    window.location.assign(`/suppliers/${invite.supplierId}`);
  };

  return (
    <div className="flex h-screen flex-row flex-wrap items-center justify-evenly gap-16 p-4 xl:p-0">
      <div className="flex flex-col items-center justify-center">
        <div className="flex max-w-md flex-1 flex-col sm:w-[420px]">
          <img
            alt="Buyer Sidebar Logo"
            src={invite.sidebarLogoUrl}
            className="h-20 max-w-[200px] object-contain object-left"
          />

          <Heading className="mb-1 mt-2">Você recebeu um convite!</Heading>

          <Text weight="medium" color="secondary">
            Você foi convidado pela {invite.buyerName} a enviar informações da empresa {invite.name}. Você aceita enviar
            essas informações?
          </Text>

          <div className="my-4 flex flex-col gap-3">
            <Button type="button" onClick={onClick} disabled={loading}>
              Aceitar o convite
            </Button>

            <Button
              type="button"
              variant="outline"
              onClick={() => window.location.assign("/suppliers?invite_ignored=true")}
            >
              Não aceitar agora
            </Button>
          </div>

          <LinkanaTerms />

          <div className="flex flex-row items-center gap-2">
            <img src={Logo} alt="Linkana Logo" className="h-7 w-7" />

            <Text color="secondary" size="xsmall">
              A {invite.buyerName} confia na ferramenta de cadastro e homologação de fornecedores da Linkana para
              simplificar e melhorar seus processos internos de gestão de fornecedores.
            </Text>
          </div>
        </div>
      </div>

      <div className="hidden max-w-xl py-8 xl:block">
        <img
          alt="Signup or Placeholder"
          src={invite.signupImgUrl || Placeholder}
          className="h-[calc(100vh-4rem)] rounded-3xl"
        />
      </div>
    </div>
  );
}

AcceptInvite.defaultProps = { user: null };

AcceptInvite.propTypes = {
  user: PropTypes.shape({}),
  invite: PropTypes.shape({
    url: PropTypes.string,
    buyerName: PropTypes.string,
    supplierId: PropTypes.string,
    name: PropTypes.string,
    signupImgUrl: PropTypes.string,
    sidebarLogoUrl: PropTypes.string,
  }).isRequired,
};

export default AcceptInvite;
