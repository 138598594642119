import React from "react";
import { Route, Routes } from "react-router-dom";

import PerformanceFormNew from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormNew";
import PerformanceFormInfo from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormInfo";
import PerformanceFormEdit from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormEdit";
import PerformanceFormsList from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormsList";

function PerformanceForms() {
  return (
    <Routes>
      <Route path="" element={<PerformanceFormsList />} />
      <Route path="new" element={<PerformanceFormNew />} />
      <Route path=":settingPerformanceFormId" element={<PerformanceFormInfo />} />
      <Route path=":settingPerformanceFormId/edit" element={<PerformanceFormEdit />} />
    </Routes>
  );
}

export default PerformanceForms;
