import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";

const QUERY = gql`
  query WebhooksSvix($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      svixApplicationUrl
    }
  }
`;

const CONTENT_STYLE = {
  display: "block",
  border: "none",
  height: "100vh",
  width: "100%",
};

const SKELETON = (
  <div className="flex flex-col gap-6">
    <div className="flex flex-row flex-wrap items-center justify-between gap-x-8 gap-y-4">
      <Skeleton className="min-h-[1500px] w-full" />
    </div>
  </div>
);

function Forms() {
  const { buyerId } = useParams();

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId },
  });

  if (loading) return SKELETON;

  return (
    <div className="flex flex-col">
      <iframe
        src={data.buyer.svixApplicationUrl}
        style={CONTENT_STYLE}
        allow="clipboard-write"
        loading="lazy"
        title="Svix Dashboard"
        allowtransparency="true"
      />
    </div>
  );
}

export default Forms;
