import React from "react";
import { useParams } from "react-router-dom";

import { AlignJustify } from "lucide-react";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { DropdownMenu, DropdownMenuItem, DropdownMenuContent, DropdownMenuTrigger } from "DS/ui/dropdown-menu";

import { Button } from "DS/ui/button";
import Skeleton from "DS/custom/skeleton";

import AbilityChecker from "Organisms/Shared/Authorization/AbilityChecker";
import WebhookDialog from "Organisms/UBuyer/SupplierHeader/Webhook/WebhookDialog";
import ToggleNotification from "Organisms/UBuyer/SupplierHeader/ToggleNotification";
import BlockSupplier from "Organisms/UBuyer/SupplierHeader/BlockSupplier/BlockSupplier";
import ShareSupplierDialog from "Organisms/UBuyer/SupplierHeader/Shareable/ShareSupplierDialog";
import RegularizationDialog from "Organisms/UBuyer/SupplierHeader/Regularization/RegularizationDialog";
import ChangeSettingCategory from "Organisms/UBuyer/SupplierHeader/ChangeSettingCategory/ChangeSettingCategory";
import DestroySupplierAlertDialog from "Organisms/UBuyer/SupplierHeader/DestroySupplier/DestroySupplierAlertDialog";

/** @internal */
export const QUERY = gql`
  query SimpleMenu($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      following
      shareableUrl
      buyer {
        id
        n8nWebhook
        svixApplicationKey
      }
    }
  }
`;

function SimpleMenu() {
  const { id } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { id } });

  if (loading) return <Skeleton className="h-10 w-10" />;

  const { supplier } = data;
  const { buyer } = supplier;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button type="button" variant="outline">
          <AlignJustify aria-label="Menu" className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="z-0">
        <ShareSupplierDialog supplierId={supplier.id} />

        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ToggleNotification />
        </DropdownMenuItem>

        <AbilityChecker ability="reviewSupplier">
          <RegularizationDialog />
        </AbilityChecker>

        {buyer.n8nWebhook || buyer.svixApplicationKey ? (
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <WebhookDialog />
          </DropdownMenuItem>
        ) : null}

        <AbilityChecker ability="reviewSupplier">
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <BlockSupplier />
          </DropdownMenuItem>
        </AbilityChecker>

        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ChangeSettingCategory />
        </DropdownMenuItem>

        <div className="my-1 border-t" />

        <AbilityChecker ability="destroySupplier">
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <DestroySupplierAlertDialog dropdownMenuItem />
          </DropdownMenuItem>
        </AbilityChecker>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default SimpleMenu;
