import React from "react";
import PropTypes from "prop-types";

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";

function WrappedTooltip({ children, message }) {
  if (!message) return children;

  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild>
          <div>{children}</div>
        </TooltipTrigger>

        <TooltipContent>{message}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

WrappedTooltip.defaultProps = { message: "" };

WrappedTooltip.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.number]),
  children: PropTypes.node.isRequired,
};

export { WrappedTooltip }; // eslint-disable-line import/prefer-default-export
