import React from "react";

import Skeleton from "DS/custom/skeleton";

export const ACTIVE = "Ativa";
const GREEN = "green";
const RED = "red";
const YELLOW = "yellow";

export const setBadgeTextColorPending = (length) => {
  const textAndVariant = {
    clear: { variant: GREEN, text: "Sem pendências" },
    notClear: { variant: RED, text: "Pendências encontradas" },
  };

  return length === 0 ? textAndVariant.clear : textAndVariant.notClear;
};

export const setBadgeTextColorRating = (cubeArray, key) => {
  const rating = Math.min(...cubeArray.map((object) => object[key]));

  const textAndVariant = {
    0: { text: "Pendências graves", variant: RED },
    5: { text: "Pendências médias", variant: YELLOW },
    default: { text: "Sem pendências", variant: GREEN },
  };

  return textAndVariant[rating] || textAndVariant.default;
};

export const infoRating = (rating) => {
  let description = "Crítico";
  let badgeVariant = "red";
  let backgroundColor = "[&>*]:bg-red-400";
  let ratingColor = "text-red-400";
  let lightBackgroundColor = "bg-red-200";

  if (rating >= 4) {
    description = "Preocupante";
    badgeVariant = "orange";
    backgroundColor = "[&>*]:bg-yellow-400";
    ratingColor = "text-yellow-400";
    lightBackgroundColor = "bg-yellow-200";
  }

  if (rating >= 7) {
    description = "Bom";
    badgeVariant = "green";
    backgroundColor = "[&>*]:bg-lime-400";
    ratingColor = "text-lime-400";
    lightBackgroundColor = "bg-lime-200";
  }

  if (rating >= 9) {
    description = "Excelente";
    badgeVariant = "green";
    backgroundColor = "[&>*]:bg-green-400";
    ratingColor = "text-green-400";
    lightBackgroundColor = "bg-green-200";
  }

  return {
    description,
    badgeVariant,
    backgroundColor,
    ratingColor,
    lightBackgroundColor,
  };
};

export const SKELETON_DETAILS = (
  <div>
    <Skeleton className="mb-4 h-6 w-72" />
    <div className="flex items-start pb-6">
      <Skeleton className="mr-4 h-32 w-[349px]" />
      <div className="flex flex-1 flex-col gap-2">
        <Skeleton className="mb-2 h-24 w-full" repeat={2} />
      </div>
    </div>
  </div>
);

export const infoPillar = (rating) => {
  const intRating = parseInt(rating, 10);

  return intRating === 10 ? { text: "Sem pendências", variant: GREEN } : { text: "Com pendências", variant: RED };
};

export const infoItem = (rating) => {
  let text = "Sem pendências";
  let variant = GREEN;

  const intRating = parseInt(rating, 10);

  if (intRating === 0) {
    text = "Pendências graves";
    variant = RED;
  } else if (intRating === 5) {
    text = "Pendências médias";
    variant = YELLOW;
  }

  return { text, variant };
};
