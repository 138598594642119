import React from "react";
import PropTypes from "prop-types";

import { Badge } from "DS/custom/badge";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

function UserSettingGroupersBadges({ settingGroupers }) {
  const visibleBadges = settingGroupers.slice(0, 2);
  const hiddenBadges = settingGroupers.slice(2);

  const message = hiddenBadges.map((settingGrouper) => settingGrouper.displayName).join(" | ");

  return (
    <div className="flex flex-row gap-2">
      {visibleBadges.map((settingGrouper) => (
        <Badge variant="secondary" key={settingGrouper.id}>
          <div className="max-w-[180px] truncate">{settingGrouper.displayName}</div>
        </Badge>
      ))}

      {hiddenBadges.length ? (
        <WrappedTooltip message={message}>
          <Badge className="max-w-[180px] truncate">+{hiddenBadges.length}</Badge>
        </WrappedTooltip>
      ) : null}
    </div>
  );
}

export default UserSettingGroupersBadges;

UserSettingGroupersBadges.propTypes = {
  settingGroupers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ).isRequired,
};
