import React from "react";

import ESG from "Organisms/UBuyer/Companies/PUF/Analysis/ESG";

function Analysis() {
  return (
    <div>
      <ESG />
    </div>
  );
}

export default Analysis;
