import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { Copy } from "lucide-react";

import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";
import { DropdownMenuItem } from "DS/ui/dropdown-menu";

/** @internal */
export const MUTATION = gql`
  mutation DuplicateCategoryButton($id: ID!) {
    duplicateSettingCategory(input: { id: $id }) {
      errors {
        path
        message
      }
      settingCategory {
        id
        displayName
        buyer {
          id
          settingCategoriesEnabled {
            id
            name
            updatedAt
            displayName
            legalEntity
          }
        }
      }
    }
  }
`;

function DuplicateCategoryButton({ settingCategoryId }) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [mutation] = useMutation(MUTATION);

  const onDuplicateCategory = async () => {
    const { data } = await mutation({ variables: { id: settingCategoryId } });
    const { settingCategory, errors } = data.duplicateSettingCategory;

    if (errors.length) {
      toast({
        variant: "destructive",
        description: errors[0].message,
        title: "Erro ao duplicar categoria",
      });
    } else {
      toast({
        title: "Categoria duplicada",
        description: "A categoria foi duplicada com sucesso",
      });

      navigate(`../${settingCategory.id}/edit`);
    }
  };

  return (
    <DropdownMenuItem onSelect={onDuplicateCategory}>
      <Text color="secondary">
        <Copy strokeWidth={3} className="mr-2 h-4 w-4 " />
      </Text>
      <Text weight="medium" color="secondary">
        Duplicar
      </Text>
    </DropdownMenuItem>
  );
}

DuplicateCategoryButton.propTypes = {
  settingCategoryId: PropTypes.string.isRequired,
};

export default DuplicateCategoryButton;
