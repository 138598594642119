import React from "react";

import { Text } from "DS/custom/text";

import WithoutSearchResultsSVG from "Assets/puf/without-search-hits.svg";

function EmptySearch() {
  return (
    <div className="mx-auto mt-8 max-w-[798px] text-center">
      <img src={WithoutSearchResultsSVG} className="mx-auto mb-10" alt="Without search results icon" />

      <Text weight="bold">Nenhuma homologação foi encontrada.</Text>
    </div>
  );
}

export default EmptySearch;
