import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { X, LogOut, Settings, AlignJustify } from "lucide-react";

import * as Dialog from "@radix-ui/react-dialog";

import Logo from "Assets/puf/logo.svg";

import logout from "Services/Authentication/Logout";

function Navbar({ buttons, buyer }) {
  const navigate = useNavigate();
  const settings = `/settings/${buyer.id}`;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="lg:hidden">
      <Dialog.Root open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-600 sm:bg-opacity-75 lg:hidden" />

          <Dialog.Content className="lg:hidden">
            <div className="fixed inset-0 z-40 flex h-full w-full flex-col justify-between bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg">
              <div>
                <div className="flex h-16 items-center justify-between px-4 sm:px-6">
                  <img alt="Linkana" className="block h-8 w-auto" src={buyer.logoUrl || Logo} />

                  <button
                    type="button"
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  >
                    <span className="sr-only">Close main menu</span>
                    <X className="block h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="max-w-8xl mx-auto my-6 flex flex-col gap-6 px-2 sm:px-4">
                  {buttons.map((button) => {
                    const { path, label, Icon, turbo } = button;

                    return (
                      <button
                        key={label}
                        type="button"
                        className="flex flex-row items-center gap-2 px-4 text-start text-sm font-medium text-gray-700"
                        onClick={() => {
                          if (turbo) {
                            window.location = path;
                          } else {
                            navigate(path);
                            setMobileMenuOpen(false);
                          }
                        }}
                      >
                        <Icon size={14} /> {label}
                      </button>
                    );
                  })}

                  <div className="w-full border-t" />

                  <button
                    type="button"
                    className="flex flex-row items-center gap-2 px-4 text-start text-sm font-medium text-gray-700"
                    onClick={() => {
                      navigate(`${settings}/general`);
                      setMobileMenuOpen(false);
                    }}
                  >
                    <Settings size={14} /> Configurações
                  </button>

                  <div className="w-full border-t" />

                  <button
                    type="button"
                    onClick={logout}
                    className="flex flex-row items-center gap-2 px-4 text-start text-sm font-medium text-gray-700"
                  >
                    <LogOut size={14} /> Sair
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>

        <div className="flex items-center justify-between bg-white px-4 py-2 drop-shadow-md sm:px-6 lg:px-8">
          <div>
            <img className="h-8 w-auto" src={buyer.logoUrl || Logo} alt="Linkana" />
          </div>

          <Dialog.Trigger asChild>
            <button
              type="button"
              className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md bg-white text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open sidebar</span>
              <AlignJustify className="h-6 w-6 text-gray-500" aria-hidden="true" />
            </button>
          </Dialog.Trigger>
        </div>
      </Dialog.Root>
    </div>
  );
}

Navbar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  buyer: PropTypes.shape({
    id: PropTypes.string,
    logoUrl: PropTypes.string,
  }).isRequired,
};

export default Navbar;
