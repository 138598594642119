const dateToString = (str, detailed = false) => {
  if (!str) return "";

  const date = new Date(str);

  const year = date.getFullYear();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  let formattedDate = `${day}/${month}/${year}`;

  if (detailed) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    formattedDate += `, ${hours}:${minutes}`;
  }

  return formattedDate;
};

// 20220130 -> 30/01/2022
const formatDate = (str, detailed = false) => {
  if (!str) return "";

  if (str.length !== 8) return dateToString(str, detailed);

  const day = str.substring(6, 8);
  const year = str.substring(0, 4);
  const month = str.substring(4, 6);

  return `${day}/${month}/${year}`;
};

export default formatDate;
