import React from "react";
import PropTypes from "prop-types";

import formatDate from "Services/DateToString";

import { Text } from "DS/custom/text";

function Shareholder({ shareholder }) {
  if (!shareholder) return null;

  const { info } = shareholder;

  return (
    <div>
      <Text>{info.nome}</Text>

      <p className="text-sm font-medium text-gray-500">
        {info.descricaoQualificacaoSocio} desde {formatDate(info.dataEntradaSociedade)}
      </p>
    </div>
  );
}

Shareholder.defaultProps = { shareholder: null };

Shareholder.propTypes = {
  shareholder: PropTypes.shape({
    cnpjOrCpfNome: PropTypes.string,
    info: PropTypes.shape({
      nome: PropTypes.string,
      cpfNome: PropTypes.string,
      dataEntradaSociedade: PropTypes.string,
      descricaoQualificacaoSocio: PropTypes.string,
    }),
  }),
};

export default Shareholder;
