import React, { useState, createContext } from "react";
import PropTypes from "prop-types";

import fetchSupplierFilter from "Services/FetchSupplierFilter";

export const Context = createContext();

function SupplierSearchContext({ children }) {
  const savedFilters = fetchSupplierFilter();
  const [search, setSearch] = useState(savedFilters);

  const setAndSaveSearch = (params) => {
    setSearch(params);
    sessionStorage.setItem("supplierFilter", JSON.stringify(params));
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ search, setSearch: setAndSaveSearch }}>{children}</Context.Provider>
  );
}

SupplierSearchContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SupplierSearchContext;
