import React from "react";
import { useOutletContext } from "react-router-dom";

import { AlertCircle } from "lucide-react";

import { useCubeQuery } from "@cubejs-client/react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "DS/ui/accordion";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

import { infoPillar, SKELETON_DETAILS } from "Config/PUF";
import formatCubeInfo from "Services/PUFFormatter";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";

/** @internal */
export const accordionName = (rating) =>
  rating ? "A empresa possui capital social mínimo ou não é obrigatória." : "Empresa não possui capital social mínimo.";

function LaborMinShareDetails() {
  const { identifier } = useOutletContext();

  const info = "LaborMinShare.info";
  const member = "LaborMinShare.cnpj";
  const rating = "LaborMinShare.labor_item_min_share";

  const { resultSet } = useCubeQuery({
    dimensions: [info, rating],
    filters: [{ member, operator: "equals", values: [identifier] }],
  });

  if (!resultSet) return SKELETON_DETAILS;

  const data = resultSet.rawData();

  const cubeInfo = data.map((item) => JSON.parse(item[info]));

  const cube = data[0] || {};

  const { variant, text } = infoPillar(cube[rating]);

  return (
    <div>
      <Breadcrumb firstPage="Análise Linkana" lastPage="Capital social mínimo" currentPage="Detalhes" />

      <div className="flex-start mt-4 flex flex-wrap content-start justify-start gap-8 py-4 lg:flex-nowrap">
        <div className="flex max-w-[349px] shrink-0 flex-col gap-2 self-start rounded-lg border border-slate-200 p-6">
          <div className="flex flex-row gap-4 ">
            <div className="flex flex-wrap gap-2">
              <Text size="small" weight="medium">
                Capital social mínimo
              </Text>

              <Badge variant={variant}>{text}</Badge>
            </div>
          </div>
          <Text size="small">Necessidade de possuir capital social mínimo.</Text>
        </div>

        <div className="flex-start flex w-full flex-col">
          {cubeInfo.length ? (
            cubeInfo.map((obj, index) => {
              const keys = Object.keys(obj);
              const uniqueKey = Object.values(obj);

              return (
                <Accordion collapsible key={uniqueKey} defaultValue="obj-0">
                  <AccordionItem value={`obj-${index}`}>
                    <AccordionTrigger>
                      <Text size="small" weight="medium">
                        {accordionName(cube[rating])}
                      </Text>
                    </AccordionTrigger>

                    <AccordionContent>
                      <div className="flex flex-col gap-3">
                        {keys.map((key) => (
                          <div key={key} className="flex flex-col gap-y-1">
                            <Text size="small">{key}</Text>

                            <Text size="small" weight="medium">
                              {formatCubeInfo(obj[key])}
                            </Text>
                          </div>
                        ))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              );
            })
          ) : (
            <div className="flex h-full flex-row items-center gap-2">
              <AlertCircle size={26} />

              <Text size="small">Não foram encontradas problemas relacionados a este item.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LaborMinShareDetails;
