import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "DS/ui/use-toast";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import FormNewForm from "Organisms/UBuyer/Settings/BuyerDocuments/FormNewForm";

/** @internal */
export const MUTATION = gql`
  mutation FormNew(
    $buyerId: ID!
    $hint: String!
    $displayName: String!
    $risk: Int!
    $options: JSON
    $fieldType: String!
    $name: String
  ) {
    createSettingBuyerDocument(
      input: {
        hint: $hint
        risk: $risk
        name: $name
        origin: "PREDEFINED"
        options: $options
        buyerId: $buyerId
        fieldType: $fieldType
        displayName: $displayName
      }
    ) {
      settingBuyerDocument {
        id
        buyer {
          id
          settingBuyerDocumentsPredefined {
            id
            displayName
            risk
            fieldType
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

function FormNew() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [createSettingBuyerDocument, { loading }] = useMutation(MUTATION);

  const onSubmit = async (values) => {
    const variables = { ...values, buyerId };

    const { data } = await createSettingBuyerDocument({ variables });
    const { errors } = data.createSettingBuyerDocument;

    if (errors.length) {
      toast({
        variant: "destructive",
        description: errors[0].message,
        title: "Erro ao criar documento!",
      });
    } else {
      toast({ title: "Documento criado com sucesso!" });
      navigate("./..");
    }
  };

  return (
    <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
      <Breadcrumb lastPage="Campos do comprador" currentPage="Novo documento" />

      <FormNewForm onSubmit={onSubmit} submitting={loading} />
    </div>
  );
}

export default FormNew;
