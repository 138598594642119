import React from "react";
import { useNavigate } from "react-router-dom";

import { PlusCircle } from "lucide-react";

import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "DS/ui/table";

import EmptyTableSVG from "Assets/landing/empty-table-min.svg";
import AbilityChecker from "Organisms/Shared/Authorization/AbilityChecker";

function EmptyTable() {
  const navigate = useNavigate();

  return (
    <div className="rounded-md border border-slate-200">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Empresa</TableHead>
            <TableHead>Categoria</TableHead>
            <TableHead>Etapa</TableHead>
            <TableHead>Características</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <div className="m-auto flex max-w-xl flex-col items-center gap-8 p-6 text-center">
                <img src={EmptyTableSVG} className="m-auto w-80" alt="Nenhum fornecedor encontrado" />

                <div>
                  <Text weight="medium">Faça sua primeira homologação na Linkana</Text>

                  <Text>
                    Você ainda não possui nenhum fornecedor na Linkana. Clique no botão abaixo e inicie sua primeira
                    homologação com um fornecedor.
                  </Text>
                </div>

                <AbilityChecker ability="addSupplier">
                  <div>
                    <Button type="button" onClick={() => navigate("new")}>
                      <PlusCircle className="mr-2 h-4 w-4" /> Iniciar minha primeira homologação
                    </Button>
                  </div>
                </AbilityChecker>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

export default EmptyTable;
