import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

/** @internal */
export const GET = gql`
  query useAbilityChecker {
    user {
      id
      iam {
        reviewDoc
        addSupplier
        startWorkflow
        reviewSupplier
        destroySupplier
        addBuyerDocument
        addPerformanceForm
        purchaseSupplierProfile
      }
    }
  }
`;

const useAbilityChecker = () => {
  const { loading, data } = useQuery(GET);

  if (loading) return { loading: true, abilities: {} };

  const abilities = data.user ? data.user.iam : {};

  return { loading: false, abilities };
};

export default useAbilityChecker;
