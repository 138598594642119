import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "DS/ui/tabs";
import Skeleton from "DS/custom/skeleton";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import AddUserButton from "Organisms/UBuyer/Settings/Users/AddUserButton";

import UsersTable from "Organisms/UBuyer/Settings/Users/UsersTable";
import PendingBuyerInvites from "Organisms/UBuyer/Settings/Users/PendingBuyerInvites";

/** @internal */
export const QUERY = gql`
  query users($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      name
      idpConnection
      users {
        id
        name
        email
        owner
        photoUrl
        settingGroupers {
          id
          displayName
        }
      }
      buyerInvitesSent {
        id
        email
        link
        createdAtText
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-1">
    <Skeleton className="h-6 w-80" />
    <Skeleton className="h-[72px] w-[543px]" />
    <Skeleton className="h-6 w-96" repeat={6} />
  </div>
);

function Users() {
  const { buyerId } = useParams();

  const { loading, data, refetch } = useQuery(QUERY, {
    variables: { buyerId },
  });

  if (loading) return SKELETON;

  const { users, buyerInvitesSent, idpConnection } = data.buyer;

  return (
    <div className="flex flex-col">
      <Heading className="hidden lg:flex space-y-1 px-4 py-2 lg:py-6 lg:px-10 border-b">Usuários</Heading>

      <div className="p-4 lg:px-10 lg:py-10 space-y-6">
        <Tabs defaultValue="users">
          <TabsList className="mb-1">
            <TabsTrigger value="users">
              Usuários ativos
              <Badge variant="secondary" className="ml-2">
                {users.length}
              </Badge>
            </TabsTrigger>

            <TabsTrigger value="invited">
              Convites pendentes
              <Badge variant="secondary" className="ml-2">
                {buyerInvitesSent.length}
              </Badge>
            </TabsTrigger>
          </TabsList>

          <TabsContent value="users">
            <div className="flex flex-col-reverse gap-2 lg:flex-row lg:justify-between">
              <Text weight="medium" className="mb-4 lg:mt-6">
                Listagem de usuários
              </Text>
              <OwnerChecker>
                <AddUserButton idpConnection={idpConnection} />
              </OwnerChecker>
            </div>
            <UsersTable users={users} refetch={refetch} />
          </TabsContent>

          <TabsContent value="invited">
            <PendingBuyerInvites buyerInvitesSent={buyerInvitesSent} refetch={refetch} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}

export default Users;
