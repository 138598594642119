import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { Text } from "DS/custom/text";
import { CurrencyInput } from "DS/ui/currencyInput";

import { FormItem, FormLabel, FormControl, FormDescription, FormMessage } from "DS/ui/form";

const CurrencyBuyerDocumentField = forwardRef(({ formField, name, value, onChange }, ref) => (
  <FormItem>
    <FormLabel className="font-medium">
      {formField.displayName}
      {formField.optional ? " (opcional)" : ""}
    </FormLabel>
    <FormControl>
      <CurrencyInput
        ref={ref}
        prefix="R$ "
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        name={name}
        value={value}
        onChange={onChange}
      />
    </FormControl>
    <FormDescription>
      <Text as="span" size="small">
        {formField.hint}
      </Text>
    </FormDescription>
    <FormMessage />
  </FormItem>
));

CurrencyBuyerDocumentField.defaultProps = {
  value: undefined,
};

CurrencyBuyerDocumentField.propTypes = {
  formField: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    hint: PropTypes.string,
    optional: PropTypes.bool,
  }).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CurrencyBuyerDocumentField;
