import React from "react";
import PropTypes from "prop-types";

import { Text, Tip, Box } from "grommet";

const VALID_POSITIONS = ["top", "bottom", "right", "left"];

const POSITION = {
  top: { align: { bottom: "top" } },
  bottom: { align: { top: "bottom" } },
  right: { align: { left: "right" } },
  left: { align: { right: "left" } },
};

function BlackTip({ children, content, margin, position, disabled }) {
  if (disabled) return <Box>{children}</Box>;

  if (VALID_POSITIONS.includes(position)) {
    return (
      <Tip
        plain
        content={
          <Box
            pad={{ vertical: "xsmall", horizontal: "small" }}
            round="xsmall"
            background="dark-1"
            responsive={false}
            margin={margin}
          >
            <Text size="small" style={{ maxWidth: 360 }}>
              {content}
            </Text>
          </Box>
        }
        dropProps={POSITION[position]}
      >
        <Box>{children}</Box>
      </Tip>
    );
  }

  return null;
}

BlackTip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  margin: PropTypes.node,
  position: PropTypes.string,
  disabled: PropTypes.bool,
};

BlackTip.defaultProps = {
  margin: "",
  position: "bottom",
  disabled: false,
};

export default BlackTip;
