import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

import Auth from "Services/Auth";

function RequireAuth({ Component }) {
  if (Auth.isAuthenticated()) return Component;

  return <Navigate to="/" />;
}

RequireAuth.propTypes = { Component: PropTypes.node.isRequired };

export default RequireAuth;
