import React from "react";

import CNAEs from "Organisms/UBuyer/Companies/PUF/InfoCNPJ/CNAEs";
import CardCNPJ from "Organisms/UBuyer/Companies/PUF/InfoCNPJ/CardCNPJ";
import Branches from "Organisms/UBuyer/Companies/PUF/InfoCNPJ/Branches/Branches";
import Shareholders from "Organisms/UBuyer/Companies/PUF/InfoCNPJ/Shareholders/Shareholders";

function InfoCNPJ() {
  return (
    <div>
      <CardCNPJ />
      <CNAEs />
      <Shareholders />
      <Branches />
    </div>
  );
}

export default InfoCNPJ;
