import React from "react";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

const TERMS = "https://www.linkana.com/termos-de-uso";
const PRIVACY = "https://www.linkana.com/politica-de-privacidade";

function LinkanaTerms() {
  return (
    <Text size="small" className="mb-12 mt-4">
      Ao continuar você declara que leu e concorda com os{" "}
      <Anchor href={TERMS} target="_blank">
        Termos de Uso
      </Anchor>{" "}
      e a{" "}
      <Anchor href={PRIVACY} target="_blank">
        Política de Privacidade
      </Anchor>{" "}
      da Linkana.
    </Text>
  );
}

export default LinkanaTerms;
