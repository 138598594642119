import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectItem } from "DS/ui/select";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

/** @internal */
export const QUERY = gql`
  query SettingGrouperFormField($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingGroupers {
        id
        displayName
        selectable
      }
    }
  }
`;

function SettingGrouperFormField({ form }) {
  const { buyerId } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return null;

  const { settingGroupers } = data.buyer;

  return (
    <FormField
      control={form.control}
      name="settingGrouperId"
      render={({ field }) => (
        <FormItem className="flex flex-row gap-8">
          <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
            <FormLabel className="font-medium">Grupo de revisão</FormLabel>

            <FormControl>
              <Select id="settingGrouperId" onValueChange={field.onChange} defaultValue={field.value}>
                <SelectTrigger>
                  <SelectValue placeholder="Selecione um grupo" />
                </SelectTrigger>

                <SelectContent>
                  <SelectGroup>
                    {settingGroupers.map(({ id, displayName, selectable }) => {
                      if (id === field.value) {
                        return (
                          <SelectItem key={id} value={id} selected>
                            {displayName}
                          </SelectItem>
                        );
                      }

                      if (!selectable) return null;

                      return (
                        <SelectItem key={id} value={id}>
                          {displayName}
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>

            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
}

SettingGrouperFormField.propTypes = {
  form: PropTypes.shape({ control: PropTypes.shape() }).isRequired,
};

export default SettingGrouperFormField;
