import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Building } from "lucide-react";

import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Badge } from "DS/custom/badge";
import { Card, CardContent } from "DS/custom/card";

import { formatCnpj } from "Services/RFFormatter";
import highlightWords from "Services/Highlight";

import { ACTIVE } from "Config/PUF";

function AddPJSupplierHIT({ branch, term }) {
  const { cnpj, razaoSocial, nomeFantasia, situacao } = branch;
  const navigate = useNavigate();

  const onSelectDocument = (doc) => {
    const params = new URLSearchParams({
      name: doc.razaoSocial,
      identifier: String(doc.cnpj).padStart(14, "0"),
    });

    navigate(`form?${String(params)}`);
  };

  return (
    <Card>
      <CardContent className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-1">
          <Text
            weight="medium"
            dangerouslySetInnerHTML={{
              __html: highlightWords(razaoSocial, term.toUpperCase()) || razaoSocial,
            }}
          />

          {nomeFantasia ? (
            <Text
              className="text-slate-700"
              dangerouslySetInnerHTML={{
                __html: highlightWords(nomeFantasia, term.toUpperCase()) || nomeFantasia,
              }}
            />
          ) : (
            <Text className="text-slate-500">Sem nome fantasia cadastrado</Text>
          )}

          <div className="flex flex-row items-center gap-2 divide-x">
            <div className="flex flex-row gap-2 pr-2">
              <Building className="h-5 w-5 text-slate-400" />

              <Text
                dangerouslySetInnerHTML={{
                  __html: term === cnpj ? highlightWords(cnpj, term.toUpperCase()) : formatCnpj(cnpj),
                }}
              />
            </div>

            <Badge variant={situacao === ACTIVE ? "green" : "red"}>{situacao}</Badge>
          </div>
        </div>

        <Button type="button" variant="outline" className="flex-none" onClick={() => onSelectDocument(branch)}>
          Selecionar fornecedor
        </Button>
      </CardContent>
    </Card>
  );
}

AddPJSupplierHIT.defaultProps = { term: "" };

AddPJSupplierHIT.propTypes = {
  term: PropTypes.string,
  branch: PropTypes.shape({
    cnpj: PropTypes.string,
    situacao: PropTypes.string,
    razaoSocial: PropTypes.string,
    nomeFantasia: PropTypes.string,
  }).isRequired,
};

export default AddPJSupplierHIT;
