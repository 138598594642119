import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { Table, TableRow, TableBody, TableCell, TableHead, TableHeader } from "DS/ui/table";

import formatDate from "Services/DateToString";
import { formatCnpj, formatCpfNome } from "Services/RFFormatter";

/** @internal */
export const QUERY = gql`
  query AllShareholders($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      shareholders {
        cnpjOrCpfNome
        info {
          nome
          cpfNome
          dataEntradaSociedade
          descricaoQualificacaoSocio
        }
      }
    }
  }
`;

const SKELETON = (
  <div>
    <Skeleton className="mb-2 h-4 w-52" />
    <Skeleton className="my-2 h-4 w-36" />
    <div className="flex flex-wrap items-center gap-4">
      <Skeleton className="h-24 w-96" repeat={6} />
    </div>
  </div>
);

function PUFShareholdersList() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { shareholders } = data.supplier;

  return (
    <div>
      <Breadcrumb levels={["Dados CNPJ", "Quadro societário"]} />

      <div className="my-4 flex flex-col gap-2">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="min-w-[200px]">
                <Text size="small" weight="medium">
                  Sócio
                </Text>
              </TableHead>

              <TableHead>
                <Text size="small" weight="medium">
                  CPF ou CNPJ
                </Text>
              </TableHead>

              <TableHead>
                <Text size="small" weight="medium">
                  Função
                </Text>
              </TableHead>

              <TableHead>
                <Text size="small" weight="medium">
                  Data de início no cargo
                </Text>
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {shareholders.map((shareholder) => {
              const { info, cnpjOrCpfNome } = shareholder;
              const formatFunction = info.cpfNome ? formatCpfNome : formatCnpj;

              return (
                <TableRow key={info.nome}>
                  <TableCell>
                    <Text size="small">{info.nome}</Text>
                  </TableCell>

                  <TableCell>
                    <Text size="small">{formatFunction(cnpjOrCpfNome)}</Text>
                  </TableCell>

                  <TableCell>
                    <Text size="small">{info.descricaoQualificacaoSocio}</Text>
                  </TableCell>

                  <TableCell>
                    <Text size="small">{formatDate(info.dataEntradaSociedade)}</Text>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default PUFShareholdersList;
