const { format } = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const formatCurrency = (value) => {
  if (value !== 0 && !value) return "";

  const parsedValue = value.toString().replace(/[^\d-]/g, ".");

  return format(parsedValue);
};

export default formatCurrency;
