import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Popover, PopoverContent, PopoverTrigger } from "DS/ui/popover";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";
import Skeleton from "DS/custom/skeleton";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

/** @internal */
export const QUERY = gql`
  query CategoriesListAnchor($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      state
      stateI18n
      displayCategory
      companySuppliers {
        id
        state
        stateI18n
        displayCategory
      }
    }
  }
`;

const tooltipMessage = (companySuppliers) => {
  if (companySuppliers.length === 1) return "Fornecedor só está cadastrado nesta categoria";

  return "";
};

function CategoriesListAnchor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return <Skeleton className="h-4 w-52" />;

  const { supplier } = data;
  const { companySuppliers } = supplier;
  const message = tooltipMessage(companySuppliers);

  return (
    <div>
      <div className="flex flex-row items-center gap-3">
        <Text size="small" color="secondary">
          Categoria: {supplier.displayCategory}
        </Text>

        <Popover>
          <WrappedTooltip message={message}>
            <PopoverTrigger asChild>
              <Anchor disabled={!!message}>
                <span>Exibir demais categorias</span>
              </Anchor>
            </PopoverTrigger>
          </WrappedTooltip>
          <PopoverContent>
            <div className="space-y-2 divide-y">
              {companySuppliers.map((companySupplier) => (
                <div key={companySupplier.id} className="pt-2 first:pt-0">
                  <Anchor onClick={() => navigate(`/companies/${companySupplier.id}`)}>
                    {companySupplier.displayCategory}
                  </Anchor>

                  <Text size="small" color="secondary">
                    {companySupplier.stateI18n}
                  </Text>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

export default CategoriesListAnchor;
