import React from "react";

import { Progress } from "DS/ui/progress";
import Skeleton from "DS/custom/skeleton";

import { Text } from "DS/custom/text";

import useCubeEsgRating from "Organisms/UBuyer/Companies/PUF/Hooks/useCubeEsgRating";

import { infoRating } from "Config/PUF";

const SKELETON = (
  <div className="w-96">
    <Skeleton className="ml-72 mt-[-25px] h-6 w-24" />
    <Skeleton className="mt-1 h-3 w-96" />
    <Skeleton className="mt-4 h-14 w-96" />
  </div>
);

function ESGRatingBar() {
  const { loading, esgRating } = useCubeEsgRating();

  if (loading) return SKELETON;

  const { description, backgroundColor, ratingColor } = infoRating(esgRating);

  return (
    <div className="flex-grow">
      <div
        style={{ marginLeft: `${esgRating * 10 - 9}%` }}
        className="align-center mb-1 mt-[-25px] flex w-fit flex-row rounded-md border px-2 py-1"
      >
        <Text size="xsmall" weight="medium" className={`pr-1 ${ratingColor}`}>
          {esgRating}
        </Text>

        <Text size="xsmall" weight="medium" className="border-l pl-1">
          {description}
        </Text>
      </div>

      <Progress className={`h-2 ${backgroundColor}`} value={esgRating * 10} />

      <div className="flex flex-row items-center justify-between">
        <p className="mt-1 text-end text-xs text-gray-400">0.0</p>
        <p className="mt-1 text-end text-xs text-gray-400">10.0</p>
      </div>
    </div>
  );
}

export default ESGRatingBar;
