import z from "zod";

import { PASSWORD_VALIDATION_REGEX, EMAIL_REGEX } from "Services/MaskUtil";

import { validateCpf, validateCnpj } from "Services/Suppliers/ValidateIdentifier";

const INVALID_PASSWORD_OBJ = { message: "Sua senha não cumpre com os requisitos mínimos" };

export const REQUIRED_MESSAGE = "Campo obrigatório";

export const OPTIONAL_STRING = z.string().default("").optional();

export const REQUIRED_NUMBER = z.number({ required_error: REQUIRED_MESSAGE });

export const REQUIRED_STRING = z
  .string({ required_error: REQUIRED_MESSAGE })
  .trim()
  .min(1, { message: REQUIRED_MESSAGE });

export const REQUIRED_EMAIL_WITH_REGEX = REQUIRED_STRING.refine((str) => EMAIL_REGEX.test(str), {
  message: "E-mail inválido",
});

export const OPTIONAL_EMAIL_WITH_REGEX = OPTIONAL_STRING.refine(
  (str) => (str ? EMAIL_REGEX.test(str) : true),
  () => ({ message: "E-mail inválido" }),
);

export const REQUIRED_PASSWORD_WITH_REGEX = REQUIRED_STRING.refine(
  (str) => PASSWORD_VALIDATION_REGEX.test(str),
  INVALID_PASSWORD_OBJ,
);

export const REQUIRED_CNPJ = REQUIRED_STRING.refine((value) => validateCnpj(value), { message: "CNPJ inválido" });

export const REQUIRED_CPF = REQUIRED_STRING.refine((value) => validateCpf(value), { message: "CPF inválido" });
