import _ from "lodash";

const ParseErrors = (errors) => {
  const errs = {};
  _.forEach(errors, (value, key) => {
    errs[key] = value.join(", ");
  });

  return errs;
};

export default ParseErrors;
