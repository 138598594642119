import React from "react";
import PropTypes from "prop-types";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";
import { Badge } from "DS/custom/badge";

function CardDetails({ path, name, description, text, variant }) {
  return (
    <div className="flex flex-wrap content-start items-center justify-between gap-4 rounded-lg border border-slate-200 p-5">
      <div className="flex flex-1 flex-col items-start gap-2">
        <div className="flex items-center gap-x-2">
          <Text size="small" className="font-medium">
            {name}
          </Text>

          <Badge variant={variant}>{text}</Badge>
        </div>

        <Text size="small">{description}</Text>
      </div>

      <Anchor to={path}>Ver detalhes</Anchor>
    </div>
  );
}

CardDetails.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CardDetails;
