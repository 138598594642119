import React from "react";
import PropTypes from "prop-types";

import { PlusCircle, MessageCirclePlus } from "lucide-react";

import { Text } from "DS/custom/text";

import { Button } from "DS/ui/button";

function FormFieldsEmptyState({ onCreateFormField }) {
  return (
    <div className="flex flex-col items-center gap-4 rounded-md border border-zinc-200 bg-zinc-50 px-4 py-10">
      <div className="flex flex-col items-center rounded-full border-4 border-zinc-200 p-4">
        <MessageCirclePlus className="h-12 w-12" />
      </div>
      <div className="flex flex-col items-center gap-2">
        <Text weight="medium">Nenhuma pergunta foi inserida neste documento</Text>
        <Text color="secondary" size="small">
          Configure perguntas de acordo com as informações que você deseja solicitar ao fornecedor.
        </Text>
      </div>

      <Button type="button" className="w-fit" onClick={onCreateFormField}>
        <PlusCircle className="mr-2" />
        Adicionar pergunta
      </Button>
    </div>
  );
}

FormFieldsEmptyState.propTypes = {
  onCreateFormField: PropTypes.func.isRequired,
};

export default FormFieldsEmptyState;
