import React from "react";
import PropTypes from "prop-types";

import { GripVertical } from "lucide-react";

import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

import { Text } from "DS/custom/text";

import FormQuestionsMenu from "Organisms/UBuyer/Settings/Forms/FormQuestionsMenu";

function FormSortableField({ index, field, onEdit, onDelete, onDuplicate, createdByTemplate }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: field });

  const style = { transition, transform: CSS.Transform.toString(transform) };

  return (
    <div {...attributes} ref={setNodeRef} style={style} {...listeners}>
      <div className="flex w-full flex-row items-center justify-between gap-2 rounded-md border border-zinc-200 bg-zinc-50 px-4 py-2">
        <div className="flex flex-row items-center gap-2">
          <GripVertical strokeWidth={2.5} size={16} />
          <Text weight="medium">
            {index + 1}. {field.display_name}
          </Text>
          {field.optional ? (
            <Text size="small" color="secondary">
              (opcional)
            </Text>
          ) : null}
        </div>
        <FormQuestionsMenu
          index={index}
          onEdit={onEdit}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          createdByTemplate={createdByTemplate}
        />
      </div>
    </div>
  );
}

FormSortableField.propTypes = {
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  field: PropTypes.shape({
    display_name: PropTypes.string,
    optional: PropTypes.bool,
  }).isRequired,
  createdByTemplate: PropTypes.bool,
};

FormSortableField.defaultProps = {
  createdByTemplate: false,
};

export default FormSortableField;
