import React from "react";
import PropTypes from "prop-types";

import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

function OwnerTooltip({ children, owner }) {
  if (owner) return children;

  return (
    <WrappedTooltip message="Apenas usuários administradores podem editar estas informações.">
      <div>{children}</div>
    </WrappedTooltip>
  );
}

OwnerTooltip.propTypes = {
  owner: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default OwnerTooltip;
