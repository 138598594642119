import React from "react";
import PropTypes from "prop-types";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "DS/ui/table";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

import { CATEGORY_OPTIONS_LABEL } from "Config/LegalEntity";
import EmptyList from "Assets/settings/shared/EmptyList.png";

function CategoriesTable({ categories }) {
  if (categories.length === 0)
    return (
      <div className="flex flex-col items-center justify-center gap-4">
        <img alt="Empty List" src={EmptyList} className="w-32" />
        <Text>Nenhuma categoria encontrada...</Text>
      </div>
    );

  return (
    <div className="rounded-md border border-slate-200">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">
              <Text size="small" weight="medium">
                Categoria
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Tipo
              </Text>
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {categories.map((category) => (
            <TableRow key={category.id}>
              <TableCell className="p-4">
                <Anchor to={category.id}>{category.displayName}</Anchor>
              </TableCell>

              <TableCell>
                <Text size="small">{CATEGORY_OPTIONS_LABEL[category.legalEntity]}</Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

CategoriesTable.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      legalEntity: PropTypes.string,
    }),
  ).isRequired,
};

export default CategoriesTable;
