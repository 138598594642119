import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Text } from "DS/custom/text";
import { FormField } from "DS/ui/form";

import SelectBuyerDocumentField from "Organisms/UBuyer/Companies/Index/AddSupplier/BuyerDocuments/SelectBuyerDocumentField";
import TextInputBuyerDocumentField from "Organisms/UBuyer/Companies/Index/AddSupplier/BuyerDocuments/TextInputBuyerDocumentField";
import DocumentBuyerDocumentField from "Organisms/UBuyer/Companies/Index/AddSupplier/BuyerDocuments/DocumentBuyerDocumentField";
import CurrencyBuyerDocumentField from "Organisms/UBuyer/Companies/Index/AddSupplier/BuyerDocuments/CurrencyBuyerDocumentField";

const COMPONENTS = {
  select: SelectBuyerDocumentField,
  document: DocumentBuyerDocumentField,
  currency: CurrencyBuyerDocumentField,
  text_input: TextInputBuyerDocumentField,
};

const BuyerDocumentFieldset = forwardRef(({ settingBuyerDocumentsOnCreation, control }, ref) => {
  if (_.isEmpty(settingBuyerDocumentsOnCreation)) return null;

  return (
    <div className="flex flex-col gap-3 max-w-3xl ml-1">
      <Text weight="medium">Campos do requisitante</Text>

      {_.map(settingBuyerDocumentsOnCreation, (formField) => {
        const Component = COMPONENTS[formField.fieldType];
        const fieldName = `buyerDocumentFields.${formField.name}`;
        const defaultValue = formField.fieldType !== "select" ? "" : undefined;

        return (
          <FormField
            key={fieldName}
            name={fieldName}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => <Component ref={ref} formField={formField} {...field} />}
          />
        );
      })}
    </div>
  );
});

BuyerDocumentFieldset.propTypes = {
  control: PropTypes.shape().isRequired,
  settingBuyerDocumentsOnCreation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BuyerDocumentFieldset;
