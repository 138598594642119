import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "DS/ui/table";

import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";

import { ACTIVE } from "Config/PUF";
import { formatCnpj, formatCnae } from "Services/RFFormatter";

/** @internal */
export const QUERY = gql`
  query Branches($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      branches {
        id
        uf
        cnpj
        numero
        situacao
        cnaeFiscal
        logradouro
        complemento
        cnaeFiscalTexto
        descTipoLogradouro
        descricaoMunicipio
      }
    }
  }
`;

const SKELETON = (
  <div>
    <Skeleton className="mb-2 h-4 w-52" />
    <Skeleton className="my-2 h-4 w-36" />
    <div className="flex flex-wrap items-center gap-4">
      <Skeleton className="h-24 w-96" repeat={6} />
    </div>
  </div>
);

function BranchesTable() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { branches } = data.supplier;

  return (
    <div className="flex flex-col gap-2">
      <Heading>Filiais</Heading>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">
              <Text size="small" weight="medium">
                Inscrição
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Situação cadastral
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                CNAE principal
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Endereço
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                UF
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Município
              </Text>
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {branches.map((branch) => (
            <TableRow key={branch.cnpj}>
              <TableCell>
                <Text size="small" weight="medium">
                  {formatCnpj(branch.cnpj)}
                </Text>
              </TableCell>

              <TableCell>
                <Text size="small" className={branch.situacao === ACTIVE ? "text-green-600" : "text-red-600"}>
                  {branch.situacao}
                </Text>
              </TableCell>

              <TableCell>
                <Text size="small" weight="medium">
                  {formatCnae(branch.cnaeFiscal)} - {branch.cnaeFiscalTexto}
                </Text>
              </TableCell>

              <TableCell>
                <Text size="small" weight="medium">
                  {branch.descTipoLogradouro} {branch.logradouro} {branch.numero} {branch.complemento}
                </Text>
              </TableCell>

              <TableCell>
                <Text size="small" weight="medium">
                  {branch.uf}
                </Text>
              </TableCell>

              <TableCell>
                <Text size="small" weight="medium">
                  {branch.descricaoMunicipio}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default BranchesTable;
