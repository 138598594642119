import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { Select, Box, Text, FormField, Button, Form, Anchor } from "grommet";
import { X, PlusCircle } from "lucide-react";
import { colors } from "Config/Theme";

import RightSideLayer from "Molecules/Layer/RightSideLayer";

/** @internal */
export const QUERY = gql`
  query WorkflowsStepLayer($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      users {
        id
        email
      }
    }
  }
`;

function WorkflowsStepLayer({ initialValues, onClose, onRemove, onSubmit }) {
  const { buyerId } = useParams();
  const [step, setStep] = useState(initialValues);
  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return <GlobalLoading />;

  const setMainApprover = ({ target }) => {
    const userId = target.value;

    setStep({ ...step, userId });
  };

  const addBlankApprover = () => {
    const approverIds = [...step.approverIds, ""];

    setStep({ ...step, approverIds });
  };

  const setApprover = (approverId, index) => {
    const approverIds = [...step.approverIds];

    approverIds[index] = approverId;

    setStep({ ...step, approverIds });
  };

  const destroyApprover = (index) => {
    const approverIds = step.approverIds.filter((_, i) => i !== index);

    setStep({ ...step, approverIds });
  };

  return (
    <RightSideLayer onClose={onClose} header="Alçada de aprovação">
      <Box pad="small" margin={{ bottom: "medium" }}>
        <Text color="dark-2">
          Aqui você escolhe quem são os usuários que podem ser escolhidos como aprovadores dessa alçada, tendo que
          escolher um aprovador como o principal
        </Text>
      </Box>

      <Form
        onSubmit={({ value }) => onSubmit(value)}
        onChange={(nextValue) => setStep(nextValue)}
        messages={{ required: "O campo é obrigatório" }}
        value={step}
      >
        <FormField
          name="displayName"
          a11yTitle="step displayName"
          required
          autoFocus
          margin={{ bottom: "medium" }}
          label={
            <Text color="dark-1" weight="bold" tag="label">
              Nome da alçada
            </Text>
          }
        />

        <FormField
          label={
            <Text color="dark-1" weight="bold" tag="label">
              Aprovador principal
            </Text>
          }
          required
          name="userId"
          margin={{ bottom: "medium" }}
        >
          <Select
            name="userId"
            placeholder="Selecione o aprovador principal"
            options={data.buyer.users}
            value={step.userId}
            labelKey="email"
            valueKey={{ key: "id", reduce: true }}
            onChange={setMainApprover}
          />
        </FormField>

        {step.approverIds.length ? (
          <Box margin={{ top: "medium", bottom: "small", left: "small" }}>
            <Text color="dark-1" weight="bold" tag="label">
              Demais aprovadores
            </Text>
          </Box>
        ) : null}

        <Box wrap flex={false}>
          {step.approverIds.map((approver, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={`approver_${index}`} direction="row" align="center">
              <Box flex="grow">
                <FormField
                  validate={() => {
                    if (!approver) return "O campo é obrigatório";

                    return undefined;
                  }}
                  name={`approverIds[${index}]`}
                >
                  <Select
                    name={`approverIds[${index}]`}
                    placeholder="Selecione o aprovador"
                    options={data.buyer.users}
                    value={approver}
                    labelKey="email"
                    valueKey={{ key: "id", reduce: true }}
                    onChange={({ target }) => setApprover(target.value, index)}
                  />
                </FormField>
              </Box>

              <Anchor icon={<X color={colors.dark} size={16} />} onClick={() => destroyApprover(index)} />
            </Box>
          ))}

          <Anchor
            icon={<PlusCircle size={26} />}
            margin={{ top: "small" }}
            onClick={addBlankApprover}
            label={
              <Text weight="normal" color="brand">
                Adicionar novo aprovador
              </Text>
            }
          />
        </Box>

        <Box gap="small" margin={{ vertical: "medium" }}>
          <Button primary type="submit" label="Salvar alçada" />

          <Button
            color="status-critical"
            label={<Text color="status-critical">Excluir alçada</Text>}
            onClick={onRemove}
          />
        </Box>
      </Form>
    </RightSideLayer>
  );
}

WorkflowsStepLayer.defaultProps = {
  initialValues: { displayName: "", userId: "", approverIds: [] },
};

WorkflowsStepLayer.propTypes = {
  initialValues: PropTypes.shape({
    displayName: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ id: PropTypes.string, email: PropTypes.string })]),
    approverIds: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ id: PropTypes.string, email: PropTypes.string })]),
    ),
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default WorkflowsStepLayer;
