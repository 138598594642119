import React from "react";
import { Route, Routes } from "react-router-dom";

import CategoriesList from "Organisms/UBuyer/Settings/Categories/CategoriesList";
import CategoryInfo from "Organisms/UBuyer/Settings/Categories/CategoryInfo";
import CategoryTypeSelector from "Organisms/UBuyer/Settings/Categories/CategoryTypeSelector";
import CategoryNew from "Organisms/UBuyer/Settings/Categories/CategoryNew";
import CategoryEdit from "Organisms/UBuyer/Settings/Categories/CategoryEdit";

function Categories() {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<CategoriesList />} />
        <Route path="new" element={<CategoryTypeSelector />} />
        <Route path="new/:legalEntity" element={<CategoryNew />} />
        <Route path=":id" element={<CategoryInfo />} />
        <Route path=":id/edit" element={<CategoryEdit />} />
      </Route>
    </Routes>
  );
}

export default Categories;
