import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Menu, Download } from "lucide-react";

import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";
import { Badge } from "DS/custom/badge";
import { useToast } from "DS/ui/use-toast";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "DS/ui/dropdown-menu";

/** @internal */
export const QUERY = gql`
  query categoryReportExperiment($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
    }
    user {
      id
      email
    }
  }
`;

/** @internal */
export const EXPERIMENT_URL = "https://n8n-product.fly.dev/webhook/category-report";

function CategoryReportExperimentDropdown() {
  const { buyerId } = useParams();
  const { toast } = useToast();

  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return <Skeleton className="h-10 w-10" />;

  const onClick = async () => {
    await axios.post(EXPERIMENT_URL, {
      buyer_id: data.buyer.id,
      email: data.user.email,
    });

    toast({
      title: "Relatório sendo gerado.",
      description: "Em breve você o receberá por e-mail.",
    });
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="p-2" size="sm" aria-label="secondary-menu">
          <Menu className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-56">
        <DropdownMenuItem onClick={onClick}>
          <Text color="secondary">
            <Download strokeWidth={3} className="mr-2 h-4 w-4 " />
          </Text>

          <div className="flex flex-row items-center gap-2">
            <Text weight="medium" color="secondary">
              Baixar relatório
            </Text>
            <WrappedTooltip message="Esta funcionalidade está em fase de testes e pode deixar de existir.">
              <Badge>BETA</Badge>
            </WrappedTooltip>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default CategoryReportExperimentDropdown;
