import * as React from "react";
import PropTypes from "prop-types";

import { cn } from "../lib/utils.ts";

const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col gap-5 rounded-lg border bg-card p-6 text-card-foreground shadow-sm", className)}
    {...props}
  />
));

Card.defaultProps = {
  className: null,
};

Card.propTypes = {
  className: PropTypes.string,
};

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex flex-col", className)} {...props} />
));

CardHeader.defaultProps = {
  className: null,
};

CardHeader.propTypes = {
  className: PropTypes.string,
};

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3
    ref={ref}
    className={cn("text-sm font-medium leading-none text-slate-800 dark:text-slate-100 md:text-base", className)}
    {...props}
  />
));

CardTitle.defaultProps = {
  className: null,
};

CardTitle.propTypes = {
  className: PropTypes.string,
};

const CardDescription = React.forwardRef(({ className, ...props }, ref) => (
  <p ref={ref} className={cn("text-sm text-slate-700 dark:text-slate-200", className)} {...props} />
));

CardDescription.defaultProps = {
  className: null,
};

CardDescription.propTypes = {
  className: PropTypes.string,
};

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex flex-col gap-4 text-sm text-slate-800", className)} {...props} />
));

CardContent.defaultProps = {
  className: null,
};

CardContent.propTypes = {
  className: PropTypes.string,
};

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex items-center", className)} {...props} />
));

CardFooter.defaultProps = {
  className: null,
};

CardFooter.propTypes = {
  className: PropTypes.string,
};

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent };
