import { useLocation } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { useToast } from "DS/ui/use-toast";

import Auth from "Services/Auth";

const QUERY = gql`
  query RedirectUser {
    user {
      id
      buyer {
        id
      }
      invites {
        id
        token
      }
    }
  }
`;

function RedirectUser() {
  const { toast } = useToast();
  const { state } = useLocation();

  const onCompleted = () => {
    if (state?.tokenExpired) {
      const title = "Erro ao acessar o link!";
      const description = "Token inexistente ou expirado.";
      toast({ title, description, variant: "destructive" });
    }

    if (state?.invitePending) {
      const title = "Login necessário!";
      const description = "Faça login para aceitar o convite.";
      toast({ title, description });
    }

    if (state?.toast) toast(state.toast);
  };

  const { loading, data } = useQuery(QUERY, { onCompleted });

  if (loading) return null;

  const { user } = data;

  if (!user) Auth.destroy();

  let path = "/login";

  if (user && Auth.isAuthenticated()) {
    path = user.buyer ? `/companies` : "/suppliers";

    const [invite] = user.invites;

    if (invite) path = `/signup/${invite.token}`;
  }

  return window.location.assign(path);
}

export default RedirectUser;
