import React from "react";

import { useFormContext } from "react-hook-form";

import { FormItem, FormField, FormLabel, FormMessage, FormControl } from "DS/ui/form";

import { Switch } from "DS/ui/switch";
import { Text } from "DS/custom/text";

import useOwnerChecker from "Organisms/Shared/Authorization/useOwnerChecker";
import OwnerTooltip from "Organisms/UBuyer/Settings/Shared/OwnerTooltip";

function UserPermissions() {
  const { control } = useFormContext();
  const { loading, owner } = useOwnerChecker();

  if (loading) return null;

  return (
    <>
      <FormField
        name="owner"
        control={control}
        render={({ field }) => (
          <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
            <FormLabel>Usuário administrador</FormLabel>
            <div className="flex flex-col gap-1">
              <FormControl>
                <OwnerTooltip owner={owner}>
                  <Switch
                    id="owner"
                    {...field}
                    disabled={!owner}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </OwnerTooltip>
              </FormControl>

              <FormMessage />
            </div>
          </FormItem>
        )}
      />

      <Text weight="medium" className="mt-1 border-t pt-2">
        Permissões
      </Text>

      <div className="flex flex-col gap-3 rounded-lg border p-4">
        <FormField
          name="addSupplier"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between">
              <FormLabel>Iniciar homologação</FormLabel>

              <div className="flex flex-col gap-1">
                <FormControl>
                  <OwnerTooltip owner={owner}>
                    <Switch
                      {...field}
                      id="addSupplier"
                      disabled={!owner}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </OwnerTooltip>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="addBuyerDocument"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between">
              <FormLabel>Preencher campos do comprador</FormLabel>

              <div className="flex flex-col gap-1">
                <FormControl>
                  <OwnerTooltip owner={owner}>
                    <Switch
                      {...field}
                      disabled={!owner}
                      id="addBuyerDocument"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </OwnerTooltip>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="startWorkflow"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between">
              <FormLabel>Iniciar fluxos de aprovação</FormLabel>

              <div className="flex flex-col gap-1">
                <FormControl>
                  <OwnerTooltip owner={owner}>
                    <Switch
                      {...field}
                      id="startWorkflow"
                      disabled={!owner}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </OwnerTooltip>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="reviewDoc"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between">
              <FormLabel>Revisar campos do fornecedor</FormLabel>

              <div className="flex flex-col gap-1">
                <FormControl>
                  <OwnerTooltip owner={owner}>
                    <Switch
                      {...field}
                      id="reviewDoc"
                      disabled={!owner}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </OwnerTooltip>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="reviewSupplier"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between">
              <FormLabel>Aprovar, reprovar ou bloquear homologação</FormLabel>

              <div className="flex flex-col gap-1">
                <FormControl>
                  <OwnerTooltip owner={owner}>
                    <Switch
                      {...field}
                      disabled={!owner}
                      id="reviewSupplier"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </OwnerTooltip>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="destroySupplier"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between">
              <FormLabel>Excluir homologação</FormLabel>

              <div className="flex flex-col gap-1">
                <FormControl>
                  <OwnerTooltip owner={owner}>
                    <Switch
                      {...field}
                      disabled={!owner}
                      id="destroySupplier"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </OwnerTooltip>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="addPerformanceForm"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between">
              <FormLabel>Enviar e excluir questionários de performance</FormLabel>

              <div className="flex flex-col gap-1">
                <FormControl>
                  <OwnerTooltip owner={owner}>
                    <Switch
                      {...field}
                      disabled={!owner}
                      checked={field.value}
                      id="addPerformanceForm"
                      onCheckedChange={field.onChange}
                    />
                  </OwnerTooltip>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          name="purchaseSupplierProfile"
          control={control}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between">
              <FormLabel>Pagar pela homologação do fornecedor</FormLabel>

              <div className="flex flex-col gap-1">
                <FormControl>
                  <OwnerTooltip owner={owner}>
                    <Switch
                      {...field}
                      disabled={!owner}
                      checked={field.value}
                      id="purchaseSupplierProfile"
                      onCheckedChange={field.onChange}
                    />
                  </OwnerTooltip>
                </FormControl>

                <FormMessage />
              </div>
            </FormItem>
          )}
        />
      </div>
    </>
  );
}

export default UserPermissions;
