import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Plus, X } from "lucide-react";

import { useFieldArray } from "react-hook-form";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";
import { FormControl, FormField, FormItem, FormMessage } from "DS/ui/form";

const CREATED_BY_TEMPLATE_MESSAGE = "Não é possível alterar opções em formulários padrão";

function OptionsFormField({ form, visible, createdByTemplate }) {
  const { fields, append, remove } = useFieldArray({ control: form.control, name: "options" });

  useEffect(() => {
    if (!visible) remove();
    if (visible && fields.length === 0) append([{ displayName: "" }, { displayName: "" }]);
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!visible) return null;

  return (
    <>
      {fields.map((option, optionIndex) => (
        <FormField
          key={option.id}
          control={form.control}
          name={`options.${optionIndex}.displayName`}
          render={({ field }) => (
            <FormItem className="my-3 flex flex-row items-center gap-2">
              <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                <FormControl>
                  <div className="flex flex-row items-center gap-2">
                    <div className="h-4 w-4 flex-none rounded-full border border-slate-200" />

                    <Input
                      {...field}
                      disabled={createdByTemplate}
                      placeholder={`Opção ${optionIndex + 1}`}
                      name={`options.${optionIndex}.displayName`}
                    />

                    <WrappedTooltip message={createdByTemplate ? CREATED_BY_TEMPLATE_MESSAGE : ""}>
                      <Button
                        variant="ghost"
                        className="p-0"
                        onClick={() => remove(optionIndex)}
                        disabled={fields.length < 3 || createdByTemplate}
                      >
                        <X />
                      </Button>
                    </WrappedTooltip>
                  </div>
                </FormControl>

                <FormMessage className="ml-6" />
              </div>
            </FormItem>
          )}
        />
      ))}

      <WrappedTooltip message={createdByTemplate ? CREATED_BY_TEMPLATE_MESSAGE : ""}>
        <Button type="button" variant="link" disabled={createdByTemplate} onClick={() => append({ displayName: "" })}>
          <Plus /> Nova opção
        </Button>
      </WrappedTooltip>
    </>
  );
}

OptionsFormField.defaultProps = { createdByTemplate: false };

OptionsFormField.propTypes = {
  createdByTemplate: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  form: PropTypes.shape({ control: PropTypes.shape() }).isRequired,
};

export default OptionsFormField;
