import React, { useState, useMemo } from "react";
import _ from "lodash";

import { Search } from "lucide-react";

import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";
import Skeleton from "DS/custom/skeleton";
import { Breadcrumb } from "DS/custom/breadcrumb";

import SearchResults from "Organisms/UBuyer/Companies/Index/SearchResults";
import AddPJSupplierHIT from "Pages/Suppliers/Companies/AddPJSupplierHIT";

/** @internal */
export const LAZY_QUERY = gql`
  query AddPJSupplier($term: String!) {
    branches: branchesByCnpjOrRazaoSocial(term: $term) {
      id
      cnpj
      situacao
      razaoSocial
      nomeFantasia
    }
  }
`;

function AddPJSupplier() {
  const [lazyQuery, { loading }] = useLazyQuery(LAZY_QUERY);
  const [search, setSearch] = useState({ page: 1, term: "", filters: {} });
  const [query, setQuery] = useState({ hits: [], found: 0, loading: false });

  const debouncedSearch = useMemo(
    () =>
      _.debounce(async ({ term }) => {
        const { data } = await lazyQuery({ variables: { term } });
        const { branches } = data;

        setQuery({ hits: branches, loading, found: branches.length });
      }, 500),
    [lazyQuery, loading],
  );

  const onUserType = ({ value }) => {
    const searchInfo = { ...search, page: 1, term: value };

    setSearch(searchInfo);
    debouncedSearch(searchInfo);
    setQuery({ ...query, loading: true, hits: [] });
  };

  return (
    <div className="space-y-4">
      <Breadcrumb levels={["Fornecedores", "Iniciar homologação", "Pessoa jurídica"]} />

      <Text className="mt-2 font-medium">Digite os dados do seu fornecedor...</Text>

      <div className="space-y-3">
        <div className="max-w-2xl rounded-xl border">
          <div className="flex flex-1 flex-row items-center pl-3">
            <Search size={18} strokeWidth={2.5} className="h-5 w-5 text-gray-500" />

            <input
              name="term"
              type="search"
              value={search.term}
              onChange={({ target }) => onUserType(target)}
              placeholder="Digite um nome ou o CNPJ do fornecedor"
              className="flex-1 rounded-xl border-none outline-none focus:ring-0"
            />
          </div>
        </div>

        <Text size="small">
          Não conseguiu encontrar seu fornecedor? Você também pode
          <Anchor to="../pj-manual"> adicioná-lo manualmente.</Anchor>
        </Text>

        <div>{search.term && !query.loading ? <SearchResults found={query.found} /> : null}</div>

        <div className="mb-4 max-w-3xl space-y-2">
          {query.loading ? (
            <Skeleton className="h-32" repeat={7} />
          ) : (
            query.hits.map((branch) => <AddPJSupplierHIT key={branch.id} branch={branch} term={search.term} />)
          )}
        </div>
      </div>
    </div>
  );
}

export default AddPJSupplier;
