import React from "react";
import { Route, Routes } from "react-router-dom";

import WorkflowsList from "Organisms/UBuyer/Settings/Workflows/WorkflowsList";
import WorkflowsInfo from "Organisms/UBuyer/Settings/Workflows/WorkflowsInfo";
import WorkflowsNew from "Organisms/UBuyer/Settings/Workflows/WorkflowsNew";
import WorkflowsEdit from "Organisms/UBuyer/Settings/Workflows/WorkflowsEdit";

function Forms() {
  return (
    <Routes>
      <Route path="" element={<WorkflowsList />} />
      <Route path="new" element={<WorkflowsNew />} />
      <Route path=":settingWorkflowId" element={<WorkflowsInfo />} />
      <Route path=":settingWorkflowId/edit" element={<WorkflowsEdit />} />
    </Routes>
  );
}

export default Forms;
