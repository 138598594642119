import React from "react";
import PropTypes from "prop-types";

import { Plus, X } from "lucide-react";

import { useFieldArray } from "react-hook-form";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { FormControl, FormField, FormItem, FormMessage } from "DS/ui/form";

function OptionsFormField({ control, options }) {
  const fieldArray = { control, name: "options" };
  const { fields, append, remove } = useFieldArray(fieldArray);

  return (
    <>
      {options.map((_, index) => (
        <FormField
          key={index} // eslint-disable-line react/no-array-index-key
          control={control}
          name={`options.${index}`}
          render={({ field }) => (
            <FormItem className="my-3 flex flex-row items-center gap-2">
              <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                <FormControl>
                  <div className="flex flex-row items-center gap-2">
                    <div className="h-4 w-4 flex-none rounded-full border border-slate-200" />

                    <Input {...field} name={`options.${index}`} placeholder={`Opção ${index + 1}`} />

                    <Button variant="ghost" className="p-0" disabled={fields.length < 3} onClick={() => remove(index)}>
                      <X />
                    </Button>
                  </div>
                </FormControl>

                <FormMessage className="ml-6" />
              </div>
            </FormItem>
          )}
        />
      ))}

      <Button type="button" variant="link" onClick={() => append("")} disabled={options.some((o) => !o)}>
        <Plus /> Nova opção
      </Button>
    </>
  );
}

OptionsFormField.propTypes = {
  control: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OptionsFormField;
