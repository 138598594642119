import * as React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { Text } from "./text";
import { cn } from "../lib/utils.ts";

const Anchor = React.forwardRef(({ className, to, disabled, ...props }, ref) => {
  if (disabled)
    return <Text ref={ref} {...props} size="small" weight="medium" className="pointer-events-none text-zinc-600" />;

  if (to)
    return (
      <Link
        className={cn(
          "w-max text-sm font-medium text-primary underline-offset-4 hover:cursor-pointer hover:underline dark:text-slate-100",
          className,
        )}
        to={to}
        ref={ref}
        {...props}
      />
    );

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      className={cn(
        "w-max text-sm font-medium text-primary underline-offset-4 hover:cursor-pointer hover:underline dark:text-slate-100",
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});

Anchor.defaultProps = {
  to: null,
  className: null,
  disabled: false,
};

Anchor.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// eslint-disable-next-line import/prefer-default-export
export { Anchor };
