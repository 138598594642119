import React from "react";

import { Text } from "DS/custom/text";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";

import GovAl from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/GovAl";
import GovCeis from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/GovCeis";
import GovCeaf from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/GovCeaf";
import GovCnep from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/GovCnep";
import GovCepim from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/GovCepim";
import GovInabilitados from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/GovInabilitados";

function GovCorruption() {
  return (
    <div>
      <Breadcrumb lastPage="Análise Linkana" currentPage="Combate à corrupção" />

      <div className="mt-4 flex flex-wrap items-start pb-6 lg:flex-nowrap">
        <div className="flex max-w-[300px] flex-col items-start justify-center gap-1 py-4">
          <Text size="small" weight="medium">
            Itens avaliados
          </Text>

          <Text size="small">Informações analisadas pela Linkana para geração da pontuação do pilar.</Text>
        </div>

        <div className="flex flex-1 flex-col gap-4 p-4 pt-4">
          <GovAl />
          <GovCeis />
          <GovCeaf />
          <GovCnep />
          <GovCepim />
          <GovInabilitados />
        </div>
      </div>
    </div>
  );
}

export default GovCorruption;
