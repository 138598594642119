import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import client from "Config/GraphqlApiService";

import Auth from "Services/Auth";
import { useToast } from "DS/ui/use-toast";
import KEY from "Services/Authentication/Config";

function SsoCallback() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [redirectUrl, setRedirectUrl] = useState();

  const searchParams = new URLSearchParams(search);
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const error = searchParams.get("error");
  const url = JSON.parse(state)?.token ? "signup" : "signin";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.post(`/sso/${url}`, { code, state });

        localStorage.setItem(KEY, resp.headers.authorization);
        client.resetStore();

        setRedirectUrl("/");
      } catch ({ response }) {
        let title = "Erro inesperado no login";

        if (response.status === 404) title = "E-mail não encontado";

        if (response.status === 403) title = "E-mail já está em uso";

        toast({ title, variant: "destructive" });
        navigate("/");
      }
    };

    if (Auth.isAuthenticated()) navigate("/");
    else if (error === "access_denied") {
      toast({ variant: "destructive", title: "Link de acesso expirado" });
      navigate("/login");
    } else fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (redirectUrl) window.location.assign(redirectUrl);
}

export default SsoCallback;
