const fetchSupplierFilter = () => {
  const initialFilters = {
    term: "",
    states: [],
    active: null,
    blocked: null,
    addedByMe: null,
    ratingLabels: [],
    settingTagIds: [],
    pendingPayment: null,
    regularization: null,
    settingCategories: [],
    qualificationStates: [],
    workflowRequested: null,
  };

  // const savedFilters = JSON.parse(sessionStorage.getItem("supplierFilter")) || {};

  const savedFilters = {};

  return { ...initialFilters, ...savedFilters };
};

export default fetchSupplierFilter;
