import gql from "graphql-tag";
import apolloClient from "Config/GraphqlApiService";
import * as Sentry from "@sentry/react";
import Auth from "Services/Auth";

let userId;

const GET = gql`
  query Intercom {
    user {
      id
      name
      email
      createdAt
      buyer {
        id
        name
      }
      owner
    }
  }
`;

const bootWithUser = async () => {
  try {
    const result = await apolloClient.query({
      query: GET,
    });

    const { user } = result.data;

    if (user) {
      userId = user.id;

      Sentry.setUser({
        id: user.id,
        username: user.name,
        email: user.email,
      });
    }
  } catch (e) {
    // Silently fail if error occurs
  }
};

const setup = async () => {
  if (Auth.isAuthenticated()) bootWithUser();
};

const page = async () => {
  if (!window.Intercom) return;

  window.Intercom("update", { user_id: userId });
};

export default {
  setup,
  page,
  bootWithUser,
};
