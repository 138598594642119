import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { MessageSquare } from "lucide-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import Logo from "Assets/logo.svg";
import changePassword from "Services/Authentication/PasswordChange";
import { REQUIRED_PASSWORD_WITH_REGEX, REQUIRED_STRING } from "Config/Zod";

import { Input } from "DS/ui/input";
import { Label } from "DS/ui/label";
import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";
import { useToast } from "DS/ui/use-toast";
import Skeleton from "DS/custom/skeleton";
import { Card, CardHeader, CardContent, CardFooter } from "DS/custom/card";

import { Form, FormItem, FormField, FormLabel, FormMessage, FormControl, FormDescription } from "DS/ui/form";

import login from "Services/Authentication/Login";

import UserPasswordReset from "Pages/User/UserPasswordReset";

/** @internal */
export const QUERY = gql`
  query UserPasswordChange($token: String!) {
    user(resetPasswordToken: $token) {
      id
      email
    }
  }
`;

const SKELETON = (
  <div className="flex min-h-screen flex-col items-center justify-center">
    <Skeleton className="h-[550px] w-96" />
  </div>
);

const zodSchema = () => {
  const zodObject = z.object({
    password: REQUIRED_PASSWORD_WITH_REGEX,
    passwordConfirmation: REQUIRED_STRING,
  });

  return zodObject.refine((value) => value.password === value.passwordConfirmation, {
    message: "As senhas não correspondem.",
    path: ["passwordConfirmation"],
  });
};

function UserPasswordChange() {
  const { toast } = useToast();
  const { search } = useLocation();
  const [submitting, setSubmitting] = useState(false);

  const queryParams = new URLSearchParams(search);
  const token = queryParams.get("reset_password_token") || "";
  const { loading, data } = useQuery(QUERY, { variables: { token } });

  const defaultValues = { password: "", passwordConfirmation: "" };
  const form = useForm({ resolver: zodResolver(zodSchema()), defaultValues });

  if (loading) return SKELETON;

  const { user } = data;

  if (!user) return <UserPasswordReset tokenExpired />;

  const { email } = user;

  const onSubmit = async (values) => {
    setSubmitting(true);

    changePassword(values, token)
      .then(() => login({ email, password: values.password }))
      .catch(() => {
        const description = "Erro inesperado. Por favor, entre em contato com o suporte.";

        toast({ title: "Erro!", variant: "destructive", description });
      });

    setSubmitting(false);
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <Card className="max-w-md sm:w-[420px]">
        <CardHeader className="flex flex-col gap-2">
          <div className="h-11 w-11 rounded-sm bg-slate-50 p-2">
            <img src={Logo} alt="Linkana Logo" />
          </div>

          <Heading>Altere sua senha.</Heading>
        </CardHeader>

        <CardContent>
          <Form {...form}>
            <form id="UserPasswordChange" className="flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex w-full flex-col gap-1">
                <Label>E-mail</Label>
                <Input disabled value={email} />
              </div>

              <FormField
                name="password"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <div className="flex flex-col gap-1">
                      <FormLabel htmlFor="password">Nova senha</FormLabel>

                      <FormControl>
                        <Input {...field} id="password" type="password" placeholder="Digite uma senha..." />
                      </FormControl>

                      <FormDescription>
                        Certifique-se de que a sua senha tenha pelo menos 8 caracteres; incluindo um número, uma letra
                        maiúscula, uma letra minúscula e um caractere especial.
                      </FormDescription>

                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                name="passwordConfirmation"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <div className="flex flex-col gap-1">
                      <FormLabel htmlFor="passwordConfirmation">Confirmação de senha</FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          type="password"
                          id="passwordConfirmation"
                          placeholder="Confirme sua senha nova..."
                        />
                      </FormControl>

                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />

              <Button type="submit" disabled={submitting} form="UserPasswordChange">
                Redefinir senha
              </Button>
            </form>
          </Form>
        </CardContent>

        <div className="w-full border-b" />

        <CardFooter className="flex flex-col gap-3">
          <Button type="button" variant="outline" className="w-full gap-2" onClick={() => window.Intercom("show")}>
            <MessageSquare size={20} /> Falar com o nosso time de suporte
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default UserPasswordChange;
