import React from "react";
import PropTypes from "prop-types";

import { AlertCircle } from "lucide-react";

import { Text } from "DS/custom/text";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "DS/ui/accordion";

import formatCubeInfo from "Services/PUFFormatter";

function DetailsAccordion({ cubeInfo }) {
  return (
    <div className="flex-start flex w-full flex-col">
      {cubeInfo.length ? (
        cubeInfo.map((info, index) => {
          const keys = Object.keys(info);
          const uniqueKey = Object.values(info);

          return (
            <Accordion collapsible key={uniqueKey} defaultValue="info-0">
              <AccordionItem value={`info-${index}`}>
                <AccordionTrigger>
                  <Text size="small" weight="medium">
                    Pendência {index + 1}
                  </Text>
                </AccordionTrigger>

                <AccordionContent>
                  <div className="flex flex-col gap-3">
                    {keys.map((key) => (
                      <div key={key}>
                        <div className="flex flex-row gap-x-1 gap-y-2">
                          <div className="flex flex-col gap-y-1">
                            <Text size="small">{key}</Text>
                            <Text size="small" weight="medium">
                              {formatCubeInfo(info[key])}
                            </Text>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })
      ) : (
        <div className="flex h-full flex-row items-center gap-2">
          <AlertCircle size={26} />
          <Text size="small">Não foram encontrados problemas relacionados a este item.</Text>
        </div>
      )}
    </div>
  );
}

DetailsAccordion.propTypes = {
  cubeInfo: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])),
  ).isRequired,
};

export default DetailsAccordion;
