import React from "react";
import { useSearchParams, Navigate } from "react-router-dom";

import { Globe } from "lucide-react";

import { Text } from "DS/custom/text";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { INTERNATIONAL } from "Config/LegalEntity";

import useSettingCategories from "Organisms/UBuyer/Companies/Index/AddSupplier/SettingCategory/useSettingCategories";

import BaseSupplierForm, { SKELETON } from "Organisms/UBuyer/Companies/Index/AddSupplier/BaseSupplierForm";

function AddInternationalSupplierForm() {
  const [searchParams] = useSearchParams();
  const { loading, settingCategories } = useSettingCategories();
  const { name, identifier, country } = Object.fromEntries(searchParams);

  if (loading) return SKELETON;

  if (!name || !identifier || !country) return <Navigate to=".." />;

  const categories = settingCategories.filter(({ legalEntity }) => legalEntity === INTERNATIONAL);

  return (
    <>
      <Breadcrumb levels={["Fornecedores", "Iniciar homologação", "Internacional", "Dados da homologação"]} />

      <BaseSupplierForm categories={categories}>
        <div className="flex flex-col gap-1 max-w-5xl rounded-md border p-6 bg-zinc-50">
          <div className="flex flex-col gap-1">
            <Text size="small" weight="medium">
              {name}
            </Text>

            <div className="flex flex-row items-center gap-2">
              <Globe size={16} className="text-slate-400" />
              <Text size="small">{identifier}</Text>
            </div>
          </div>

          <div className="flex flex-row gap-2">
            <Text size="small" weight="medium" className="w-8">
              País
            </Text>

            <Text size="small">{country}</Text>
          </div>
        </div>
      </BaseSupplierForm>
    </>
  );
}

export default AddInternationalSupplierForm;
