import React from "react";
import PropTypes from "prop-types";

import { Box, Image, Heading, Text } from "grommet";

import PerformanceFormAnswered from "Assets/performance/performance-form-answered.svg";

function PerformanceForm({ name }) {
  return (
    <Box align="center" pad="xlarge">
      <Box width="medium">
        <Image src={PerformanceFormAnswered} />
      </Box>
      <Heading size="small" color="light-0">
        Avaliação enviada com sucesso!
      </Heading>
      <Text color="dark-3">Obrigado por concluir a avaliação de desempenho de {name}.</Text>
    </Box>
  );
}

PerformanceForm.propTypes = { name: PropTypes.string.isRequired };

export default PerformanceForm;
