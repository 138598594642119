import React, { useState, useRef, useContext } from "react";
import _ from "lodash";

import { Context } from "Organisms/UBuyer/Companies/Index/SuppliersSearch/SupplierSearchContext";

import SearchInput from "Organisms/UBuyer/Shared/SearchInput/SearchInput";
import QuickFilters from "Organisms/UBuyer/Companies/Index/SuppliersSearch/QuickFilters";
import AdvancedFilters from "Organisms/UBuyer/Companies/Index/SuppliersSearch/AdvancedFilters";

function SearchBarAndFilter() {
  const { search, setSearch } = useContext(Context);
  const [term, setTerm] = useState(search.term);
  const debouncedSave = useRef(_.debounce(setSearch, 300)).current;

  return (
    <div className="flex flex-row flex-wrap items-center gap-4">
      <SearchInput
        value={term}
        placeholder="Procurar pelo nome ou CNPJ do fornecedor..."
        onChange={(value) => {
          const parsedTerm = value.slice(0, 128);
          setTerm(parsedTerm);
          debouncedSave({ ...search, term: parsedTerm });
        }}
      />

      <QuickFilters />

      <AdvancedFilters />
    </div>
  );
}

export default SearchBarAndFilter;
