import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Box, Heading, Form, FormField, Text, Button, TextArea } from "grommet";

import ScoreRadioButtonGroup from "Organisms/UPublic/PerformanceForm/ScoreRadioButtonGroup";

/** @internal */
export const MUTATION = gql`
  mutation PerformanceFormMutation($token: String!, $answers: [PerformanceQuestionnaireInput!]!) {
    answerPerformanceForm(input: { token: $token, answers: $answers }) {
      performanceForm {
        id
        state
      }
      errors {
        path
      }
    }
  }
`;

const parseValues = (values) => {
  const parsedValues = {};

  _.each(values, (value, key) => {
    const [questionnaireId, answerId] = key.split("_");

    parsedValues[questionnaireId] = parsedValues[questionnaireId] || {
      id: questionnaireId,
      performanceAnswers: [],
    };

    if (answerId) {
      parsedValues[questionnaireId].performanceAnswers.push({
        id: answerId,
        answer: value >= 0 ? value : null,
      });
    } else parsedValues[questionnaireId].comment = value;
  });

  return Object.values(parsedValues);
};

function PerformanceFormMutation({ token, performanceForm }) {
  const [answerPerformanceForm, { loading }] = useMutation(MUTATION);

  const onSubmit = ({ value }) => {
    const answers = parseValues(value);
    answerPerformanceForm({ variables: { token, answers } });
  };

  const { performanceQuestionnaires, commentRequired } = performanceForm;

  return (
    <Form messages={{ required: "campo obrigatório" }} onSubmit={onSubmit}>
      {performanceQuestionnaires.map((questionnaire) => {
        const { id, displayName, performanceAnswers } = questionnaire;

        return (
          <Box key={id}>
            <Heading level={3} dangerouslySetInnerHTML={{ __html: displayName }} />

            <Box margin={{ bottom: "small" }}>
              {performanceAnswers.map((answer, i) => {
                const name = `${id}_${answer.id}`;

                return (
                  <Box key={answer.id}>
                    <FormField name={name} htmlFor={name} contentProps={{ border: false }} required margin="none">
                      <Box
                        flex
                        align="center"
                        justify="between"
                        direction="row-responsive"
                        border={{ side: i === 0 ? "horizontal" : "bottom", size: "xsmall", color: "light-4" }}
                      >
                        <Text margin={{ vertical: "small" }} dangerouslySetInnerHTML={{ __html: answer.displayName }} />

                        <Box>
                          <ScoreRadioButtonGroup id={name} name={name} answerId={answer.id} />
                        </Box>
                      </Box>
                    </FormField>
                  </Box>
                );
              })}
            </Box>

            <FormField
              name={id}
              htmlFor={`comment_${id}`}
              required={commentRequired}
              margin={{ bottom: "medium" }}
              contentProps={{ border: { size: "xsmall", color: "light-4" }, round: "xsmall" }}
              label={
                <Text
                  size="small"
                  color="dark-2"
                  weight="bold"
                  dangerouslySetInnerHTML={{
                    __html: `Comentário sobre sua avaliação em ${displayName} ${commentRequired ? "" : " (opcional)"}`,
                  }}
                />
              }
            >
              <TextArea id={`comment_${id}`} name={id} border="small" resize={false} rows="5" />
            </FormField>
          </Box>
        );
      })}

      <Box align="center" margin={{ top: "medium" }}>
        <Button type="submit" label="Finalizar avaliação" primary disabled={loading} />
      </Box>
    </Form>
  );
}

PerformanceFormMutation.propTypes = {
  token: PropTypes.string.isRequired,
  performanceForm: PropTypes.shape({
    commentRequired: PropTypes.bool,
    performanceQuestionnaires: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default PerformanceFormMutation;
