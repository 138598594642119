import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import { ArrowLeft } from "lucide-react";

import axios from "axios";

import LogoSVG from "Assets/logo-brand.svg";
import SupplierLoginSVG from "Assets/supplier-login-min.svg";

import { Label } from "DS/ui/label";
import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Anchor } from "DS/custom/anchor";
import { Heading } from "DS/custom/heading";

function LoginWithoutPassword() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { email } = state;

  const { setLayoutInfo } = useOutletContext();
  const [submitting, setSubmitting] = useState(false);
  const [loginRequested, setLoginRequested] = useState(false);

  useEffect(() => {
    setLayoutInfo({
      text: null,
      svg: SupplierLoginSVG,
      heading: `Processo rápido e prático de cadastro e homologação em grandes empresas!`,
    });
  }, [setLayoutInfo]);

  const onClick = async () => {
    setSubmitting(true);
    await axios.post("/sso/passwordless", { email });
    setLoginRequested(true);
    setSubmitting(false);
  };

  return (
    <>
      <img src={LogoSVG} alt="Linkana Logo" className="w-32" />

      <Anchor className="flex items-center gap-2" onClick={() => navigate(-1)}>
        <ArrowLeft size={16} className="text-blue-600" strokeWidth={2.5} />
        Voltar
      </Anchor>

      {loginRequested ? (
        <div className="flex flex-col gap-1">
          <Heading>Próximos passos para o acesso sem senha</Heading>

          <Text>
            Caso o e-mail <b>{email}</b> seja um usuário cadastrado na Linkana, você receberá um e-mail na sua caixa de
            entrada com o link de acesso.
          </Text>

          <Text size="small" color="disabled">
            Este processo pode levar alguns minutos.
          </Text>
        </div>
      ) : (
        <>
          <Heading>Escolha sua forma de login</Heading>

          <div className="flex flex-col gap-3">
            <div className="flex w-full flex-col gap-1">
              <Label>E-mail</Label>
              <Input disabled value={email} />
            </div>

            <Button type="button" onClick={onClick} disabled={submitting}>
              Enviar link de acesso
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default LoginWithoutPassword;
