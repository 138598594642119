import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { useToast } from "DS/ui/use-toast";

import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { Box, Text, Anchor } from "grommet";

import WorkflowsForm from "Organisms/UBuyer/Settings/Workflows/WorkflowsForm";

/** @internal */
export const QUERY = gql`
  query WorkflowsEdit($buyerId: ID!, $settingWorkflowId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingWorkflow(settingWorkflowId: $settingWorkflowId) {
        id
        displayName
        settingWorkflowSteps {
          id
          displayName
          mainApprover {
            id
            email
          }
          approvers {
            id
            email
          }
        }
      }
    }
  }
`;

/** @internal */
export const MUTATION = gql`
  mutation WorkflowsEdit(
    $settingWorkflowId: ID!
    $displayName: String!
    $settingWorkflowStepsAttributes: [SettingWorkflowStepInput!]!
  ) {
    updateSettingWorkflow(
      input: {
        id: $settingWorkflowId
        displayName: $displayName
        settingWorkflowStepsAttributes: $settingWorkflowStepsAttributes
      }
    ) {
      settingWorkflow {
        id
        displayName
        settingWorkflowSteps {
          id
          displayName
          mainApprover {
            id
            email
          }
          approvers {
            id
            email
          }
        }
      }
    }
  }
`;

/** @internal */
export function parseSteps(settingWorkflowStepsAttributes) {
  return settingWorkflowStepsAttributes.map((step) => {
    const { id, displayName, approvers, mainApprover } = step;

    const approverIds = approvers.map((user) => user.id);
    const userId = mainApprover.id;

    return { id, displayName, userId, approverIds };
  });
}

function WorkflowsEdit() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { buyerId, settingWorkflowId } = useParams();
  const [updateSettingWorkflow, { loading }] = useMutation(MUTATION);

  const { loading: queryLoading, data } = useQuery(QUERY, {
    variables: { buyerId, settingWorkflowId },
  });

  if (queryLoading) return <GlobalLoading />;

  const { settingWorkflow } = data.buyer;

  const onSubmit = async ({ displayName, settingWorkflowStepsAttributes }) => {
    await updateSettingWorkflow({ variables: { settingWorkflowId, displayName, settingWorkflowStepsAttributes } });
    toast({ title: "Fluxo de aprovação editado com sucesso!" });
    navigate(`../${settingWorkflowId}`);
  };

  return (
    <Box className="px-4 py-2 lg:py-6 lg:px-10">
      <Box direction="row" align="center" gap="small">
        <Anchor label="Fluxos de aprovação" onClick={() => navigate("../.")} weight="normal" size="22px" />
        <Text size="22px">&gt;</Text>
      </Box>

      <Text size="large" color="dark-2" weight="bold" margin="none">
        {settingWorkflow.displayName}
      </Text>

      <Box margin={{ vertical: "medium" }} flex={false}>
        <Text color="dark-1">
          Você está no modo edição de fluxo de aprovação. Você conseguirá criar quais serão as alçadas de aprovação do
          fluxo e quais usuários estarão vinculados a ele. Vale ressaltar que apenas usuários criados na Linkana poderão
          se vincular a fluxos de aprovação.
        </Text>
      </Box>

      <WorkflowsForm
        initialValues={{
          displayName: settingWorkflow.displayName,
          settingWorkflowStepsAttributes: parseSteps(settingWorkflow.settingWorkflowSteps),
        }}
        onSubmit={onSubmit}
        submitButtonLabel="Salvar edição"
        cancelButtonLabel="Cancelar edição"
        submitting={loading}
        removable
      />
    </Box>
  );
}

export default WorkflowsEdit;
