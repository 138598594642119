import React from "react";
import { useParams } from "react-router-dom";

import { Download } from "lucide-react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";

/** @internal */
export const MUTATION = gql`
  mutation CreateSettingPerformanceFormReportCsv($settingPerformanceFormId: ID!) {
    createSettingPerformanceFormReportCsv(input: { settingPerformanceFormId: $settingPerformanceFormId }) {
      errors {
        message
        path
      }
    }
  }
`;

function CreateSettingPerformanceFormReportCsv() {
  const { toast } = useToast();
  const { settingPerformanceFormId } = useParams();

  const [createSettingPerformanceFormReportCsv, { loading }] = useMutation(MUTATION);

  const onClick = () => {
    createSettingPerformanceFormReportCsv({
      variables: { settingPerformanceFormId },
    });

    toast({
      title: "Respostas exportadas com sucesso!",
      description: "Em breve você as receberá por e-mail.",
    });
  };

  return (
    <Button type="button" variant="outline" onClick={onClick} disabled={loading}>
      <Download className="mr-2 h-4 w-4" /> Exportar respostas
    </Button>
  );
}

export default CreateSettingPerformanceFormReportCsv;
