import React from "react";
import PropTypes from "prop-types";

import { Link2 } from "lucide-react";

import { Button } from "DS/ui/button";

function ShareSupplierDialog({ supplierId }) {
  const meta = document.querySelector("[name='csrf-token']");
  const token = meta ? meta.content : "";

  return (
    <form action={`/buyers/suppliers/${supplierId}/shareable_tokens`} method="POST">
      <input type="hidden" name="authenticity_token" value={token} />

      <Button type="submit" variant="ghost" className="p-6">
        <Link2 className="mr-2 h-4 w-4" />
        Compartilhar homologação
      </Button>
    </form>
  );
}

ShareSupplierDialog.propTypes = { supplierId: PropTypes.string.isRequired };

export default ShareSupplierDialog;
