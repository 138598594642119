import React from "react";
import PropTypes from "prop-types";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "DS/ui/table";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

import EmptyList from "Assets/settings/shared/EmptyList.png";

function WorkflowsTable({ workflows }) {
  if (workflows.length === 0)
    return (
      <div className="flex flex-col items-center justify-center gap-4">
        <img alt="Empty List" src={EmptyList} className="w-32" />
        <Text>Nenhum fluxo de aprovação encontrado...</Text>
      </div>
    );

  return (
    <div className="rounded-md border border-slate-200">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">
              <Text size="small" weight="medium">
                Fluxo de aprovação
              </Text>
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {workflows.map((workflow) => (
            <TableRow key={workflow.id}>
              <TableCell className="p-4">
                <Anchor to={workflow.id}>{workflow.displayName}</Anchor>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

WorkflowsTable.propTypes = {
  workflows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ).isRequired,
};

export default WorkflowsTable;
