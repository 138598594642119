import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

/** @internal */
export const QUERY = gql`
  query useOwnerChecker {
    user {
      id
      owner
    }
  }
`;

const useOwnerChecker = () => {
  const { loading, data } = useQuery(QUERY);

  if (loading) return { loading: true };

  const { owner } = data.user;

  return { loading: false, owner };
};

export default useOwnerChecker;
