import _ from "lodash";

const validateCpf = (cpf) => {
  if (!cpf) return false;

  const parsedCpf = String(cpf).replace(/\D/g, "");

  if (parsedCpf.length !== 11) return false;

  if (_.uniq(parsedCpf).length === 1) return false;

  let add = 0;
  for (let i = 0; i < 9; i += 1) add += Number(parsedCpf.charAt(i)) * (10 - i);

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;

  if (rev !== Number(parsedCpf.charAt(9))) return false;

  add = 0;
  for (let i = 0; i < 10; i += 1) add += Number(parsedCpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) rev = 0;

  return rev === Number(parsedCpf.charAt(10));
};

const validateCnpj = (cnpj) => {
  if (!cnpj) return false;

  const parsedCnpj = String(cnpj).replace(/\D/g, "");

  if (parsedCnpj.length !== 14) return false;

  if (_.uniq(parsedCnpj).length === 1) return false;

  let sum = 0;
  let size = parsedCnpj.length - 2;
  let numbers = parsedCnpj.substring(0, size);

  let pos = size - 7;
  for (let i = size; i >= 1; i -= 1) {
    sum += numbers.charAt(size - i) * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }

  const digits = parsedCnpj.substring(size);
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result !== Number(digits.charAt(0))) return false;

  sum = 0;
  size += 1;
  pos = size - 7;
  numbers = parsedCnpj.substring(0, size);

  for (let i = size; i >= 1; i -= 1) {
    sum += numbers.charAt(size - i) * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  return result === Number(digits.charAt(1));
};

export { validateCpf, validateCnpj };
