import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { ChevronRight } from "lucide-react";

import { Text } from "./text";
import { Anchor } from "./anchor";

function Breadcrumb({ levels }) {
  return (
    <div className="flex flex-row flex-wrap items-center gap-1">
      {levels.map((level, index) => {
        let path = "./";

        const paths = levels.length - (index + 1);

        _.times(paths).forEach(() => {
          path += "../";
        });

        return (
          <div key={level} className="flex flex-row items-center gap-1">
            {index ? <ChevronRight className="w-5 text-slate-400" /> : null}

            {paths ? (
              <Anchor to={path.slice(0, -1)}>{level}</Anchor>
            ) : (
              <Text size="small" weight="medium">
                {level}
              </Text>
            )}
          </div>
        );
      })}
    </div>
  );
}

Breadcrumb.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { Breadcrumb }; // eslint-disable-line import/prefer-default-export
