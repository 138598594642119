import React from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { useToast } from "DS/ui/use-toast";

import CategoryNewForm from "Organisms/UBuyer/Settings/Categories/CategoryNewForm";

/** @internal */
export const MUTATION = gql`
  mutation categoryNew($buyerId: ID!, $settingCategory: SettingCategoryInput!) {
    addSettingCategory(input: { buyerId: $buyerId, settingCategory: $settingCategory }) {
      settingCategory {
        id
        buyer {
          id
          hasPfSettingCategories
          hasInternationalSettingCategories
          settingCategoriesEnabled {
            id
            displayName
            legalEntity
            hasSettingForms
          }
        }
      }
    }
  }
`;

function CategoryNew() {
  const { toast } = useToast();
  const { buyerId, legalEntity } = useParams();
  const navigate = useNavigate();
  const [addSettingCategory, { loading }] = useMutation(MUTATION);

  const onSubmit = async (values) => {
    const settingRisksAttributes = [];

    _.forEach(values.settingDocuments, (risk, settingDocumentId) => {
      settingRisksAttributes.push({ risk: Number(risk), settingDocumentId });
    });

    const { data } = await addSettingCategory({
      variables: {
        buyerId,
        settingCategory: {
          settingRisksAttributes,
          legalEntity: values.legalEntity,
          displayName: values.displayName,
          keepActiveOnSupplierReview: values.keepActiveOnSupplierReview,
        },
      },
    });

    const { settingCategory } = data.addSettingCategory;

    toast({ title: "Categoria criada com sucesso!" });
    navigate(`./../../${settingCategory.id}`);
  };

  return <CategoryNewForm legalEntity={legalEntity.toUpperCase()} onSubmit={onSubmit} submitting={loading} />;
}

export default CategoryNew;
