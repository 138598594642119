import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { cn } from "DS/lib/utils";
import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";

import formatCurrency from "Config/Currency";
import formatDate from "Services/DateToString";
import { ACTIVE } from "Config/PUF";

/** @internal */
export const QUERY = gql`
  query InfoCNPJ($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      branch {
        id
        ddd1
        rais
        porte
        situacao
        telefone1
        razaoSocial
        nomeFantasia
        opcaoPeloMei
        capitalSocial
        opcaoPeloSimples
        correioEletronico
        dataInicioAtividade
        dataSituacaoCadastral
        descricaoNaturezaJuridica
      }
    }
  }
`;

const SKELETON = (
  <div className="mt-4 gap-x-4 border-b pb-4 lg:grid lg:grid-cols-[288px_1fr_1fr]">
    <Skeleton className="h-24 w-64 max-w-[286px]" />
    <div className="flex flex-col gap-4">
      <Skeleton className="h-8 w-96" repeat={6} />
    </div>
    <div className="flex flex-col gap-4">
      <Skeleton className="h-8 w-96" repeat={5} />
    </div>
  </div>
);

function InfoCNPJ() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { branch } = data.supplier;

  return (
    <div className="grid-flow-dense gap-x-4 border-b pb-4 lg:grid lg:grid-cols-[288px_1fr_1fr]">
      <div className="mb-4 flex flex-col gap-1">
        <p className="text-base font-bold text-gray-700">Cartão CNPJ</p>

        <p className="text-sm text-gray-500">
          Todos os dados que estão presentes no cartão CNPJ do fornecedor, emitido pela Receita Federal do Brasil.
        </p>
      </div>

      <div className="flex flex-col gap-y-2 lg:gap-y-6">
        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Razão social</p>
          <p className="text-sm font-medium text-gray-700">{branch.razaoSocial}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Situação cadastral</p>

          <div className="flex flex-row items-center gap-2">
            <div
              className={cn(
                "h-2.5 w-2.5 flex-none rounded-full",
                branch.situacao === ACTIVE ? "bg-green-600" : "bg-red-600",
              )}
            />

            <Text size="small" weight="medium">
              Empresa {branch.situacao}
            </Text>
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">MEI</p>
          <p className="text-sm font-medium text-gray-700">{branch.opcaoPeloMei ? "Sim" : "Não"}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Data de abertura</p>
          <p className="text-sm font-medium text-gray-700">{formatDate(branch.dataInicioAtividade)}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Capital social</p>
          <p className="text-sm font-medium text-gray-700">{formatCurrency(branch.capitalSocial)}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">E-mail</p>
          <p className="text-sm font-medium text-gray-700">{branch.correioEletronico}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">N.º de funcionários da RAIS de 2021</p>

          <p className="text-sm font-medium text-gray-700">{branch.rais || "Não informado"}</p>
        </div>
      </div>

      <div className="flex flex-col gap-y-2 lg:gap-y-6">
        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Nome fantasia</p>
          <p className="text-sm font-medium text-gray-700">{branch.nomeFantasia}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Porte (RFB)</p>
          <p className="text-sm font-medium text-gray-700">{branch.porte}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Simples</p>
          <p className="text-sm font-medium text-gray-700">{branch.opcaoPeloSimples}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Natureza jurídica</p>
          <p className="text-sm font-medium text-gray-700">{branch.descricaoNaturezaJuridica}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Última atualização</p>
          <p className="text-sm font-medium text-gray-700">{formatDate(branch.dataSituacaoCadastral)}</p>
        </div>

        <div className="flex flex-row gap-2">
          <p className="w-32 flex-none text-sm text-gray-500">Telefone</p>
          <p className="text-sm font-medium text-gray-700">
            {branch.ddd1} {branch.telefone1}
          </p>
        </div>
      </div>
    </div>
  );
}

export default InfoCNPJ;
