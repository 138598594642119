import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { FormItem, FormLabel, FormControl, FormDescription, FormMessage } from "DS/ui/form";

import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "DS/ui/select";

const SelectBuyerDocumentField = forwardRef(({ value, onChange, formField }, ref) => (
  <FormItem>
    <FormLabel>
      {formField.displayName}
      {formField.optional ? " (opcional)" : ""}
    </FormLabel>
    <Select onValueChange={onChange} defaultValue={value}>
      <FormControl>
        <SelectTrigger>
          <SelectValue placeholder="Selecione" />
        </SelectTrigger>
      </FormControl>
      <SelectContent className="max-h-56 overflow-auto">
        {formField.options.map((option) => (
          <SelectItem ref={ref} key={option} value={option}>
            {option}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
    <FormDescription>{formField.hint}</FormDescription>
    <FormMessage />
  </FormItem>
));

SelectBuyerDocumentField.defaultProps = {
  value: undefined,
};

SelectBuyerDocumentField.propTypes = {
  formField: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
    hint: PropTypes.string,
    optional: PropTypes.bool,
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SelectBuyerDocumentField;
