import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

import logout from "Services/Authentication/Logout";

import z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { REQUIRED_EMAIL_WITH_REGEX } from "Config/Zod";

import LogoSVG from "Assets/logo-brand.svg";
import BuyerLoginSVG from "Assets/buyer-login-min.svg";

import { Input } from "DS/ui/input";
import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";

import { Form, FormItem, FormField, FormLabel, FormMessage, FormControl } from "DS/ui/form";

/** @internal */
export const LAZY_QUERY = gql`
  query EmailInput($email: String!) {
    loginMethod(email: $email)
  }
`;

const ZOD_SCHEMA = z.object({ email: REQUIRED_EMAIL_WITH_REGEX });

function EmailInput() {
  const navigate = useNavigate();
  const { setLayoutInfo } = useOutletContext();
  const [userByEmail, { loading }] = useLazyQuery(LAZY_QUERY);

  const defaultValues = { email: "" };
  const form = useForm({ resolver: zodResolver(ZOD_SCHEMA), defaultValues });

  useEffect(() => {
    setLayoutInfo({
      svg: BuyerLoginSVG,
      heading: "Seja bem-vindo a Linkana",
      text: "Faça o login e aproveite o melhor da homologação de fornecedores.",
    });
  }, [setLayoutInfo]);

  useEffect(() => {
    logout();
  }, []);

  const onSubmit = async (values) => {
    const { email } = values;
    const withState = { state: { email } };
    const { data } = await userByEmail({ variables: { email } });

    switch (data.loginMethod) {
      case "MULTIPLE_METHODS":
        return navigate("multiple-methods", withState);
      case "PASSWORD_ONLY":
        return navigate("password-only", withState);
      default:
        return navigate("idp-only", withState);
    }
  };

  return (
    <>
      <img src={LogoSVG} alt="Linkana Logo" className="w-32" />

      <Heading>Digite o seu e-mail</Heading>

      <Form {...form}>
        <form
          id="EmailInput"
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col items-center justify-center gap-3"
        >
          <FormField
            name="email"
            control={form.control}
            render={({ field }) => (
              <FormItem className="w-full">
                <div className="flex flex-col gap-1">
                  <FormLabel htmlFor="email">E-mail</FormLabel>

                  <FormControl>
                    <Input {...field} id="email" placeholder="Digite seu email..." />
                  </FormControl>

                  <FormMessage />
                </div>
              </FormItem>
            )}
          />

          <Button type="submit" form="EmailInput" className="w-full" disabled={loading}>
            Continuar
          </Button>
        </form>
      </Form>
    </>
  );
}

export default EmailInput;
