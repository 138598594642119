import React from "react";

import { Button } from "DS/ui/button";
import { useToast } from "DS/ui/use-toast";
import { Upload } from "lucide-react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

/** @internal */
export const MUTATION = gql`
  mutation suppliersReportRequestButton {
    suppliersReportRequest(input: {}) {
      errors {
        message
        path
      }
    }
  }
`;

function SuppliersReportRequestButton() {
  const { toast } = useToast();
  const [suppliersReportRequest, { loading }] = useMutation(MUTATION);

  return (
    <Button
      type="button"
      disabled={loading}
      variant="outline"
      onClick={() => {
        suppliersReportRequest();

        toast({
          title: "Relatório solicitado com sucesso!",
          description: "Em breve você o receberá por e-mail.",
        });
      }}
    >
      <Upload size={20} strokeWidth={2} className="mr-2" /> Exportar
    </Button>
  );
}

export default SuppliersReportRequestButton;
