import React from "react";

import { Grommet, Main, Box, Heading, Paragraph } from "grommet";
import Theme from "Config/Theme";

function FallbackComponent() {
  return (
    <Grommet full theme={Theme} plain>
      <Main pad="large">
        <Box width="large" align="center" alignSelf="center">
          <Heading>Algo deu errado =(</Heading>
          <Paragraph>Por favor, conte-nos o que ocorreu.</Paragraph>
        </Box>
      </Main>
    </Grommet>
  );
}

export default FallbackComponent;
