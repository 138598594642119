import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { useToast } from "DS/ui/use-toast";
import Skeleton from "DS/custom/skeleton";

import PerformanceFormForm, {
  addOrderToQuestions,
} from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormForm";

const MUTATION = gql`
  mutation PerformanceFormEdit(
    $displayName: String!
    $commentRequired: Boolean!
    $settingPerformanceFormId: ID!
    $settingPerformanceQuestionnairesAttributes: [SettingPerformanceQuestionnaireInput!]!
  ) {
    updateSettingPerformanceForm(
      input: {
        displayName: $displayName
        commentRequired: $commentRequired
        settingPerformanceFormId: $settingPerformanceFormId
        settingPerformanceQuestionnairesAttributes: $settingPerformanceQuestionnairesAttributes
      }
    ) {
      settingPerformanceForm {
        id
        displayName
        commentRequired
        settingPerformanceQuestionnaires {
          id
          weight
          displayName
          settingPerformanceQuestions {
            id
            displayName
          }
        }
      }
    }
  }
`;

/** @internal */
export const QUERY = gql`
  query PerformanceFormEdit($buyerId: ID!, $settingPerformanceFormId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingPerformanceForm(settingPerformanceFormId: $settingPerformanceFormId) {
        id
        displayName
        commentRequired
        settingPerformanceQuestionnairesAttributes: settingPerformanceQuestionnaires {
          id
          weight
          displayName
          settingPerformanceQuestionsAttributes: settingPerformanceQuestions {
            id
            displayName
          }
        }
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
    <div className="flex flex-row flex-wrap justify-between">
      <Skeleton className="h-6 w-96" />
      <Skeleton className="h-10 w-72" />
    </div>
    <div className="gap-x-8 gap-y-4 border-b pb-5 lg:grid lg:grid-cols-[286px_1fr]">
      <div className="flex w-72 flex-col gap-1">
        <Skeleton className="h-6 w-20" />
        <Skeleton className="h-16 w-72" />
      </div>
      <div className="flex flex-col gap-3">
        <Skeleton className="h-12 w-72" repeat={2} />
      </div>
    </div>
    <div className="gap-x-8 gap-y-4 lg:grid lg:grid-cols-[286px_1fr]">
      <div className="flex w-72 flex-col gap-1">
        <Skeleton className="h-6 w-20" />
        <Skeleton className="h-40 w-72" />
      </div>
      <div className="flex flex-col gap-3">
        <Skeleton className="h-96 w-full" />
      </div>
    </div>
  </div>
);

function PerformanceFormEdit() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { buyerId, settingPerformanceFormId } = useParams();
  const [updateSettingPerformanceForm, { loading }] = useMutation(MUTATION);

  const { data, loading: qLoading } = useQuery(QUERY, {
    variables: { buyerId, settingPerformanceFormId },
  });

  if (qLoading) return SKELETON;

  const { settingPerformanceForm } = data.buyer;

  const onSubmit = async ({ displayName, commentRequired, settingPerformanceQuestionnairesAttributes }) => {
    const orderedQuestionnaires = addOrderToQuestions([...settingPerformanceQuestionnairesAttributes]);

    await updateSettingPerformanceForm({
      variables: {
        displayName,
        commentRequired,
        settingPerformanceFormId,
        settingPerformanceQuestionnairesAttributes: orderedQuestionnaires,
      },
    });

    toast({ title: "Formulário de performance editado com sucesso!" });
    navigate("./..");
  };

  return (
    <PerformanceFormForm
      loading={loading}
      onSubmit={onSubmit}
      defaultValues={settingPerformanceForm}
      breadcrumb={{
        firstPage: "Performance",
        lastPage: settingPerformanceForm.displayName,
        currentPage: "Editando",
      }}
    />
  );
}

export default PerformanceFormEdit;
