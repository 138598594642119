import React from "react";
import PropTypes from "prop-types";

import { Box, Text, Button, List, Anchor, FormField } from "grommet";

function WorkflowsListSteps({ onClick, onRevert, settingWorkflowStepsAttributes }) {
  return (
    <FormField
      name="settingWorkflowStepsAttributes"
      value={settingWorkflowStepsAttributes}
      contentProps={{ border: false }}
    >
      {!settingWorkflowStepsAttributes.length ? (
        <Text color="dark-3">Você ainda não adicionou nenhuma alçada. Adicione a primeira!</Text>
      ) : (
        <List data={settingWorkflowStepsAttributes} onClickItem={({ item, index }) => !item._destroy && onClick(index)}>
          {(step, index) => (
            <Box direction="row" justify="between" align="center">
              <Anchor
                key={`step-${index}`}
                weight="normal"
                disabled={step._destroy}
                label={
                  <Box direction="row" align="center" gap="xxsmall">
                    <Text size="16px" color="brand">
                      {step.displayName}
                    </Text>

                    {step._destroy ? (
                      <Text size="small" color="brand">
                        (excluído)
                      </Text>
                    ) : null}
                  </Box>
                }
              />

              {step._destroy ? (
                <Button
                  plain
                  onClick={(event) => {
                    event.stopPropagation();
                    onRevert(index);
                  }}
                  label={
                    <Text color="brand" size="small">
                      Reverter
                    </Text>
                  }
                />
              ) : null}
            </Box>
          )}
        </List>
      )}
    </FormField>
  );
}

WorkflowsListSteps.propTypes = {
  settingWorkflowStepsAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      _destroy: PropTypes.bool,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
};

export default WorkflowsListSteps;
