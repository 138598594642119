import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { AlertTriangle } from "lucide-react";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";
import { Heading } from "DS/custom/heading";

import {
  Dialog,
  DialogTitle,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogDescription,
} from "DS/ui/dialog";

import RegularizationForm from "Organisms/UBuyer/SupplierHeader/Regularization/RegularizationForm";
import RegularizationSwitch from "Organisms/UBuyer/SupplierHeader/Regularization/RegularizationSwitch";

/** @internal */
export const QUERY = gql`
  query RegularizationDialog($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      regularization
      user {
        id
        email
      }
      issues {
        id
        displayName
      }
    }
  }
`;

function RegularizationDialog() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { loading, data } = useQuery(QUERY, { variables: { id } });

  if (loading) return <Skeleton className="h-10 w-full" />;

  const { supplier } = data;
  const { regularization } = supplier;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button type="button" variant="ghost" className="p-4">
          <AlertTriangle className="mr-2 h-4 w-4" />

          {regularization ? "Remover regularização" : "Solicitar regularização"}
        </Button>
      </DialogTrigger>

      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle asChild>
            <Heading>Regularização</Heading>
          </DialogTitle>

          <DialogDescription asChild>
            <Text size="small" color="secondary">
              Você pode cobrar a regularização de pendências da sua escolha. Ao marcar a opção, você poderá escrever
              sobre as pendências que devem ser regularizadas.
            </Text>
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-3">
          <RegularizationSwitch inRegularization={regularization} />

          {regularization ? (
            <RegularizationForm
              user={supplier.user}
              issues={supplier.issues}
              setSubmitting={setSubmitting}
              onClose={() => setOpen(false)}
            />
          ) : null}
        </div>

        {regularization ? (
          <DialogFooter>
            <Button type="submit" disabled={submitting} form="RegularizationForm">
              Enviar solicitação
            </Button>
          </DialogFooter>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export default RegularizationDialog;
