import axios from "axios";
import client from "Config/GraphqlApiService";
import KEY from "./Config";

const logout = () =>
  new Promise((res) => {
    axios.get("/users/sign_out.json").then(() => {
      if (localStorage.getItem(KEY)) {
        localStorage.removeItem(KEY);
        client.clearStore();
      }

      res(true);

      if (window.location.pathname !== "/login") {
        window.location.assign("/login");
      }
    });
  });

export default logout;
