import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";
import { Anchor } from "DS/custom/anchor";
import { AlertCircle, MapPin } from "lucide-react";

import { formatCnpj, formatCEP } from "Services/RFFormatter";
import Branch from "Organisms/UBuyer/Companies/PUF/InfoCNPJ/Branches/Branch";

/** @internal */
export const QUERY = gql`
  query Branches($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      branch {
        id
        mf
        uf
        cep
        cnpj
        numero
        logradouro
        descTipoLogradouro
        descricaoMunicipio
      }
      branches {
        id
        mf
        uf
        cep
        cnpj
        numero
        situacao
        cnaeFiscal
        logradouro
        cnaeFiscalTexto
        descTipoLogradouro
        descricaoMunicipio
      }
    }
  }
`;

const SKELETON = (
  <div className="mt-4 gap-x-4 lg:grid lg:grid-cols-[288px_1fr_1fr]">
    <Skeleton className="h-24 w-64" />
    <div className="col-span-2">
      <Skeleton className="h-36 w-44" />
      <Skeleton className="mt-2 h-6 w-80" />
    </div>
  </div>
);

function Branches() {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY, { variables: { id } });

  if (loading) return SKELETON;

  const { branch, branches } = data.supplier;

  return (
    <div className="mt-4 gap-x-4 lg:grid lg:grid-cols-[286px_1fr_1fr]">
      <div className="flex max-w-[265px] flex-col gap-1">
        <p className="text-base font-bold text-gray-700">Matriz e filiais</p>

        <p className="text-sm text-gray-500">
          Estabelecimentos que pertencem a um mesmo CNPJ básico, sendo divididos por matrizes e filiais.
        </p>
      </div>

      <div className="col-span-2">
        <p className="text-sm font-medium text-gray-700">{branch.mf === "1" ? "Matriz" : "Filial"}</p>

        <p className="text-sm text-gray-700">{formatCnpj(branch.cnpj)}</p>

        <p className="mb-4 w-60 text-sm text-gray-500">
          {branch.descTipoLogradouro} {branch.logradouro}, {branch.numero} {branch.descricaoMunicipio}-{branch.uf} -{" "}
          {formatCEP(branch.cep)}
          <Anchor to="location" className="mt-1 flex flex-row items-center gap-1 no-underline">
            <MapPin size={20} />
            Ver no mapa
          </Anchor>
        </p>
      </div>

      <div />

      {branches.length ? (
        <>
          <div className="flex flex-col gap-2 lg:gap-4">
            <Branch branch={branches[0]} />
            <Branch branch={branches[2]} />

            {branches.length > 4 ? (
              <Anchor to="branches" className="mb-2 no-underline">
                Ver todos os estabelecimentos
              </Anchor>
            ) : null}
          </div>

          <div className="flex flex-col gap-2 lg:gap-4">
            <Branch branch={branches[1]} />
            <Branch branch={branches[3]} />
          </div>
        </>
      ) : (
        <div className="col-span-2 flex flex-row items-center gap-1">
          <AlertCircle size={26} className="flex-none text-gray-500" />

          <p className="text-sm font-medium text-gray-700">Essa empresa não possui endereços de filiais.</p>
        </div>
      )}
    </div>
  );
}

export default Branches;
