import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import { ArrowLeft } from "lucide-react";

import z from "zod";
import { useForm } from "react-hook-form";
import { REQUIRED_STRING } from "Config/Zod";
import { zodResolver } from "@hookform/resolvers/zod";

import LogoSVG from "Assets/logo-brand.svg";
import BuyerLoginSVG from "Assets/buyer-login-min.svg";

import login from "Services/Authentication/Login";

import { Input } from "DS/ui/input";
import { Label } from "DS/ui/label";
import { Button } from "DS/ui/button";
import { Anchor } from "DS/custom/anchor";
import { Heading } from "DS/custom/heading";
import { useToast } from "DS/ui/use-toast";

import { Form, FormItem, FormField, FormLabel, FormMessage, FormControl } from "DS/ui/form";

const ZOD_SCHEMA = z.object({ password: REQUIRED_STRING });

function PasswordOnlyLogin() {
  const [submitting, setSubmitting] = useState(false);
  const { setLayoutInfo } = useOutletContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { email } = state;

  const defaultValues = { password: "" };
  const form = useForm({ resolver: zodResolver(ZOD_SCHEMA), defaultValues });

  useEffect(() => {
    setLayoutInfo({
      svg: BuyerLoginSVG,
      heading: "Os melhores compradores confiam na Linkana",
      text: "Analise e homologue fornecedores em segundos, não semanas.",
    });
  }, [setLayoutInfo]);

  const onSubmit = async ({ password }) => {
    setSubmitting(true);

    const { error } = await login({ email, password });

    if (error) {
      const title = "Erro no login!";
      toast({ title, variant: "destructive", description: error });
    }

    setSubmitting(false);
  };

  return (
    <>
      <img src={LogoSVG} alt="Linkana Logo" className="w-32" />

      <Anchor className="flex items-center gap-2" onClick={() => navigate(-1)}>
        <ArrowLeft size={16} className="text-blue-600" strokeWidth={2.5} />
        Voltar
      </Anchor>

      <Heading>Digite a sua senha para entrar</Heading>

      <Form {...form}>
        <form id="PasswordOnlyLogin" className="flex flex-col gap-3" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex w-full flex-col gap-1">
            <Label>E-mail</Label>
            <Input disabled value={email} />
          </div>

          <FormField
            name="password"
            control={form.control}
            render={({ field }) => (
              <FormItem className="w-full">
                <div className="flex flex-col gap-1">
                  <FormLabel htmlFor="password">Senha</FormLabel>

                  <FormControl>
                    <Input {...field} id="password" type="password" placeholder="Digite sua senha..." />
                  </FormControl>

                  <FormMessage />
                </div>
              </FormItem>
            )}
          />

          <Anchor className="self-end px-4 py-2" onClick={() => navigate("../forgot-password", { state })}>
            Esqueceu sua senha?
          </Anchor>

          <Button type="submit" disabled={submitting} form="PasswordOnlyLogin">
            Entrar
          </Button>
        </form>
      </Form>
    </>
  );
}

export default PasswordOnlyLogin;
