import React from "react";
import { useSearchParams, Navigate } from "react-router-dom";

import { Building } from "lucide-react";

import { Text } from "DS/custom/text";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { formatCnpj } from "Services/RFFormatter";
import { NATIONAL_PJ } from "Config/LegalEntity";

import useSettingCategories from "Organisms/UBuyer/Companies/Index/AddSupplier/SettingCategory/useSettingCategories";

import BaseSupplierForm, { SKELETON } from "Organisms/UBuyer/Companies/Index/AddSupplier/BaseSupplierForm";

function AddPJSupplierManuallyForm() {
  const [searchParams] = useSearchParams();
  const { name, identifier } = Object.fromEntries(searchParams);
  const { loading, settingCategories } = useSettingCategories();

  if (loading) return SKELETON;

  if (!name || !identifier) return <Navigate to=".." />;

  const categories = settingCategories.filter(({ legalEntity }) => legalEntity === NATIONAL_PJ);

  return (
    <>
      <Breadcrumb levels={["Fornecedores", "Iniciar homologação", "Pessoa jurídica", "Dados da homologação"]} />

      <BaseSupplierForm categories={categories}>
        <div className="flex flex-col gap-1 max-w-5xl rounded-md border p-6 bg-zinc-50">
          <Text size="small" weight="medium">
            {name}
          </Text>

          <div className="flex flex-row items-center gap-2">
            <Building size={16} className="text-slate-400" />
            <Text size="small">{formatCnpj(identifier)}</Text>
          </div>
        </div>
      </BaseSupplierForm>
    </>
  );
}

export default AddPJSupplierManuallyForm;
