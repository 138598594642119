import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

/** @internal */
export const MUTATION = gql`
  mutation useResetPasswordRequest($email: String!) {
    resetPasswordRequest(input: { email: $email }) {
      error
    }
  }
`;

const useResetPasswordRequest = () => {
  const [mutation, hash] = useMutation(MUTATION);

  const mutationCallback = ({ email }) => mutation({ variables: { email } });

  return [mutationCallback, hash];
};

export default useResetPasswordRequest;
