export const CLEAR = "CLEAR";
export const INFORMATIVE = "INFORMATIVE";

export const RISK = {
  0: { label: "menos 0 pontos", color: "text-green-700" },
  7: { label: "menos 7 pontos", color: "text-blue-600" },
  20: { label: "menos 20 pontos", color: "text-yellow-700" },
  60: { label: "menos 60 pontos", color: "text-orange-600" },
  100: { label: "menos 100 pontos", color: "text-red-700" },
};
