import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";
import Skeleton from "DS/custom/skeleton";

import formatDate from "Services/DateToString";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import CreateSettingPerformanceFormReportCsv from "Organisms/UBuyer/Settings/PerformanceForms/CreateSettingPerformanceFormReportCsv";

/** @internal */
export const QUERY = gql`
  query PerformanceFormInfo($buyerId: ID!, $settingPerformanceFormId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingPerformanceForm(settingPerformanceFormId: $settingPerformanceFormId) {
        id
        displayName
        createdAt
        updatedAt
        updatedBy
        settingPerformanceQuestionnaires {
          id
          weight
          displayName
          settingPerformanceQuestions {
            id
            displayName
          }
        }
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
    <div className="flex flex-row items-center justify-between">
      <Skeleton className="h-6 w-80" />
      <Skeleton className="h-10 w-20" />
    </div>
    <div className="flex flex-row flex-wrap justify-between gap-4">
      <Skeleton className="h-6 w-72" />
      <Skeleton className="h-11 w-72" />
    </div>
    <div className="-mt-2 flex flex-col gap-4">
      <Skeleton className="h-6 w-96" repeat={8} />
    </div>
  </div>
);

function PerformanceFormInfo() {
  const navigate = useNavigate();
  const { settingPerformanceFormId, buyerId } = useParams();

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId, settingPerformanceFormId },
  });

  if (loading) return SKELETON;

  const { settingPerformanceForm } = data.buyer;
  const { settingPerformanceQuestionnaires } = settingPerformanceForm;

  return (
    <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
      <div className="flex flex-row items-start justify-between">
        <Breadcrumb lastPage="Performance" currentPage={settingPerformanceForm.displayName} />

        <div className="flex flex-row gap-4">
          <OwnerChecker>
            <Button type="button" onClick={() => navigate("edit")}>
              Editar
            </Button>
          </OwnerChecker>

          <CreateSettingPerformanceFormReportCsv />
        </div>
      </div>

      <div className="flex flex-row flex-wrap justify-between gap-4">
        <Text weight="medium">Detalhes do formulário de performance</Text>

        <div className="flex flex-row flex-wrap items-center gap-6">
          <div className="flex flex-col gap-1">
            <Text size="small" weight="medium">
              Data de criação
            </Text>

            <Text size="small">{formatDate(settingPerformanceForm.createdAt)}</Text>
          </div>

          <div className="flex flex-col gap-1">
            <Text size="small" weight="medium">
              Última atualização
            </Text>

            <Text size="small">{formatDate(settingPerformanceForm.updatedAt)}</Text>
          </div>

          <div className="flex flex-col gap-1">
            <Text size="small" weight="medium">
              Atualizado por
            </Text>

            <Text size="small">{settingPerformanceForm.updatedBy}</Text>
          </div>
        </div>
      </div>

      <div className="-mt-2">
        {settingPerformanceQuestionnaires.map((questionnaire) => (
          <div key={questionnaire.id} className="flex flex-col gap-4 border-b pb-4 pt-5 first:pt-0 last:border-none">
            <div className="flex flex-row items-center gap-2">
              <Text weight="medium" dangerouslySetInnerHTML={{ __html: questionnaire.displayName }} />
              <Badge variant="secondary">Peso {questionnaire.weight}</Badge>
            </div>

            <div>
              {questionnaire.settingPerformanceQuestions.map((question) => (
                <Text key={question.id} className="py-2" dangerouslySetInnerHTML={{ __html: question.displayName }} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PerformanceFormInfo;
