import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Forms from "Organisms/UBuyer/Settings/Forms/Forms";
import BuyerDocuments from "Organisms/UBuyer/Settings/BuyerDocuments/BuyerDocuments";

function Fields() {
  return (
    <Routes>
      <Route path="">
        <Route path="" element={<Navigate to="forms" />} />
        <Route path="forms/*" element={<Forms />} />
        <Route path="buyer_documents/*" element={<BuyerDocuments />} />
      </Route>
    </Routes>
  );
}

export default Fields;
