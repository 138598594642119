import React from "react";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import DetailsAccordion from "Organisms/UBuyer/Companies/PUF/Analysis/ESG/DetailsAccordion";
import useCubeInfo from "Organisms/UBuyer/Companies/PUF/Hooks/useCubeInfo";

import { setBadgeTextColorPending, SKELETON_DETAILS } from "Config/PUF";

function GovAlDetails() {
  const table = "ChecksAl";
  const { resultSet } = useCubeInfo({ table });

  if (!resultSet) return SKELETON_DETAILS;

  const data = resultSet.rawData();

  const cubeInfo = data.map((item) => JSON.parse(item[`${table}.info`]));

  const { variant, text } = setBadgeTextColorPending(cubeInfo.length);

  return (
    <div>
      <Breadcrumb firstPage="Análise Linkana" lastPage="Combate à corrupção" currentPage="Acordo de Leniência" />

      <div className="flex-start mt-4 flex flex-wrap content-start justify-start gap-8 py-4 lg:flex-nowrap">
        <div className="flex max-w-[349px] shrink-0 flex-col gap-2 self-start rounded-lg border border-slate-200 p-6">
          <div className="flex flex-row gap-4 ">
            <div className="flex flex-wrap gap-2">
              <Text size="small" weight="medium">
                Acordo de Leniência
              </Text>

              <Badge variant={variant}>{text}</Badge>
            </div>
          </div>

          <Text size="small">Consulta relativa a empresa e seu QSA.</Text>
        </div>

        <DetailsAccordion cubeInfo={cubeInfo} />
      </div>
    </div>
  );
}

export default GovAlDetails;
