import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Skeleton from "DS/custom/skeleton";

import { Heading } from "DS/custom/heading";

import AdvancedForm from "Organisms/UBuyer/Settings/Advanced/AdvancedForm";

/** @internal */
export const QUERY = gql`
  query Advanced($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      preStateReview
      restrictionListPersonCreatedAt
      restrictionListCompanyCreatedAt
    }
  }
`;

const SKELETON = (
  <div className="p-10 flex max-w-[1500px] flex-grow flex-col gap-4">
    <Skeleton className="h-6 w-96" repeat={3} />
    <div className="w-full border-b" />
    <Skeleton className="h-6 w-96" repeat={9} />
    <div className="w-full border-b" />
    <Skeleton className="h-6 w-96" repeat={7} />
  </div>
);

function Advanced() {
  const { buyerId } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return SKELETON;

  return (
    <div>
      <Heading className="hidden lg:flex space-y-1 px-4 py-2 lg:py-6 lg:px-10 border-b">Config. avançadas</Heading>
      <AdvancedForm buyer={data.buyer} />
    </div>
  );
}

export default Advanced;
