import React from "react";
import propTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";
import { Button } from "DS/ui/button";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
} from "DS/ui/alert-dialog";

/** @internal */
export const MUTATION = gql`
  mutation CategoryDeleteAlert($settingCategoryId: ID!) {
    deleteSettingCategory(input: { settingCategoryId: $settingCategoryId }) {
      settingCategory {
        id
        disabled
        buyer {
          id
          settingCategoriesEnabled {
            id
          }
        }
      }
    }
  }
`;

function CategoryDeleteAlert({ visible, setVisible }) {
  const { id } = useParams();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [deleteSettingCategory, { loading }] = useMutation(MUTATION);

  const onClick = async () => {
    await deleteSettingCategory({ variables: { settingCategoryId: id } });
    toast({ title: "Categoria excluída com sucesso!" });
    navigate("./../..");
  };

  return (
    <AlertDialog open={visible} onOpenChange={setVisible}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle asChild>
            <Heading>Tem certeza que deseja excluir esta categoria?</Heading>
          </AlertDialogTitle>

          <AlertDialogDescription asChild>
            <Text size="small" weight="medium">
              Ao excluir esta categoria, ela não ficará mais visível na criação de uma nova homologação. Entretanto, ela
              continuará sendo aplicada nas homologações já criadas.
            </Text>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <Button
            type="button"
            onClick={onClick}
            disabled={loading}
            variant="destructive"
            aria-label="Excluir categoria"
          >
            Excluir
          </Button>

          <AlertDialogCancel asChild>
            <Button type="button" variant="outline" disabled={loading}>
              Cancelar
            </Button>
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

CategoryDeleteAlert.propTypes = {
  visible: propTypes.bool.isRequired,
  setVisible: propTypes.func.isRequired,
};

export default CategoryDeleteAlert;
