import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Box, Heading, Text, Image } from "grommet";

import InvalidToken from "Assets/auth/invalid_token.png";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import PerformanceFormSent from "Organisms/UPublic/PerformanceForm/PerformanceFormSent";
import PerformanceFormMutation from "Organisms/UPublic/PerformanceForm/PerformanceFormMutation";
import { ANSWERED } from "Config/PerformanceForm";

/** @internal */
export const QUERY = gql`
  query performanceForm($token: String!) {
    performanceForm: performanceFormByToken(token: $token) {
      id
      email
      state
      displayName
      observations
      commentRequired
      supplier {
        id
        name
        nomeFantasia
        buyerLogoUrl
        identifierMasked
      }
      performanceQuestionnaires {
        id
        displayName
        performanceAnswers {
          id
          displayName
        }
      }
    }
  }
`;

function PerformanceForm({ token }) {
  const { data, loading } = useQuery(QUERY, { variables: { token } });

  if (loading) return <GlobalLoading />;

  const { performanceForm } = data;

  if (!performanceForm) {
    return (
      <Box align="center" height="100vh" justify="center">
        <Box height="500px">
          <Image src={InvalidToken} />
        </Box>

        <Heading level="3" color="dark-1">
          O convite para avaliação está expirado ou inválido.
        </Heading>

        <Box width="large">
          <Text color="#444" size="large" width="medium" textAlign="center">
            Entre em contato com o responsável para que ele consiga enviar novamente seu convite de avaliação.
          </Text>
        </Box>
      </Box>
    );
  }

  const { email, state, supplier, displayName, observations } = performanceForm;

  if (state === ANSWERED) return <PerformanceFormSent name={supplier.name} />;

  return (
    <Box margin="auto" pad={{ bottom: "large", top: "medium", horizontal: "medium" }} width="xlarge">
      <Box direction="row" align="start">
        <Box flex>
          <Heading margin={{ top: "none" }}>Avaliação de Desempenho</Heading>
        </Box>

        <Box width="150px">
          <Image src={supplier.buyerLogoUrl} />
        </Box>
      </Box>

      <Box gap="xsmall" margin={{ vertical: "medium" }}>
        <Text color="dark-2" weight="bold">
          Fornecedor: {supplier.name}
        </Text>

        <Text color="dark-2">Nome Fantasia: {supplier.nomeFantasia}</Text>
        <Text color="dark-2">CNPJ: {supplier.identifierMasked}</Text>
        <Text color="dark-2">Formulário: {displayName}</Text>
        <Text color="dark-2">Avaliador: {email}</Text>
        <Text color="dark-2">Observações: {observations}</Text>
      </Box>

      <Box>
        <PerformanceFormMutation token={token} performanceForm={performanceForm} />
      </Box>
    </Box>
  );
}

PerformanceForm.propTypes = { token: PropTypes.string.isRequired };

export default PerformanceForm;
