import React from "react";
import { useOutletContext } from "react-router-dom";

import { AlertCircle } from "lucide-react";

import { useCubeQuery } from "@cubejs-client/react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "DS/ui/accordion";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

import { infoPillar, SKELETON_DETAILS } from "Config/PUF";
import formatCubeInfo from "Services/PUFFormatter";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";

/** @internal */
export const accordionName = (rating) => {
  if (rating === 5) return "Queda inferior a 10%";

  return rating ? "Aumento ou manutenção" : "Queda superior a 10%";
};

function LaborStaffDetails() {
  const { identifier } = useOutletContext();

  const info = "LaborStaff.info";
  const member = "LaborStaff.cnpj";
  const rating = "LaborStaff.labor_item_staff";

  const { resultSet } = useCubeQuery({
    dimensions: [info, rating],
    filters: [{ member, operator: "equals", values: [identifier] }],
  });

  if (!resultSet) return SKELETON_DETAILS;

  const data = resultSet.rawData();

  const cubeInfo = data.map((item) => JSON.parse(item[info]));

  const cube = data[0] || {};

  const { variant, text } = infoPillar(cube[rating]);

  return (
    <div>
      <Breadcrumb firstPage="Análise Linkana" lastPage="Quadro de funcionários" currentPage="Detalhes" />

      <div className="flex-start mt-4 flex flex-wrap content-start justify-start gap-8 py-4 lg:flex-nowrap">
        <div className="flex max-w-[349px] shrink-0 flex-col gap-2 self-start rounded-lg border border-slate-200 p-6">
          <div className="flex flex-row gap-4 ">
            <Text size="small" weight="medium">
              Quadro de funcionários
            </Text>

            <Badge variant={variant}>{text}</Badge>
          </div>

          <Text size="small">
            Realizando uma comparação da quantidade de colaboradores da empresa nos anos de 2021 e 2020, utilizando os
            dados fornecidos pela RAIS.
          </Text>
        </div>

        <div className="flex-start flex w-full flex-col">
          {data.length ? (
            <Accordion collapsible defaultValue="info-0">
              {cubeInfo.map((obj, index) => (
                <AccordionItem key={obj} value={`obj-${index}`}>
                  <AccordionTrigger>
                    <Text size="small" weight="medium">
                      {accordionName(cube[rating])}
                    </Text>
                  </AccordionTrigger>

                  <AccordionContent>
                    <div className="flex flex-col gap-3">
                      {Object.keys(obj).map((key) => (
                        <div key={obj + key} className="flex-col gap-y-1">
                          <Text size="small">{key}</Text>

                          <Text size="small" weight="medium">
                            {formatCubeInfo(obj[key])}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          ) : (
            <div className="flex h-full flex-row items-center gap-2">
              <AlertCircle size={26} />

              <Text size="small">Não foi possível obter a quantidade de funcionários.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LaborStaffDetails;
