import React from "react";
import { useParams } from "react-router-dom";

import { Copy, Blocks } from "lucide-react";

import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";
import { Anchor } from "DS/custom/anchor";

import { Dialog, DialogTitle, DialogHeader, DialogTrigger, DialogContent, DialogDescription } from "DS/ui/dialog";

import { useToast } from "DS/ui/use-toast";

import { APPROVED } from "Config/Qualification";

/** @internal */
export const QUERY = gql`
  query WebhookDialog($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      state
      webhook
      qualification {
        id
        state
      }
    }
  }
`;

/** @internal */
export const MUTATION = gql`
  mutation WebhookDialog($id: ID!) {
    supplierApprovalWebhook(input: { id: $id }) {
      errors {
        path
        message
      }
    }
  }
`;

function WebhookDialog() {
  const { id } = useParams();
  const { toast } = useToast();
  const [supplierApprovalWebhook] = useMutation(MUTATION);
  const { loading, data } = useQuery(QUERY, { variables: { id } });

  if (loading) return null;

  const { qualification, webhook } = data.supplier;

  const parsedWebhook = JSON.stringify(webhook, null, 2);

  const onClick = async () => {
    await supplierApprovalWebhook({ variables: { id } });
    toast({ title: "Informações enviadas para integração" });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button type="button" variant="ghost" className="p-4">
          <Blocks className="mr-2 h-4 w-4" />
          Ver retorno da integração
        </Button>
      </DialogTrigger>

      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle asChild>
            <Heading>Integração</Heading>
          </DialogTitle>

          {qualification.state === APPROVED ? (
            <DialogDescription asChild>
              <Text size="small" color="secondary">
                Enviaremos automaticamente as informações do fornecedor para o sistema integrado, após ele ser aprovado.
                Caso isso não tenha acontecido ou deseja reenviar as informações, você pode{" "}
                <Anchor className="text-xs md:text-sm" onClick={onClick}>
                  enviar informações manualmente.
                </Anchor>
              </Text>
            </DialogDescription>
          ) : null}
        </DialogHeader>

        {parsedWebhook === "{}" ? (
          <Text size="small" weight="medium">
            Ainda não foram enviadas informações (webhook) para este fornecedor.
          </Text>
        ) : (
          <div className="flex max-h-96 flex-col gap-2 overflow-auto">
            <div className="flex flex-row items-center justify-between gap-4">
              <Text size="small" weight="medium">
                Resposta do webhook
              </Text>

              <Anchor
                className="flex min-w-fit flex-row items-center gap-2 px-4 py-2"
                onClick={async () => {
                  await navigator.clipboard.writeText(parsedWebhook);
                  toast({ title: "JSON copiado para área de transferência" });
                }}
              >
                <Copy size={20} /> Copiar JSON
              </Anchor>
            </div>

            <Text size="small" as="code" className="whitespace-break-spaces">
              {parsedWebhook}
            </Text>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default WebhookDialog;
