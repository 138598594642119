import React from "react";
import { Outlet } from "react-router-dom";

import Navigation from "Organisms/UBuyer/Settings/Shared/Navigation";

function SettingsContainer() {
  return (
    <div className="flex flex-col lg:flex-row min-w-full min-h-full">
      <Navigation />
      <div className="flex max-w-7xl grow flex-col pb-10 lg:ml-56">
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsContainer;
