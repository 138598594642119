const parseErrors = (errors) => {
  const parsedErrors = {};

  errors.forEach(({ path, message }) => {
    parsedErrors[path] = message;
  });

  return parsedErrors;
};

export { parseErrors }; // eslint-disable-line import/prefer-default-export
