import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { Input } from "DS/ui/input";

import { FormItem, FormLabel, FormControl, FormDescription, FormMessage } from "DS/ui/form";

const mimeTypes = [
  "image/jpg",
  ".jpg",
  "image/jpeg",
  ".jpeg",
  "image/png",
  ".png",
  "text/csv",
  ".csv",
  "application/pdf",
  ".pdf",
  "application/vnd.ms-excel",
  ".xlsx",
  ".xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".xlsx",
  ".xls",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".docx",
  "application/vnd.ms-outlook",
  ".msg",
  "message/rfc822",
  ".eml",
  "application/zip",
  ".zip",
  "application/x-zip-compressed",
  ".zip",
];

const DocumentBuyerDocumentField = forwardRef(({ name, onChange, formField }, ref) => (
  <FormItem>
    <FormLabel>
      {formField.displayName}
      {formField.optional ? " (opcional)" : ""}
    </FormLabel>
    <FormControl>
      <Input
        ref={ref}
        type="file"
        accept={mimeTypes.join(",")}
        placeholder="Preencha"
        name={name}
        onChange={({ target }) => onChange(target.files)}
      />
    </FormControl>
    <FormDescription>{formField.hint}</FormDescription>
    <FormMessage />
  </FormItem>
));

DocumentBuyerDocumentField.propTypes = {
  formField: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    hint: PropTypes.string,
    optional: PropTypes.bool,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DocumentBuyerDocumentField;
