import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Meter } from "grommet";
import { Heading } from "DS/custom/heading";
import Skeleton from "DS/custom/skeleton";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

import RatingPending from "Assets/suppliers/rating_pending.svg";

import { RATING_COLOR } from "Config/Supplier";

/** @internal */
export const GET = gql`
  query ratingMeter($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      rating
      ratingLabel
      processing
    }
  }
`;

function calculateNormalizedValue(value) {
  if (value >= 100) return 100;
  if (value > 80) return 80;
  if (value > 40) return 40;
  if (value > 0) return 20;

  return 2;
}

function RatingMeter() {
  const { id } = useParams();
  const { loading, data } = useQuery(GET, { variables: { id } });

  if (loading) return <Skeleton className="h-16 w-16" />;

  const { processing, ratingLabel, rating } = data.supplier;

  const level = ratingLabel.length === 2 ? 2 : 1;
  const value = rating < 5 ? 5 : rating;
  const color = RATING_COLOR(rating);

  if (processing)
    return (
      <WrappedTooltip message="Aguardando realização de consultas">
        <img src={RatingPending} alt="loading icon" />
      </WrappedTooltip>
    );

  return (
    <div className="w-16">
      <div className="relative rounded-full">
        <Meter
          type="circle"
          background="light-2"
          values={[{ value: calculateNormalizedValue(value), color }]}
          size="68px"
          thickness="8px"
        />
        <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform flex-row items-center">
          <Heading size="small" level={level}>
            {ratingLabel}
          </Heading>
        </div>
      </div>
    </div>
  );
}

export default RatingMeter;
