import React from "react";
import PropTypes from "prop-types";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "DS/ui/table";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";

import EmptyList from "Assets/settings/shared/EmptyList.png";

import { RISK } from "Config/DataAndDocuments";

import { FORM_FIELD_TYPE_OPTIONS } from "Config/BuyerDocument";

function FormsTable({ forms }) {
  if (forms.length === 0)
    return (
      <div className="flex flex-col items-center justify-center gap-4">
        <img alt="Empty List" src={EmptyList} className="w-32" />
        <Text>Nenhum documento encontrado...</Text>
      </div>
    );

  return (
    <div className="rounded-md border border-slate-200">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px]">
              <Text size="small" weight="medium">
                Nome
              </Text>
            </TableHead>

            <TableHead>
              <Text size="small" weight="medium">
                Tipo
              </Text>
            </TableHead>

            <TableHead className="text-left">
              <Text size="small" weight="medium">
                Pontuação
              </Text>
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {forms.map((form) => (
            <TableRow key={form.id}>
              <TableCell className="p-4">
                <Anchor to={form.id}>{form.displayName}</Anchor>
              </TableCell>

              <TableCell>
                <Text size="small">
                  {FORM_FIELD_TYPE_OPTIONS.find((option) => option.value === form.fieldType).label}
                </Text>
              </TableCell>

              <TableCell>
                <Text className={`${RISK[form.risk].color} min-w-fit text-left`} size="small" weight="medium">
                  {RISK[form.risk].label}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

FormsTable.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      updatedAt: PropTypes.string,
      displayName: PropTypes.string,
      settingGrouper: PropTypes.shape({
        displayName: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default FormsTable;
