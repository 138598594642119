import React from "react";
import PropTypes from "prop-types";

import { GripVertical } from "lucide-react";

import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

function FormSortableForm({ settingForm }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: settingForm.id });

  const style = { transition, transform: CSS.Transform.toString(transform) };

  return (
    <div {...attributes} ref={setNodeRef} style={style} {...listeners}>
      <div className="flex flex-row items-center gap-8 p-2 hover:bg-slate-50">
        <div className="p-2">
          <GripVertical strokeWidth={2.5} size={16} />
        </div>

        <div className="flex flex-row items-center gap-2 p-2 hover:cursor-pointer hover:bg-slate-50">
          <Text>{settingForm.displayName}</Text>

          <Badge variant="secondary">{settingForm.settingGrouper.displayName}</Badge>
        </div>
      </div>
    </div>
  );
}

FormSortableForm.propTypes = {
  settingForm: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    settingGrouper: PropTypes.shape({ displayName: PropTypes.string }),
  }).isRequired,
};

export default FormSortableForm;
