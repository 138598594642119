import { useCubeQuery } from "@cubejs-client/react";
import { useOutletContext } from "react-router-dom";

function useCubeInfo({ table, rating = null }) {
  const { identifier } = useOutletContext();

  const dimensions = [`${table}.info`];

  if (rating) dimensions.push(rating);

  const { resultSet } = useCubeQuery({
    dimensions,
    segments: [`${table}.valid_rows`],
    filters: [{ member: `${table}.cnpj`, operator: "equals", values: [identifier] }],
  });

  return { resultSet };
}

export default useCubeInfo;
