import React from "react";

import SupplierSearchContext from "Organisms/UBuyer/Companies/Index/SuppliersSearch/SupplierSearchContext";

import Search from "Organisms/UBuyer/Companies/Index/SuppliersSearch/Search";
import SearchBarAndFilter from "Organisms/UBuyer/Companies/Index/SuppliersSearch/SearchBarAndFilter";

function Suppliers() {
  return (
    <SupplierSearchContext>
      <div className="space-y-6">
        <SearchBarAndFilter />
        <Search />
      </div>
    </SupplierSearchContext>
  );
}

export default Suppliers;
