import React from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { Tags, CheckIcon } from "lucide-react";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { cn } from "DS/lib/utils";
import { Button } from "DS/ui/button";
import { Badge } from "DS/custom/badge";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "DS/ui/dropdown-menu";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "DS/ui/command";

/** @internal */
export const MUTATION = gql`
  mutation UpdateTags($id: ID!, $settingTagIds: [ID!]!) {
    updateTags(input: { supplierId: $id, settingTagIds: $settingTagIds }) {
      supplier {
        id
        tags {
          id
          displayName
        }
      }
    }
  }
`;

/** @internal */
export const QUERY = gql`
  query UpdateTags($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      tags {
        id
        displayName
        settingTagId
      }
      buyer {
        id
        settingTags {
          id
          displayName
        }
      }
    }
  }
`;

function UpdateTags() {
  const { id } = useParams();
  const { loading, data } = useQuery(QUERY, { variables: { id } });
  const [updateTags, { loading: submitting }] = useMutation(MUTATION);

  if (loading) return null;

  const { supplier } = data;
  const { buyer, tags } = supplier;
  const { settingTags } = buyer;

  if (settingTags.length === 0) return null;

  const settingTagIds = tags.map(({ settingTagId }) => settingTagId);

  return (
    <div className="flex flex-row flex-wrap items-center gap-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button type="button" variant="link" className="w-fit h-fit p-0">
            <Tags className="mr-2 h-4 w-4" /> Marcadores
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <Command>
            <CommandInput placeholder="Selecione o marcador" className="border-0 focus:ring-0" />

            <CommandList>
              <CommandEmpty>Nenhum marcador encontrado</CommandEmpty>

              <CommandGroup>
                {_.sortBy(settingTags, "displayName").map((settingTag) => {
                  const selectedSettingTagIds = new Set(settingTagIds);
                  const isSelected = selectedSettingTagIds.has(settingTag.id);

                  const onSelect = () => {
                    if (submitting) return;

                    if (isSelected) selectedSettingTagIds.delete(settingTag.id);
                    else selectedSettingTagIds.add(settingTag.id);

                    updateTags({ variables: { id, settingTagIds: [...selectedSettingTagIds.values()] } });
                  };

                  return (
                    <CommandItem key={settingTag.id} onSelect={onSelect}>
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                        )}
                      >
                        <CheckIcon className={cn("h-4 w-4")} />
                      </div>

                      <span>{settingTag.displayName}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </DropdownMenuContent>
      </DropdownMenu>

      {_.sortBy(tags, "displayName").map((tag) => (
        <Badge variant="neutral" key={tag.id}>
          {tag.displayName}
        </Badge>
      ))}
    </div>
  );
}

export default UpdateTags;
