import React from "react";

import { useParams } from "react-router-dom";

import PerformanceForm from "Organisms/UPublic/PerformanceForm/PerformanceForm";

function PerformanceFormPage() {
  const { token } = useParams();

  return <PerformanceForm token={token} />;
}

export default PerformanceFormPage;
