import React from "react";
import { useOutletContext } from "react-router-dom";

import { AlertCircle } from "lucide-react";

import { useCubeQuery } from "@cubejs-client/react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "DS/ui/accordion";

import { Text } from "DS/custom/text";
import { Badge } from "DS/custom/badge";

import { infoPillar, SKELETON_DETAILS } from "Config/PUF";
import formatCubeInfo from "Services/PUFFormatter";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";

/** @internal */
export const accordionName = (rating) => {
  if (rating === 5) return "Empresa de pequeno porte";

  return rating ? "Empresa de médio ou grande porte" : "Micro empresa ou empresário individual";
};

function FiscalCompanyScaleDetails() {
  const { identifier } = useOutletContext();

  const info = "FiscalCompanyScale.info";
  const member = "FiscalCompanyScale.cnpj";
  const rating = "FiscalCompanyScale.fiscal_item_company_scale";

  const { resultSet } = useCubeQuery({
    dimensions: [info, rating],
    filters: [{ member, operator: "equals", values: [identifier] }],
  });

  if (!resultSet) return SKELETON_DETAILS;

  const data = resultSet.rawData();
  const cube = data[0] || {};

  const { variant, text } = infoPillar(cube[rating]);

  return (
    <div>
      <Breadcrumb firstPage="Análise Linkana" lastPage="Porte" currentPage="Detalhes" />

      <div className="flex-start mt-4 flex flex-wrap content-start justify-start gap-8 py-4 lg:flex-nowrap">
        <div className="flex max-w-[349px] shrink-0 flex-col gap-2 self-start rounded-lg border border-slate-200 p-6">
          <div className="flex flex-row gap-4 ">
            <Text size="small" weight="medium">
              Porte
            </Text>

            <Badge variant={variant}>{text}</Badge>
          </div>

          <Text size="small">
            Examinando a significância do status fiscal como elemento decisivo na avaliação de sua solidez financeira.
          </Text>
        </div>

        <div className="flex-start flex w-full flex-col">
          {data.length ? (
            <Accordion collapsible defaultValue="info-0">
              <AccordionItem value="info-0">
                <AccordionTrigger>
                  <Text size="small" weight="medium">
                    {accordionName(cube[rating])}
                  </Text>
                </AccordionTrigger>

                <AccordionContent>
                  <Text size="small" weight="medium">
                    {formatCubeInfo(cube[info])}
                  </Text>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ) : (
            <div className="flex h-full flex-row items-center gap-2">
              <AlertCircle size={26} />

              <Text size="small">Não identificamos o porte da empresa com base no CNPJ informado.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FiscalCompanyScaleDetails;
