import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Skeleton from "DS/custom/skeleton";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { Card, CardDescription, CardHeader, CardTitle, CardContent } from "DS/custom/card";

import { formatCEP } from "Services/RFFormatter";

const QUERY = gql`
  query HeadquarterCard($id: ID!) {
    supplier: supplierById(id: $id) {
      id
      branch {
        id
        mf
        uf
        cep
        bairro
        numero
        logradouro
        descTipoLogradouro
        descricaoMunicipio
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6 p-4 lg:p-10 lg:pb-20">
    <Skeleton className="h-8 w-80" />
    <Card>
      <CardHeader>
        <CardTitle>
          <Skeleton className="mb-3 h-6 w-52" />
          <Skeleton className="mb-2 h-4 w-72" repeat={2} />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Skeleton className="h-96 w-full" />
      </CardContent>
    </Card>
  </div>
);

const renderMap = (branch) => {
  const address =
    `${branch.descTipoLogradouro} ${branch.logradouro}, ${branch.numero} - ` +
    `${branch.bairro} ${branch.descricaoMunicipio} - ${branch.uf} ${branch.cep}`;

  const geocoder = new google.maps.Geocoder();

  geocoder.geocode({ address }, (results, status) => {
    if (status !== "OK") return;

    const center = results[0].geometry.location;
    const params = { center, zoom: 18, mapTypeId: "satellite" };
    const map = new google.maps.Map(document.getElementById("map"), params);

    // eslint-disable-next-line no-new
    new google.maps.Marker({ position: results[0].geometry.location, map });
  });
};

function BranchLocation() {
  const { id } = useParams();

  const { data, loading } = useQuery(QUERY, {
    variables: { id },
    onCompleted: ({ supplier }) => renderMap(supplier.branch),
  });

  if (loading) return SKELETON;

  const { branch } = data.supplier;

  return (
    <div>
      <Breadcrumb levels={["Dados CNPJ", "Localização"]} />

      <Card className="mt-4">
        <CardHeader>
          <CardTitle>Endereço desta {branch.mf === "1" ? "Matriz" : "Filial"}</CardTitle>

          <CardDescription>
            {branch.descTipoLogradouro} {branch.logradouro}, {branch.numero} {branch.descricaoMunicipio}-{branch.uf} -{" "}
            {formatCEP(branch.cep)}
          </CardDescription>
        </CardHeader>

        <CardContent>
          <div id="map" className="h-96 w-full" />
        </CardContent>
      </Card>
    </div>
  );
}

export default BranchLocation;
