import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import NoMatch from "Pages/LandingPage/Static/NoMatch";

import SettingsContainer from "Organisms/UBuyer/Settings/Shared/SettingsContainer";

import Fields from "Organisms/UBuyer/Settings/Fields/Fields";
import UserInfo from "Organisms/UBuyer/Settings/Users/UserInfo";
import UsersList from "Organisms/UBuyer/Settings/Users/UsersList";
import Advanced from "Organisms/UBuyer/Settings/Advanced/Advanced";
import Workflows from "Organisms/UBuyer/Settings/Workflows/Workflows";
import Webhooks from "Organisms/UBuyer/Settings/Webhooks/Webhooks";
import Categories from "Organisms/UBuyer/Settings/Categories/Categories";
import PerformanceForms from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceForms";

function Index() {
  const { pathname } = useLocation();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="./../" replace />} />
      <Route path=":buyerId" element={<SettingsContainer key={pathname} />}>
        <Route path="users" element={<UsersList />} />
        <Route path="users/:userId" element={<UserInfo />} />

        <Route path="" element={<Navigate to="categories" replace />} />

        <Route path="categories/*" element={<Categories />} />

        <Route path="fields/*" element={<Fields />} />

        <Route path="performance_forms/*" element={<PerformanceForms />} />
        <Route path="workflows/*" element={<Workflows />} />
        <Route path="webhooks/*" element={<Webhooks />} />

        <Route path="advanced" element={<Advanced />} />

        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default Index;
