import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";
import { Button } from "DS/ui/button";
import Skeleton from "DS/custom/skeleton";

import createSafeRegExp from "Services/Search";
import SearchInput from "Organisms/UBuyer/Shared/SearchInput/SearchInput";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import EmptyList from "Assets/settings/shared/EmptyList.png";
import PerformanceFormsTable from "Organisms/UBuyer/Settings/PerformanceForms/PerformanceFormsTable";

/** @internal */
export const QUERY = gql`
  query PerformanceFormsList($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingPerformanceForms {
        id
        createdAt
        updatedAt
        displayName
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6">
    <Skeleton className="h-6 w-80" />
    <div className="flex flex-row flex-wrap justify-between gap-x-8 gap-y-4">
      <Skeleton className="h-[72px] w-[543px]" />
      <Skeleton className="h-10 w-60" />
    </div>
    <Skeleton className="h-6 w-96" repeat={6} />
  </div>
);

function PerformanceFormsList() {
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [term, setTerm] = useState("");
  const regExp = createSafeRegExp(term);

  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return SKELETON;

  const { settingPerformanceForms } = data.buyer;

  const filteredSettingPerformanceForms = settingPerformanceForms.filter(({ displayName }) =>
    regExp.test(_.deburr(displayName)),
  );

  return (
    <div>
      <div className="px-4 hidden lg:flex py-2 lg:py-6 lg:px-10 border-b">
        <Heading>Questionários</Heading>
      </div>
      <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
        <div className="flex flex-row flex-wrap justify-between gap-x-8 gap-y-4">
          <div className="flex max-w-[543px] flex-col gap-4">
            <Text weight="medium">Como funcionam os formulários de avaliação de performance</Text>

            <Text>
              É possível criar formulários de avaliação de desempenho em um processo de homologação. Para entender como
              configurar um novo formulário avaliação de performance,{" "}
              <Anchor
                target="_blank"
                className="text-sm md:text-base"
                href="https://suporte.linkana.com/pt-BR/articles/5760725-como-configurar-sua-avaliacao-de-performance-na-linkana"
              >
                utilize nosso guia
              </Anchor>
              .
            </Text>
          </div>

          <OwnerChecker>
            <Button type="button" onClick={() => navigate("new")}>
              Novo formulário de performance
            </Button>
          </OwnerChecker>
        </div>

        <div className="flex flex-col gap-4">
          <SearchInput
            value={term}
            placeholder="Procurar formulário de performance..."
            onChange={(value) => {
              setTerm(_.deburr(value));
            }}
          />

          {settingPerformanceForms.length ? (
            <PerformanceFormsTable performanceForms={filteredSettingPerformanceForms} />
          ) : (
            <div className="flex flex-col items-center justify-center gap-4">
              <img alt="Empty List" src={EmptyList} className="w-32" />
              <Text>Você não criou nenhum formulário de performance dentro da Linkana.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PerformanceFormsList;
