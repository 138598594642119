import React from "react";
import PropTypes from "prop-types";
import { useLocation, useParams, Link } from "react-router-dom";

import { Tabs, TabsList, TabsTrigger } from "DS/custom/tabs";

function SupplierTabs({ tabs }) {
  const { id } = useParams();
  const { pathname } = useLocation();
  const rootPath = `/companies/${id}`;
  const rootRoute = pathname.split("/")[3];

  return (
    <Tabs defaultValue={rootRoute} className="hidden lg:block">
      <TabsList>
        {tabs.map(({ title, path, turbo }) => (
          <Link to={turbo ? path : `${rootPath}/${path}`} reloadDocument={turbo} key={path}>
            <TabsTrigger value={path}>{title}</TabsTrigger>
          </Link>
        ))}
      </TabsList>
    </Tabs>
  );
}

SupplierTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    }),
  ).isRequired,
};

export default SupplierTabs;
