import { DirectUpload } from "@rails/activestorage";

const URL = "/rails/active_storage/direct_uploads";

class Uploader {
  constructor(file, cb) {
    this.upload = new DirectUpload(file, URL, this);
    this.cb = cb;
  }

  directUpload() {
    return new Promise((res, rej) => {
      this.upload.create((error, blob) => {
        if (error) {
          rej(error);
        } else {
          res(blob.signed_id);
        }
      });
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    this.cb(event);
  }
}

const uploadFile = async ({ file, setProgress }) => {
  if (setProgress) setProgress(1);

  const directUploader = new Uploader(file.data, (event) => {
    const progress = (event.loaded / event.total) * 100;

    if (setProgress) setProgress(progress);
  });

  return directUploader.directUpload();
};

const attachFile = async (files) => {
  if (!files || !files.length) return undefined;

  const data = files[0];
  const { name } = data;

  return uploadFile({ file: { data, name } }).then((blob) => blob);
};

export default attachFile;
