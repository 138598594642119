import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { Input } from "DS/ui/input";

import { FormItem, FormLabel, FormControl, FormDescription, FormMessage } from "DS/ui/form";

const TextInputBuyerDocumentField = forwardRef(({ name, value, onChange, formField }, ref) => (
  <FormItem>
    <FormLabel>
      {formField.displayName}
      {formField.optional ? " (opcional)" : ""}
    </FormLabel>
    <FormControl>
      <Input ref={ref} placeholder="Preencha" name={name} value={value} onChange={onChange} />
    </FormControl>
    <FormDescription>{formField.hint}</FormDescription>
    <FormMessage />
  </FormItem>
));

TextInputBuyerDocumentField.defaultProps = {
  value: undefined,
};

TextInputBuyerDocumentField.propTypes = {
  formField: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    hint: PropTypes.string,
    optional: PropTypes.bool,
  }).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextInputBuyerDocumentField;
