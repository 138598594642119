import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import { ArrowLeft } from "lucide-react";

import LogoSVG from "Assets/logo-brand.svg";
import BuyerLoginSVG from "Assets/buyer-login-min.svg";

import { Input } from "DS/ui/input";
import { Label } from "DS/ui/label";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Anchor } from "DS/custom/anchor";
import { Heading } from "DS/custom/heading";
import { useToast } from "DS/ui/use-toast";

import useResetPasswordRequest from "Organisms/Shared/ApolloHooks/useResetPasswordRequest";

function ForgotPassword() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { email } = state;

  const { setLayoutInfo } = useOutletContext();
  const [loginRequested, setLoginRequested] = useState(false);
  const [resetPasswordRequest, { loading }] = useResetPasswordRequest();

  useEffect(() => {
    setLayoutInfo({
      svg: BuyerLoginSVG,
      heading: "Recuperação de senha",
      text: `Digite seu e-mail e enviaremos novamente as instruções para você criar uma nova senha.`,
    });
  }, [setLayoutInfo]);

  const onClick = () => {
    resetPasswordRequest({ email });
    toast({ title: "Solicitação recebida com sucesso!" });
    setLoginRequested(true);
  };

  return (
    <>
      <img src={LogoSVG} alt="Linkana Logo" className="w-32" />

      <Anchor className="flex items-center gap-2" onClick={() => navigate(-1)}>
        <ArrowLeft size={16} className="text-blue-600" strokeWidth={2.5} />
        Voltar
      </Anchor>

      {loginRequested ? (
        <div className="flex flex-col gap-1">
          <Heading>Próximos passos para redefinir sua senha</Heading>

          <Text>
            Caso o e-mail <b>{email}</b> seja um usuário cadastrado na Linkana, você receberá um e-mail em sua caixa de
            entrada com as instruções para a redefinição de sua senha.
          </Text>

          <Text size="small" color="disabled">
            Este processo pode levar alguns minutos.
          </Text>
        </div>
      ) : (
        <>
          <Heading>Esqueceu sua senha?</Heading>

          <div className="flex w-full flex-col gap-1">
            <Label>E-mail</Label>
            <Input disabled value={email} />
          </div>

          <Button type="button" disabled={loading} onClick={onClick}>
            Enviar instruções
          </Button>
        </>
      )}
    </>
  );
}

export default ForgotPassword;
