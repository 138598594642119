import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import Skeleton from "DS/custom/skeleton";

import FieldsHeader from "Organisms/UBuyer/Settings/Fields/Shared/FieldsHeader";

import createSafeRegExp from "Services/Search";
import SearchInput from "Organisms/UBuyer/Shared/SearchInput/SearchInput";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import EmptyList from "Assets/settings/shared/EmptyList.png";
import FormsTable from "Organisms/UBuyer/Settings/Forms/FormsTable";

/** @internal */
export const QUERY = gql`
  query FormsList($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingForms {
        id
        updatedAt
        displayName
        settingGrouper {
          id
          displayName
        }
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6">
    <Skeleton className="h-6 w-80" />
    <div className="flex flex-row flex-wrap items-center justify-between gap-x-8 gap-y-4">
      <Skeleton className="h-12 w-[543px]" />
      <Skeleton className="h-10 w-80" />
    </div>
    <Skeleton className="h-10 w-full" />
    <Skeleton className="h-6 w-96" repeat={6} />
  </div>
);

function FormsList() {
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [term, setTerm] = useState("");
  const regExp = createSafeRegExp(term);
  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return SKELETON;

  const { settingForms } = data.buyer;

  const filteredSettingForms = settingForms.filter(({ displayName }) => regExp.test(_.deburr(displayName)));

  return (
    <div>
      <FieldsHeader />

      <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-10">
        <div className="flex flex-col lg:flex-row flex-nowrap justify-between gap-x-8 gap-y-4">
          <div className="flex-1">
            <Text size="small">Campos preenchidos pelo fornecedor na categoria de homologação.</Text>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col lg:flex-row flex-nowrap justify-between gap-x-8 gap-y-4">
            <div className="flex-1">
              <SearchInput
                value={term}
                placeholder="Encontre um documento..."
                onChange={(value) => {
                  setTerm(_.deburr(value));
                }}
              />
            </div>
            <OwnerChecker className="flex-1">
              <div className="flex flex-row flex-wrap justify-between lg:justify-end gap-4">
                <Button type="button" size="sm" onClick={() => navigate("new")}>
                  Criar documento
                </Button>

                <Button type="button" size="sm" variant="outline" onClick={() => navigate("reorder")}>
                  Alterar ordenamento
                </Button>
              </div>
            </OwnerChecker>
          </div>

          {settingForms.length ? (
            <FormsTable forms={filteredSettingForms} />
          ) : (
            <div className="flex flex-col items-center justify-center gap-4">
              <img alt="Empty List" src={EmptyList} className="w-32" />
              <Text>Nenhum documento criado.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormsList;
